import { isFeatureFlagOn } from "../../Utils";
import { featureFlags } from "../../Utils/checkFeatureFlag";

const days = ["Mon", "Tues", "Wed", "Thu", "Fri", "Sat", "Sun"];
const reportOptions = [
  { label: "9am daily", value: 1 },
  { label: "4pm daily", value: 2 },
  { label: "9am + 4pm daily", value: 3 },
  { label: "9am Monday + 9am Friday", value: 4 },
  { label: "Never", value: 9 },
];

const stopTicketOptions = [{ label: "1 hour before doors open", value: "period" }];

if (isFeatureFlagOn(featureFlags.LAST_ENTRY_STOP_MODE)) {
  stopTicketOptions.push({
    label: "At the event's last entry",
    value: "lastEntry",
  });
}
if (isFeatureFlagOn(featureFlags.EVENT_END_STOP_MODE)) {
  stopTicketOptions.push({ label: "At event's end time", value: "eventEnd" });
}

stopTicketOptions.push({ label: "On a specific date & time", value: "datetime" });

const startTicketOptions = [
  { label: "Now", value: "now", grid: { md: 4 } },
  { label: "On a specific date & time", value: "datetime", grid: { md: 8 } },
  {
    label: "Automatically when another ticket goes off sale",
    value: "chained",
    hidden: currentTicketData => !currentTicketData.length,
  },
];
const radioOptions = [
  { value: "no", label: "No", grid: { md: 6 } },
  { value: "yes", label: "Yes", grid: { md: 6 } },
];

export const TICKET_LIMIT = import.meta.env.TICKET_LIMIT || 500;

export { days, reportOptions, stopTicketOptions, startTicketOptions, radioOptions };
