import get from "lodash/get";
import { decodeHTML5 } from "entities";

const getResponse = async (response, structureData) => {
  let res;
  let meta;
  let links;
  if (get(response, "data.data.data")) {
    res = response.data.data.data;
  } else if (get(response, "data.data")) {
    res = response.data.data;
  } else if (get(response, "data")) {
    res = response.data;
  }

  if (get(response, "data.meta")) {
    ({ meta } = response.data);
  }

  if (get(response, "data.links")) {
    ({ links } = response.data);
  }

  if (structureData) {
    try {
      const newData = await structureData(res, meta);
      res = newData;
    } catch (e) {
      throw new Error(e);
    }
  }

  if (Array.isArray(res)) {
    res = res.map(r => {
      const toReturn = {};
      Object.keys(r).forEach(key => {
        if (typeof r[key] === "string") {
          toReturn[key] = decodeHTML5(r[key]);
          return;
        }

        toReturn[key] = r[key];
      });
      return toReturn;
    });
  } else if (typeof res === "object" && res !== null) {
    Object.keys(res).forEach(key => {
      if (typeof res[key] === "string") {
        res[key] = decodeHTML5(res[key]);
      }
    });
  }
  return { res, meta, links };
};

export default getResponse;
