export const DATE_FORMAT_API = "yyyy-MM-dd";
export const TIME_FORMAT_API = "HH:mm";
export const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";
export const ISO_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:00ZZ";
export const ISO_DATE_TIME_FORMAT_UTC = "yyyy-MM-dd'T'HH:mm:ss+00:00";
export const ISO_DATE_TIME_FORMAT_NO_TIMEZONE = "yyyy-MM-dd'T'HH:mm:ss";
export const ISO_DATE_TIME_FORMAT_WITH_MS = "yyyy-MM-dd'T'HH:mm:ss.SSSZZ";

export const TIME_FORMAT_USER = "h:mma";
export const TIME_FORMAT_USER_WITH_PADDING = "hh:mma";
export const TIME_FORMAT_USER_WITH_SECONDS = "h:mm:ssa";

export const CSV_DATE_FORMAT = "dd/MM/yyyy HH:mm";
export const FILENAME_DATE_TIME = "dd-MM-yyyy HH-mm";

export const DATE_PICKER_FORMAT = "dd/MM/yyyy";

export const DATE_TIME_USER = "dd/MM/yyyy HH:mm:ss";

export const DATE_FORMAT_USER = {
  month: { short: true },
};
export const SHORT_DAY_USER = {
  day: { short: true },
  month: { short: true },
};
export const SHORT_DATE_USER = {
  day: { short: true },
  month: { short: true },
};

export const formatOnlyTime = {
  time: { show: true },
  weekday: { show: false },
  day: { show: false },
  month: { show: false },
  year: { show: false },
};
