import React, { useContext } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { AppContext } from "../../Context";

const Modal = ({
  children = React.node,
  open = false,
  onClose,
  maxWidth = "sm",
  header = "",
  showCloseButton = true,
  closeButton = null,
  actions = React.node,
  overflow = "auto",
  contentProps = {},
  fullScreenOnMobile = true,
  ...rest
}) => {
  const { isMobile } = useContext(AppContext);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`${header}-dialog-title`}
      fullScreen={isMobile && fullScreenOnMobile}
      fullWidth
      maxWidth={maxWidth}
      aria-describedby="modal-description"
      {...rest}
    >
      <Grid container spacing={2}>
        <Grid size={10}>
          <DialogTitle id={`${header}-dialog-title`}>{header}</DialogTitle>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "start",
          }}
          size={2}
        >
          {showCloseButton &&
            (closeButton || (
              <IconButton aria-label="close" onClick={onClose} size="medium" disableRipple>
                <CloseIcon />
              </IconButton>
            ))}
        </Grid>
      </Grid>
      <DialogContent style={{ overflow }} {...contentProps}>
        <Box
          id="modal-description"
          data-testId={`${header}-dialog-description`}
          sx={{
            pt: 2,
          }}
        >
          {children}
        </Box>
      </DialogContent>
      {actions && <DialogActions data-testId={`${header}-dialog-actions`}>{actions}</DialogActions>}
    </Dialog>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  header: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  closeButton: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any),
  open: PropTypes.bool,
  maxWidth: PropTypes.string,
  showCloseButton: PropTypes.bool,
  overflow: PropTypes.string,
  contentProps: PropTypes.objectOf(PropTypes.any),
  fullScreenOnMobile: PropTypes.bool,
};

export default Modal;
