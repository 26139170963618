import React from "react";
import PropTypes from "prop-types";
import { Button, Grid2 as Grid, TextField } from "@mui/material";

const RegisterStepOne = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  qsEmail = null,
  token = null,
  isStepOneComplete,
  setStep,
}) => (
  <>
    <Grid size={12}>
      <TextField
        id="email"
        variant="outlined"
        label="Email address"
        fullWidth
        name="email"
        value={values.email}
        placeholder="Email address"
        error={Boolean(touched.email && errors.email)}
        onChange={handleChange}
        onBlur={handleBlur}
        type="email"
        autoFocus={!qsEmail}
        autoComplete="email"
        helperText={touched.email && errors.email}
        required
        disabled={token}
      />
    </Grid>
    <Grid size={12}>
      <TextField
        id="password"
        variant="outlined"
        label="Password"
        fullWidth
        name="password"
        value={values.password}
        placeholder="Password"
        error={Boolean(errors.password && touched.password)}
        onChange={handleChange}
        onBlur={handleBlur}
        type="password"
        autoComplete="new-password"
        helperText={touched.password && errors.password}
        autoFocus={qsEmail}
        required
      />
    </Grid>
    <Grid size={12}>
      <TextField
        id="passwordConfirm"
        variant="outlined"
        label="Confirm Password"
        fullWidth
        name="passwordConfirm"
        value={values.passwordConfirm}
        placeholder="Confirm password"
        error={Boolean(errors.passwordConfirm && touched.passwordConfirm)}
        onChange={handleChange}
        onBlur={handleBlur}
        type="password"
        autoComplete="new-password"
        helperText={touched.passwordConfirm && errors.passwordConfirm}
        required
      />
    </Grid>

    {token && <input type="hidden" name="token" value={token} disabled />}

    <Grid
      size={{
        xs: 12,
        md: 6,
      }}
    >
      <Button
        fullWidth
        variant="contained"
        color="primary"
        mb={2}
        mt={2}
        type="button"
        disabled={!isStepOneComplete}
        onClick={() => setStep(1)}
      >
        Next step
      </Button>
    </Grid>
  </>
);

RegisterStepOne.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  qsEmail: PropTypes.string,
  token: PropTypes.string,
  isStepOneComplete: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
};
export default RegisterStepOne;
