import React from "react";
import { styled, Grid2 as Grid, Box, IconButton as MuiIconButton } from "@mui/material";
import PropTypes from "prop-types";

import { Menu as MenuIcon } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { Logo as L } from "../../Reusable";
import Link from "../../Reusable/Link";

const Logo = styled(L)`
  width: 100px;
`;

const IconButton = styled(MuiIconButton)`
  color: ${({ theme: { palette } }) => palette.skiddle.white};
  padding: 0;
`;

const MobileHeaderContent = ({ onDrawerToggle, onSettingsDrawerToggle }) => (
  <Grid
    container
    size={12}
    sx={{
      alignItems: "center",
    }}
  >
    <Grid size={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle} size="large">
          <MenuIcon fontSize="large" />
        </IconButton>
      </Box>
    </Grid>
    <Grid size={8}>
      <Link to="/">
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo color="white" />
        </Box>
      </Link>
    </Grid>
    <Grid size={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="Open Settings"
          onClick={onSettingsDrawerToggle}
          size="large"
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      </Box>
    </Grid>
  </Grid>
);

MobileHeaderContent.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
  onSettingsDrawerToggle: PropTypes.func.isRequired,
};
export default MobileHeaderContent;
