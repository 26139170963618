import date from "./date";

const getIsInMaintenance = async (): Promise<boolean> => {
  if (window.location.hostname !== "localhost") {
    try {
      const maintenanceTxt = await fetch(
        `${window.location.protocol}//${window.location.host}/maintenance.txt?t=${date().toUnixInteger()}`,
      );
      const text = await maintenanceTxt.text();
      return text === "1";
    } catch {
      return false;
    }
  }

  return false;
};

export default getIsInMaintenance;
