import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { FormControl, Select, InputLabel, MenuItem, Typography, Box } from "@mui/material";

import { success, error } from "../../../Utils/toast";
import useAuth from "../../NamedComponents/Auth/hooks/useAuth";
import changePromoter from "../../../Utils/Auth/changePromoter";
import LoadingIcon from "../../Reusable/LoadingIcon";
import errorMessages from "../../../Utils/errorMessages";
import { setInnerLoading } from "../../../Store/reducers/AuthReducer";
import promoterActiveStatuses from "../../../Constants/promoterActiveStatuses";

const SwitchPromoter = ({ setOpen = () => null }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const promotersDropdown = Object.keys(auth.userData.promoters).reduce((result, item) => {
    const {
      details: { name, active },
    } = auth.userData.promoters[item];
    if (active !== promoterActiveStatuses.BANNED.id) {
      result.push({
        value: item,
        label: `${name} (${item})`,
        isDisabled: false,
        name,
      });
    }
    return result;
  }, []);
  const defaultValue = promotersDropdown.find(
    p => Number(p.value) === Number(auth.promoter.promoterId),
  ).value;

  const handleChange = async e => {
    setLoading(true);
    try {
      dispatch(setInnerLoading(true));
      navigate("/");
      await changePromoter(e.target.value, auth);
      setOpen(false);
      success(
        `Successfully switched to ${
          promotersDropdown.find(p => Number(p.value) === Number(e.target.value))?.label
        }`,
      );
      dispatch(setInnerLoading(false));
      navigate("/");
      setLoading(false);
    } catch {
      setLoading(false);
      error(errorMessages.switchAccounts);
    }
  };

  if (loading) {
    return (
      <Typography variant="body1">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mr: 3,
            }}
          >
            <LoadingIcon size="1.0625rem" />
          </Box>
          Switching accounts...
        </Box>
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      <FormControl
        variant="outlined"
        style={{ minWidth: "100%", maxWidth: "100%" }}
        data-testid="promoter-dropdown"
      >
        <InputLabel id="promoter-dropdown-label">Change promoter</InputLabel>
        <Select
          labelId="promoter-dropdown-label"
          id="promoter-dropdown"
          onChange={handleChange}
          label="Change promoter"
          value={defaultValue}
          fullWidth
        >
          {promotersDropdown.map(promoter => [
            <MenuItem
              data-name={promoter.name}
              value={promoter.value}
              disabled={promoter.value === defaultValue.value}
            >
              {promoter.label}
            </MenuItem>,
          ])}
        </Select>
      </FormControl>
    </Box>
  );
};

SwitchPromoter.propTypes = {
  setOpen: PropTypes.func,
};

export default SwitchPromoter;
