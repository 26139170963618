import React, { createContext } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { Endpoints } from "../../../Constants";
import useHttpRequest from "../../../Hooks/useHttpRequest/useHttpRequest";

import useAuth from "../Auth/hooks/useAuth";

export const SelfBillContext = createContext(null);

const Finance = ({ children }) => {
  const auth = useAuth();
  const { promoterId } = auth.promoter;
  const cognitoUUID = get(auth, "userData.username");
  const { data } = useHttpRequest({
    method: "GET",
    params: { id: promoterId, cognitoUUID },
    queryStringParams: { requiresSelfBillAgreement: 1 },
    endpoint: Endpoints.GET_PROMOTER_WITH_USERS,
    authenticated: true,
  });

  return (
    <SelfBillContext.Provider value={data?.requiresSelfBillAgreement}>
      {children}
    </SelfBillContext.Provider>
  );
};
Finance.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
export default Finance;
