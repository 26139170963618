import React from "react";
import { Helmet } from "react-helmet";

const StatusPage = () => {
  return (
    <Helmet>
      <script src="https://skiddlepromotioncentre.statuspage.io/embed/script.js" />
    </Helmet>
  );
};

export default StatusPage;
