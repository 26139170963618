import { LOGGED_IN_AS_PROMOTER_ID } from "../../Constants/auth";

const buildQueryString = (
  queryStringParams,
  state,
  authenticated,
  promoterId,
  cacheBust,
  fetchAll,
  method,
) => {
  const queryString = { ...queryStringParams, ...state?.queryStringParams };
  if (localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID) && authenticated) {
    queryString.promoterId = Number(promoterId) || localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID);
  }

  if (state?.timestamp && cacheBust && method === "GET") {
    queryString.cacheTimestamp = state?.timestamp;
  }

  // If fetching all, only fetch one result to try
  // and speed the request up, as we're only interested
  // in the meta here.
  if (fetchAll) {
    queryString["page[size]"] = 1;
    queryString["page[number]"] = 1;
  }

  if (!authenticated) {
    delete queryString.promoterId;
  }

  return queryString;
};

export default buildQueryString;
