import addOnTypes from "../events/addOnTypes";

export default [
  {
    value: addOnTypes.SESSION_ALLOCATED,
    label: "Per session",
  },
  {
    value: addOnTypes.DAY_ALLOCATED,
    label: "Per day",
  },
  {
    value: addOnTypes.SERIES_ALLOCATED,
    label: "Per entire series",
  },
];
