import * as Yup from "yup";
import { hasValueChanged } from "../../../../../Utils/formHelpers";
import { getCurrencySymbol } from "../../../../../Utils/numberFormat";
import lazy from "../../../../../Utils/lazy";

const TicketPrice = lazy(() => import("../../FieldComponents/TicketPrice"));

const ticketPrice = {
  name: "ticketPrice",
  displayName: () => "Price of ticket",
  defaultValue: null,
  type: TicketPrice,
  validation: (_, __, ___, ____, currency = "GBP") =>
    Yup.number()
      .transform(value => (Number.isNaN(value) ? undefined : value))
      .min(0.01, "A ticket that isn't free must have a price")
      .max(999999.99, `Ticket price can only be ${getCurrencySymbol(currency)}999,999.99`)
      .when(["freeTicketOption", "skipTickets"], {
        is: (free, skip) => free === "no" && skip === "no",
        then: schema => schema.required("Ticket price is required"),
        otherwise: schema => schema,
      })
      .when(["freeTicketOption"], {
        is: free => free === "yes",
        then: schema => schema.min(0),
      }),
  sendToApi: (value, formValues, initialValues, queryStringValues) => {
    // Always send price when relisting or creating
    if (queryStringValues?.relist === "true" || !queryStringValues?.ticketId) {
      return true;
    }

    // Never send price if tickets sold
    if (
      (formValues?.ticketsSold && Number(formValues.ticketsSold) > 0) ||
      (formValues?.masterSalesCount && Number(formValues.masterSalesCount) > 0)
    ) {
      return false;
    }

    // Now only send if value has changed
    return hasValueChanged(
      "ticketPrice",
      { ...formValues, ticketPrice: Number(formValues.ticketPrice) },
      initialValues,
    );
  },
  postFormat: (val, data) => {
    if (val === undefined || data.freeTicketOption === "yes") {
      return 0;
    }

    if (val) {
      return Number(val);
    }

    return 0;
  },
  grid: formValues => ({ md: formValues?.isAddon ? 12 : 6 }),
};

export default ticketPrice;
