import React, { useState } from "react";
import Helmet from "react-helmet";
import { useLocation, useNavigate } from "react-router";
import qs from "query-string";

import { spacing } from "@mui/system";
import {
  Alert,
  Box,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography as MuiTypography,
  Button as MuiButton,
  Link as MuiLink,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { Logo } from "../../../Reusable";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import ForgotPasswordCode from "./ForgotPasswordCode";
import useAuth from "../hooks/useAuth";
import Link from "../../../Reusable/Link";
import sanitiseEmail from "../../../../Utils/Auth/sanitiseEmail";

const Typography = styled(MuiTypography)(spacing);
const BackToLogin = styled(MuiButton)`
  position: absolute;
  top: 10px;
  left: 8px;

  .MuiButton-startIcon {
    margin-right: 0;
  }
`;

const RetryButton = styled(MuiButton)`
  display: inline;
  font-size: 0.8rem;
  vertical-align: initial;

  :hover {
    background: none;
  }
`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const qsValues = qs.parse(location.search);

  const defaultStep = qsValues?.step || 1;

  const [error, setError] = useState();
  const [step, setStep] = useState(defaultStep - 1);
  const [stepOneComplete, setStepOneComplete] = useState(false);
  const [emailError, setEmailError] = useState();
  const [codeError, setCodeError] = useState();
  const [email, setEmail] = useState("");

  if (auth.isAuthenticated) {
    navigate("/");
  }

  return (
    <>
      <BackToLogin
        color="primary"
        size="small"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => navigate("/")}
      >
        Back to login
      </BackToLogin>
      <Helmet title="Sign Up" />
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Logo width="50%" />
      </Box>
      {step === 0 && (
        <Typography component="h2" variant="body1" align="center">
          If you&apos;ve already registered but forgot your password, provide your email below and
          we&apos;ll send you a One-Time code to reset your password.
        </Typography>
      )}
      {step === 1 && (
        <>
          <Typography component="h2" variant="body1" align="center">
            If you have a valid account, we&apos;ve emailed you a One-Time code. Please enter it
            below, along with your new password.
          </Typography>
          <Typography variant="body2" align="center" mt={2}>
            Didn&apos;t get the email?
            <RetryButton
              color="secondary"
              component={MuiLink}
              onClick={() => {
                setError();
                setStep(0);
              }}
            >
              Click here to try again
            </RetryButton>
          </Typography>
        </>
      )}
      {error && (
        <Box
          sx={{
            mb: 3,
            mt: 3,
          }}
        >
          <Alert id="error" severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Box>
      )}
      <Stepper alternativeLabel nonLinear activeStep={step} sx={{ py: 6 }}>
        <Step completed={stepOneComplete}>
          <StepLabel error={emailError}>Send Email</StepLabel>
        </Step>
        <Step>
          <StepLabel error={codeError}>One Time Code</StepLabel>
        </Step>
      </Stepper>
      {step === 0 && (
        <ForgotPasswordEmail
          setError={setError}
          setStep={setStep}
          setStepOneComplete={setStepOneComplete}
          setEmailError={setEmailError}
          setEmail={setEmail}
        />
      )}
      {step === 1 && (
        <ForgotPasswordCode
          setError={setError}
          setStep={setStep}
          setCodeError={setCodeError}
          email={sanitiseEmail(qsValues?.email || "") || sanitiseEmail(email || "")}
          qsValues={qsValues}
        />
      )}
      {step === 2 && (
        <Box
          sx={{
            mb: 3,
            mt: 3,
          }}
        >
          <Alert id="success" severity="success">
            <Typography variant="body1">
              Password successfully changed. Redirecting you to login. If you aren&apos;t
              automatically taken there, click <Link to="/">here</Link>
            </Typography>
          </Alert>
        </Box>
      )}
    </>
  );
};

export default ForgotPassword;
