import lazy from "../../../../../Utils/lazy";

const TicketAdvancedOptions = lazy(() => import("../../FieldComponents/TicketAdvancedOptions"));

const advancedOptions = {
  name: "advancedOpts",
  sendToApi: () => false,
  type: TicketAdvancedOptions,
  hidden: () => false,
};

export default advancedOptions;
