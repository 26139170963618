import { CurlGenerator } from "curl-generator";

export default (request, includeJwt = true) => {
  if (!request || !Object.keys(request)?.length) {
    return "Invalid request";
  }

  const requestClone = { ...request };
  let { url } = requestClone;

  if (request?.queryStringParams) {
    Object.entries(request?.queryStringParams).forEach(([key, value], index) => {
      if (value) {
        if (index === 0) {
          url += `?${key}=${value}`;
        } else {
          url += `&${key}=${value}`;
        }
      }
    });
  }

  const headers = { "Content-type": "application/json", ...request?.headers };

  if (!Object.keys(requestClone.body).length) {
    delete requestClone.body;
  }

  if (!includeJwt && headers.Authorization) {
    delete headers.Authorization;
    delete headers["x-api-key"];
  }

  delete headers.Accept;
  return CurlGenerator({
    ...requestClone,
    url: `'${url}'`,
    headers,
  });
};
