import React from "react";
import { DELETE_EVENT } from "../../../Constants/events";
import date from "../../../Utils/date";
import permissions from "../../../Utils/permissions";
import { skiddle } from "../../../theme/variants";
import {
  eventsRecurringModalSettings,
  getDisabledTooltipText,
  isCancelled,
  isRecurringEvent,
  isSessionEvent,
  shouldShow,
} from "./helpers";
import { CHILD } from "../../../Constants/events/listingTypes";
import ConfirmDetachModal from "../../NamedComponents/EventsDashboard/EventsList/ConfirmDetachModal";
import cancellationTypes from "../../../Constants/events/cancellationTypes";
import { isReschedule } from "../../../Utils/eventHelpers";

const eventsPath = "/events/:id/edit?step=:step&stage=:stage";

const isFuture = event => {
  return event?.date ? date().isBefore(event.endDate) : true;
};

const showIfNoSales = (match, currentEvent) => {
  if (!shouldShow(match)) {
    return false;
  }

  return (
    currentEvent &&
    currentEvent.ticketsSoldCountCumulative === 0 &&
    currentEvent.masterListingSales === 0
  );
};

const manageEventsChildren = (id, match) => [
  {
    name: "Edit basic info",
    path: `/events/${id}/edit?step=1&stage=1`,
    route: eventsPath,
    activeOverride: params => Number(params.step) === 1 && Number(params.stage) !== 4,
    id: "editEvent1",
    isDisabled: event => (!isFuture(event) && !isRecurringEvent(event)) || isCancelled(event),
    disabledTooltipText: (event, hasPermission) => getDisabledTooltipText(event, hasPermission),
    show: shouldShow,
    permissions: permissions().CREATE_EVENT | permissions().UPDATE_EVENT,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(
        event,
        `/events/${event.masterListingInstanceId}/edit?step=1&stage=1`,
      ),
  },
  {
    name: "Edit dates & times",
    path: `/events/${id}/edit?step=1&stage=4`,
    route: eventsPath,
    activeOverride: params => Number(params.step) === 1 && Number(params.stage) === 4,
    id: "editEvent2",
    isDisabled: event => (!isFuture(event) && !isRecurringEvent(event)) || isCancelled(event),
    disabledTooltipText: (event, hasPermission) => getDisabledTooltipText(event, hasPermission),
    show: shouldShow,
    permissions: permissions().CREATE_EVENT | permissions().UPDATE_EVENT,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(
        event,
        `/events/${event.masterListingInstanceId}/edit?step=1&stage=4`,
      ),
  },
  {
    name: "Edit additional info",
    path: `/events/${id}/edit?step=2&stage=1`,
    route: eventsPath,
    activeOverride: params => Number(params.step) === 2,
    id: "editEvent3",
    isDisabled: event => (!isFuture(event) && !isRecurringEvent(event)) || isCancelled(event),
    disabledTooltipText: (event, hasPermission) => getDisabledTooltipText(event, hasPermission),
    show: shouldShow,
    permissions: permissions().CREATE_EVENT | permissions().UPDATE_EVENT,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(
        event,
        `/events/${event.masterListingInstanceId}/edit?step=2&stage=1`,
      ),
  },
  {
    name: "Edit images",
    path: `/events/${id}/edit?step=3&stage=1`,
    route: eventsPath,
    activeOverride: params => Number(params.step) === 3,
    id: "editEvent4",
    isDisabled: event => (!isFuture(event) && !isRecurringEvent(event)) || isCancelled(event),
    disabledTooltipText: (event, hasPermission) => getDisabledTooltipText(event, hasPermission),
    show: shouldShow,
    permissions: permissions().CREATE_EVENT | permissions().UPDATE_EVENT,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(
        event,
        `/events/${event.masterListingInstanceId}/edit?step=3&stage=1`,
      ),
  },
  {
    name: "Edit event visibility",
    path: `/events/${id}/edit?step=5&stage=1`,
    route: eventsPath,
    activeOverride: params => Number(params.step) === 5 && Number(params.stage) === 1,
    id: "editEvent5",
    isDisabled: event => (!isFuture(event) && !isRecurringEvent(event)) || isCancelled(event),
    disabledTooltipText: (event, hasPermission) => getDisabledTooltipText(event, hasPermission),
    show: shouldShow,
    permissions: permissions().CREATE_EVENT | permissions().UPDATE_EVENT,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(
        event,
        `/events/${event.masterListingInstanceId}/edit?step=5&stage=1`,
      ),
  },
  {
    name: "Copy event",
    path: `/events/${id}/relist?step=1&stage=1`,
    route: "/events/:id/relist?step=:step&stage=:stage",
    id: "copyEvent",
    show: (m, event) => shouldShow(m) && !isReschedule(true, event?.cancellation),
    permissions: permissions().CREATE_EVENT | permissions().UPDATE_EVENT,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(
        event,
        `/events/${event.masterListingInstanceId}/relist?step=1&stage=1`,
      ),
    disabledTooltipText: event => getDisabledTooltipText(event),
    isDisabled: event => isRecurringEvent(event) || isSessionEvent(event) || isCancelled(event),
  },
  {
    name: "Reschedule event",
    path: `/events/${id}/relist?step=1&stage=1`,
    route: "/events/:id/relist?step=:step&stage=:stage",
    id: "rescheduleEvent",
    show: (m, event) => shouldShow(m) && isReschedule(true, event?.cancellation),
    permissions: permissions().CREATE_EVENT | permissions().UPDATE_EVENT,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(
        event,
        `/events/${event.masterListingInstanceId}/relist?step=1&stage=1`,
      ),
    disabledTooltipText: event => getDisabledTooltipText(event),
    isDisabled: event => isRecurringEvent(event) || isSessionEvent(event),
  },
  {
    name: "Allocated seating",
    path: `/events/${id}/allocated-seating`,
    route: "/events/:id/allocated-seating",
    id: "allocatedSeating",
    show: (_m, currentEvent) =>
      (currentEvent && currentEvent.seatingPlanId) ||
      (currentEvent[0] && currentEvent[0].seatingPlanId),
    modal: event =>
      eventsRecurringModalSettings(event, `/events/${event.masterListingInstanceId}/waiting-list`),
    isDisabled: event => isCancelled(event),
    disabledTooltipText: (event, hasPermission) => getDisabledTooltipText(event, hasPermission),
  },
  {
    name: "Detach event",
    path: `/events/${id}/detach`,
    route: "/events/:id/detach",
    id: "detachEvent",
    show: (_m, event) =>
      isFuture(event) &&
      event.active !== DELETE_EVENT &&
      event.listingType === CHILD &&
      isRecurringEvent(event) &&
      !isSessionEvent(event),
    permissions: permissions().UPDATE_EVENT,
    hideIfNoPermissions: true,
    modalOverride: (event, children) => (
      <ConfirmDetachModal
        startDate={event.startDate}
        listingId={event.listingId}
        eventId={event.id}
        refreshOnSubmit
      >
        {children}
      </ConfirmDetachModal>
    ),
    isDisabled: event => isCancelled(event),
    disabledTooltipText: (event, hasPermission) => getDisabledTooltipText(event, hasPermission),
  },
  {
    name: "Cancel / Postpone",
    path: `/events/${id}/cancel`,
    route: "/events/:id/cancel",
    id: "cancelEvent",
    textStyle: { color: skiddle.palette.error.main },
    show: (_m, event) => isFuture(event) && event.active !== DELETE_EVENT && !isSessionEvent(event),
    permissions: permissions().DELETE_EVENT | permissions().UPDATE_EVENT,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(event, `/events/${event.masterListingInstanceId}/cancel`),
    isDisabled: event =>
      isCancelled(event) && event?.cancellation?.type !== cancellationTypes.POSTPONED,
    disabledTooltipText: (event, hasPermission) => getDisabledTooltipText(event, hasPermission),
  },
  {
    name: "Delete event",
    path: `/events/${id}/delete`,
    route: "/events/:id/delete",
    id: "deleteEvent",
    show: (_m, event) =>
      showIfNoSales(match, event) &&
      isFuture(event) &&
      event.active !== DELETE_EVENT &&
      !isRecurringEvent(event) &&
      !isSessionEvent(event),
    textStyle: { color: skiddle.palette.error.main },
    permissions: permissions().DELETE_EVENT,
    hideIfNoPermissions: false,
    isDisabled: event => isCancelled(event),
    disabledTooltipText: (event, hasPermission) => getDisabledTooltipText(event, hasPermission),
    modal: event =>
      eventsRecurringModalSettings(
        event,
        `/events/${event.masterListingInstanceId}/delete`,
        <>
          Clicking &apos;continue&apos; will redirect you to the Event Series, which can be deleted,
          however, this will also delete all{" "}
          {event?.recurringDates && event?.recurringDates.length > 1 && (
            <strong>{event.recurringDates.length} </strong>
          )}
          events in the series.
        </>,
      ),
  },
];

export default manageEventsChildren;
