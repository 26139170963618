import AuthLayout from "../../Components/Layout/AuthLayout";
import ErrorPage from "../../Components/Layout/ErrorPages/ErrorPage";
import ChangeEmailCodeConfirm from "../../Components/NamedComponents/Auth/Components/ChangeEmailCodeConfirm";
import ConfirmSignUp from "../../Components/NamedComponents/Auth/Components/ConfirmSignUp";
import ForgotPassword from "../../Components/NamedComponents/Auth/Components/ForgotPassword";
import Register from "../../Components/NamedComponents/Auth/Components/Register";
import SetNewPassword from "../../Components/NamedComponents/Auth/Components/SetNewPassword";
import Finance from "../../Components/NamedComponents/Finance";
import lazy from "../lazy";

const InviteSplashScreen = lazy(
  () =>
    import(
      /* webpackChunkName: "InviteSplashScreen" */ "../../Components/NamedComponents/Auth/Components/InviteSplashScreen"
    ),
);
const AccessDenied = lazy(
  () =>
    import(
      /* webpackChunkName: "AccessDenied" */ "../../Components/Layout/AccessDenied/AccessDenied"
    ),
);
const EventsDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "EventsDashboard" */ "../../Components/NamedComponents/EventsDashboard/EventsDashboard"
    ),
);
const LoginAsPromoter = lazy(
  () =>
    import(
      /* webpackChunkName: "LoginAsPromoter" */ "../../Components/NamedComponents/LoginAsPromoter/LoginAsPromoter"
    ),
);
const OrdersListWrapper = lazy(
  () =>
    import(
      /* webpackChunkName: "EventOrders" */ "../../Components/NamedComponents/Orders/EventOrders"
    ),
);
const Order = lazy(
  () =>
    import(/* webpackChunkName: "Order" */ "../../Components/NamedComponents/Orders/Single/Order"),
);
const TicketListDownloadContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "TicketListDownloadContainer" */ "../../Components/NamedComponents/TicketListDownload/TicketListDownloadContainer"
    ),
);
const ManageRapidscan = lazy(
  () =>
    import(
      /* webpackChunkName: "ManageRapidscan" */ "../../Components/NamedComponents/Manage/ManageRapidscan"
    ),
);
const RemindMe = lazy(
  () =>
    import(
      /* webpackChunkName: "RemindMe" */ "../../Components/NamedComponents/EventDashboard/RemindMe"
    ),
);
const DiscountCodes = lazy(
  () =>
    import(
      /* webpackChunkName: "PromotionsPage" */ "../../Components/NamedComponents/EventDashboard/Promotions/PromotionsPage"
    ),
);
const DiscountCodeForm = lazy(
  () =>
    import(
      /* webpackChunkName: "PromotionFormContainer" */ "../../Components/NamedComponents/EventDashboard/Promotions/PromotionForm/PromotionFormContainer"
    ),
);
const CoolOff = lazy(
  () =>
    import(
      /* webpackChunkName: "CoolOff" */ "../../Components/NamedComponents/EventDashboard/CoolOff/CoolOff"
    ),
);
const TicketDelivery = lazy(
  () =>
    import(
      /* webpackChunkName: "TicketDelivery" */ "../../Components/NamedComponents/EventDashboard/TicketDeliveryOptions/TicketDeliveryOptions"
    ),
);
const Resell = lazy(
  () =>
    import(
      /* webpackChunkName: "Resell" */ "../../Components/NamedComponents/EventDashboard/Resell/Resell"
    ),
);
const GuestlistManager = lazy(
  () =>
    import(
      /* webpackChunkName: "GuestlistManager" */ "../../Components/NamedComponents/EventDashboard/GuestlistManager/GuestlistManager"
    ),
);
const WaitingList = lazy(
  () =>
    import(
      /* webpackChunkName: "WaitingList" */ "../../Components/NamedComponents/EventDashboard/WaitingList/WaitingList"
    ),
);
const EventTracking = lazy(
  () =>
    import(
      /* webpackChunkName: "EventPixel" */ "../../Components/NamedComponents/EventDashboard/EventTracking"
    ),
);
const Reps = lazy(
  () =>
    import(
      /* webpackChunkName: "Reps" */ "../../Components/NamedComponents/EventDashboard/Reps/Reps"
    ),
);
const AllocatedSeating = lazy(
  () =>
    import(
      /* webpackChunkName: "AllocatedSeating" */ "../../Components/NamedComponents/EventDashboard/AllocatedSeating"
    ),
);
const DeleteEvent = lazy(
  () =>
    import(
      /* webpackChunkName: "DeleteEvent" */ "../../Components/NamedComponents/Events/DeleteEvent"
    ),
);

const SingleEventDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "EventDashboard" */ "../../Components/NamedComponents/EventDashboard/EventDashboard"
    ),
);
const CheckoutQuestionBuilder = lazy(
  () =>
    import(
      /* webpackChunkName: "CheckoutQuestionBuilder" */ "../../Components/NamedComponents/Events/CheckoutQuestionBuilder/CheckoutQuestionBuilder"
    ),
);

const EventFlow = lazy(
  () =>
    import(
      /* webpackChunkName: "EventFlowWrapper" */ "../../Components/NamedComponents/EventFlow/EventFlowWrapper"
    ),
);
const PaymentPlanBuilderContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "PaymentPlanBuilderContainer" */ "../../Components/NamedComponents/PaymentPlanBuilder/PaymentPlanBuilderContainer"
    ),
);
const ManageUsers = lazy(
  () =>
    import(
      /* webpackChunkName: "ManageUsers" */ "../../Components/NamedComponents/Manage/ManageUsers"
    ),
);
const ManageProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "ManageProfile" */ "../../Components/NamedComponents/Manage/ManageProfile"
    ),
);
const GlobalOrdersListWrapper = lazy(
  () =>
    import(
      /* webpackChunkName: "OrdersListWrapper" */ "../../Components/NamedComponents/Orders/OrdersListWrapper"
    ),
);
const BrandsList = lazy(
  () =>
    import(
      /* webpackChunkName: "BrandsList" */ "../../Components/NamedComponents/Brands/BrandsList"
    ),
);
const BrandPage = lazy(
  () =>
    import(/* webpackChunkName: "BrandPage" */ "../../Components/NamedComponents/Brands/BrandPage"),
);
const EmbedListingsWidget = lazy(
  () =>
    import(
      /* webpackChunkName: "EmbedListingsWidget" */ "../../Components/NamedComponents/Promote/EmbedListingsWidget/EmbedListingsWidget"
    ),
);
const TicketBox = lazy(
  () =>
    import(
      /* webpackChunkName: "TicketBox" */ "../../Components/NamedComponents/Promote/TicketBox/TicketBox"
    ),
);
const RapidScanEntry = lazy(
  () =>
    import(
      /* webpackChunkName: "RapidScanContainer" */ "../../Components/NamedComponents/Analytics/RapidScan/RapidScanContainer"
    ),
);
const TicketSalesReport = lazy(
  () =>
    import(
      /* webpackChunkName: "TicketSalesReportContainer" */ "../../Components/NamedComponents/Analytics/TicketSalesReport/TicketSalesReportContainer"
    ),
);
const BankDetailsFormContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "BankDetailsFormContainer" */ "../../Components/NamedComponents/Finance/BankDetails/BankDetailsForm/BankDetailsFormContainer"
    ),
);
const BankDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "BankDetails" */ "../../Components/NamedComponents/Finance/BankDetails/BankDetailsOverview/BankDetails"
    ),
);
const PaidRemittanceContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "PaidRemittanceContainer" */ "../../Components/NamedComponents/Finance/RemittanceReports/PaidRemittance/PaidRemittanceContainer"
    ),
);
const RemittanceBreakdownContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "RemittanceBreakdownContainer" */ "../../Components/NamedComponents/Finance/RemittanceReports/RemittanceBreakdown/RemittanceBreakdownContainer"
    ),
);
const RemittanceOverviewContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "RemittanceOverviewContainer" */ "../../Components/NamedComponents/Finance/RemittanceReports/RemittanceOverview/RemittanceOverviewContainer"
    ),
);
const UnpaidRemittanceContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "UnpaidRemittanceContainer" */ "../../Components/NamedComponents/Finance/RemittanceReports/UnpaidRemittance/UnpaidRemittanceContainer"
    ),
);
const SelfBill = lazy(
  () =>
    import(
      /* webpackChunkName: "SelfBill" */ "../../Components/NamedComponents/Finance/SelfBill/SelfBill"
    ),
);
const VATDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "VATDetails" */ "../../Components/NamedComponents/Finance/VATDetails/VATDetails"
    ),
);
const VATReportContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "VATReportContainer" */ "../../Components/NamedComponents/Finance/VATReport/VATReportContainer"
    ),
);
const SelfBillSuccess = lazy(
  () =>
    import(
      /* webpackChunkName: "SelfBillSuccess" */ "../../Components/NamedComponents/Finance/SelfBill/SelfBillSuccess"
    ),
);
const CancelEvent = lazy(
  () =>
    import(
      /* webpackChunkName: "CancelEvent" */ "../../Components/NamedComponents/Events/CancelEvent/CancelEventContainer"
    ),
);
const CreateBrandContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateBrandContainer" */ "../../Components/NamedComponents/Brands/CreateBrand/CreateBrandContainer"
    ),
);
const Login = lazy(
  () =>
    import(
      /* webpackChunkName: "Login" */ "../../Components/NamedComponents/Auth/Components/Login"
    ),
);

const CampaignStats = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignStats" */ "../../Components/NamedComponents/EventsDashboard/CustomersAndOrders/CampaignStats"
    ),
);

const TicketOrders = lazy(
  () =>
    import(
      /* webpackChunkName: "TicketOrders" */ "../../Components/NamedComponents/Orders/TicketOrdersOfflineSales"
    ),
);
const DirectPay = lazy(
  () =>
    import(
      /* webpackChunkName: "DirectPay" */ "../../Components/NamedComponents/Finance/DirectPay/DirectPay"
    ),
);
const MyArtists = lazy(
  () =>
    import(
      /* webpackChunkName: "MyArtists" */ "../../Components/NamedComponents/Promote/MyArtists/MyArtists"
    ),
);

const MyVenues = lazy(
  () =>
    import(
      /* webpackChunkName: "MyVenues" */ "../../Components/NamedComponents/Promote/MyVenues/MyVenues"
    ),
);
const Eflyers = lazy(
  () =>
    import(
      /* webpackChunkName: "Eflyers" */ "../../Components/NamedComponents/Promote/Eflyers/Eflyers"
    ),
);
const Videos = lazy(
  () =>
    import(
      /* webpackChunkName: "Videos" */ "../../Components/NamedComponents/Promote/Videos/Videos"
    ),
);
const AdditionalReports = lazy(
  () =>
    import(
      /* webpackChunkName: "AdditionalReports" */ "../../Components/NamedComponents/Analytics/AdditionalReports/AdditionalReports"
    ),
);

export {
  InviteSplashScreen,
  AccessDenied,
  EventsDashboard,
  LoginAsPromoter,
  OrdersListWrapper,
  Order,
  TicketListDownloadContainer,
  ManageRapidscan,
  RemindMe,
  DiscountCodes,
  DiscountCodeForm,
  Resell,
  GuestlistManager,
  WaitingList,
  EventTracking,
  Reps,
  AllocatedSeating,
  DeleteEvent,
  SingleEventDashboard,
  CheckoutQuestionBuilder,
  EventFlow,
  PaymentPlanBuilderContainer,
  ManageUsers,
  ManageProfile,
  GlobalOrdersListWrapper,
  BrandsList,
  BrandPage,
  EmbedListingsWidget,
  TicketBox,
  RapidScanEntry,
  TicketSalesReport,
  BankDetailsFormContainer,
  BankDetails,
  PaidRemittanceContainer,
  RemittanceBreakdownContainer,
  RemittanceOverviewContainer,
  UnpaidRemittanceContainer,
  SelfBill,
  VATDetails,
  VATReportContainer,
  SelfBillSuccess,
  AuthLayout,
  ErrorPage,
  ChangeEmailCodeConfirm,
  ConfirmSignUp,
  ForgotPassword,
  Login,
  Register,
  SetNewPassword,
  Finance,
  CancelEvent,
  CreateBrandContainer,
  CampaignStats,
  TicketOrders,
  DirectPay,
  MyArtists,
  MyVenues,
  Eflyers,
  Videos,
  AdditionalReports,
  CoolOff,
  TicketDelivery,
};
