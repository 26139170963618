const DEFAULT_LOCALE = "en-GB";

export const formatCurrency = (number, currency = "GBP", opts = {}) => {
  const userLocale = localStorage.getItem("USER_LOCALE") || DEFAULT_LOCALE;
  const removeCommas = number.toString().replace(/,/g, "");
  return new Intl.NumberFormat(userLocale || "en-GB", {
    style: "currency",
    currency,
    ...opts,
  }).format(Number(removeCommas));
};

export const formatNumber = number => {
  const userLocale = localStorage.getItem("USER_LOCALE") || DEFAULT_LOCALE;
  const removeCommas = number.toString().replace(/,/g, "");
  return new Intl.NumberFormat(userLocale).format(removeCommas);
};

export const simpleStats = (value, curr = false) => {
  const userLocale = localStorage.getItem("USER_LOCALE") || DEFAULT_LOCALE;
  const opts = {
    notation: "compact",
    compactDisplay: "short",
    minimumFractionDigits: 2,
  };

  if (curr) {
    opts.style = "currency";
    opts.currency = curr.toUpperCase() || "GBP";
  }

  return new Intl.NumberFormat(userLocale || "en-GB", opts).format(value);
};

export const SIMPLE_ROUNDING_CUTOFF = 1000000;

export const formatStats = (number, curr = false) => {
  const formatted = Number(number.toString().replace(/,/g, "")).toFixed(0);

  if (Number(formatted) >= SIMPLE_ROUNDING_CUTOFF) {
    return simpleStats(formatted, curr);
  }

  return `${curr ? formatCurrency(number, curr) : formatNumber(number)}`;
};

export const getCurrencySymbol = currency => {
  const userLocale = localStorage.getItem("USER_LOCALE") || DEFAULT_LOCALE;
  return (0)
    .toLocaleString(userLocale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
};
