import React from "react";
import PropTypes from "prop-types";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconWithText from "../IconWithText";

const RefreshButton = ({ refreshFn, loading = false, disabled, title = "refresh" }) => {
  return (
    <IconWithText
      icon={<RefreshIcon />}
      loading={loading}
      onClick={() => refreshFn && refreshFn()}
      title={title}
      disabled={loading || disabled}
    />
  );
};

RefreshButton.propTypes = {
  refreshFn: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

export default React.memo(RefreshButton);
