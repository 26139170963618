import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";

import { spacing } from "@mui/system";
import { Drawer as MuiDrawer, Paper as MuiPaper, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import Header from "./Header/Header";
import useAuth from "../NamedComponents/Auth/hooks/useAuth";
import promoterActiveStatuses from "../../Constants/promoterActiveStatuses";
import useHeaderHeight from "../../Hooks/useHeaderHeight";
import { AppContext } from "../../Context";
import ErrorBoundary from "./ErrorBoundary";
import LoadingIcon from "../Reusable/LoadingIcon";
import useSidebarWidth from "../../Hooks/useSidebarWidth";
import theme from "../../theme";
import { closedDrawerWidth, drawerTransitionTime, drawerWidth } from "../../Constants/layout";
import BannedAccount from "./BannedAccount";
import onRouteChange from "../../Utils/onRouteChange";
import MaintenanceSplash from "../Reusable/MaintenanceSplash";
import warningToast, {
  cancelClick,
  defaultCountdown,
  updateWarning,
} from "../Reusable/warningToast";

const Root = styled("div")`
  display: flex;
  height: 100%;
  min-height: 100vh;
`;

const Drawer = styled(MuiDrawer)`
  ${props => props.theme.breakpoints.up("md")} {
    transition: ${`width ${drawerTransitionTime}`};
    width: ${({ width }) => `${width}`};
    flex-shrink: 0;
  }
  .MuiPaper-root {
    overflow-x: hidden;
  }
`;

const AppContent = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const MainContent = styled(MuiPaper)`
  ${spacing};
  max-width: ${({ $maxWidth }) => $maxWidth} !important;
  flex: 1;
  margin-left: ${({ $drawerWidth, $isMdDown }) => ($isMdDown ? 0 : $drawerWidth)};
  transition: ${({ $drawerTransitionTime }) =>
    `margin-left ${$drawerTransitionTime}, maxWidth ${drawerTransitionTime}`};
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: ${props => props.theme.body.background};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
  padding-top: ${({ $headerHeight }) => `${$headerHeight + 10}px`};
`;

const Layout = () => {
  const [inMaintenance, setInMaintenance] = useState(false);
  const {
    appMaxWidth: { width },
    isFullscreen,
    sidebarOpen,
    setSidebarOpen,
    appVersion,
  } = useContext(AppContext);

  const currentDrawerWidth = useSidebarWidth();
  const headerHeight = useHeaderHeight();

  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [mobileOpenSettings, setMobileOpenSettings] = useState(false);

  const { pathname, search } = useLocation();
  const fullPath = `${pathname}${search}`;

  const [countdown, setCountdown] = useState(defaultCountdown);

  const {
    loading,
    promoter: { details },
    innerLoading,
    isAuthenticated,
  } = useAuth();

  useEffect(() => {
    let interval = null;

    async function onLoad() {
      const { hasNewVersion, isInMaintenance } = await onRouteChange(appVersion);
      setInMaintenance(isInMaintenance);

      // Version is state is out of sync with version in version.txt
      if (hasNewVersion) {
        const toast = warningToast(countdown, () => cancelClick(interval, setCountdown));

        interval = setInterval(() => {
          setCountdown(prevState => {
            if (prevState - 100 >= 0) {
              // Update the toast content so the countdown is dynamic
              updateWarning(toast.toastId, toast, prevState, () =>
                cancelClick(interval, setCountdown),
              );

              return prevState - 100;
            }

            // If the user hasn't cancelled the operation, reload the page
            window.location.reload();
            return prevState;
          });
        }, 100);
      }
    }

    onLoad();

    return () => clearInterval(interval);
  }, [fullPath]);

  if (inMaintenance) {
    return <MaintenanceSplash />;
  }

  if (loading || !isAuthenticated) {
    return null;
  }

  const handleDrawerToggle = () => {
    if (isMdDown) {
      setSidebarOpen(prev => !prev);
    }
    setMobileOpenSettings(false);
  };

  const handleSettingsDrawerToggle = () => {
    setMobileOpenSettings(!mobileOpenSettings);
  };

  if (details?.active === promoterActiveStatuses.BANNED.id && pathname !== "/auth/logout") {
    return <BannedAccount />;
  }

  return (
    <Root>
      <AppContent>
        {!window.location.pathname.includes("ticket-list-print") && (
          <ErrorBoundary code="HD">
            <Header
              onDrawerToggle={handleDrawerToggle}
              onSettingsDrawerToggle={handleSettingsDrawerToggle}
              mobileOpenSettings={mobileOpenSettings}
            />
          </ErrorBoundary>
        )}
        {!window.location.pathname.includes("ticket-list-print") && (
          <ErrorBoundary code="SB">
            <Drawer
              sx={{ "flex-shrink": 0, "overflow-y": "hidden" }}
              width={0}
              onClose={handleDrawerToggle}
              open={sidebarOpen}
              variant={isMdDown ? "temporary" : "permanent"}
              id="side-bar"
            >
              <Sidebar open={sidebarOpen} onClose={handleDrawerToggle} anchor="left" />
            </Drawer>
          </ErrorBoundary>
        )}
        <MainContent
          p={isLgUp ? 4 : 2}
          id="mainContent"
          data-testId="mainContent"
          $headerHeight={headerHeight}
          $maxWidth={
            isFullscreen
              ? "100%"
              : `calc(${width}px + ${
                  sidebarOpen ? drawerWidth : drawerWidth * 2 - closedDrawerWidth
                }px)`
          }
          $drawerWidth={currentDrawerWidth}
          $drawerTransitionTime={drawerTransitionTime}
          $isMdDown={isMdDown}
        >
          {innerLoading ? (
            <LoadingIcon />
          ) : (
            <ErrorBoundary code="Main">
              <Outlet />
            </ErrorBoundary>
          )}
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Layout;
