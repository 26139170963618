import React from "react";
import NewVersionWarning from "./NewVersionWarning";
import { dismissToast, updateToast, warning } from "../../Utils/toast";

export const defaultCountdown = 10000;
export const cancelClick = (
  interval: NodeJS.Timeout,
  setCountdown: (_newCount: number) => void,
) => {
  dismissToast();
  clearInterval(interval);
  setCountdown(defaultCountdown);
};

const warningToast = (countdown: number, onCancel: () => void) =>
  warning(<NewVersionWarning countdown={countdown} onClick={onCancel} />, {
    autoClose: false,
    progress: 1,
  });

export const updateWarning = (
  toastId: string,
  toast: object,
  prevState: number,
  onCancel: () => void,
) =>
  updateToast(
    toastId,
    { ...toast, progress: prevState / 10000 },
    <NewVersionWarning countdown={prevState - 100} onClick={onCancel} />,
  );

export default warningToast;
