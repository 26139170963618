/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const networkSlice = createSlice({
  name: "network",
  initialState: {
    network: {},
  },
  reducers: {
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
  },
});

export const { setNetwork } = networkSlice.actions;

export default networkSlice.reducer;
