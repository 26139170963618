import { isRecurringEvent, isSessionEvent } from "../../../Layout/Navigation/helpers";
import {
  bookingFeeMode,
  bookingFees,
  showTicketsRemaining,
  showPendingOnsale,
  waitingListEligible,
  availability,
  passwordDisplay,
  eTicketInfo,
  eTicketTerms,
  setUpTicketReports,
  ticketCountEmail,
  ticketCountPeriod,
  revealTicketId,
  minPerTransaction,
  maxPerTransaction,
} from "../fields/stepFour";

export const ticketAdvancedOptions = [
  {
    name: "Fees",
    fields: [bookingFeeMode, bookingFees],
    hide: (form, qsValues) => {
      if (
        (form.values.freeTicketOption === "yes" ||
          form.values.ticketsSold > 0 ||
          form.values.masterSalesCount > 0 ||
          form.values.customFeesApplied === 1) &&
        (!qsValues.relist || qsValues?.relist !== "true")
      ) {
        return true;
      }

      return (
        qsValues.ticketId &&
        Number(form.values.ticketPrice) === Number(form.initialValues.ticketPrice) &&
        qsValues?.relist !== "true"
      );
    },
  },
  {
    name: "Ticket visibility",
    fields: [
      showTicketsRemaining,
      showPendingOnsale,
      waitingListEligible,
      availability,
      passwordDisplay,
    ],
    hide: () => false,
  },
  {
    name: "Optional RapidScan customisation",
    fields: [eTicketInfo, eTicketTerms],
    hide: () => false,
  },
  {
    name: "Ticket counts & reports",
    fields: [setUpTicketReports, ticketCountEmail, ticketCountPeriod],
    hide: () => false,
  },
  {
    name: "Ticket dependency",
    fields: [revealTicketId],
    hide: (_, __, globalForm) =>
      isRecurringEvent(globalForm.values) || isSessionEvent(globalForm.values),
  },
  {
    name: "Minimum & maximum purchase amount",
    fields: [minPerTransaction, maxPerTransaction],
    hide: () => false,
  },
];

export const allAdvancedOptionTicketInputs = ticketAdvancedOptions.reduce(
  (acc, advancedOption) => [...acc, ...advancedOption.fields],
  [],
);

export default ticketAdvancedOptions;
