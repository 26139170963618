import * as Yup from "yup";

const maxPerTransaction = {
  name: "maxPerTransaction",
  label: "Maximum tickets per order",
  displayName: () => "Max per order",
  defaultValue: "8",
  inputType: "number",
  validation: () =>
    Yup.number()
      .min(
        Yup.ref("minPerTransaction"),
        "Maximum tickets per order must be more than or equal to the minimum tickets per order",
      )
      .max(99, "Maximum tickets per order must be no more than 99"),
  sendToApi: () => true,
  postFormat: val => (val ? Number(val) : 8),
  advancedOption: true,
  grid: {
    md: 6,
  },
};
export default maxPerTransaction;
