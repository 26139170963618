import * as Yup from "yup";

import date from "../../../../../Utils/date";
import { SHORT_DAY_USER } from "../../../../../Constants/luxonDateFormats";
import { postFormatDateTime } from "../../../../../Utils/ticketHelpers";
import lazy from "../../../../../Utils/lazy";

const InputDateTime = lazy(
  () => import(/* webpackChunkName: "InputDateTime"  */ "../../../../Reusable/Atoms/InputDateTime"),
);

const onSaleDateTime = {
  name: "onSaleDateTime",
  hidden: formValues => formValues.startMode !== "datetime",
  displayName: () => "On sale date & time",
  sendToApi: (val, values) => values.startMode === "datetime",
  advancedOption: false,
  type: InputDateTime,
  defaultValue: { date: "", time: "", value: "" },
  postFormat: val => postFormatDateTime(val),
  validation: globalForm => {
    return Yup.object()
      .when("startMode", {
        is: "datetime",
        then: schema => {
          return schema.shape({
            date: Yup.object().required("Date is required").nullable(),
            time: Yup.string().required("Time is required").nullable(),
            value: Yup.string()
              .test(
                "embargo-after-event-date-test",
                `Please choose a date & time before your event start time of ${date(
                  globalForm.values.startDateTime.value,
                ).formatUserDate({ ...SHORT_DAY_USER, time: { show: true, separator: "," } })}`,
                value => date(value).isSameOrBefore(date(globalForm.values.startDateTime.value)),
              )
              .test("after now", "Date must be in the future", value =>
                date(value).isAfter(date()),
              ),
          });
        },
        otherwise: schema =>
          schema.shape({
            date: Yup.object().nullable(),
            time: Yup.string().nullable(),
            value: Yup.string(),
          }),
      })
      .nullable();
  },
  group: "Start selling tickets",
};

export default onSaleDateTime;
