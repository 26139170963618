import * as Yup from "yup";
import lazy from "../../../../../Utils/lazy";

const RepCommissionPerTicket = lazy(() => import("../../FieldComponents/RepCommissionPerTicket"));

const repCommissionPerTicket = {
  name: "repCommissionFixed",
  displayName: () => "Commission per ticket sold",
  defaultValue: null,
  type: RepCommissionPerTicket,
  validation: () =>
    Yup.number()
      .transform(value => (Number.isNaN(value) ? undefined : value))
      .min(0.01, "Must be greater than 0")
      .when("ticketPrice", ([price], schema) => {
        return price > 0
          ? schema.max(price, "Commission must be no greater than ticket price")
          : schema.max(1000000, "Commission can only be 1,000,000");
      })
      .nullable(),
  sendToApi: (value, formValues) =>
    formValues?.repCommissionType !== "none" && formValues.freeTicketOption === "no",
  postFormat: (val, data) => {
    if (val === undefined || data.freeTicketOption === "yes") {
      return 0;
    }

    if (val) {
      return Number(val);
    }

    return 0;
  },
  hidden: formValues => formValues.repCommissionType === "none",
  grid: {
    md: 6,
  },
};

export default repCommissionPerTicket;
