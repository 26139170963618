import React from "react";
import { Box, Typography } from "@mui/material";
import { CHILD, PARENT, SINGLE } from "../../../Constants/events/listingTypes";
import { DEFAULT_MESSAGE } from "../../Reusable/Restricted/Restricted";
import { isFeatureFlagOn } from "../../../Utils";
import { featureFlags } from "../../../Utils/checkFeatureFlag";

export const shouldShow = match => {
  if (!match || (match && match.params["*"] === "")) {
    return false;
  }

  const path = `${match.pathname}`;

  const allowedRoutes = [
    `/events/${match.params["*"]}`,
    `/events/${match.params["*"]}/relist`,
    `/events/${match.params["*"]}/edit`,
    `/events/${match.params["*"]}/waiting-list`,
    `/events/${match.params["*"]}/resale`,
    `/events/${match.params["*"]}/remind-me`,
    `/events/${match.params["*"]}/discount-codes`,
    `/events/${match.params["*"]}/tracking`,
    `/events/${match.params["*"]}/allocated-seating`,
    `/events/${match.params["*"]}/ticket-box`,
    `/events/${match.params["*"]}/payment-plan`,
  ].filter(r => r !== "/events/create");

  return allowedRoutes.indexOf(path) !== -1;
};

export const childEventTooltipMessage = "You cannot perform this action on an Event Series";
export const sessionEventTooltipMessage =
  "You cannot currently perform this action on a session based event";
export const isRecurringEvent = event =>
  event.listingType === CHILD || event.listingType === PARENT;
export const isSessionEvent = event => event?.sessionBased;
export const isCancelled = event => event?.cancellation?.cancelled === 1;

export const allowPaymentPlans = event =>
  isFeatureFlagOn(featureFlags.PAYMENT_PLAN_BUILDER) &&
  event.listingType === SINGLE &&
  !isSessionEvent(event);

export const eventsRecurringModalSettings = (event, url, content = null) => ({
  header: (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      Changes can only be made on the Event Series
    </Box>
  ),
  content: (
    <Typography variant="body1">
      {content || (
        <>
          Clicking &apos;continue&apos; will redirect you to the Event Series, all changes made here
          will be applied to all{" "}
          {event?.recurringDates && event?.recurringDates.length > 1 && (
            <strong>{event.recurringDates.length} </strong>
          )}
          events in the series.
        </>
      )}
    </Typography>
  ),
  actions: {
    primary: {
      label: "Continue to Event Series",
      url,
    },
    secondary: { label: "Cancel" },
  },
  shouldShow: event.listingType === CHILD,
});

export const getDisabledTooltipText = (event, hasPermission = true) => {
  if (!hasPermission) {
    return DEFAULT_MESSAGE;
  }
  if (isRecurringEvent(event)) {
    return childEventTooltipMessage;
  }
  if (isSessionEvent(event)) {
    return sessionEventTooltipMessage;
  }
  if (isCancelled(event)) {
    return `You cannot perform this action as the event has been ${event?.cancellation.type}.`;
  }
  return DEFAULT_MESSAGE;
};
