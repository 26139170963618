import lazy from "../../../../../Utils/lazy";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);

const setUpTicketReports = {
  name: "setUpTicketReports",
  placeholder: "Do you want to receive ticket report emails?",
  displayName: () => "Do you want to receive ticket report emails?",
  defaultValue: "yes",
  type: InputRadio,
  options: [
    { value: "no", label: "No", grid: { md: 6 } },
    { value: "yes", label: "Yes", grid: { md: 6 } },
  ],
  sendToApi: () => false,
  advancedOption: true,
};

export default setUpTicketReports;
