import addOnType from "./addOnType";
import affectsSessionCapacity from "./affectsSessionCapacity";
import customFeesApplied from "./customFeesApplied";
import freeTicketOption from "./freeTicketOption";
import masterSalesCount from "./masterSalesCount";
import restrictPurchase from "./restrictPurchase";
import ticketPrice from "./ticketPrice";
import ticketsAvailable from "./ticketsAvailable";
import ticketsLeft from "./ticketsLeft";
import ticketsSold from "./ticketsSold";

const entryCosts = {
  isGroup: true,
  label: "Entry costs",
  fields: [
    freeTicketOption,
    restrictPurchase,
    ticketPrice,
    ticketsAvailable,
    addOnType,
    affectsSessionCapacity,
    ticketsSold,
    masterSalesCount,
    ticketsLeft,
    customFeesApplied,
  ],
  hidden: (formValues, context) =>
    formValues.skipTickets === "yes" && !context.currentTicketData.length,
  showDivider: true,
};

export default entryCosts;
