export const MODERATION_ERROR = "moderation";
export const UPLOAD_ERROR = "upload";
export const API_ERROR = "api";
export const DROP_ERROR = "drop";
export const UNKNOWN_ERROR = "unknown";
export const MODERATION_WARNING = "moderation_warning";
export const MAX_RESOLUTION = 5000;

export const MAX_SIZE = 31457280;
export const MIN_SIZE = 5000;
export const MIN_SIZE_READABLE = "30mb";
export const MAX_SIZE_READABLE = "5kb";
export const MIN_RESOLUTION_WIDTH = 800;
export const MIN_RESOLUTION_HEIGHT = 800;
