import { hasValueChanged } from "../../../../../Utils/formHelpers";
import lazy from "../../../../../Utils/lazy";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);

const bookingFeeMode = {
  name: "bookingFeeMode",
  displayName: () => "Customer Pays Fees",
  sendToApi: (value, formValues, initialValues, queryStringValues) => {
    // Always send when relisting or creating
    if (queryStringValues?.relist === "true" || !queryStringValues?.ticketId) {
      return true;
    }

    // Never send if tickets sold
    if (
      (formValues?.ticketsSold && Number(formValues.ticketsSold) > 0) ||
      (formValues?.masterSalesCount && Number(formValues.masterSalesCount) > 0)
    ) {
      return false;
    }

    // Now only send if value has changed
    return hasValueChanged("bookingFeeMode", formValues, initialValues);
  },
  defaultValue: "c",
  type: InputRadio,
  advancedOption: true,
  postFormat: val => (val === "customer" || val === "c" ? "c" : "p"),
  options: [
    { label: "Customer pays fees", value: "c" },
    { label: "I pay fees", value: "p" },
  ],
};

export default bookingFeeMode;
