/* eslint-disable import/no-import-module-exports */
import React from "react";
import get from "lodash/get";
import { captureException, withScope } from "@sentry/react";

import { Box, Typography } from "@mui/material";

import { error, warning } from "./toast";
import errorMessages from "./errorMessages";
import { LOGGED_IN_AS_PROMOTER_ID } from "../Constants/auth";
import Store from "../Store";
import { removePromoter, updateCurrentPromoter } from "../Store/reducers/AuthReducer";
import ButtonAsLink from "../Components/Reusable/ButtonAsLink";
import { TIMEOUT } from "../Constants/requests";
import generateCurl from "./generateCurl";
import getUserSession from "./Auth/getUserSession";

export const handle401 = (auth, navigate = null) => {
  const currentPromoter = get(auth, "promoter.details.name") || null;
  const mainPromoterId = get(auth, "userData.mainPromoterId") || null;

  if (localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID)) {
    error(`Your login as '${currentPromoter}' has expired`);
    const newPromoter = {
      ...auth.userData.promoters[mainPromoterId],
      promoterId: mainPromoterId,
    };
    Store.dispatch(updateCurrentPromoter(newPromoter));

    Store.dispatch(removePromoter(localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID)));

    localStorage.removeItem(LOGGED_IN_AS_PROMOTER_ID);

    if (navigate) {
      navigate("/");
    }
  }

  navigate("/error", {
    state: { httpCode: 401, showLogoutButton: true, previousPage: window.location.pathname },
  });
};

const getErrorMessage = (
  requestId,
  status,
  message,
  errData,
  showRawErrorMessage,
  navigate = null,
  messageOverride = null,
) => {
  const store = Store.getState();

  const mainPromoterId = store.auth.isAuthenticated
    ? get(store, "auth.userData.mainPromoterId")
    : null;
  const isSkiddleStaff = store.auth.isAuthenticated
    ? Boolean(get(store, `auth.userData.promoters[${mainPromoterId}].skiddleStaff`))
    : false;

  // Prepare the error message
  let errorMessage = errorMessages.default;

  // Check if an error message exists for the http status code of Nxx, e.g 3xx
  // would be a generic error for all 3xx http status codes
  if (errorMessages[`error${status.toString().slice(0, 1)}xx`]) {
    errorMessage = errorMessages[`error${status.toString().slice(0, 1)}xx`];
  }

  // Now check if there's an error message for this specific http code, e.g 422
  if (errorMessages[`error${status}`] || errorMessages[`error${status.toString().slice(0, 3)}`]) {
    errorMessage =
      errorMessages[`error${status}`] || errorMessages[`error${status.toString().slice(0, 3)}`];
  }

  // Also prepare the raw error message
  let rawErrMessage = "";

  if (message) {
    rawErrMessage = message;
  }

  if (errData?.detail) {
    rawErrMessage = errData?.detail;
  }

  if (errData?.data?.detail) {
    rawErrMessage = errData?.data?.detail;
  }

  if (messageOverride) {
    errorMessage = messageOverride;
    rawErrMessage = messageOverride;
  }

  // Then return the error message, based on showRawErrorMessage
  return {
    htmlError: (
      <Box display="flex" flexDirection="column">
        <Typography gutterBottom variant="body2">
          {(showRawErrorMessage && showRawErrorMessage(status)) || status.toString() === "422"
            ? rawErrMessage
            : errorMessage}
        </Typography>
        <Typography gutterBottom variant="caption">
          {status === 999 ? "Code:" : "Error code:"} {status === 999 ? "NETWORK_ERR" : status}
          {errData?.source?.pointer?.includes("/events") && "_EVENTS"}
        </Typography>
        {isSkiddleStaff && navigate && (
          <Typography gutterBottom variant="caption">
            <ButtonAsLink
              onClick={() =>
                navigate(
                  `${window.location.pathname}${
                    window.location.search
                      ? `${window.location.search}&admin=1&tab=Network&requestId=${requestId}`
                      : `?admin=1&tab=Network&requestId=${requestId}`
                  }`,
                )
              }
            >
              Open in admin panel
            </ButtonAsLink>
          </Typography>
        )}
      </Box>
    ),
    errorMessage,
    rawErrMessage,
  };
};

const { VITE_API_URL: apiUrl } = import.meta.env;

/**
 * @function handleError - Handle API errors.
 * Different statuses will lead to different outcomes.
 *
 * 3xx - Generic "something went wrong error"
 * 400 - Generic "something went wrong error"
 *
 * 401/403 will both perform redirects back to the homepage
 * as we're assuming they're trying to access something they're
 * not supposed to, therefore they will both ignore the options
 * passed.
 *
 * @param {*} e - The error event from Axios
 * @param {Object} opts
 * @param {bool} opts.showRawErrorMessage - Whether or not to show the error message
 * directly from the API, useful for validation errors etc.
 * @param {bool} opts.showGlobalError - Whether or not to show a toast error, if false
 * we assume the component which made the request will handle the error in some other way,
 * in this case we just return the error object (unless 401/403, see above)
 */
export const handleError = async (e, opts, navigate = null) => {
  const { showRawErrorMessage, showGlobalError, redirectOn403, requestId, authenticated } = opts;
  const timeout = opts?.timeout || TIMEOUT;
  let status = Number(e?.response?.status) || 1;
  const { message } = e;
  const errData = get(e, "response.data", null);

  // If it's an authed request, attempt to refresh the session
  if (authenticated) {
    // This will log the user out if the session is expired or invalid
    // meaning it doesn't get to the Sentry logging or throwing the
    // toast. This should stop any uncessary network errors on Sentry
    // await getUserSession();
  }

  // Handle the pesky network errors
  if (e?.code === "ERR_NETWORK") {
    status = 999;
    withScope(scope => {
      scope.setLevel("warning");
      scope.setContext("request", { curl: generateCurl(opts?.request, false) });
      scope.setTags({ httpStatus: "NETWORK_ERR" });
      scope.setFingerprint(["NETWORK_ERROR"]);
      captureException(
        new Error("NETWORK_ERROR", {
          name: "NETWORK_ERROR",
          cause: e,
          message: "A network error occured when making a request",
        }),
      );
    });

    const url = opts?.request?.url || "";

    if (url.includes(apiUrl)) {
      warning(
        getErrorMessage(requestId, status, message, errData, showRawErrorMessage, navigate)
          .htmlError,
      );
    }
    return null;
  }

  const { getState } = Store;
  const { auth } = getState();

  const returnDefault = {
    ...e,
    message: errorMessages.default,
    status,
    source: errData?.source || {},
    ...getErrorMessage(requestId, status, message, errData, navigate, showRawErrorMessage),
    errData,
  };

  // Handle request timeout, always just throw a toast for this one
  if (message === `timeout of ${timeout}ms exceeded`) {
    error(
      getErrorMessage(requestId, Number(`408${timeout}`), errorMessages.default).htmlError,
      message,
      errData,
      showRawErrorMessage,
      navigate,
    );
    withScope(scope => {
      scope.setLevel("warning");
      scope.setTags({ httpStatus: 408 });
      scope.setContext("request", { curl: generateCurl(opts?.request, false) });
      scope.setFingerprint(["timeout exceeded", "Your request timed out"]);
      captureException(
        new Error(
          getErrorMessage(
            requestId,
            Number(`408${timeout}`),
            errorMessages.default,
            navigate,
          ).errorMessage,
        ),
      );
    });
    return { ...returnDefault, status: Number(`408${timeout}`) };
  }
  // Next handle 401 specifically, as it has some custom logic
  // If we get a 401 it usually implies something is wrong with the JWT
  if (status === 401) {
    handle401(auth, navigate);
    return { ...returnDefault, message: message || errorMessages.default };
  }
  if (status === 403) {
    if (redirectOn403 && navigate) {
      navigate("/access-denied", {
        state: { error: "API_403", attemptedPage: window.location.pathname },
      });
    }
    const errMessage = showRawErrorMessage
      ? getErrorMessage(requestId, status, message, errData, showRawErrorMessage, navigate)
          .htmlError
      : errorMessages.default;
    if (showGlobalError) {
      error(
        getErrorMessage(requestId, status, message, errData, showRawErrorMessage, navigate)
          .htmlError,
      );
    }
    return { ...returnDefault, message: errMessage };
  }
  if (status === 1) {
    withScope(scope => {
      const tags = {
        httpStatus: status,
      };

      if (e?.url) {
        tags.endpoint = e.url;
      }

      scope.setTags({ httpStatus: status });
      scope.setContext("request", { curl: generateCurl(opts?.request, false) });
      captureException(
        e ||
          new Error(
            "Unknown error status 1 (JavaScript error, not HTTP. Usually this means there is an error in a structureData function on a request.)",
          ),
      );
    });
  }
  if (status.toString().slice(0, 1) !== "4" && status !== 1 && status !== 999) {
    withScope(scope => {
      scope.setTags({ httpStatus: status });
      scope.setContext("request", { curl: generateCurl(opts?.request, false) });
      captureException(
        new Error(
          `${errorMessages[`error${status.toString().slice(0, 1)}xx`]}` ||
            "Unknown error in handleApiErrors.js",
        ),
      );
    });
  }

  // If the request doesn't want the toast to show (i.e. wants to handle error state itself)
  // then just return the error object with the appropriate error message
  if (!showGlobalError) {
    const errMessage = showRawErrorMessage
      ? getErrorMessage(requestId, status, message, errData, showRawErrorMessage, navigate)
          .htmlError
      : errorMessages.default;
    return { ...returnDefault, message: errMessage };
  }

  if (status !== 0) {
    // If they do want to show the error globally, throw a toast here
    // and return the error object anyway
    error(
      getErrorMessage(requestId, status, message, errData, showRawErrorMessage, navigate).htmlError,
    );
  }

  return {
    ...returnDefault,
    message:
      getErrorMessage(requestId, status, message, errData, showRawErrorMessage, navigate)
        .errorMessage || errorMessages.default,
  };
};
