import Resizer from "react-image-file-resizer";
import "react-toastify/dist/ReactToastify.min.css";
import { error } from "./toast";
import { MAX_RESOLUTION, MAX_SIZE, MIN_SIZE } from "../Constants/images";
import { FILE_TYPES } from "../Constants/files/fileTypes";

export const maxSize = 31457280;
export const minSize = 5000;
export const MIN_SIZE_READABLE = "30mb";
export const MAX_SIZE_READABLE = "5kb";
export const MIN_RESOLUTION_WIDTH = 800;
export const MIN_RESOLUTION_HEIGHT = 800;

export const acceptedFileTypes = [
  "bmp",
  "cod",
  "gif",
  "ief",
  "jpeg",
  "peg",
  "jpg",
  "fif",
  "svg",
  "tif",
  "iff",
  "ras",
  "cmx",
  "ico",
  "pnm",
  "pbm",
  "pgm",
  "ppm",
  "rgb",
  "xbm",
  "xpm",
  "xwd",
  "png",
];

export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", err => reject(err));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

// function saved for future if we want to implement rotation to the cropper
// function getRadianAngle(degreeValue) {
//   return (degreeValue * Math.PI) / 180;
// }

export const resizeFile = (file, width, height) =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "JPEG",
      100,
      0,
      uri => {
        resolve(uri);
      },
      "file",
    );
  });

export const calculateAspectRatio = (width, height) => {
  if (width === height) {
    return { width: MAX_RESOLUTION, height: MAX_RESOLUTION };
  }

  let aspectRatio = width / height;
  let newWidth;
  let newHeight;
  if (width > height) {
    newWidth = MAX_RESOLUTION;
    newHeight = newWidth / aspectRatio;
    return { width: newWidth, height: newHeight };
  }

  aspectRatio = height / width;
  newHeight = MAX_RESOLUTION;
  newWidth = newHeight / aspectRatio;
  return { width: newWidth, height: newHeight };
};

/**
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export async function getCroppedImg(imageSrc, crop, fileName) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }
      const newBlob = new Blob([blob], { type: blob.type });
      newBlob.name = fileName;
      window.URL.revokeObjectURL(newBlob);
      const croppedImageUrl = window.URL.createObjectURL(newBlob);
      const croppedImageBase64 = canvas.toDataURL();
      resolve([croppedImageUrl, croppedImageBase64]);
    }, "image/jpeg");
  });
}

export const isFileTypeAccepted = (fileName = "") =>
  acceptedFileTypes.includes(fileName.split(".").pop());

export const isFileSizeValid = (size = 0) => size <= MAX_SIZE && size >= MIN_SIZE;

export const setOnHoverDropzoneText = bool =>
  bool ? "Drop file here" : "Upload a file from your device";

export const displayErrors = (progressFailReason, files = false) => {
  if (!files || !files.length) {
    error(`${progressFailReason}`);
  } else {
    files.forEach(file =>
      error(
        `File "${file.path}" could not be uploaded for the following reasons: ${
          file.failedReasons && file.failedReasons.map(reason => `${reason}. `).join("")
        }`,
      ),
    );
  }
};

export function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const getS3BucketName = type => {
  switch (type) {
    case FILE_TYPES.EVENT_IMAGE:
      return "skiddlecdn-images-listings-uploads";
    case FILE_TYPES.FLYER_FRONT_IMAGE:
      return "skiddlecdn-images-listings-flyer-uploads";
    case FILE_TYPES.ARTIST_IMAGE:
      return "skiddlecdn-images-artists-uploads";
    case FILE_TYPES.BRAND_ICON_IMAGE:
    case FILE_TYPES.BRAND_BANNER_IMAGE:
      return "skiddlecdn-images-brands-uploads";
    case FILE_TYPES.GUESTLIST_CSV:
      return "pcv2-guestlist-csv-uploads";
    default:
      return "skiddlecdn-images-listings-uploads";
  }
};

async function isWebPSupported() {
  return new Promise(resolve => {
    const webpData =
      "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = webpData;
  });
}

export async function isWebPFileSupported(file) {
  if (!file.name.endsWith(".webp")) {
    return true;
  }

  return isWebPSupported();
}
