import React from "react";
import Help from "../../../../Reusable/Help";
import helpTips from "../../../../../Constants/helpTips";
import { isAddon } from "../../../../../Utils/ticketHelpers";
import addOnTypes from "../../../../../Constants/events/addOnTypes";
import addonOptions from "../../../../../Constants/tickets/addonOptions";
import lazy from "../../../../../Utils/lazy";

const AddOnType = lazy(
  () => import(/* webpackChunkName: "AddOnType"  */ "../../FieldComponents/AddOnType"),
);

const addOnType = {
  name: "addOnType",
  displayName: () => (
    <>
      Allocation type: <Help tip={helpTips.addOnType} />
    </>
  ),
  grid: {
    md: 6,
  },
  type: AddOnType,
  defaultValue: (event, __, qsValues, ticket) =>
    isAddon(qsValues, ticket)
      ? addonOptions.find(o => o.value === addOnTypes.SESSION_ALLOCATED)
      : "",
  hidden: formValues => !formValues?.isAddon,
  sendToApi: (_, formValues, ___, qsValues) => isAddon(qsValues, formValues),
  postFormat: val => val?.value || val,
};

export default addOnType;
