import lazy from "../../../../../Utils/lazy";
import { isAddon } from "../../../../../Utils/ticketHelpers";

const StartMode = lazy(() => import("../../FieldComponents/StartMode"));

const startMode = {
  name: "startMode",
  sendToApi: () => true,
  advancedOption: false,
  type: StartMode,
  defaultValue: "now",
  group: "Start selling tickets",
  hidden: (formValues, { qsValues }) => isAddon(qsValues, formValues),
};

export default startMode;
