import { createTheme } from "@mui/material/styles";
import { alpha } from "@mui/system";

import { skiddle } from "./variants";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import props from "./props";
import shadows from "./shadows";

declare module "@mui/material/styles" {
  interface Theme {
    containerWidth: string;
  }
  interface ThemeOptions {
    containerWidth?: string;
  }
}

const theme = createTheme(
  {
    spacing: 4,
    breakpoints,
    overrides,
    props,
    typography,
    // @ts-expect-error limitation of MUI theme
    shadows,
    body: skiddle.body,
    header: skiddle.header,
    palette: skiddle.palette,
    sidebar: skiddle.sidebar,
    containerWidth: "682px",
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            "&[disabled]": {
              color: "white",
              pointerEvents: "none",
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: "100%",
          },
          body: {
            height: "100%",
            background: "#F7F9FC",
          },
          MuiCardHeader: {
            action: {
              padding: "4px",
              width: "28px",
              height: "28px",
            },
          },
          MuiIconButton: {
            root: {
              padding: "4px",
              width: "28px",
              height: "28px",
            },
          },
        },
      },
    },
  },
  skiddle.name,
);

export default createTheme(theme, {
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none !important",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "grey" },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300]),
            backgroundColor: theme.palette.grey[300],
            "&:hover": {
              backgroundColor: theme.palette.grey[400],
            },
          },
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: {
            color: theme.palette.text.primary,
            borderColor: "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${theme.palette.action.disabledBackground}`,
            },
            "&:hover": {
              borderColor: "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
            },
          },
        },
        {
          props: { color: "grey", variant: "text" },
          style: {
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
            },
          },
        },
      ],
    },
  },
});
