import { getCurrentUser, fetchAuthSession, AuthUser } from "aws-amplify/auth";
import getUserData from "./getUserData";
import { Session } from "./types/Session.type";

async function fetchSession(options?: Parameters<typeof fetchAuthSession>[0]): Promise<Session> {
  const authSession = await fetchAuthSession(options);

  if (!authSession.tokens?.idToken?.payload) {
    throw new Error("Failed to fetch a valid auth session");
  }

  const payload = authSession.tokens.idToken.payload as any;

  if (
    typeof payload.userdata !== "string" ||
    typeof payload.promoters !== "string" ||
    typeof payload.exp !== "number"
  ) {
    // Changed to check for number
    throw new Error("Auth session is missing required custom fields or they are of incorrect type");
  }

  return {
    ...authSession,
    tokens: {
      ...authSession.tokens,
      idToken: {
        ...authSession.tokens.idToken,
        payload: {
          ...payload,
          exp: payload.exp, // Keeping as number
        },
      },
    },
  } as Session;
}

const getUserSession = async (promoterId = null) => {
  const user: AuthUser = await getCurrentUser();
  // Grab current session
  const session = await fetchSession({ forceRefresh: true });

  return getUserData(user, session, promoterId);
};

export default getUserSession;
