import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router";
import get from "lodash/get";

import useAuth from "../hooks/useAuth";
import { checkUserPermission, decodeUserRoles, isOwner } from "../../../../Utils/permissions";
import isFeatureFlagOn from "../../../../Utils/checkFeatureFlag";
import BannedAccount from "../../../Layout/BannedAccount";
import promoterActiveStatuses from "../../../../Constants/promoterActiveStatuses";
import SecurityLockedAccount from "../../../Layout/SecurityLockedAccount";
import useAuthMainPromoter from "../hooks/useAuthMainPromoter";
import date from "../../../../Utils/date";
import PendingAccount from "../../../Layout/PendingAccount";
import { AppContext } from "../../../../Context";

const ProtectedRoute = ({
  children,
  routePermissions = null,
  featureFlag = null,
  ownerOnly = false,
}) => {
  const { BANNED, PENDING, SECURITY_TIMEOUT } = promoterActiveStatuses;

  const { isLoggedIn } = useContext(AppContext);

  const auth = useAuth();
  const mainPromoter = useAuthMainPromoter();
  const location = useLocation();
  const {
    isAuthenticated,
    userData,
    loading,
    promoter: { permissions, details, roles },
  } = auth;

  const active = details?.active;

  const attemptedPage = `${location.pathname}${location.search}`;
  const onLoginPage = location.pathname === "/auth/login";

  if (loading || isLoggedIn === undefined) {
    return null;
  }

  if (!isAuthenticated && !onLoginPage) {
    return <Navigate to="/auth/login" state={{ attemptedPage }} />;
  }

  if (active === BANNED.id && !mainPromoter?.skiddleStaff) {
    return <BannedAccount />;
  }
  if (active === SECURITY_TIMEOUT.id && !mainPromoter?.skiddleStaff) {
    return <SecurityLockedAccount />;
  }

  // If an account is pending but you're not the owner
  // you won't be able to access the email to get the code
  // so no point sending them to that page
  if (active === PENDING.id && !isOwner(auth)) {
    return <PendingAccount />;
  }

  // Send to email confirm screen if they're pending AND they're the owner
  if (!get(userData, "promoter.emailVerified") || (active === PENDING.id && isOwner(auth))) {
    return <Navigate to="/auth/change-email-confirm" />;
  }
  if (
    get(userData, "invites") &&
    isOwner(auth) &&
    userData?.invites.some(({ expiryDateTime }) => date(expiryDateTime).isSameOrAfter(date()))
  ) {
    return <Navigate to="/invites-splash" />;
  }

  let hasPermission = false;
  if (!routePermissions) {
    hasPermission = true;
  }

  if (routePermissions || routePermissions === 0) {
    hasPermission = checkUserPermission(permissions, routePermissions);
  }

  if (ownerOnly && !isOwner(auth)) {
    hasPermission = false;
  }

  if (!hasPermission || (featureFlag && !isFeatureFlagOn(featureFlag))) {
    const permissionName = decodeUserRoles(routePermissions);
    const error = `${permissionName[0]?.label.replace(/\s/g, "_").toUpperCase()}_${roles}`;

    return <Navigate to="/access-denied" state={{ attemptedPage, error }} />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  routePermissions: PropTypes.number,
  featureFlag: PropTypes.string,
  ownerOnly: PropTypes.bool,
};
export default ProtectedRoute;
