/* eslint-disable array-callback-return */
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import set from "lodash/set";

import { DATE_FORMAT_API, ISO_DATE_TIME_FORMAT } from "../Constants/luxonDateFormats";
import {
  eventStatuses,
  imageStep,
  MAKE_DRAFT,
  ticketStep,
  ticketSummaryStage,
} from "../Constants/events/eventOptions";
import dashboardStatFilters from "../Constants/events/dashboardStatFilters";
import date from "./date";
import { Endpoints } from "../Constants";
import { findAndReplaceTicket, getInstalments, structureTicketData } from "./ticketHelpers";
import { allTicketInputs } from "../Components/NamedComponents/EventFlow/config";
import cancellationTypes from "../Constants/events/cancellationTypes";
import httpRequestAsync from "./httpRequestAsync";
import { normalCharactersRegex } from "../Constants/regex";
import isFeatureFlagOn, { featureFlags } from "./checkFeatureFlag";
import { STANDARD_TICKETING } from "../Constants/events/ticketingTypes";
import { RECURRING } from "../Constants/events/eventTypes";
import { allAdvancedOptionTicketInputs } from "../Components/NamedComponents/EventFlow/config/ticketAdvancedOptions";

export const calculateEndDay = (formValues, api = true) => {
  const { date: dt, duration, doorsOpen, doorsClose } = formValues;
  // Add duration to start date
  let endDay = date(dt).plus(duration?.value ? Number(duration.value) : Number(duration), "days");

  // Check if end time is before start time. If it is, we need to add another day to end date
  if (doorsClose?.value <= doorsOpen?.value) {
    endDay = date(endDay).plus(1, "days");
  }
  if (api) {
    const formattedEndTime = doorsClose.value || doorsClose;
    const formattedEndDate = date(endDay).format(DATE_FORMAT_API);
    return `${formattedEndDate} ${formattedEndTime}`;
  }
  return endDay;
};

export const hasChanged = form => !isEqual(form.initialValues, form.values);

// Use Lodash set to set the value on the body. This helps with nested fields
// e.g. `postApiName: "sessions.interval" would become body: { sessions: { interval: value } }
const setBodyValue = (body, name, value) => set(body, name, value);

const calculateField = (fieldData, shouldSendToApi, relist, apiBody, data, method) => {
  const body = { ...apiBody };
  const { field, val } = fieldData;

  if (val === "xLargeImageUrl") {
    body.imageName = data.xLargeImageUrl;
  }

  if (!field) {
    return body;
  }

  // If the field needs a different name to be sent to API as set in events constant
  const name = field.postApiName || field.name;
  // Grab the value
  let value = data[val];

  if (field.postFormat) {
    // If the field needs to be formatted in some way before being sent to API
    value = field.postFormat(value, data);
  }

  // If relist and POST send all step 1 fields
  if (relist && method === "POST" && field.step === 1 && shouldSendToApi) {
    setBodyValue(body, name, value);
  }
  const ticketNames = allTicketInputs.map(i => i.name);

  // If relist and PUT send everything but step 1 fields
  if (
    relist &&
    method === "PUT" &&
    shouldSendToApi &&
    field.step !== 1 &&
    !ticketNames.includes(name)
  ) {
    setBodyValue(body, name, value);
  }

  // Else normal checks
  if (!relist && !ticketNames.includes(name) && shouldSendToApi) {
    setBodyValue(body, name, value);
  }

  if (name === "ticketDescription" && value === "") {
    delete body.ticketDescription;
  }

  return body;
};

export const isReschedule = (relist, cancellation) =>
  // is being relisted
  relist &&
  cancellation &&
  // Has cancellation type of rescheduled
  cancellation?.type === cancellationTypes.RESCHEDULED &&
  // Hasn't already been rescheduled
  !cancellation?.rescheduledToListingId;

export const constructBody = (
  form,
  relist,
  currentEventData,
  eventId,
  fields,
  method,
  qsValues,
  navigationState = null,
) => {
  let apiBody = {};
  if (relist) {
    apiBody.relist = 1;
  }

  if (relist && isReschedule(relist, currentEventData?.cancellation) && method === "POST") {
    apiBody.relistedFromListingId = currentEventData.listingId;
  }

  let data = { ...form.values };

  if (relist || !eventId) {
    data = { ...currentEventData, ...form.values };
    // We want to make this draft as the user will only be at the end of Step 1
    // and may not want it to have the same active status as the previous event yet
    apiBody.active = MAKE_DRAFT;
  }

  // Flattens groups of fields
  const flattenedFields = [...fields.filter(field => field.step !== 4)];
  fields
    .filter(field => field.isGroup)
    .forEach(f => f.fields.forEach(field => flattenedFields.push(field)));

  // Loop through the values on this form
  Object.keys(data).forEach(val => {
    const field = flattenedFields.find(f => f.name === val);

    if (!field || data[val] === undefined) {
      return;
    }

    const defaultValues = fields.reduce((acc, v) => {
      acc[v.name] = v.defaultValue || "";
      return acc;
    }, {});

    const shouldSendToApi = field.sendToApi(
      val,
      { ...currentEventData, ...data },
      navigationState?.initialValues ? defaultValues : form.initialValues,
      qsValues,
      relist,
      Boolean(!eventId),
    );

    apiBody = {
      ...apiBody,
      ...calculateField(
        { field, val },
        shouldSendToApi,
        relist,
        apiBody,
        { ...currentEventData, ...data },
        method,
      ),
    };
  });

  if (form.values?.imageType && form.values?.imageName) {
    apiBody.relist = 1;
    apiBody.eventName = currentEventData?.eventName;
  }

  return apiBody;
};

export const getDashboardStatDateRange = filter => {
  switch (filter) {
    case dashboardStatFilters.TODAY.name:
      return {
        startDate: date(new Date()).startOf("day").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).endOf("day").format(ISO_DATE_TIME_FORMAT),
      };
    case dashboardStatFilters.YESTERDAY.name:
      return {
        startDate: date(new Date()).minus(1, "days").startOf("day").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).minus(1, "days").endOf("day").format(ISO_DATE_TIME_FORMAT),
      };
    case dashboardStatFilters.LAST_7_DAYS.name:
      return {
        startDate: date(new Date()).minus(6, "days").startOf("day").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).endOf("day").format(ISO_DATE_TIME_FORMAT),
      };

    case dashboardStatFilters.LAST_14_DAYS.name:
      return {
        startDate: date(new Date()).minus(13, "days").startOf("day").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).endOf("day").format(ISO_DATE_TIME_FORMAT),
      };
    case dashboardStatFilters.LAST_30_DAYS.name:
      return {
        startDate: date(new Date()).minus(29, "days").startOf("day").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).endOf("day").format(ISO_DATE_TIME_FORMAT),
      };
    case dashboardStatFilters.THIS_MONTH.name:
      return {
        startDate: date(new Date()).startOf("month").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).endOf("day").format(ISO_DATE_TIME_FORMAT),
      };
    case dashboardStatFilters.LAST_MONTH.name:
      return {
        startDate: date(new Date())
          .minus(1, "months")
          .startOf("month")
          .format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).minus(1, "months").endOf("month").format(ISO_DATE_TIME_FORMAT),
      };
    case dashboardStatFilters.THIS_YEAR.name:
      return {
        startDate: date(new Date()).startOf("year").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).format(ISO_DATE_TIME_FORMAT),
      };
    case dashboardStatFilters.LAST_YEAR.name:
      return {
        startDate: date(new Date()).minus(1, "years").startOf("year").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).minus(1, "years").endOf("year").format(ISO_DATE_TIME_FORMAT),
      };
    case dashboardStatFilters.LAST_12_MONTHS.name:
      return {
        startDate: date(new Date()).minus(12, "months").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).format(ISO_DATE_TIME_FORMAT),
      };
    case dashboardStatFilters.ALL_TIME.name:
      return {};
    default:
      return {
        startDate: date(new Date()).minus(7, "days").format(ISO_DATE_TIME_FORMAT),
        endDate: date(new Date()).startOf("day").format(ISO_DATE_TIME_FORMAT),
      };
  }
};

export const isEventInPast = dt => date().diff(dt) > 0;

export const calculateNavigateNext = (
  eventId,
  skipToSummary,
  currentTicketData,
  current,
  next,
  qsValues,
  relist,
) => {
  const { nextStep, nextStage } = next;
  const { step, stage } = current;
  if (skipToSummary) {
    return {
      prevStepStage: { step, stage, direction: "skipToSummary" },
      navigateTo: `${window.location.pathname}?step=5&stage=1`,
    };
  }

  if (nextStage === 1 && step >= 5) {
    return {
      prevStepStage: {},
      navigateTo: "/",
    };
  }
  if (eventId && step === imageStep && currentTicketData.length) {
    return {
      prevStepStage: { step, stage, direction: "skipToTicketSummary" },
      navigateTo: `/events/${eventId}/edit?step=${ticketStep}&stage=${ticketSummaryStage}`,
    };
  }

  if (
    (eventId && step === imageStep && !currentTicketData.length) ||
    (eventId && step !== imageStep)
  ) {
    return {
      prevStepStage: { step, stage, direction: "next" },
      navigateTo: `/events/${eventId}${
        relist && step === 1 && stage !== 5 ? "/relist" : "/edit"
      }?step=${nextStep}&stage=${nextStage}${
        qsValues.min && qsValues.max ? `&min=${qsValues.min}&max=${qsValues.max}` : ""
      }`,
    };
  }

  return {
    navigateTo: `/events/create?step=${nextStep}&stage=${nextStage}${
      qsValues.min && qsValues.max ? `&min=${qsValues.min}&max=${qsValues.max}` : ""
    }`,
    prevStepStage: { step, stage, direction: "next" },
  };
};

export const shouldPostOrPut = (relist, step, form, eventId, stage, maxStage) =>
  !relist &&
  (step !== imageStep || (step === imageStep && form.values?.imageType)) &&
  ((eventId && form.dirty) || (!eventId && step === 1 && stage === maxStage));

export const goToNextStage = (eventId, navigate, saveAndExit, onFinish, navigateNext) => {
  // Not posting or putting, just navigate
  if (saveAndExit) {
    navigate(`/events/${eventId}`);
    return;
  }

  if (!onFinish) {
    navigateNext(eventId);
  } else {
    onFinish();
  }
};

export const httpMethod = eventId => {
  if (eventId) {
    return {
      method: "PUT",
      endpoint: Endpoints.EVENT_BY_ID,
    };
  }

  return {
    method: "POST",
    endpoint: Endpoints.POST_NEW_EVENT,
  };
};

export const setValidationSchemaFn = (
  fields,
  globalForm,
  currentTicketData,
  eventId,
  setValidationSchema,
  qsValues,
  currency,
  initialValues,
  event,
) => {
  const validation = {};
  fields.forEach(field => {
    if (field.isGroup) {
      field.fields.forEach(f => {
        validation[f.name] = f.validation
          ? f?.validation(
              globalForm,
              currentTicketData,
              eventId,
              qsValues,
              currency,
              initialValues,
              event,
            )
          : null;
      });
      return;
    }

    validation[field.name] = field.validation
      ? field.validation(
          globalForm,
          currentTicketData,
          eventId,
          qsValues,
          currency,
          initialValues,
          event,
        )
      : null;
  });
  setValidationSchema(validation);
};

const redirectIfIsPaymentPlan = (currentTicketData, data, navigate, ids, qsValues) => {
  const instalments = getInstalments(currentTicketData);
  if (
    data.paymentPlan === 1 ||
    (instalments.length && instalments.indexOf(Number(data.ticketId)) !== -1) ||
    (data.closed && qsValues.relist !== "true")
  ) {
    navigate(`/events/${ids.eventId}/edit?step=4&stage=2`);
  }
};

const getInitialName = (value, defaultValue = "") => value || defaultValue;

const getInitialDefaultValue = (defaultValue, event, ticket, name, qsValues, currentTicketData) => {
  const defaultVal =
    typeof defaultValue === "function"
      ? defaultValue(event, {}, qsValues, ticket, currentTicketData)
      : defaultValue;
  return getInitialName(get(ticket, name), defaultVal);
};

const getGroupFieldInitialDefaultValue = (groupField, event, ticket, qsValues) => {
  return typeof groupField.defaultValue === "function"
    ? groupField.defaultValue(event, {}, qsValues, ticket)
    : groupField.defaultValue;
};

export const setInitialTickets = async (
  fields,
  auth,
  ids,
  qsValues,
  currentTicketDataState,
  navigate,
  event,
) => {
  const { currentTicketData, setCurrentTicketData } = currentTicketDataState;
  const initial = {};
  const { data } = await httpRequestAsync({
    authenticated: true,
    endpoint: Endpoints.SINGLE_TICKET,
    params: { id: ids.ticketId },
  });

  // If payment plan, don't let edit happen so redirect
  redirectIfIsPaymentPlan(currentTicketData, data, navigate, ids, qsValues);

  const ticket = structureTicketData(data, auth.promoter.promoterId);

  setCurrentTicketData(findAndReplaceTicket(ids.ticketId, currentTicketData, ticket));

  if (!ticket) {
    return initial;
  }

  initial.ticketId = Number(ticket.ticketId);

  fields.forEach(({ name, isGroup, defaultValue, fields: groupFields }) => {
    initial[name] = getInitialDefaultValue(
      defaultValue,
      event,
      ticket,
      name,
      qsValues,
      currentTicketData,
    );

    if (isGroup) {
      groupFields.forEach(groupField => {
        const groupDefaultVal = getGroupFieldInitialDefaultValue(
          groupField,
          event,
          ticket,
          qsValues,
        );
        initial[groupField.name] = getInitialName(get(ticket, groupField.name), groupDefaultVal);

        if (groupField.name === "advancedOpts") {
          allAdvancedOptionTicketInputs.forEach(adv => {
            const advDefaultVal =
              typeof adv.defaultValue === "function"
                ? adv.defaultValue(event, {}, qsValues, ticket)
                : adv.defaultValue;
            if (get(ticket, adv.name)) {
              initial[adv.name] = get(ticket, adv.name);
            } else if (qsValues.category && adv.name === "category") {
              initial[adv.name] = qsValues.category;
            } else {
              initial[adv.name] = advDefaultVal || "";
            }
          });
        }
      });
    }
  });

  return initial;
};

export const setInitial = (
  fields,
  globalForm,
  event,
  promoterId,
  qsValues,
  currentTicketData = [],
) => {
  const initial = {};

  fields.forEach(field => {
    const defaultVal =
      typeof field.defaultValue === "function"
        ? field.defaultValue(event, globalForm, qsValues, {}, currentTicketData)
        : field.defaultValue;

    const hasGlobalFormValue =
      get(globalForm.values, field.name) !== undefined &&
      get(globalForm.values, field.name) !== null &&
      get(globalForm.values, field.name) !== "";

    if (hasGlobalFormValue && field.name) {
      initial[field.name] =
        typeof get(globalForm.values, field.name) !== "function"
          ? get(globalForm.values, field.name)
          : defaultVal;
    } else if (field.name) {
      initial[field.name] = defaultVal || "";
    }

    if (field.isGroup) {
      field.fields.forEach(f => {
        const groupDefaultVal =
          typeof f.defaultValue === "function"
            ? f.defaultValue(event, globalForm, qsValues, {}, currentTicketData)
            : f.defaultValue;
        if (get(globalForm.values, f.name)) {
          initial[f.name] = get(globalForm.values, f.name);
        } else {
          initial[f.name] = groupDefaultVal || "";
        }
      });
    }
  });

  if (
    promoterId === import.meta.env.TEST_PROMOTER_ID &&
    fields.find(f => f.name === "ticketCountPeriod")
  ) {
    initial.setUpTicketReports = "no";
    initial.ticketCountPeriod = "9";
  }

  return initial;
};

export const shouldHideArtistsAndGenres = eventCode => {
  return (
    eventCode &&
    eventCode !== "CLUB" &&
    eventCode !== "LIVE" &&
    eventCode !== "FEST" &&
    eventCode !== "COMEDY"
  );
};

export const getEventStatus = (active, cancelled, embargoDate, processing) => {
  const status = Number(cancelled) === 1 ? "Cancelled" : eventStatuses[Number(active)];
  let label = status;
  let stat = status;

  if (embargoDate && active === MAKE_DRAFT) {
    stat = "embargoed";
    label = "Embargoed";
  }
  if (processing) {
    stat = "processing";
    label = "Processing...";
  }

  return { status: stat, label };
};

export const testSpecialChars = value => {
  if (value) {
    return !value.match(normalCharactersRegex.regex);
  }
  return true;
};

export const shouldHideSessionField = (formValues, context) =>
  !isFeatureFlagOn(featureFlags.SESSION_BASED_TICKETING) ||
  formValues.ticketingType === STANDARD_TICKETING ||
  (context.eventId && !context.relist);

export const sortRecurringDates = (initialValues, formValues) => {
  if (formValues.eventType !== RECURRING) {
    return { sortedInitialValues: [], sortedFormValues: [] };
  }
  const sortedInitialValues = initialValues?.recurringDates
    ? [...initialValues.recurringDates].sort(
        (a, b) =>
          Date.parse(new Date(date(a.start).toISO())) - Date.parse(new Date(date(b.start).toISO())),
      )
    : [];

  const sortedFormValues = [...formValues.recurringDates].sort(
    (a, b) =>
      Date.parse(new Date(date(a.start).toISO())) - Date.parse(new Date(date(b.start).toISO())),
  );

  return { sortedInitialValues, sortedFormValues };
};
