import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2 as Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import currencies from "../../../../Constants/currencies";
import Link from "../../../Reusable/Link";
import links from "../../../../Utils/links";

const RegisterStepTwo = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isSubmitting,
  setStep,
}) => (
  <>
    <Grid
      size={{
        xs: 12,
        md: 6,
      }}
    >
      <TextField
        id="firstName"
        variant="outlined"
        label="First name"
        fullWidth
        name="firstName"
        value={values.firstName}
        placeholder="First name"
        error={Boolean(touched.firstName && errors.firstName)}
        helperText={touched.firstName && errors.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        autoFocus
        autoComplete="given-name"
        required
      />
    </Grid>
    <Grid
      size={{
        xs: 12,
        md: 6,
      }}
    >
      <TextField
        id="lastName"
        variant="outlined"
        label="Last name"
        fullWidth
        name="surname"
        value={values.surname}
        placeholder="Last name"
        error={Boolean(touched.surname && errors.surname)}
        helperText={touched.surname && errors.surname}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        autoComplete="family-name"
        required
      />
    </Grid>
    <Grid size={12}>
      <TextField
        id="organisation"
        variant="outlined"
        label="Organisation"
        fullWidth
        name="organisation"
        value={values.organisation}
        placeholder="Organisation"
        error={Boolean(touched.organisation && errors.organisation)}
        helperText={touched.organisation && errors.organisation}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        autoComplete="organization"
      />
    </Grid>
    <Grid size={12}>
      <TextField
        id="phone"
        variant="outlined"
        label="Phone number"
        fullWidth
        name="phone"
        value={values.phone}
        placeholder="Phone number"
        error={Boolean(touched.phone && errors.phone)}
        helperText={touched.phone && errors.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        autoComplete="tel"
        required
      />
    </Grid>
    <Grid size={12}>
      <TextField
        select
        id="currency"
        variant="outlined"
        label="Currency"
        fullWidth
        name="currency"
        value={values.currency}
        error={Boolean(touched.currency && errors.currency)}
        helperText={touched.currency && errors.currency}
        onChange={handleChange}
        onBlur={handleBlur}
        required
      >
        <MenuItem value="" disabled>
          <em>Select a currency</em>
        </MenuItem>
        {currencies.map(curr => (
          <MenuItem value={curr.value} key={curr.value}>
            {curr.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
    <Grid sx={{ my: 2 }} size={12}>
      <FormControl
        required
        error={touched.termsAccepted && errors.termsAccepted}
        helperText={touched.termsAccepted && errors.termsAccepted}
        component="fieldset"
      >
        <FormControlLabel
          control={
            <Checkbox
              sx={{ color: touched.termsAccepted && errors.termsAccepted ? "red" : undefined }}
              name="termsAccepted"
              onChange={handleChange}
              checked={values.termsAccepted}
            />
          }
          label={
            <Typography
              sx={{ color: touched.termsAccepted && errors.termsAccepted ? "red" : undefined }}
            >
              By ticking, you are confirming that you have read, understood and agree to Skiddle{" "}
              <Link isRouterLink={false} target="_blank" to={links.TERMS}>
                Terms & Conditions
              </Link>
            </Typography>
          }
        />
        {touched.termsAccepted && errors.termsAccepted && (
          <FormHelperText error>{errors.termsAccepted}</FormHelperText>
        )}
      </FormControl>
    </Grid>

    <Grid
      size={{
        xs: 12,
        md: 6,
      }}
    >
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        mb={2}
        mt={2}
        type="button"
        disabled={isSubmitting}
        onClick={() => setStep(0)}
      >
        Back
      </Button>
    </Grid>
    <Grid
      size={{
        xs: 12,
        md: 6,
      }}
    >
      <Button
        fullWidth
        variant="contained"
        color="primary"
        mb={2}
        mt={2}
        type="submit"
        disabled={isSubmitting}
      >
        Register
      </Button>
    </Grid>
  </>
);

RegisterStepTwo.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
};
export default RegisterStepTwo;
