import { styled } from "@mui/material";

export { default as Button } from "./Button/Button";
export { default as ErrorList } from "./ErrorList";
export { default as Loader } from "./LoadingIcon";
export { default as Logo } from "./Logo";

export { default as Modal } from "./Modal";

export const inputBorderColor = ({ $hasErrors, theme }) => {
  if ($hasErrors) {
    return theme.danger;
  }
  return "rgba(0, 0, 0, 0.2)";
};

export const InputContainer = styled("div")`
  font-size: 16px;
  margin: 10px 0px;
  label {
    margin-bottom: 4px;
    width: 100%;
    float: left;
    text-align: left;
  }
  input {
    background: ${({ theme }) => `${theme.inputGrey}`};
    border: 1px solid;
    border-color: ${props => inputBorderColor(props)};
    border-radius: 5px;
    padding: 15px;
    font-size: 14px;
    width: 100%;
    &::placeholder {
      color: ${({ theme }) => theme.darkGrey};
    }
  }
  textarea {
    display: none;
  }
`;
