import React from "react";

export interface AppContextType {
  currentEvent?: object;
  isMobile: boolean;
  appVersion: string;
}

export const EventContext = React.createContext(null);
export const DashboardContext = React.createContext(null);
export const AppContext = React.createContext<AppContextType | null>(null);
export const EventsDashboardContext = React.createContext(null);
export const SingleOrderContext = React.createContext(null);
export const OrdersListContext = React.createContext(null);
