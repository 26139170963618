import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Hidden, AppBar as MuiAppBar, Toolbar, styled, useMediaQuery } from "@mui/material";

import MobileHeaderContent from "./MobileHeaderContent";
import DesktopHeaderContent from "./DesktopHeaderContent";
import useAuth from "../../NamedComponents/Auth/hooks/useAuth";
import useAuthMainPromoter from "../../NamedComponents/Auth/hooks/useAuthMainPromoter";
import useHttpRequest from "../../../Hooks/useHttpRequest/useHttpRequest";
import { Endpoints } from "../../../Constants";
import usePageVisibility from "../../../Hooks/usePageVisibility";
import { error } from "../../../Utils/toast";
import SettingsDrawer from "./SettingsDrawer";
import isFeatureFlagOn from "../../../Utils/checkFeatureFlag";
import { ERRORED, FETCHED } from "../../../Constants/requestStatuses";
import useHttpRequestMultiple from "../../../Hooks/useHttpRequest/useHttpRequestMultiple";
import theme from "../../../theme";
import ErrorBoundary from "../ErrorBoundary";
import { AppContext } from "../../../Context";

const drawerWidth = "80%";

const AppBar = styled(MuiAppBar)`
  background: ${({ theme: { palette } }) => palette.skiddle.gradient};
  color: ${({ theme: { palette } }) => palette.skiddle.textBlack};
  position: fixed;
`;

const Header = ({ onDrawerToggle, onSettingsDrawerToggle, mobileOpenSettings }) => {
  const auth = useAuth();
  const mainPromoter = useAuthMainPromoter();
  const pageVisible = usePageVisibility();
  const navigate = useNavigate();

  const [shouldExecute, setShouldExecute] = useState(false);
  const [runRequest, setRunRequest] = useState(false);
  const [payload, setPayload] = useState();

  const [notifications, setNotifications] = useState([]);
  const drawerHidden = useMediaQuery(theme.breakpoints.down("md"));

  const { isLoggedIn } = useContext(AppContext);

  useEffect(() => {
    setShouldExecute(true);
  }, []);

  const { status, data, retryFn } = useHttpRequest({
    authenticated: true,
    endpoint: Endpoints.GET_NOTIFICATIONS,
    shouldExecute: isFeatureFlagOn("notifications") && auth.isAuthenticated && shouldExecute,
  });

  useEffect(() => {
    let interval = null;

    if (status === FETCHED && pageVisible) {
      setNotifications(data);

      interval = setInterval(() => retryFn(), 30000);
    }

    if (!pageVisible && interval) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [status, retryFn, pageVisible]);

  const deleteNotifications = useHttpRequestMultiple(payload, runRequest);

  if (deleteNotifications.filter(n => n.status === ERRORED).length) {
    error("Could not delete this notification");
  }

  const handleClearNotification = async id => {
    setPayload([
      {
        authenticated: true,
        endpoint: Endpoints.DELETE_NOTIFICATION,
        method: "DELETE",
        params: { id },
        key: id,
      },
    ]);
    setRunRequest(true);

    const newNotifications = [...notifications].filter(mem => mem.id !== id);
    setNotifications(newNotifications);
  };

  const handleClearAllNotifications = async () => {
    setPayload(
      notifications.map(n => ({
        authenticated: true,
        endpoint: Endpoints.DELETE_NOTIFICATION,
        method: "DELETE",
        params: { id: n.id },
        key: n.id,
      })),
    );

    setRunRequest(true);

    setNotifications([]);
  };

  const handleLogout = async e => {
    e.preventDefault();
    try {
      navigate("/auth/logout");
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <>
      <AppBar
        sx={{ height: "54px" }}
        position="sticky"
        elevation={0}
        id="navbar"
        $drawerHidden={drawerHidden}
      >
        {isLoggedIn && (
          <Toolbar>
            <Hidden mdUp>
              <MobileHeaderContent
                auth={auth}
                mainPromoter={mainPromoter}
                onDrawerToggle={onDrawerToggle}
                onSettingsDrawerToggle={onSettingsDrawerToggle}
              />
              <SettingsDrawer
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpenSettings}
                onClose={onSettingsDrawerToggle}
                notifications={notifications}
                handleClearAllNotifications={handleClearAllNotifications}
                handleClearNotification={handleClearNotification}
                handleLogout={handleLogout}
              />
            </Hidden>
          </Toolbar>
        )}
      </AppBar>
      <Hidden mdDown>
        <ErrorBoundary sx={{ zIndex: 1102 }} code="H-DSK">
          <DesktopHeaderContent
            auth={auth}
            mainPromoter={mainPromoter}
            handleLogout={handleLogout}
          />
        </ErrorBoundary>
      </Hidden>
    </>
  );
};

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
  onSettingsDrawerToggle: PropTypes.func.isRequired,
  mobileOpenSettings: PropTypes.bool.isRequired,
};
export default React.memo(Header);
