import date from "./date";

const getVersion = async () => {
  let versionText = "";
  if (window.location.hostname !== "localhost") {
    try {
      const version = await fetch(
        `${window.location.protocol}//${window.location.host}/version.txt?t=${date().toUnixInteger()}`,
      );
      versionText = await version.text();
    } catch {
      versionText = "";
    }
  } else {
    versionText = "local";
  }

  return versionText;
};

export default getVersion;
