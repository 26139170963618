import Store from "../Store";

const { VITE_API_URL_VERSION } = import.meta.env;

export default function getApiVersion() {
  let apiUrlVersion = VITE_API_URL_VERSION;

  if (!VITE_API_URL_VERSION) {
    apiUrlVersion = "v3";
  }

  if (
    Store.getState().apiVersion.version === "dev" ||
    Store.getState().apiVersion.version === "v3"
  ) {
    apiUrlVersion = Store.getState().apiVersion.version;
  }

  return apiUrlVersion;
}
