import React from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArticleIcon from "@mui/icons-material/Article";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import BarChartIcon from "@mui/icons-material/BarChart";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import EuroIcon from "@mui/icons-material/Euro";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { skiddle } from "../../../theme/variants";
import isFeatureFlagOn, { featureFlags } from "../../../Utils/checkFeatureFlag";
import permissions, { rolesBitwise, userRoles } from "../../../Utils/permissions";
import manageEventsChildren from "./manageEventsChildren";
import manageTicketsChildren from "./manageTicketsChildren";
import {
  childEventTooltipMessage,
  eventsRecurringModalSettings,
  getDisabledTooltipText,
  isCancelled,
  isRecurringEvent,
  isSessionEvent,
  shouldShow,
} from "./helpers";
import date from "../../../Utils/date";
import helpCentre from "../../../Constants/helpCentre";
import links from "../../../Utils/links";

/**
 * @Navigation
 *
 * Create a new `const` for each section in the side nav. Each const can have
 * the following properties:
 * @param {String | required} id - The header of the link
 * @param {Func | required} show - A function that decides whether to show the section
 * it can take `location` which can be used to work out whether to show or not
 * @param {String | required} path - The actual path/link
 * @param {Component} icon - The icon to display next to the header
 * @param {Number | optional} permissions - If this route has any permissions
 * needed to access it. Omit if the link is available for everyone.
 * @param {Func - returns array} children - The sub-routes for the section, can be null if it's
 * just a single link e.g. main dashboard. Can take `match` which provides any params from the url
 * useful when you need the event id from the URL to build the path for example
 *
 * Each section can have child links, the @children param is a function which returns an
 * array of objects, each object represents a link and can have the following params:
 * @param {String | required} name - The name of the link
 * @param {String | optional} path - The path which will be followed on click of the link.
 * doesn't need to be provided if the link should act like a button e.g. delete event
 * @param {String | optional} route - The path of the link but with the params replaced by
 * wildcards, used for deciding whether a link is active. E.g. if your path is /events/123
 * you'll need the @route params to be /events/:id
 * @param {Func | optional} activeOverride - A function used when we need more complex or in
 * depth logic to decide if a route is active, this function takes a `params` object which will
 * contain the parameters in the url, e.g. /events/123/edit?step=1&stage=1, when given a @route
 * of /events/:id/edit?step=:step&stage=:stage will return the params { id: 123, step: 1, stage: 1 }
 * which can then be used to add some custom logic to decide if the link is active.
 * @param {Component | optional} - Pass a React component to render something custom. Useful for
 * links that should act as buttons e.g. delete event. Keep all components created for this purpose
 * in './Components'
 * @param {Func | optional} disabledTooltipText - A function which takes `currentEvent` and
 * `hasPermission` and can be used to display a tooltip message in the case that the link
 * is disabled for something like permissions or an event being in the past.
 * @param {Number | optional} permissions - If this particular child route has any permissions
 * needed to access it. Omit if the link is available for everyone.
 * @param {Bool | optional = true} hideIfNoPermissions - Whether or not to hide the link entirely
 * if it doesn't pass the permissions check. Deafults to true, i.e. the link will hide if no
 * perms. Use in conjuction with @disabledTooltipText to display a tooltip message. Note: this
 * option will have no effect if @permissions isn't set on the link also.
 */

const listingsRole = rolesBitwise["Listings and tickets"];
const repsTooltipText = event => {
  if (isRecurringEvent(event)) {
    return childEventTooltipMessage;
  }

  return event.gateway === "external-stripe"
    ? "The Reps system is currently suspended whilst on Stripe payments"
    : "The Reps system is currently disabled as this event is suspended.";
};

export const manageEvents = {
  id: "Events",
  matchString: "/events/*",
  path: "/events",
  icon: () => <CalendarTodayIcon />,
  activeOverride: pathName => Boolean(pathName === "/"),
  children: match => {
    let id = null;
    if (match) {
      id = match.params["*"].slice(
        0,
        match.params["*"].indexOf("/") !== -1
          ? match.params["*"].indexOf("/")
          : match.params.length,
      );
    }

    return [
      {
        name: "Add new event",
        path: `/events/create?timestamp=${date().toUnixInteger()}`,
        id: "addEvent",
      },
      {
        name: "View all events",
        path: "/",
        activeStateFor: ["/events"],
        id: "dashboard",
        style: { marginBottom: "12px" },
      },
      {
        name: "Event dashboard",
        path: `/events/${id}`,
        route: "/events/:id",
        id: "eventDashboard",
        show: () => shouldShow(match),
        permissions: permissions().READ_EVENT,
        hideIfNoPermissions: false,
      },
      {
        name: "Manage event",
        id: "manageEvent",
        show: shouldShow,
        textStyle: { fontWeight: skiddle.sidebar.subheader.fontWeight },
        path: `/events/${id}/edit?step=1&stage=1`,
        permissions: userRoles[listingsRole],
        children: () => manageEventsChildren(id, match),
      },
      {
        name: "Manage tickets",
        id: "manageTickets",
        textStyle: { fontWeight: skiddle.sidebar.subheader.fontWeight },
        show: shouldShow,
        permissions: userRoles[listingsRole],
        children: () => manageTicketsChildren(id, match),
      },

      {
        name: "Customers/Orders",
        id: "customersAndOrders",
        textStyle: { fontWeight: skiddle.sidebar.subheader.fontWeight },
        show: shouldShow,
        // permissions: userRoles[rolesBitwise["Customer data"]],
        children: () => [
          {
            name: "Customer lists",
            path: `/events/${id}/customer-lists`,
            route: "/events/:id/customer-lists",
            id: "customerLists",
            show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.TICKET_LIST_DOWNLOAD),
          },
          {
            name: "View orders",
            path: `/events/${id}/orders`,
            route: "/events/:id/orders",
            activeStateFor: ["/events/:id/orders/:order"],
            id: "viewOrders",
            show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.ORDER_ADMIN),
          },
          {
            name: "Checkout questions",
            path: `/events/${id}/checkout-questions`,
            route: "/events/:id/checkout-questions",
            id: "checkoutQuestions",
            disabledTooltipText: event => getDisabledTooltipText(event),
            isDisabled: event => isRecurringEvent(event) || isSessionEvent(event),
            show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.CHECKOUT_QUESTIONS),
          },

          {
            name: "Campaign Stats",
            path: `/events/${id}/event-campaign`,
            route: "/events/:id/event-campaign",
            id: "campaignStats",
            show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.CAMPAIGN_STATS),
          },
        ],
      },

      {
        name: "Promotion",
        id: "promotion",
        textStyle: { fontWeight: skiddle.sidebar.subheader.fontWeight },
        show: shouldShow,
        permissions: userRoles[listingsRole],
        children: () => [
          {
            name: "Tracking",
            path: `/events/${id}/tracking`,
            route: "/events/:id/tracking",
            id: "manageTracking",
            show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.TRACKING),
            isDisabled: event => isCancelled(event),
            disabledTooltipText: (event, hasPermission) =>
              getDisabledTooltipText(event, hasPermission),
            modal: event =>
              eventsRecurringModalSettings(
                event,
                `/events/${event.masterListingInstanceId}/tracking`,
              ),
          },
          {
            name: "Manage reps",
            path: `/events/${id}/reps`,
            route: "/events/:id/reps",
            id: "manageReps",
            disabledTooltipText: event => repsTooltipText(event),
            isDisabled: event =>
              event.gateway === "external-stripe" ||
              !(event.active === "1" || event.active === "8") ||
              isRecurringEvent(event) ||
              isSessionEvent(event) ||
              isCancelled(event),
            show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.REPS),
          },
          {
            name: "Embed ticket box",
            path: `/events/${id}/website-ticketbox`,
            route: "/events/:id/website-ticketbox",
            id: "embedTicketBoxListing",
            show: () => shouldShow(match),
            style: { marginBottom: "12px" },
          },
        ],
      },
    ];
  },
};

export const orders = {
  id: "Orders",
  header: "",
  show: () => true,
  path: "/orders",
  icon: () => <ArticleIcon />,
  children: () => [
    {
      name: "View all orders",
      path: "/orders",
      route: "/orders",
      id: "viewAllOrders",
      activeStateFor: ["/orders/:orders"],
      activeOverride: (_x, location) => location.pathname !== "/orders/tickets-order",
      needsMatch: false,
      show: () => true,
    },
    {
      name: "Order printed tickets",
      path: "/orders/tickets-order",
      id: "ticketsOrder",
      show: () => isFeatureFlagOn(featureFlags.ORDER_TICKETS_OFFLINE_SALES),
    },
  ],
};

export const promote = {
  id: "Promote",
  header: "",
  show: () => true,
  path: "/promote",
  icon: () => <RssFeedIcon />,
  children: () => [
    {
      name: "My brands",
      path: "/promote/brands",
      route: "/promote/brands",
      id: "createBrand",
      show: () => isFeatureFlagOn(featureFlags.CREATE_BRAND),
    },
    {
      name: "Embed listings widget",
      path: "/promote/website-listings-widget",
      route: "/promote/website-listings-widget",
      id: "embedListings",
    },
    {
      name: "Embed ticket box",
      path: "/promote/website-ticketbox",
      route: "/promote/website-ticketbox",
      id: "ticketBox",
      show: () => isFeatureFlagOn(featureFlags.TICKET_BOX),
      // useAnchor: true, // Uses an anchor tag instead of react router link
    },
    {
      name: "My artists",
      path: "/promote/my-artists",
      route: "/promote/my-artists",
      id: "myArtists",
      show: () => isFeatureFlagOn(featureFlags.MY_ARTISTS),
    },
    {
      name: "My venues",
      path: "/promote/my-venues",
      route: "/promote/my-venues",
      id: "myVenues",
      show: () => isFeatureFlagOn(featureFlags.MY_VENUES),
    },
    {
      name: "Eflyers",
      path: "/promote/eflyers",
      route: "/promote/eflyers",
      id: "eflyers",
      show: () => isFeatureFlagOn(featureFlags.EFLYERS),
    },
    {
      name: "Videos",
      path: "/promote/videos",
      route: "/promote/videos",
      id: "videos",
      show: () => isFeatureFlagOn(featureFlags.VIDEOS),
    },
  ],
};

export const analytics = {
  id: "Analytics",
  header: "",
  show: () => true,
  path: "/analytics",
  icon: () => <BarChartIcon />,
  children: () => [
    {
      name: "Ticket sales breakdown",
      path: "/analytics/ticket-sales",
      id: "ticketSalesReport",
      show: () => isFeatureFlagOn(featureFlags.TICKET_SALES_REPORT),
    },
    {
      name: "Rapidscan entry stats",
      path: "/analytics/rapidscan-entry",
      id: "rapidscanEntry",
      show: () => isFeatureFlagOn(featureFlags.RAPIDSCAN_ENTRY),
    },
    {
      name: "Additional reports",
      path: "/analytics/additional-reports",
      id: "additionalReports",
      show: () => isFeatureFlagOn(featureFlags.ADDITIONAL_REPORTS),
    },
  ],
};

export const finance = {
  id: "Finance",
  header: "",
  show: () => true,
  path: "/finance",
  icon: currency => (currency === "GBP" ? <CurrencyPoundIcon /> : <EuroIcon />),
  // notification: true,
  children: () => [
    {
      name: "Remittance",
      path: "/finance/remittance",
      id: "remittance",
      // notification: true,
      textStyle: { fontWeight: skiddle.sidebar.subheader.fontWeight },
      show: () =>
        isFeatureFlagOn(featureFlags.REMITTANCE_OVERVIEW) ||
        isFeatureFlagOn(featureFlags.REMITTANCE_UNPAID) ||
        isFeatureFlagOn(featureFlags.REMITTANCE_PAID) ||
        isFeatureFlagOn(featureFlags.REMITTANCE_BREAKDOWN),
      children: () => [
        {
          name: "Overview report",
          path: "/finance/remittance/overview",
          id: "remittance-overview",
          show: () => isFeatureFlagOn(featureFlags.REMITTANCE_OVERVIEW),
        },
        {
          name: "Unpaid report",
          path: "/finance/remittance/unpaid",
          id: "unpaidRemittance",
          show: () => isFeatureFlagOn(featureFlags.REMITTANCE_UNPAID),
        },
        {
          name: "Paid report",
          path: "/finance/remittance/paid",
          id: "paidRemittance",
          // notification: true,
          show: () => isFeatureFlagOn(featureFlags.REMITTANCE_PAID),
        },
        {
          name: "Payout report",
          path: "/finance/remittance/payout-breakdown",
          id: "remittanceBreakdown",
          show: () => isFeatureFlagOn(featureFlags.REMITTANCE_BREAKDOWN),
        },
      ],
    },
    {
      name: "VAT breakdown",
      path: "/finance/vat-report",
      id: "vat-report",
      show: () => isFeatureFlagOn(featureFlags.VAT_BREAKDOWN_REPORT),
    },
    {
      name: "Bank accounts",
      path: "/finance/bank-accounts",
      activeStateFor: ["/finance/bank-accounts/:id/edit", "/finance/bank-accounts/self-bill"],
      id: "bank-accounts",
      show: () => isFeatureFlagOn(featureFlags.BANK_ACCOUNTS),
    },
    {
      name: "VAT details",
      path: "/finance/vat-details",
      id: "vat-details",
      show: () => isFeatureFlagOn(featureFlags.VAT_DETAILS),
    },
    {
      name: "Direct Pay",
      path: "/finance/direct-pay",
      id: "directPay",
      show: () => isFeatureFlagOn(featureFlags.STRIPE_DIRECT_PAY),
    },
  ],
};

export const help = {
  id: "Help",
  header: "",
  show: () => true,
  path: "/help",
  icon: () => <HelpOutlineIcon />,
  children: () => [
    {
      name: "Knowledge base",
      path: helpCentre.HOME(),
      id: "knowledge-base",
      useAnchor: true,
    },
    {
      name: "Promoter blog",
      path: links.BLOG,
      id: "promoter-blog",
      useAnchor: true,
    },
    {
      name: "Terms and conditions",
      id: "terms-and-conditions",
      path: links.TERMS,
      useAnchor: true,
    },
    {
      name: "Privacy policy",
      id: "privacy-policy",
      path: "https://promotioncentre.co.uk/privacy-policy/",
      useAnchor: true,
    },
    {
      name: "Cookie policy",
      id: "cookie-policy",
      path: "https://promotioncentre.co.uk/cookie-policy/",
      useAnchor: true,
    },
  ],
};

export default [manageEvents, orders, promote, analytics, finance, help];
