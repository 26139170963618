import React, { useEffect, useState } from "react";
import {
  styled,
  Button as MuiButton,
  Typography,
  Box,
  Grid2 as Grid,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { signIn } from "aws-amplify/auth";
import Helmet from "react-helmet";
import { useLocation, useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import qs from "query-string";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { Logo } from "../../../Reusable";

import { Endpoints } from "../../../../Constants";
import { ERRORED, FETCHED, FETCHING, IDLE } from "../../../../Constants/requestStatuses";
import LoadingIcon from "../../../Reusable/LoadingIcon";
import RegisterStepOne from "./RegisterStepOne";
import RegisterStepTwo from "./RegisterStepTwo";
import useHttpRequest from "../../../../Hooks/useHttpRequest/useHttpRequest";
import { userLoggedIn as userLoggedInAction } from "../../../../Store/reducers/AuthReducer";
import useAuth from "../hooks/useAuth";
import { mobileRegex, passwordRegex } from "../../../../Constants/regex";
import RegisterResend from "./RegisterResend";
import sanitiseEmail from "../../../../Utils/Auth/sanitiseEmail";
import { trackAccountCreation } from "../../../../Utils/pixels";

const BackToLogin = styled(MuiButton)`
  position: absolute;
  top: 10px;
  left: 8px;

  .MuiButton-startIcon {
    margin-right: 0;
  }
`;

const getStepContent = (step, formik, qsEmail, token, isStepOneComplete, setStep) => {
  if (step === 1) {
    return <RegisterStepTwo {...formik} setStep={setStep} />;
  }

  return (
    <RegisterStepOne
      {...formik}
      qsEmail={qsEmail}
      token={token}
      isStepOneComplete={isStepOneComplete}
      setStep={setStep}
    />
  );
};

const inviteRequestParams = (token, inviteId) => ({
  endpoint: Endpoints.GET_INVITE_BY_TOKEN,
  queryStringParams: { token },
  shouldExecute: Boolean(token),
  params: { inviteId },
  showRawErrorMessage: () => true,
  showGlobalError: false,
});
const postRequestParams = payload => ({
  endpoint: Endpoints.REGISTER,
  shouldExecute: Boolean(payload),
  body: payload,
  method: "POST",
  showRawErrorMessage: () => true,
});

const Register = ({ userLoggedIn }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, email: qsEmail, inviteId } = qs.parse(location.search);
  const auth = useAuth();

  const [resendTimeout, setResendTimeout] = useState(15000);
  const [error, setError] = useState();
  const [step, setStep] = useState(0);
  const [payload, setPayload] = useState();
  const [loggingIn, setLoggingIn] = useState(false);

  const stepOneFields = ["email", "password", "passwordConfirm"];
  const stepTwoFields = [
    "firstName",
    "surname",
    "organisation",
    "phone",
    "currency",
    "termsAccepted",
  ];

  const {
    status,
    data,
    error: requestError,
  } = useHttpRequest(inviteRequestParams(token, inviteId));

  const {
    status: postStatus,
    data: postData,
    error: postError,
  } = useHttpRequest(postRequestParams(payload));

  const handleRegister = async (vals, { setSubmitting }) => {
    setSubmitting(true);

    const pay = { ...vals };

    pay.email = sanitiseEmail(vals.email);

    if (!vals.organisation) {
      delete pay.organisation;
    }
    setPayload(pay);
    setSubmitting(false);
  };

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email address is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(passwordRegex.regex, passwordRegex.explanation),
      passwordConfirm: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      token: Yup.string(),
      firstName: Yup.string().required("First name is required"),
      surname: Yup.string().required("Last name is required"),
      organisation: Yup.string(),
      phone: Yup.string().required("Phone number is required").matches(mobileRegex.regex, {
        excludeEmptyString: true,
        message: mobileRegex.explanation,
      }),
      currency: Yup.string().required("Currency is required"),
      termsAccepted: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
    }),
    initialValues: {
      email: sanitiseEmail(qsEmail || ""),
      password: "",
      passwordConfirm: "",
      token,
      firstName: "",
      surname: "",
      organisation: "",
      phone: "",
      currency: "GBP",
      termsAccepted: false,
    },
    onSubmit: handleRegister,
  });

  useEffect(() => {
    const login = async (email, password) => {
      setLoggingIn(true);
      const res = await signIn({ username: sanitiseEmail(email), password });
      userLoggedIn(res);
      setPayload(null);
      setLoggingIn(false);
      navigate("/");
    };

    if (postStatus === FETCHED) {
      trackAccountCreation();
      if (token) {
        login(payload?.email, payload?.password);
      }

      if (!token) {
        const interval = setInterval(() => {
          setResendTimeout(prevState => {
            return prevState <= 1000 ? 0 : prevState - 1000;
          });
        }, 1000);

        if (resendTimeout === 0) {
          clearInterval(interval);
        }
      }
    }

    if (postStatus === ERRORED) {
      setPayload(null);
      if (postError?.source?.pointer) {
        if (stepOneFields.includes(postError?.source?.pointer)) {
          setStep(0);
        }

        if (stepTwoFields.includes(postError?.source?.pointer)) {
          setStep(1);
        }
      }

      if (postError.status === 409) {
        setStep(0);
        formik.setFieldError("email", "An account with this email already exists");
      }
    }
  }, [postStatus]);

  if (auth.isAuthenticated) {
    navigate("/");
  }

  if (requestError) {
    return <Alert severity="error">{requestError.error}</Alert>;
  }

  const { values, errors, handleSubmit, isSubmitting, touched } = formik;

  const isStepOneComplete = !stepOneFields.some(f => !values[f] || errors[f]);

  const isLoadingSubmit =
    isSubmitting || (token && postStatus !== FETCHED && Boolean(payload)) || loggingIn;

  return (
    <>
      <BackToLogin
        color="primary"
        size="small"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => navigate("/")}
      >
        Back to login
      </BackToLogin>
      <Helmet title="Sign Up" />
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Logo width="50%" />
      </Box>
      {isLoadingSubmit && (
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <LoadingIcon size={20} />
        </Box>
      )}
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Welcome to the Promotion Centre
      </Typography>
      {!token && postStatus === IDLE && (
        <Typography component="h2" variant="body1" align="center">
          Create an account to manage your events, tickets and promotion.
        </Typography>
      )}
      {error && (
        <Box
          sx={{
            mb: 3,
            mt: 3,
          }}
        >
          <Alert id="error" severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Box>
      )}
      {!token && postStatus === FETCHED && (
        <RegisterResend
          email={sanitiseEmail(values.email)}
          cognitoUUID={postData?.cognitoUUID}
          resendTimeout={resendTimeout}
          setResendTimeout={setResendTimeout}
        />
      )}
      {data && status === FETCHED && (
        <Box
          sx={{
            mb: 3,
            mt: 3,
          }}
        >
          <Alert severity="info">
            You have been invited to join the Promotion Centre by {data.promoterName}
          </Alert>
        </Box>
      )}
      {isLoadingSubmit && <Typography variant="body1">Processing your registration</Typography>}
      {status === FETCHING ? (
        <LoadingIcon />
      ) : (
        postStatus !== FETCHED &&
        postStatus !== ERRORED && (
          <>
            <Stepper alternativeLabel nonLinear activeStep={step} sx={{ py: 6 }}>
              <Step completed={isStepOneComplete}>
                <StepLabel error={stepOneFields.some(f => errors[f] && touched[f])}>
                  Login details
                </StepLabel>
              </Step>
              <Step>
                <StepLabel error={stepTwoFields.some(f => errors[f] && touched[f])}>
                  Additional information
                </StepLabel>
              </Step>
            </Stepper>

            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={4}
                sx={{
                  justifyContent: "flex-end",
                }}
              >
                {getStepContent(step, formik, qsEmail, token, isStepOneComplete, setStep)}
              </Grid>
            </form>
          </>
        )
      )}
    </>
  );
};

Register.propTypes = {
  userLoggedIn: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = {
  userLoggedIn: userLoggedInAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
