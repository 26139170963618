/* eslint-disable consistent-return */
import { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { signOut } from "aws-amplify/auth";

import { AppContext } from "../../Context";
import Store from "../../Store";
import useAuthMainPromoter from "../../Components/NamedComponents/Auth/hooks/useAuthMainPromoter";
import fetchData from "./fetchData";
import { shouldReset, useRequestReducer } from "./utils";
import { setLoading, userLoggedOut } from "../../Store/reducers/AuthReducer";

/**
 *
 * @param authenticated {Boolean = false} - Whether this is an auth protected API call
 * @param endpoint {String} - The actual endpoint to call, from the endpoints.js constants file
 * @param isExternal {Boolean = false} - If it's an external endpoint our API url won't be prepended
 * @param options {Object = {}} - A group of optional parameters to manipulate the request as
 *  detailed below:
 * @param options.queryStringParams {Object = {}} - key-value object for data to be appended as
 *  query strings
 * @param options.params {Object = {}} - A key-value object for replacing params in the endpoint
 * itself (e.g. events/:id would need params = { id: 123 })
 * @param options.body {Object = {}} - Used for POST/PUT requests, the body of the request
 * @param options.method {String = "GET"} - The HTTP method, one of POST, PUT, DELETE or GET
 * @param options.structureData {Func = null} - An optional function that is called after data is
 * fetched to do some manipulation before returing the data to the source
 * @param options.shouldExecute {Boolean = true} - The request won't execute until this is true,
 * useful for delaying an API call if it needs data from another call that may not be complete yet.
 * Also useful for triggering the API call on the click of a button or some other action
 * @param options.showGlobalError {Boolean = true} - Whether to show a global toast error in the
 * case of a 4xx or 5xx error. Useful if you want the component to diplay some error state instead
 * @param options.timeout {Number = 3000} - How long the request stays pending before throwing a
 * a timeout error (in ms)
 * @param options.showRawErrorMessage {Boolean = false} - If you want the default error popup to
 * show the error message directly from the API instead of the generic error message
 * @param options.fetchAll {Boolean | false} - If you want this request
 * to also fetch all possible data, useful in the case of CSV downloads. This will tell the
 * fetchData method to loop over as many requests as needed and return all available data.
 * NOTE: filters will stay applied to any fetch all requests.
 * disregard the main request.
 * @param options.structureAllData {Func = null} - Same principal as @param options.structureData
 * but can be used to manipulate the entire data set. The way @param options.fetchAll works is
 * that it iterates over each page of a request to combine them into one data set, the issue with
 * this is that structure data will be applied to each pages' response, which in the case of
 * something like CSV download might mean you end up with a totals row after each 'page' worth
 * of data, leading to random totals rows in the middle of the data set.
 * Use @param options.structureAllData instead in this case to apply the totals at the end of
 * the entire data set. This option will be disregarded if @param options.fetchAll is not passed.
 *
 * @returns data - The data from a successful request
 * @returns meta - The meta data from a successful request (pagination etc)
 * @returns stats - The request status, one of "idle", "fetching", "fetched", "errored"
 * @returns error - The error object from a failed request, includes a retryFn which is a function
 * that can be called to retry the request
 * @returns retryFn - Returned on a successful request, allows the request to be retried whenver,
 * useful for when filters change or if you just want the user to be able to fetch the latest data
 * @returns allData - Defaults to an empty array. Only if options.fetchAll is passed as true will
 * this ever return data.
 */
export default function useHttpRequest(options = null) {
  const {
    queryStringParams = null,
    params = {},
    body = {},
    method = "GET",
    shouldExecute = true,
    endpoint = undefined,
    authenticated = false,
    fetchAll = false,
  } = options;

  const navigate = useNavigate();
  const location = useLocation();
  const mainPromoter = useAuthMainPromoter();

  const {
    promoter: { details },
  } = Store.getState().auth;

  const { setCurrentEvent } = useContext(AppContext);

  const [state, dispatch] = useRequestReducer();

  const retryFn = (additionalParams = {}) => {
    const dt = Date.now();

    const payload = { retries: state.retries + 1, additionalParams };

    if (method === "GET") {
      payload.timestamp = dt;
    }

    dispatch({
      type: "RETRY",
      payload,
    });
  };

  useEffect(() => {
    const controller = new AbortController();

    shouldReset({ endpoint, method, shouldExecute, body }, details, authenticated, dispatch);

    const fetch = async () => {
      try {
        if (shouldExecute) {
          await fetchData(
            options,
            { mainPromoter, location, state, dispatch, navigate },
            retryFn,
            setCurrentEvent,
            controller,
          );
        }
      } catch (e) {
        console.log("USEHTTPREQUEST CATCH", e);
        // Log them out
        localStorage.clear();
        Store.dispatch(userLoggedOut());
        Store.dispatch(setLoading(false));
        await signOut();
      }
    };

    fetch();

    // eslint-disable-next-line
    return () => {
      if (!fetchAll) {
        controller.abort();
      }
    };
    // WARNING: If this starts to run slow, JSON.stringify may be the culprit
    // this is here as useEffect doesn't do object comparison and causes an infinite
    // loop, stringifying it solves this. As per Dan Abramov: https://github.com/facebook/react/issues/14476#issuecomment-471199055
  }, [
    endpoint,
    shouldExecute,
    JSON.stringify(queryStringParams),
    state.retries,
    JSON.stringify(params),
    JSON.stringify(body),
  ]);

  return state;
}
