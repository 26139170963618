import getIsInMaintenance from "./getIsInMaintenance";
import getVersion from "./getVersion";

export default async function onRouteChange(appVersion?: string): Promise<{
  hasNewVersion: boolean;
  isInMaintenance: boolean;
}> {
  const versionText = await getVersion();
  const isInMaintenance = await getIsInMaintenance();

  return { hasNewVersion: versionText !== appVersion, isInMaintenance };
}
