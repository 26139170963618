import { isAddon } from "../../../../../Utils/ticketHelpers";
import autoStartTicketId from "./autoStartTicketId";
import onSaleDateTime from "./onSaleDateTime";
import startMode from "./startMode";

const startSelling = {
  isGroup: true,
  label: "Start selling tickets",
  fields: [startMode, onSaleDateTime, autoStartTicketId],
  hidden: (formValues, context) => {
    return (
      (formValues.skipTickets === "yes" && !context.currentTicketData.length) ||
      isAddon(context?.qsValues, formValues)
    );
  },
  showDivider: true,
};

export default startSelling;
