import { isAddon } from "../../../../../Utils/ticketHelpers";
import offSaleDateTime from "./offSaleDateTime";
import stopMode from "./stopMode";

const stopSelling = {
  isGroup: true,
  label: "Stop selling tickets",
  fields: [stopMode, offSaleDateTime],
  hidden: (formValues, context) =>
    (formValues.skipTickets === "yes" && !context.currentTicketData.length) ||
    isAddon(context?.qsValues, formValues),
  showDivider: true,
};

export default stopSelling;
