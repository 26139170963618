import React, { Suspense } from "react";
import { Route, createRoutesFromElements } from "react-router-dom";
import Root from "./Components/Layout/Root";
import { ErrorPage } from "./Utils/routes";
import routesConfig from "./Utils/routes/routesConfig";
import { renderRoute, routeDefaults } from "./Utils/routes/routesHelpers";
import LoadingIcon from "./Components/Reusable/LoadingIcon";
import FallbackLoader from "./Components/Reusable/FallbackLoader";

const render = (
  suspense,
  protectedRoute,
  permissions,
  Component,
  children,
  featureFlag,
  ownerOnly,
  promoterPermissions,
  authLoading = false,
) => {
  if (authLoading) {
    return <LoadingIcon />;
  }

  if (suspense) {
    return (
      <Suspense fallback={<FallbackLoader />}>
        {renderRoute(
          protectedRoute,
          permissions,
          Component,
          children,
          featureFlag,
          ownerOnly,
          promoterPermissions,
        )}
      </Suspense>
    );
  }

  return renderRoute(
    protectedRoute,
    permissions,
    Component,
    children,
    featureFlag,
    ownerOnly,
    promoterPermissions,
  );
};

const router = (promoterPermissions, authLoading = false) => [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: createRoutesFromElements(
      <>
        {routesConfig.map(route => {
          const options = { ...routeDefaults, ...route };

          return (
            <Route
              path={route?.path}
              key={route?.path}
              element={render(
                options?.suspense,
                options?.protectedRoute,
                options?.permissions,
                options?.Component,
                options?.children,
                options?.featureFlag,
                options?.ownerOnly,
                promoterPermissions,
                authLoading,
              )}
            />
          );
        })}
      </>,
    ),
  },
];

export default router;
