import lazy from "../../../../../Utils/lazy";

const BookingFee = lazy(() => import("../../../Tickets/Components/BookingFee"));

const bookingFees = {
  name: "bookingFees",
  sendToApi: () => false,
  type: BookingFee,
  hidden: (formValues, context) =>
    formValues.freeTicketOption === "yes" ||
    (formValues.skipTickets === "yes" && !context.currentTicketData.length),
};
export default bookingFees;
