import { Hub } from "aws-amplify/utils";

import confirmSignIn from "./Utils/Auth/confirmSignIn";

export default class AmplifyBridge {
  constructor(store) {
    this.store = store;

    this.onAuthEvent = this.onAuthEvent.bind(this);
    Hub.listen(
      "auth",
      data => {
        const { payload } = data;
        this.onAuthEvent(payload);
      },
      "AmplifyBridge",
    ); // Add this component as a listener of auth events.

    confirmSignIn(this.store.dispatch); // first check
  }

  onAuthEvent(payload) {
    if (
      payload.event === "signedIn" &&
      (window.location.pathname === "/auth/login" || window.location.pathname === "/auth/register")
    ) {
      confirmSignIn(this.store.dispatch); // triggered every time user sign in / out
    }
  }
}
