import {
  MAX_SIZE_READABLE,
  MIN_RESOLUTION_HEIGHT,
  MIN_RESOLUTION_WIDTH,
  MIN_SIZE_READABLE,
} from "../Constants/images";
import { acceptedFileTypes } from "./imageHelpers";

const contactAdmin = " If the problem persists, please contact an administrator.";
const refresh = " Please refresh the page.";
const tryAgain = " Please try again.";
const fatalError = " You can continue editing your event by closing this box or try again.";
const somethingWentWrongGeneric = `Something went wrong with your request.${tryAgain}${contactAdmin}`;

const errorMessages = {
  somethingWentWrongGeneric,
  alreadyInvited: "Cannot invite an existing or pending user.",
  cannotSkipToStep: "You cannot skip to that step yet. Please continue to fill in the form.",
  default: `An error has occurred.${tryAgain}${contactAdmin}`,
  disturbing: "Image contains content highlighted as disturbing. Please use another image.",
  endTimeLast: "Event close time cannot be before last entry.",
  eventCreating: `Error creating event.${refresh}${contactAdmin}`,
  eventEndDateBeforeToday:
    "Event end date cannot be before today. Please correct your start date and duration",
  eventLoading: `Error loading event.${refresh}${contactAdmin}`,
  eventSaving: `There was an error saving the event.${fatalError}${contactAdmin}`,
  eventsLoading: `Error loading events.${refresh}${contactAdmin}`,
  eventUpdate: `Error updating your event.${tryAgain}${contactAdmin}`,
  eventUpdatePermission: "You do not have permission to update this event.",
  eventDelete: `Error deleting event.${tryAgain}${contactAdmin}`,
  eventDeletePermission: "You do not have permission to delete this event.",
  fileType: `Please choose an image file with one of the following extensions: ${
    acceptedFileTypes && acceptedFileTypes.map(string => ` .${string}`)
  }`,
  fileSize: `Please choose a file between ${MIN_SIZE_READABLE} bytes and ${MAX_SIZE_READABLE} bytes`,
  formNotValid: "There are some errors on the page. Please correct them before you move on.",
  imageAtLeastOne: "Please upload at least one image to continue.",
  imageDelete: "Error deleting image.",
  imagePreviouslyUsed: `Something went wrong getting your previously used image.${contactAdmin}`,
  inviteFail: `We could not create the invite at this time.${tryAgain}`,
  lastEntryStartTime: "Last entry cannot be before event start time.",
  lastEntryEndTime: "Last entry cannot be after event end time.",
  multiple: "You have uploaded too many files at once. Please upload only one file at a time",
  noEventId: "No event ID.",
  noPermission:
    "You do not have permission to perform this action. Please ensure you're logged in as the correct user.",
  nudity: "Your image has been flagged as containing 'nudity'. Please use a different image.",
  processing:
    "Error processing this image. Please try again, or try another image. If the problem persists please contact us",
  resolution: `This image is too small. Please try uploading a higher resolution image of at least ${MIN_RESOLUTION_WIDTH} x ${MIN_RESOLUTION_HEIGHT} pixels`,
  startTimeLast: "Event start time cannot be after last entry.",
  text: "Too much text in the image. Please crop the image to remove some text, or use a different image. If you wish to use this image, we may not be able to promote your event fully.",
  thumbnail: `Error displaying cropped image thumbnail.${tryAgain}${contactAdmin}`,

  ticketAdded: `Error adding ticket.${tryAgain}${contactAdmin}`,
  ticketDeleted: `Error deleting ticket.${tryAgain}${contactAdmin}`,
  ticketUpdated: `Error updating ticket.${tryAgain}${contactAdmin}`,
  updateDetails: "Error. Not able to update your details at this time.",
  imageGeneric: "Something went wrong when trying to upload your image, please try again later.",
  fileGeneric: "Something went wrong when trying to upload your file, please try again later.",

  // Cognito errors
  loginNotConfirmed:
    "This account has not been confirmed, please click the link in the confirmation email to confirm your email address",

  switchAccounts: "Something went wrong when switching accounts. Please try again later.",

  // HTTP code errors
  error999:
    "A request was unable to complete, please refresh your browser. If this issue persists please contact Skiddle.",
  error408: "Your request timed out, please try again later or use a more stable connection.",
  error3xx: somethingWentWrongGeneric,
  error5xx: somethingWentWrongGeneric,
  error4xx: somethingWentWrongGeneric,
  error422:
    "Your request could not be processed, this is usually due to a form validation issue, please check all fields are correct and try again.",
};

export default errorMessages;
