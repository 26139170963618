import eventRepStatus from "../../../../../Constants/events/eventRepStatus";
import { isRecurringEvent, isSessionEvent } from "../../../../Layout/Navigation/helpers";
import repCommissionPerTicket from "./repCommissionPerTicket";
import repCommissionType from "./repCommissionType";

const repCommission = {
  isGroup: true,
  label: "Rep commission",
  fields: [repCommissionType, repCommissionPerTicket],
  hidden: (formValues, context) => {
    return (
      formValues.freeTicketOption === "yes" ||
      (formValues.skipTickets === "yes" && !context.currentTicketData.length) ||
      context.event?.repEnabled === eventRepStatus.OFF ||
      context.event?.gateway === "external-stripe" ||
      isRecurringEvent(context.event) ||
      isSessionEvent(context.event)
    );
  },
  showDivider: true,
};

export default repCommission;
