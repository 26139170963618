import React from "react";
import { Tooltip } from "@mui/material";

import PropTypes from "prop-types";
import usePermission from "../../../Hooks/usePermission";

export const DEFAULT_MESSAGE =
  "You do not have the permissions to access this. If you think this is a mistake, please contact your account administrator.";

const Restricted = ({ to, fallbackMessage = DEFAULT_MESSAGE, children, shouldHaveSpan = true }) => {
  const hasAccess = usePermission(to);

  if (hasAccess) {
    return children;
  }

  return (
    <Tooltip title={fallbackMessage}>{shouldHaveSpan ? <span>{children}</span> : children}</Tooltip>
  );
};

Restricted.propTypes = {
  to: PropTypes.number.isRequired,
  fallbackMessage: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  shouldHaveSpan: PropTypes.bool,
};
export default Restricted;
