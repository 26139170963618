import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import { styled, Box, Collapse } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { LinkText, Link, CategoryDropdownSymbol } from "./Styles";
import SidebarLink from "./SidebarLink";
import { AppContext } from "../../../Context";
import useAuth from "../../NamedComponents/Auth/hooks/useAuth";
import { checkUserPermission } from "../../../Utils/permissions";
import ErrorBoundary from "../ErrorBoundary";
import { drawerTransitionTime } from "../../../Constants/layout";
import { isActive } from "./NavLink";

const StyledCollapse = styled(Collapse)`
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

/**
 * Decides whether the dropdown should be open depending
 * on if you're on a child page of the dropdown at the time
 */
const isOpen = (location, routes) => {
  const pathName = location.pathname;

  return routes.some(route => {
    const active = isActive(
      route?.path,
      route?.route,
      location,
      route?.activeOverride || null,
      route?.activeStateFor || [],
      qs.parse(location.search),
      route?.needsMatch,
    );
    if (active) {
      return true;
    }
    let paths = route.path ? [route.path] : null;
    if (route.alternativePaths) {
      paths = [route.path].concat(route.alternativePaths);
    }
    if (paths && paths.length) {
      return paths.some(path => {
        if (route.route) {
          /**
           * Temporary logic specific to View Tickets page
           * to allow it to belong in one nav, but still
           * have edit page URL and params
           */
          const currentRoute = route.route.split("?")[0];
          if (currentRoute === "/events/:id/edit") {
            return path === `${pathName}${location.search}`;
          }
        }
        return path.split("?")[0] === pathName;
      });
    }

    return false;
  });
};

const SidebarLinkDropdown = ({
  name,
  to = null,
  isCollapsable = false,
  onClose = null,
  match = null,
  location,
  isDisabled = () => false,
  show = () => true,
  permissions = null,
  notification = false,
  ...rest
}) => {
  const [open, setOpen] = useState();
  const auth = useAuth();
  const { currentEvent, sidebarOpen } = useContext(AppContext);

  useEffect(() => {
    setOpen(isOpen(location, rest.children(match)));
  }, [location.pathname, location.search]);

  if (!show(match, currentEvent, auth?.promoter?.permissions)) {
    return null;
  }

  if (permissions && !checkUserPermission(auth?.promoter?.permissions, permissions)) {
    return null;
  }

  const categoryProps = { ...rest };
  // props that should not be passed to component
  delete categoryProps.activeOverride;
  delete categoryProps.isOpen;
  delete categoryProps.show;
  delete categoryProps.textStyle;

  return (
    <>
      <ErrorBoundary code="SLD">
        <Link
          button
          dense
          to={to}
          onClose={onClose}
          onClick={() => setOpen(!open)}
          $activeClassName="active"
          disabled={isDisabled(currentEvent)}
          aria-disabled={isDisabled(currentEvent)}
          data-testId={rest.id}
          sx={{ ...categoryProps?.sx, cursor: "pointer" }}
          {...categoryProps}
        >
          <LinkText style={rest?.textStyle} hierarchy={1}>
            {name}
          </LinkText>
          {notification && isCollapsable && !open && (
            <NotificationsIcon style={{ color: "#ff6666", height: "20px" }} />
          )}
          <CategoryDropdownSymbol
            className="sidebar-arrow"
            $sidebarOpen={sidebarOpen}
            $drawerTransitionTime={drawerTransitionTime}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </CategoryDropdownSymbol>
        </Link>
      </ErrorBoundary>
      <StyledCollapse in={open} timeout="auto" unmountOnExit>
        {rest.children(match).map(route => (
          <Box
            key={route.id}
            sx={{
              pl: 5,
            }}
          >
            <ErrorBoundary code="SLD2">
              <SidebarLink
                {...route}
                name={route.name}
                to={route.path}
                icon={route.icon}
                badge={route.badge}
                onClose={onClose}
                location={location}
                match={match}
                isCollapsable={false}
              />
            </ErrorBoundary>
          </Box>
        ))}
      </StyledCollapse>
    </>
  );
};

SidebarLinkDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  badge: PropTypes.string,
  isCollapsable: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  match: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.func,
  show: PropTypes.func,
  permissions: PropTypes.number,
  notification: PropTypes.bool,
};

export default SidebarLinkDropdown;
