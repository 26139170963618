// Import from index.js to save on some clutter
import {
  InviteSplashScreen,
  AccessDenied,
  EventsDashboard,
  LoginAsPromoter,
  OrdersListWrapper,
  Order,
  TicketListDownloadContainer,
  ManageRapidscan,
  RemindMe,
  CoolOff,
  Resell,
  WaitingList,
  EventTracking,
  Reps,
  AllocatedSeating,
  DeleteEvent,
  SingleEventDashboard,
  CheckoutQuestionBuilder,
  EventFlow,
  GuestlistManager,
  PaymentPlanBuilderContainer,
  ManageUsers,
  ManageProfile,
  BrandsList,
  BrandPage,
  EmbedListingsWidget,
  TicketBox,
  RapidScanEntry,
  TicketSalesReport,
  BankDetailsFormContainer,
  BankDetails,
  PaidRemittanceContainer,
  RemittanceBreakdownContainer,
  RemittanceOverviewContainer,
  UnpaidRemittanceContainer,
  SelfBill,
  VATDetails,
  VATReportContainer,
  SelfBillSuccess,
  AuthLayout,
  ErrorPage,
  ChangeEmailCodeConfirm,
  ConfirmSignUp,
  ForgotPassword,
  Register,
  SetNewPassword,
  Finance,
  CancelEvent,
  CreateBrandContainer,
  DiscountCodes,
  DiscountCodeForm,
  Login,
  MyArtists,
  MyVenues,
  Eflyers,
  Videos,
  CampaignStats,
  TicketDelivery,
  AdditionalReports,
  DirectPay,
  TicketOrders,
} from ".";
import Logout from "../../Components/NamedComponents/Auth/Components/Logout";

import { featureFlags } from "../checkFeatureFlag";
import permissions, { rolesBitwise } from "../permissions";

const listingsRole = rolesBitwise["Listings and tickets"];
const customerDataRole = rolesBitwise["Customer data"];
const financeRole = rolesBitwise.Finance;
const analyticsRole = rolesBitwise.Analytics;
const adminRole = rolesBitwise.Admin;
const promoteRole = rolesBitwise.Promote;

const {
  REGISTER,
  FORGOT_PASSWORD,
  RESELL,
  REPS,
  RAPIDSCAN_DEVICE_CODES,
  PROFILE,
  MANAGE_USERS,
  SELF_BILLING,
  BRANDS,
  CREATE_BRAND,
  DISCOUNT_CODES,
  GUESTLIST,
} = featureFlags;

const { CREATE_EVENT, READ_EVENT, UPDATE_EVENT, DELETE_EVENT, CREATE_TICKET, UPDATE_TICKET } =
  permissions();

/**
 * @routesConfig - This is the main config for routes in the app. Set any routes and
 * their properties here.
 *
 * @option {String | required } path - The routes' path.
 * @option {React.Component | optional | default = <Layout />} Layout - The layout to be
 * rendered around the route. Will default to the main layout, use AuthLayout for
 * logged-out pages.
 * @option {Boolean | optional | default = true} Suspense - Whether the route/route group
 * should leverage React.suspense. If set to true make sure to include your component
 * using React.lazy. (see ./index.js for reference), otherwise just import normally.
 * @option {Boolean | optional | default = true} protectedRoute - Whether or not
 * a <ProtectedRoute /> should be rendered around this route. Defaults to true so
 * can be ommitted from the config.
 * @option {Boolean | optional | default = false} routePermissions - The bitwise number
 * for the route permissions. Omit from config if no permissions are needed.
 * @option {React.Component | optional} Component - If there are no child routes this
 * will just be the component the route should render. If there are children this needs
 * to be a component which renders {children} at some point to make sure it renders the
 * child-routes.
 * @option {String | optional | default = false} featureFlag - If the route needs feature
 * flagging, include the constant here.
 * @option {Boolean | optional | default = false} ownerOnly - If the route should be owner-only
 *
 *
 * @option {Array | optional | default = false} children - An array of child-routes
 * A child route object must include @path and @Component and optionally can include
 * @featureFlag
 */
const routesConfig = [
  {
    path: "auth/*",
    Layout: AuthLayout,
    protectedRoute: false,
    children: [
      { path: "login", Component: Login },
      { path: "register", Component: Register, featureFlag: REGISTER },
      { path: "new-password", Component: SetNewPassword },
      { path: "change-email-confirm", Component: ChangeEmailCodeConfirm },
      { path: "forgot-password", Component: ForgotPassword, featureFlag: FORGOT_PASSWORD },
      { path: "confirm", Component: ConfirmSignUp },
      { path: "logout", Component: Logout },
    ],
  },
  // Test route to check error boundary working with
  // Sentry
  {
    path: "/error-boundary",
    Component: () => {
      throw new Error("This is a test error");
    },
    protectedRoute: false,
  },
  {
    path: "/invites-splash",
    Component: InviteSplashScreen,
    protectedRoute: false,
  },
  {
    path: "/access-denied",
    Component: AccessDenied,
    protectedRoute: false,
  },
  {
    path: "/",
    Component: EventsDashboard,
  },
  {
    path: "/error",
    Component: ErrorPage,
    protectedRoute: false,
  },
  {
    path: "events/*",
    permissions: listingsRole,
    children: [
      { path: "/", Component: EventsDashboard, permissions: READ_EVENT },
      { path: ":eventId", Component: SingleEventDashboard, permissions: READ_EVENT },
      { path: ":eventId/new", Component: EventFlow, permissions: CREATE_EVENT },
      { path: ":eventId/edit", Component: EventFlow },
      { path: ":eventId/relist", Component: EventFlow, permissions: CREATE_EVENT | UPDATE_EVENT },
      {
        path: ":eventId/resale",
        Component: Resell,
        featureFlag: RESELL,
        permissions: UPDATE_EVENT,
      },
      {
        path: "create",
        Component: EventFlow,
        permissions: CREATE_EVENT,
      },
      {
        path: ":eventId/guestlist",
        Component: GuestlistManager,
        permissions: UPDATE_TICKET,
        featureFlag: GUESTLIST,
      },
      { path: ":eventId/waiting-list", Component: WaitingList, permissions: UPDATE_TICKET },
      { path: ":eventId/remind-me", Component: RemindMe, permissions: UPDATE_TICKET },
      {
        path: ":eventId/discount-codes",
        Component: DiscountCodes,
        permissions: UPDATE_TICKET,
        featureFlag: DISCOUNT_CODES,
      },
      { path: ":eventId/cool-off", Component: CoolOff, permissions: UPDATE_TICKET },
      { path: ":eventId/ticket-delivery", Component: TicketDelivery, permissions: UPDATE_TICKET },
      {
        path: ":eventId/discount-codes/create",
        Component: DiscountCodeForm,
        permissions: UPDATE_TICKET,
        featureFlag: DISCOUNT_CODES,
      },
      {
        path: ":eventId/discount-codes/:id/edit",
        Component: DiscountCodeForm,
        permissions: UPDATE_TICKET,
        featureFlag: DISCOUNT_CODES,
      },
      { path: ":eventId/tracking", Component: EventTracking, permissions: UPDATE_EVENT },
      { path: ":eventId/website-ticketbox", Component: TicketBox, permissions: UPDATE_EVENT },
      { path: ":eventId/allocated-seating", Component: AllocatedSeating, permissions: READ_EVENT },
      {
        path: ":eventId/payment-plan",
        Component: PaymentPlanBuilderContainer,
        permissions: CREATE_TICKET,
      },
      { path: ":eventId/delete", Component: DeleteEvent, permissions: DELETE_EVENT },
      {
        path: ":eventId/checkout-questions",
        Component: CheckoutQuestionBuilder,
        permissions: UPDATE_EVENT,
      },
      {
        path: ":eventId/event-campaign",
        Component: CampaignStats,
        permissions: UPDATE_EVENT,
      },
      { path: ":eventId/reps", Component: Reps, featureFlag: REPS, permissions: READ_EVENT },
      { path: ":eventId/cancel", Component: CancelEvent, permissions: adminRole },
    ],
  },
  {
    path: "/events/:eventId/orders",
    permissions: customerDataRole,
    Component: OrdersListWrapper,
  },
  {
    path: "/events/:eventId/orders/:orderId",
    permissions: customerDataRole,
    Component: Order,
  },
  {
    path: "/events/:eventId/guestlist/:orderId",
    permissions: customerDataRole,
    Component: Order,
    featureFlag: GUESTLIST,
  },
  {
    path: "/events/:eventId/customer-lists",
    permissions: customerDataRole,
    Component: TicketListDownloadContainer,
  },
  {
    path: "/manage/rapidscan-devices",
    permissions: adminRole,
    Component: ManageRapidscan,
    featureFlag: RAPIDSCAN_DEVICE_CODES,
  },
  {
    path: "/manage/*",
    ownerOnly: true,
    children: [
      {
        path: "/",
        Component: ManageProfile,
        featureFlag: PROFILE,
      },
      {
        path: "profile",
        Component: ManageProfile,
        featureFlag: PROFILE,
      },
      {
        path: "users",
        Component: ManageUsers,
        featureFlag: MANAGE_USERS,
      },
    ],
  },
  {
    path: "/login-as-promoter",
    Component: LoginAsPromoter,
  },
  {
    path: "/orders/*",
    permissions: customerDataRole,
    children: [
      {
        path: "/",
        Component: OrdersListWrapper,
      },
      {
        path: ":orderId",
        Component: Order,
      },
      {
        path: "/tickets-order",
        Component: TicketOrders,
      },
    ],
  },
  {
    path: "promote/*",
    permissions: promoteRole,
    children: [
      {
        path: "/",
        Component: BrandsList,
        featureFlag: BRANDS,
      },
      {
        path: "/brands",
        Component: BrandsList,
        featureFlag: BRANDS,
      },
      {
        path: "/brands/:brandId",
        Component: BrandPage,
        featureFlag: BRANDS,
      },
      {
        path: "/brands/create",
        Component: CreateBrandContainer,
        featureFlag: CREATE_BRAND,
      },
      {
        path: "website-listings-widget",
        Component: EmbedListingsWidget,
      },
      {
        path: "website-ticketbox",
        Component: TicketBox,
      },
      {
        path: "my-artists",
        Component: MyArtists,
      },
      {
        path: "my-venues",
        Component: MyVenues,
      },
      {
        path: "eflyers",
        Component: Eflyers,
      },
      {
        path: "videos",
        Component: Videos,
      },
    ],
  },
  {
    path: "analytics/*",
    permissions: analyticsRole,
    children: [
      {
        path: "/ticket-sales",
        Component: TicketSalesReport,
      },
      {
        path: "/rapidscan-entry",
        Component: RapidScanEntry,
      },
      {
        path: "/additional-reports",
        Component: AdditionalReports,
      },
    ],
  },
  {
    path: "finance/*",
    permissions: financeRole,
    Component: Finance,
    children: [
      {
        path: "/",
        Component: VATReportContainer,
      },
      {
        path: "/remittance",
        Component: RemittanceOverviewContainer,
      },
      {
        path: "/remittance/overview",
        Component: RemittanceOverviewContainer,
      },
      {
        path: "/remittance/unpaid",
        Component: UnpaidRemittanceContainer,
      },
      {
        path: "/remittance/paid",
        Component: PaidRemittanceContainer,
      },
      {
        path: "/remittance/payout-breakdown",
        Component: RemittanceBreakdownContainer,
      },
      {
        path: "/bank-accounts/self-bill",
        Component: SelfBill,
        featureFlag: SELF_BILLING,
      },
      {
        path: "/vat-report",
        Component: VATReportContainer,
      },
      {
        path: "/bank-accounts",
        Component: BankDetails,
      },
      {
        path: "/bank-accounts/create",
        Component: BankDetailsFormContainer,
      },
      {
        path: "/bank-accounts/:id/edit",
        Component: BankDetailsFormContainer,
      },
      {
        path: "/vat-details",
        Component: VATDetails,
      },
      {
        path: "/direct-pay",
        Component: DirectPay,
      },
    ],
  },
  {
    path: "self-bill/success",
    Component: SelfBillSuccess,
  },
];

export default routesConfig;
