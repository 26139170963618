import React, { useContext } from "react";
import { Navigate, useLocation, useNavigate, useRouteError } from "react-router";

import {
  Box,
  Card,
  CardContent as MuiCardContent,
  Typography,
  styled,
  CardActions,
  Paper,
} from "@mui/material";
import { spacing } from "@mui/system";

import errorMessages from "../../../Utils/errorMessages";
import useAuth from "../../NamedComponents/Auth/hooks/useAuth";
import { Button } from "../../Reusable";
import LogoutButton from "../../NamedComponents/Auth/Components/LogoutButton";
import { AppContext } from "../../../Context";
import Page404 from "./Page404";
import AuthLayout from "../AuthLayout";

const CardContent = styled(MuiCardContent)`
  ${spacing};
`;

const Container = styled("div")`
  max-width: 560px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  height: 100vh;
`;

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)};

  position: relative;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)};
  }
`;

const ErrorPage = () => {
  const error = useRouteError();
  const auth = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { isMobile } = useContext(AppContext);

  if (error && error?.status === 404) {
    return (
      <Container>
        <Wrapper>
          <Page404 />
        </Wrapper>
      </Container>
    );
  }

  if (!auth.loading && !auth.isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  if (
    !state?.httpCode &&
    !state?.message &&
    !state?.showLogoutButton &&
    !state?.previousPage &&
    !state?.title
  ) {
    return <Navigate to="/" />;
  }

  const handleTryAgainClick = () => {
    navigate(state?.previousPage || "/");
  };

  return (
    <AuthLayout>
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          width: isMobile ? "100%" : "50%",
          minHeight: "100%",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <Card>
          <CardContent p={8}>
            <Typography variant="h1" gutterBottom>
              {state?.title || "Something went wrong"}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {state?.message || errorMessages.somethingWentWrongGeneric}
            </Typography>
            {state?.httpCode && (
              <Typography variant="caption" gutterBottom>
                Error code: {state?.httpCode}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row-reverse",
                pr: 8,
              }}
            >
              {state?.previousPage && (
                <Button variant="contained" color="primary" ml={2} onClick={handleTryAgainClick}>
                  Try again
                </Button>
              )}
              {state?.showLogoutButton && <LogoutButton variant="outlined" />}
            </Box>
          </CardActions>
        </Card>
      </Box>
    </AuthLayout>
  );
};

export default ErrorPage;
