import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Grid2 as Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { confirmResetPassword } from "aws-amplify/auth";
import getAwsErrorMessage from "../../../../Utils/getAwsErrorMessage";
import { passwordRegex } from "../../../../Constants/regex";

const ForgotPasswordCode = ({ setStep, setError, email, setCodeError, qsValues }) => {
  const navigate = useNavigate();
  const { code: qsCode } = qsValues;

  const handleCode = async (vals, { setSubmitting }) => {
    const { oneTimeCode: code, password } = vals;
    setSubmitting(true);
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: password,
      });
      setStep(2);
      setTimeout(() => navigate("/"), 3000);
      setError(undefined);
    } catch (err) {
      const message = getAwsErrorMessage(err);
      setError(message);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      oneTimeCode: Yup.string().required("Your One Time Code is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(passwordRegex.regex, passwordRegex.explanation),
      passwordConfirm: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    initialValues: {
      oneTimeCode: qsCode || "",
      password: "",
      passwordConfirm: "",
    },
    onSubmit: handleCode,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = formik;
  const fields = ["oneTimeCode", "password", "passwordConfirm"];

  useEffect(() => {
    setCodeError(fields.some(f => errors[f] && touched[f]));
  }, [errors]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={4}
        sx={{
          justifyContent: "flex-end",
        }}
      >
        <Grid size={12}>
          <TextField
            id="oneTimeCode"
            variant="outlined"
            label="One Time Code"
            fullWidth
            name="oneTimeCode"
            value={values.oneTimeCode}
            placeholder="One Time Code"
            error={Boolean(touched.oneTimeCode && errors.oneTimeCode)}
            helperText={touched.oneTimeCode && errors.oneTimeCode}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            autoComplete="off"
            disabled={qsCode}
          />
        </Grid>

        <Grid size={12}>
          <TextField
            id="password"
            variant="outlined"
            label="New Password"
            fullWidth
            name="password"
            value={values.password}
            placeholder="New Password"
            error={Boolean(errors.password && touched.password)}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            autoComplete="new-password"
            helperText={touched.password && errors.password}
            required
          />
        </Grid>
        <Grid size={12}>
          <TextField
            id="passwordConfirm"
            variant="outlined"
            label="Confirm Password"
            fullWidth
            name="passwordConfirm"
            value={values.passwordConfirm}
            placeholder="Confirm password"
            error={Boolean(errors.passwordConfirm && touched.passwordConfirm)}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            autoComplete="new-password"
            helperText={touched.passwordConfirm && errors.passwordConfirm}
            required
          />
        </Grid>

        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            mb={2}
            mt={2}
            type="button"
            disabled={isSubmitting}
            onClick={() => setStep(0)}
          >
            Back
          </Button>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            mb={2}
            mt={2}
            type="submit"
            disabled={isSubmitting}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ForgotPasswordCode.propTypes = {
  setStep: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setCodeError: PropTypes.func.isRequired,
  qsValues: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default ForgotPasswordCode;
