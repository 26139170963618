import React from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuList as MuiMenuList,
  MenuItem,
  Typography as MuiTypography,
  Box,
  Button,
  Grid2 as Grid,
  IconButton as MuiIconButton,
  styled,
} from "@mui/material";
import { spacing } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import isFeatureFlagOn from "../../../Utils/checkFeatureFlag";

const Typography = styled(MuiTypography)(spacing);

const Notification = styled(MenuItem)`
  white-space: break-spaces;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  z-index: 1;
`;

const ClearButton = styled("button")`
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  display: flex;
  z-index: 4;
  align-items: center;
  justify-content: center;
  background: transparent;
  font-size: 22px;
  cursor: pointer;
`;

const IconButton = styled(MuiIconButton)`
  color: ${({ theme: { palette } }) => palette.skiddle.black};
  padding: 0;
`;

const MenuList = styled(MuiMenuList)`
  max-height: 280px;
  overflow: auto;
`;

const MobileNotifications = ({
  notifications,
  handleClearAllNotifications,
  handleClearNotification,
}) => {
  const navigate = useNavigate();

  if (!isFeatureFlagOn("notifications")) {
    return null;
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Notifications</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MenuList id="menu-list-grow">
          {notifications.length && notifications !== undefined ? (
            <Box
              sx={{
                pb: 3,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClearAllNotifications()}
                fullWidth
              >
                Clear all notifications
              </Button>
            </Box>
          ) : (
            <Typography variant="subtitle1">No new notifications</Typography>
          )}
          {notifications.map(n => (
            <Notification>
              <Grid
                container
                sx={{
                  alignItems: "center",
                }}
              >
                <Grid
                  tag={n.tag}
                  key={n.id}
                  onClick={() => navigate(`/promoter/manage?inviteId=${n.inviteId}`)}
                  size="11"
                >
                  <Typography variant="subtitle2">{n.title}</Typography>
                  <Typography variant="body1">
                    {n.description.length > 75 ? `${n.description.slice(0, 75)}...` : n.description}
                  </Typography>
                </Grid>
                <Grid size="1">
                  <ClearButton
                    variant="primary"
                    onClick={() => {
                      handleClearNotification(n.id);
                    }}
                  >
                    <IconButton size="small" edge="end">
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </ClearButton>
                </Grid>
              </Grid>
            </Notification>
          ))}
        </MenuList>
      </AccordionDetails>
    </Accordion>
  );
};

MobileNotifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleClearNotification: PropTypes.func.isRequired,
  handleClearAllNotifications: PropTypes.func.isRequired,
};
export default MobileNotifications;
