const TICKET_AVAILABILITY_STATUS = {
  STANDARD: "a",
  BOX_OFFICE: "b",
  NO_BOOKING_FEE: "f",
  PASSWORDED: "p",
  REP_REWARD: "r",
  PAPER: "z",
};

export default TICKET_AVAILABILITY_STATUS;
