import * as Yup from "yup";
import lazy from "../../../../../Utils/lazy";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);
const coolingOffListingEnabled = {
  name: "coolingOffListingEnabled",
  displayName: () => "",
  // sendToApi: (key, formValues, initialValues) => hasValueChanged(key, formValues, initialValues),
  sendToApi: () => true,
  defaultValue: "1",
  type: InputRadio,
  options: [
    { value: "yes", label: "On", grid: { md: 6 } },
    { value: "no", label: "Off", grid: { md: 6 } },
  ],
  validation: () => Yup.string().required("Please choose at least one option"),
  errorFriendlyName: "Cool off",
};
export default coolingOffListingEnabled;
