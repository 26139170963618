import React from "react";
import { isRecurringEvent, isSessionEvent } from "../../../../Layout/Navigation/helpers";
import lazy from "../../../../../Utils/lazy";

const TicketDependency = lazy(
  () => import("../../../Tickets/Components/AdvancedOptions/TicketDependency"),
);

const revealTicketId = {
  name: "revealTicketId",
  displayName: () => "Set ticket dependency",
  defaultValue: [],
  type: TicketDependency,
  sendToApi: () => true,
  advancedOption: true,
  paragraph: (
    <>
      <br />
      If this ticket should be hidden unless another type of ticket is purchased, please select the
      ticket(s) that must be purchased here.
      <br />
      <br />
      For example, a child may only be purchased with an adult ticket.
    </>
  ),
  postFormat: value => (value.length ? value.map(v => v.value || v).join(",") : ""),
  hidden: (_, { event }) => {
    return isRecurringEvent(event) || isSessionEvent(event);
  },
};
export default revealTicketId;
