import React from "react";

import { Divider, Typography } from "@mui/material";

import AuthLayout from "./AuthLayout";
import LogoutButton from "../NamedComponents/Auth/Components/LogoutButton";
import SwitchToMainAccount from "./SwitchToMainAccount";

const PendingAccount = () => (
  <AuthLayout>
    <Typography variant="h6">
      This account is currently pending email verification, please contact the account owner.
    </Typography>
    <SwitchToMainAccount sx={{ mt: 4 }} />
    <Divider sx={{ my: 6 }} />
    <LogoutButton />
  </AuthLayout>
);

export default PendingAccount;
