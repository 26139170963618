import { radioOptions } from "../../../../../Constants/tickets/ticketOptions";
import lazy from "../../../../../Utils/lazy";
import { isSessionEvent } from "../../../../Layout/Navigation/helpers";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);

const waitingListEligible = {
  name: "waitingListEligible",
  displayName: () => "Turn off waiting list for ticket?",
  sendToApi: () => true,
  handleClick: (action, form) => form.addValue("waitingListEligible", action),
  type: InputRadio,
  options: radioOptions,
  advancedOption: true,
  defaultValue: "yes",
  placeholder: "Ticket to be eligible for waiting list?",
  postFormat: val => (val === "no" || val === 0 ? 1 : 0),
  hidden: (_, { event }) => isSessionEvent(event),
};

export default waitingListEligible;
