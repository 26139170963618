import Store from "../../Store";
import { handleError } from "../../Utils/handleApiErrors";
import date from "../../Utils/date";
import addOrUpdateAdminPanelRequest from "../../Utils/AdminPanel/addOrUpdateAdminPanelRequest";
import { ABORTED, ERRORED, FETCHED, FETCHING, NETWORK_ERR } from "../../Constants/requestStatuses";
import handleMock from "./handleMock";
import buildQueryString from "./buildQueryString";
import buildHeaders from "./buildHeaders";
import fetchAllData from "./fetchAllData";
import { getMainPromoter } from "../../Components/NamedComponents/Auth/hooks/useAuthMainPromoter";
import checkIsExpired from "../../Utils/Auth/checkIsExpired";
import prepareRequest from "./prepareRequest";
import { constructAdminPanelObject } from "./utils";
import makeRequest from "./makeRequest";
import formatRequestResponse from "./formatRequestResponse";
import getIdToken from "../../Utils/Auth/getIdToken";

const fetchData = async (options, hooks, retryFn, setCurrentEvent, controller = {}, key = null) => {
  const token = await getIdToken();

  const mainPromoter = getMainPromoter();
  const {
    queryStringParams = null,
    body = {},
    method = "GET",
    structureData = null,
    shouldExecute = true,
    showGlobalError = true,
    endpoint = undefined,
    authenticated = false,
    cacheBust = false,
    showRawErrorMessage = false,
    mockData = null,
    mockStatus = 200,
    mockError = null,
    redirectOn403 = true,
    fetchAll,
    structureAllData,
  } = options;

  const {
    promoter: { details, promoterId, mainAccount },
  } = Store.getState().auth;

  const { uuid, startTime, timeout, isMock, url } = prepareRequest(options);

  const adminPanelObject = constructAdminPanelObject(
    method,
    url,
    timeout,
    endpoint,
    shouldExecute,
    body,
    hooks,
  );

  if (mainPromoter?.skiddleStaff) {
    addOrUpdateAdminPanelRequest(uuid, {
      ...adminPanelObject,
      params: {},
      queryStringParams,
      headers: {},
      date: date(),
      requestId: uuid,
      startTime,
      endTime: null,
      requestStatus: FETCHING,
    });
  }

  hooks.dispatch({ type: "FETCHING", payload: { key } });

  // If logged in as a user, check the expiry and logout if expired
  if (!mainAccount && authenticated) {
    await checkIsExpired(promoterId, details?.name, hooks?.navigate);
  }

  // Return mockData
  if (isMock) {
    handleMock(
      {
        showGlobalError,
        showRawErrorMessage,
        mockError,
        mockStatus,
        fetchAll,
        structureData,
        requestId: uuid,
      },
      hooks?.navigate,
      hooks?.dispatch,
      retryFn,
      mockData,
      hooks?.state,
      key,
    );
    return;
  }

  const queryString = buildQueryString(
    queryStringParams,
    hooks?.state,
    authenticated,
    promoterId,
    cacheBust,
    fetchAll,
    method,
  );

  const { headersToLog, headers } = buildHeaders(token, authenticated, method);

  try {
    const { res, meta, links, axiosResponse } = await makeRequest(
      method,
      url,
      headers,
      queryString,
      timeout,
      body,
      { key, controller, setCurrentEvent, endpoint, structureData },
    );

    const pay = formatRequestResponse(
      res,
      meta,
      links,
      retryFn,
      axiosResponse?.status,
      hooks?.state?.retries,
    );

    if (Object.keys(pay).length && mainPromoter?.skiddleStaff) {
      addOrUpdateAdminPanelRequest(uuid, {
        ...pay,
        ...adminPanelObject,
        queryStringParams: queryString,
        headers: { ...headersToLog, Authorization: `Bearer ${token}` },
        date: date(),
        startTime,
        endTime: date(),
        key,
        requestStatus: FETCHED,
      });
    }

    hooks.dispatch({
      type: "FETCHED",
      payload: { ...pay, key },
    });

    // If there's no meta, then it's probably not a paginated request
    // so all data should be available regardless. If there is meta data
    // and total results is greater than the response length, then we
    // know there are more results to fetch.
    const hasMoreResults = meta && meta?.totalResults > res.length;

    if (fetchAll && hasMoreResults) {
      hooks?.dispatch({
        type: "FETCHING_ALL",
        payload: { key, meta, progress: 0 },
      });
      fetchAllData(
        meta,
        {
          method,
          url,
          headers,
          queryString,
          structureData,
          structureAllData,
          pay,
          key,
          timeout,
        },
        controller,
        hooks?.dispatch,
      );
    } else if (fetchAll && !hasMoreResults) {
      // If fetchAll is true but we already got all
      // the results from the initial request
      hooks?.dispatch({
        type: "FETCHED_ALL",
        payload: { ...pay, key, allData: structureAllData(res) },
      });
    }
  } catch (e) {
    if (e?.code === "ERR_NETWORK") {
      addOrUpdateAdminPanelRequest(uuid, {
        ...adminPanelObject,
        params: queryString,
        headers: { ...headersToLog, Authorization: `Bearer ${token}` },
        date: date(),
        startTime,
        endTime: date(),
        key: "",
        requestStatus: NETWORK_ERR,
      });
      await handleError(
        e,
        {
          request: {
            ...adminPanelObject,
            params: queryString,
            headers: {
              ...headersToLog,
              Authorization: `Bearer ${token}`,
            },
            date: date(),
            startTime,
            endTime: date(),
            key: "",
            requestStatus: NETWORK_ERR,
          },
          authenticated,
          showGlobalError,
          showRawErrorMessage,
          redirectOn403,
          requestId: uuid,
          timeout,
        },
        hooks?.navigate,
      );
      return;
    }

    if (e?.message === "canceled") {
      addOrUpdateAdminPanelRequest(uuid, {
        ...adminPanelObject,
        params: queryString,
        headers: { ...headersToLog, Authorization: `Bearer ${token}` },
        date: date(),
        startTime,
        endTime: date(),
        key,
        requestStatus: ABORTED,
      });
      return;
    }

    const err = await handleError(
      e,
      {
        showGlobalError,
        showRawErrorMessage,
        redirectOn403,
        requestId: uuid,
        timeout,
        authenticated,
        request: {
          ...adminPanelObject,
          params: queryString,
          headers: {
            ...headersToLog,
            Authorization: `Bearer ${token}`,
          },
          date: date(),
          startTime,
          endTime: date(),
          key: "",
        },
      },
      hooks?.navigate,
    );

    const reduxErr = { ...err };
    delete reduxErr.htmlError;
    delete reduxErr.request;
    delete reduxErr.config;
    delete reduxErr.message;

    if (mainPromoter?.skiddleStaff) {
      let { status } = err;
      if (e?.response?.status) {
        status = e?.response?.status;
      }
      addOrUpdateAdminPanelRequest(uuid, {
        ...adminPanelObject,
        queryStringParams: queryString,
        headers: { ...headersToLog, Authorization: `Bearer ${token}` },
        timestamp: date(),
        date: date(),
        status,
        error: { ...reduxErr, response: { ...e?.response?.data } },
        response: { ...e?.response?.data },
        startTime,
        endTime: date(),
        key,
        requestStatus: ERRORED,
      });
    }

    hooks.dispatch({
      type: "FETCH_ERROR",
      payload: {
        error: err.message || e,
        retryFn,
        retries: hooks?.state.retries,
        status: err.status,
        ...err,
        key,
      },
    });
  }
};

export default fetchData;
