import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Grid2 as Grid, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { resetPassword } from "aws-amplify/auth";
import { captureException, withScope } from "@sentry/react";

import getAwsErrorMessage from "../../../../Utils/getAwsErrorMessage";
import sanitiseEmail from "../../../../Utils/Auth/sanitiseEmail";
import sentryTags from "../../../../Constants/sentryTags";

const ForgotPasswordEmail = ({
  setStep,
  setError,
  setStepOneComplete,
  setEmail,
  setEmailError,
}) => {
  const handleEmail = async (vals, { setSubmitting }) => {
    setEmail(sanitiseEmail(vals.email));
    setSubmitting(true);
    try {
      await resetPassword({ username: sanitiseEmail(vals.email) });
      setStep(1);
      setError(undefined);
    } catch (err) {
      withScope(scope => {
        scope.setTags({ ...scope?._tags, ...sentryTags.auth });
        scope.setUser({ ...scope?._user, email: vals?.email });
        captureException(err);
      });
      const message = getAwsErrorMessage(err);
      setError(message);
    }
    setSubmitting(false);
  };
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email address is required"),
      oneTimeCode: Yup.string(),
    }),
    initialValues: {
      email: "",
      oneTimeCode: "",
    },
    onSubmit: handleEmail,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = formik;
  const fields = ["email"];
  const formIsComplete = !fields.some(f => !values[f] || errors[f]);

  useEffect(() => {
    setEmailError(fields.some(f => errors[f] && touched[f]));
  }, [errors]);

  useEffect(() => {
    setStepOneComplete(formIsComplete);
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={4}
        sx={{
          justifyContent: "flex-end",
        }}
      >
        <Grid size={12}>
          <TextField
            id="email"
            variant="outlined"
            label="Email address"
            fullWidth
            name="email"
            value={values.email}
            placeholder="Email address"
            error={Boolean(touched.email && errors.email)}
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            autoComplete="email"
            helperText={touched.email && errors.email}
            required
          />
        </Grid>

        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Grid
            container
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              mb={2}
              mt={2}
              type="submit"
              disabled={!formIsComplete || isSubmitting}
            >
              Send code
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

ForgotPasswordEmail.propTypes = {
  setStep: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setStepOneComplete: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setEmailError: PropTypes.func.isRequired,
};
export default ForgotPasswordEmail;
