import useAuth from "../Components/NamedComponents/Auth/hooks/useAuth";
import { checkUserPermission } from "../Utils/permissions";

const usePermission = permission => {
  const {
    promoter: { permissions },
  } = useAuth();

  return checkUserPermission(permissions, permission);
};

export default usePermission;
