import { configureStore } from "@reduxjs/toolkit";
import { createReduxEnhancer } from "@sentry/react";

import AuthReducer from "./reducers/AuthReducer";
import AdminPanelReducer from "./reducers/AdminPanelReducer";
import RequestCacheReducer from "./reducers/RequestCacheReducer";
import ApiVersionReducer from "./reducers/ApiVersionReducer";
import NetworkReducer from "./reducers/NetworkReducer";

const sentryReduxEnhancer = createReduxEnhancer({});

export default configureStore({
  reducer: {
    auth: AuthReducer,
    adminPanel: AdminPanelReducer,
    requestCache: RequestCacheReducer,
    apiVersion: ApiVersionReducer,
    network: NetworkReducer,
    sentryReduxEnhancer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
