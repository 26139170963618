import { Paper, styled } from "@mui/material";

interface WrapperProps {
  $minHeight?: string;
  $minWidth?: string;
}

const AuthWrapper = styled(Paper)<WrapperProps>`
  padding: ${props => props.theme.spacing(6)};

  position: relative;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)};
  }

  min-height: ${({ $minHeight }) => $minHeight ?? "calc(533px + 40px)"};
  min-width: ${({ $minWidth }) => $minWidth ?? "calc(480px + 40px)"};

  ${props => props.theme.breakpoints.down("md")} {
    width: 100%;
    min-width: 100%;
  }
`;

export default AuthWrapper;
