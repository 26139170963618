import React from "react";
import PropTypes from "prop-types";

import {
  Grid2 as Grid,
  styled,
  Popper as MuiPopper,
  Grow,
  Box,
  ClickAwayListener,
  Typography,
} from "@mui/material";

const Popper = styled(MuiPopper)`
  box-shadow: rgba(51, 51, 51, 0.075) 0px 1px 12px;
  background-color: ${({ theme: { palette } }) => palette.skiddle.white};
  border-radius: 3px;
`;

const boxPadding = 5;

const DesktopHeaderPopout = ({
  elementRef,
  open,
  setOpen,
  title = null,
  verticalOffset = null,
  children,
}) => {
  const handleClose = event => {
    if (
      (elementRef.current && elementRef.current.contains(event.target)) ||
      (event.target.id && event.target.id === "body")
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Popper
      open={open}
      anchorEl={elementRef.current}
      transition
      disablePortal
      placement="bottom-end"
      style={{ top: `-${boxPadding}px`, left: `-${boxPadding}px` }}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [15, verticalOffset || 22],
          },
        },
      ]}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === "bottom-end" ? "right top" : "right bottom",
            top: `-${boxPadding}px`,
            left: `-${boxPadding}px`,
          }}
        >
          <Box
            sx={{
              p: 2,
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Grid
                container
                spacing={3}
                sx={{
                  alignItems: "center",
                }}
              >
                {title && (
                  <Grid size={12}>
                    <Typography variant="h4">{title}</Typography>
                  </Grid>
                )}
                {children}
              </Grid>
            </ClickAwayListener>
          </Box>
        </Grow>
      )}
    </Popper>
  );
};

DesktopHeaderPopout.propTypes = {
  elementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  verticalOffset: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default DesktopHeaderPopout;
