import { styled } from "@mui/material";

const AuthRoot = styled("div")`
  max-width: 560px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  height: 100vh;
`;

export default AuthRoot;
