import * as Yup from "yup";
import lazy from "../../../../../Utils/lazy";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);

const freeTicketOption = {
  name: "freeTicketOption",
  displayName: () => "Is this a free ticket?",
  type: InputRadio,
  options: [
    { value: "no", label: "No", grid: { md: 6 } },
    { value: "yes", label: "Yes", grid: { md: 6 } },
  ],
  defaultValue: "no",
  validation: () =>
    Yup.string().when("skipTickets", {
      is: "no",
      then: schema => schema.required("Please choose whether this is a free ticket"),
      otherwise: schema => schema,
    }),
  sendToApi: () => false,
  hidden: formValues =>
    (formValues.ticketsSold && formValues.ticketsSold > 0) ||
    (formValues.masterSalesCount && formValues.masterSalesCount > 0) ||
    formValues.customFeesApplied === 1,
};

export default freeTicketOption;
