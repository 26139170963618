import { v4 } from "uuid";
import date from "../../Utils/date";
import { TIMEOUT } from "../../Constants/requests";
import replaceParams from "./replaceParams";
import getApiVersion from "../../Utils/getApiVersion";

const { VITE_API_URL: apiUrl } = import.meta.env;

export default options => {
  // Unique ID for this request, used in admin panel
  const uuid = v4();
  // Start time of request, used in admin panel
  const startTime = date();

  const timeout = options?.timeout || TIMEOUT;
  const isMock = options?.mock && (options?.mockData || options?.mockError);

  // Replace params, e.g. `params: { id: 123 } | /endpoint/:id => /endpoint/123
  const url = replaceParams(options.endpoint, options?.params);

  const apiUrlVersion = getApiVersion();

  const endpointUrl = `${apiUrl}/${apiUrlVersion}${url}`;

  return {
    uuid,
    startTime,
    timeout,
    isMock,
    url: options?.isExternal ? url : endpointUrl,
  };
};
