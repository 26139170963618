import get from "lodash/get";
import Store from "../Store";

export const featureFlags = {
  // For tests
  TEST_FLAG_OFF: "TEST_FLAG_OFF",
  TEST_FLAG_ON: "TEST_FLAG_ON",
  DASHBOARD_DOUGHNUT: "DASHBOARD_DOUGHNUT",
  EVENT_DASHBOARD_TIPS: "EVENT_DASHBOARD_TIPS",
  EMBARGO: "EMBARGO",
  COPY_TICKET: "COPY_TICKET",
  DASHBOARD_FILTERS: "DASHBOARD_FILTERS",
  DASHBOARD_ALL_TIME: "DASHBOARD_ALL_TIME",
  BULK_EDIT_EVENTS: "BULK_EDIT_EVENTS",
  EVENT_SWITCH_VISIBILITY: "EVENT_SWITCH_VISIBILITY",
  EVENT_SWITCH_STATUS: "EVENT_SWITCH_STATUS",
  CUSTOM_STATS: "CUSTOM_STATS",
  TICKET_BOX: "TICKET_BOX",
  TRACKING: "TRACKING",
  BULK_EDIT_TICKETS: "BULK_EDIT_TICKETS",
  REMIND_ME: "REMIND_ME",
  WAITLIST: "WAITLIST",
  RESELL: "RESELL",
  REORDER_TICKETS: "REORDER_TICKETS",
  TICKET_OPTIONS: "TICKET_OPTIONS",
  WAITING_LIST: "WAITING_LIST",
  GUESTLIST: "GUESTLIST",
  GUESTLIST_CSV: "GUESTLIST_CSV",
  GUESTLIST_CSV_TEMPLATE: "GUESTLIST_CSV_TEMPLATE",
  NOTIFICATIONS: "NOTIFICATIONS",
  BULK_COPY_DATES: "BULK_COPY_DATES",
  PAYMENT_PLAN_BUILDER: "PAYMENT_PLAN_BUILDER",
  TICKET_PASSWORDS: "TICKET_PASSWORDS",
  AUDIT_LOG: "AUDIT_LOG",
  TICKET_LIST_DOWNLOAD: "TICKET_LIST_DOWNLOAD",
  ORDER_ADMIN: "ORDER_ADMIN",
  AUTO_REFRESH_STATS: "AUTO_REFRESH_STATS",
  INDIVIDUAL_REFUNDS: "INDIVIDUAL_REFUNDS",
  CHECKOUT_QUESTIONS: "CHECKOUT_QUESTIONS",
  TICKET_SALES_REPORT: "TICKET_SALES_REPORT",
  VAT_BREAKDOWN_REPORT: "VAT_BREAKDOWN_REPORT",
  VAT_DETAILS: "VAT_DETAILS",
  PROFILE: "PROFILE",
  PERMISSIONS: "PERMISSIONS",
  MANAGE_USERS: "MANAGE_USERS",
  REGISTER: "REGISTER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  BANK_ACCOUNTS: "BANK_ACCOUNTS",
  REMITTANCE_OVERVIEW: "REMITTANCE_OVERVIEW",
  REMITTANCE_PAID: "REMITTANCE_PAID",
  REMITTANCE_UNPAID: "REMITTANCE_UNPAID",
  REMITTANCE_BREAKDOWN: "REMITTANCE_BREAKDOWN",
  INTERCOM: "INTERCOM",
  DASHBOARD_GROUPBY: "DASHBOARD_GROUPBY",
  SELF_BILLING: "SELF_BILLING",
  RAPIDSCAN_ENTRY: "RAPIDSCAN_ENTRY",
  RAPIDSCAN_DEVICE_CODES: "RAPIDSCAN_DEVICE_CODES",
  REOPEN: "REOPEN",
  FULLSCREEN: "FULLSCREEN",
  ELIGIBLE_FOR_PAYOUT_DASHBOARD: "ELIGIBLE_FOR_PAYOUT_DASHBOARD",
  REPS: "REPS",
  BRANDS: "BRANDS",
  BRAND_EVENTS: "BRAND_EVENTS",
  BRAND_FAQS: "BRAND_FAQS",
  BRAND_REVIEW_REPORT: "BRAND_REVIEW_REPORT",
  BRAND_REVIEW_RESPOND: "BRAND_REVIEW_RESPOND",
  BRAND_REVIEW_DISPUTED_FILTER: "BRAND_REVIEW_DISPUTED_FILTER",
  EDIT_BRAND: "EDIT_BRAND",
  CREATE_BRAND: "CREATE_BRAND",
  DELETE_BRAND: "DELETE_BRAND",
  SENTRY: "SENTRY",
  SKIDDLE_STAFF: "SKIDDLE_STAFF",
  DISCOUNT_CODES: "DISCOUNT_CODES",
  WAITING_LIST_PROMPT: "WAITING_LIST_PROMPT",
  PREMIUM_REMIND_ME: "PREMIUM_REMIND_ME",
  PROMOTION_USAGE: "PROMOTION_USAGE",
  SESSION_BASED_TICKETING: "SESSION_BASED_TICKETING",
  SESSIONS_DASHBOARD: "SESSIONS_DASHBOARD",
  RECURRING_EVENTS: "RECURRING_EVENTS",
  RECURRING_EVENTS_DASHBOARD: "RECURRING_EVENTS_DASHBOARD",
  GROUP_EVENTS: "GROUP_EVENTS",
  GROUP_RECURRING_EVENTS: "GROUP_RECURRING_EVENTS",
  SUGGESTED_IMAGES: "SUGGESTED_IMAGES",
  PREVIOUS_IMAGES: "PREVIOUS_IMAGES",
  ORDERS_CSV_DOWNLOAD: "ORDERS_CSV_DOWNLOAD",
  ORDERS_CSV_DOWNLOAD_SINGLE: "ORDERS_CSV_DOWNLOAD_SINGLE",
  CANCEL_EVENT: "CANCEL_EVENT",
  CAMPAIGN_STATS: "CAMPAIGN STATS",
  ORDER_TICKETS_OFFLINE_SALES: "ORDER_TICKETS_OFFLINE_SALES",
  STRIPE_DIRECT_PAY: "STRIPE_DIRECT_PAY",
  MY_ARTISTS: "MY_ARTISTS",
  MY_VENUES: "MY_VENUES",
  EFLYERS: "EFLYERS",
  VIDEOS: "VIDEOS",
  ADDITIONAL_REPORTS: "ADDITIONAL_REPORTS",
  ADMIN_PANEL: "ADMIN_PANEL",
  COOL_OFF: "COOL_OFF",
  TICKET_DELIVERY_OPTIONS: "TICKET_DELIVERY_OPTIONS",
  SBT_ADDONS: "SBT_ADDONS",
  HELP_TOUR: "HELP_TOUR",
  BRAND_TRACKING: "BRAND_TRACKING",
  LAST_ENTRY_STOP_MODE: "LAST_ENTRY_STOP_MODE",
  EVENT_END_STOP_MODE: "EVENT_END_STOP_MODE",
  // TODO: Remove after maintenance
  TEMP_MAINTENANCE_WARNING: "TEMP_MAINTENANCE_WARNING",
};

const FEATURE_FLAG_VALUES = {
  OFF: 0,
  ON_ALL: 1,
  ON_SKIDDLE: "SKIDDLE_STAFF",
  ON_DEV: "DEV_ACCOUNT",
};

const isPromoterInPercentage = (perc, promoterId) =>
  Number(perc) >= Number(promoterId.toString().slice(-2));

/**
 * Check if a given feature flag is switched on
 *
 * @param {String} name
 * @returns {Boolean}
 */
const isFeatureFlagOn = (name, strictCheck = false) => {
  const store = Store.getState();
  const { promoterId } = store.auth.promoter;

  const mainPromoter =
    store?.auth?.userData &&
    get(store, `auth.userData.promoters[${store.auth.userData.mainPromoterId}]`, {});
  const featureFlag =
    Object.keys(import.meta.env).find(key => key === `VITE_FEATURE_${name.toUpperCase()}`) ||
    `VITE_FEATURE_${name.toUpperCase()}`;
  // If strictCheck is true, only check import.meta.env, not session storage
  // This is for sensitive flags that people could feasibly give themselves
  // by adding them to session storage.
  let value = import.meta.env[featureFlag];

  if (!strictCheck && sessionStorage.getItem(featureFlag)) {
    value = sessionStorage.getItem(featureFlag);
  }
  // If feature flag is explicitly off, return false
  if (Number(value) === FEATURE_FLAG_VALUES.OFF) {
    return false;
  }

  // If the string length is greater than 1, then it can't be "1" or "0",
  // so we assume it's a CSV of strings and/or a percentage
  if (value && value.length > 1) {
    const split = value.split(",");

    // Any promoters who have the FF on for them
    const includedPromoters = split.filter(pId => !pId.includes("!"));

    // Any promoters who have a ! at the start of the Pid, meaning they
    // should be excluded from the feature
    const excludedPromoters = split
      .filter(pId => pId.includes("!"))
      .map(pId => pId.replace("!", ""));

    const percentageArr = split.filter(s => s.includes("%"));
    // If for whatever reason there's more than 1 percentage, take the last one
    const percentage = percentageArr.map(s => s.replace("%", ""))[percentageArr.length - 1];

    if (
      excludedPromoters.length &&
      promoterId &&
      excludedPromoters.indexOf(promoterId.toString()) !== -1
    ) {
      // Explicitly excluded, so always return false
      return false;
    }

    if (
      includedPromoters.length &&
      promoterId &&
      includedPromoters.indexOf(promoterId.toString()) !== -1
    ) {
      // Explicitly included, so always true
      return true;
    }

    // A percentage of promoters should be allowed access
    if (percentage && Number(percentage) > 0 && Number(percentage) < 100) {
      return isPromoterInPercentage(percentage, promoterId);
    }

    // If SKIDDLE_STAFF is present in the flag and the account is skiddleStaff
    if (split.some(s => s === FEATURE_FLAG_VALUES.ON_SKIDDLE)) {
      return mainPromoter?.skiddleStaff;
    }

    // If DEV_ACCOUNT is present in the flag and the promoterID matches that test ID
    if (split.some(s => s === FEATURE_FLAG_VALUES.ON_DEV)) {
      return Number(promoterId || 0) === Number(import.meta.env.VITE_TEST_PROMOTER_ID);
    }

    return value.split(",").some(pId => Number(pId) === Number(promoterId || 0));
  }

  // If enable all is true or this feature flag is explicitly set to on
  return (
    Number(import.meta.env.VITE_FEATURE_ENABLE_ALL) === FEATURE_FLAG_VALUES.ON_ALL ||
    Number(value) === FEATURE_FLAG_VALUES.ON_ALL
  );
};

export default isFeatureFlagOn;
