/**
 * At least one lower case English letter, (?=.*?[a-z])
 * At least one upper case English letter, (?=.*?[A-Z])
 * At least one digit, (?=.*?[0-9])
 * At least one of the following special characters, (?=.*?[!@#$%^&*])
 * Minimum eight in length .{8,}
 */
export const passwordRegex = {
  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
  explanation:
    "Password must be at least 8 characters, contain one uppercase and lowercase letter, one number and one special character (!@#$%^&*)",
};
export const ticketPasswordRegex = {
  regex: /^[a-zA-Z0-9()@_\-.\r\n]+$/,
  explanation: "May contain the following characters only: A-Z 0-9 - _ @ ( ) .",
};

export const mobileRegex = {
  regex: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
  explanation: "Must be a valid mobile number",
};

export const vatRegex = {
  regex:
    /^(?=([A-Za-z]{2,4}))\1(?![\W_]+$)(?=.{8,12}$)[ 0-9]*(?:[a-zA-Z][ 0-9]*){0,2}|^(RO)?[0-9]{2,10}$/,
  explanation: "Must be a valid EU VAT Number",
};

export const normalCharactersRegex = {
  regex:
    "(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])",
  explanation: "Cannot include any special characters or emojis",
};
