import { Button, styled } from "@mui/material";
import { darken } from "polished";

const ButtonAsLink = styled(Button)`
  justify-content: ${({ $justifyContent }) => $justifyContent || "center"};
  font-family: ${({ theme: { typography } }) => typography.fontFamily};
  font-weight: 600;
  color: ${({ theme: { palette } }) => palette.secondary.light};
  &:hover {
    color: ${({ theme: { palette } }) => darken(0.2, palette.secondary.light)};
    text-decoration: underline;
    background-color: transparent;
  }
  cursor: pointer;
  background: transparent;
  padding: 0;
  text-decoration: underline;
  font-size: ${({ $fontSize }) => $fontSize || "inherit"};
  min-width: ${({ $minWidth }) => $minWidth};
  ${({ sx }) => sx};
`;

export default ButtonAsLink;
