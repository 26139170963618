import { Amplify } from "aws-amplify";

export default async function configureAmplify(bucket?: string) {
  const env = import.meta.env ?? process.env;
  return Amplify.configure({
    Auth: {
      Cognito: {
        identityPoolId: env.VITE_COGNITO_USER_POOL_CREDENTIALS_ID,
        userPoolId: env.VITE_POOL_ID,
        userPoolClientId: env.VITE_CLIENT,
      },
    },
    Storage: {
      S3: {
        bucket: bucket ?? "skiddlecdn-images-listings-uploads",
        region: "eu-west-1",
      },
    },
  });
}
