import { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router";

import { useRequestReducer, shouldReset } from "./utils";
import useAuthMainPromoter from "../../Components/NamedComponents/Auth/hooks/useAuthMainPromoter";
import { AppContext } from "../../Context";
import fetchData from "./fetchData";

/**
 * @function useHttpRequestMultiple
 *
 * This hook is very similar to the main useHttpRequest hook with the
 * key difference being this will accept an array of options, one for
 * each request needed. It's basically a way to make multiple requests
 * at the same time, like Promise.all.
 *
 * @param {*} options - An array of objects, each object containing the
 * options, these can be seen in the comment above useHttpRequest with the
 * addition of "key", which is required and is used to ditinguish between the
 * requests
 *
 * NOTE: There is no way for the component that calls this request to know which
 * order the requests will return in, if one finishes quicker, or errors first it will
 * be returned first regardless of the order the requests are passed to the function.
 * The "key" of the request will be returned so the component can use that to distinguish
 * which request is which
 */
const useHttpRequestMultiple = (options = [], shouldExecuteGlobal = true) => {
  const navigate = useNavigate();
  const mainPromoter = useAuthMainPromoter();
  const location = useLocation();

  const { setCurrentEvent } = useContext(AppContext);

  const [state, dispatch] = useRequestReducer(true, options);

  const retryFn = () => {
    const dt = Date.now();
    dispatch({
      type: "RETRY",
      payload: {
        retries: state.retries + 1,
        timestamp: dt,
      },
    });
  };

  useEffect(() => {
    const controller = new AbortController();

    if (!options.length || !shouldExecuteGlobal) {
      return undefined;
    }

    if (options.some(opt => !opt.key)) {
      throw new Error(
        "Please include a unique key with each request when using useHttpRequestMultiple",
      );
    }

    options.forEach(({ method, body, endpoint, shouldExecute, details, authenticated, key }) => {
      shouldReset({ endpoint, method, shouldExecute, body }, details, authenticated, dispatch, key);
    });

    options.forEach(opt => {
      const { shouldExecute = true, key } = opt;

      if (shouldExecute) {
        fetchData(
          opt,
          { mainPromoter, location, state, dispatch, navigate },
          retryFn,
          setCurrentEvent,
          controller,
          key,
        );
      }

      return null;
    });

    // eslint-disable-next-line
    return () => {
      controller.abort();
    };
    // WARNING: If this starts to run slow, JSON.stringify may be the culprit
    // this is here as useEffect doesn't do object comparison and causes an infinite
    // loop, stringifying it solves this, As per Dan Abramov: https://github.com/facebook/react/issues/14476#issuecomment-471199055
  }, [JSON.stringify(options), state.retries]);

  return state;
};

export default useHttpRequestMultiple;
