import * as Yup from "yup";
import lazy from "../../../../../Utils/lazy";

const SkipTickets = lazy(() => import("../../FieldComponents/SkipTickets"));

const entryPrice = {
  defaultValue: "",
  name: "entryPrice",
  type: SkipTickets,
  displayName: () => "Entry Price GBP",
  validation: () =>
    Yup.string()
      .trim()
      .when("skipTickets", {
        is: "yes",
        then: schema => schema.required("Entry price is required"),
        otherwise: schema => schema,
      })
      .min(1, "Entry price must be at least 1 character")
      .max(50, "Entry price can only be 50 characters"),
  inputProps: {
    minLength: 1,
    maxLength: 50,
  },
  sendToApi: () => true,
  postFormat: val => (val === undefined || val === "" ? "0" : val),
  hidden: (formValues, context) =>
    formValues.skipTickets !== "yes" || Boolean(context.currentTicketData.length),
};
export default entryPrice;
