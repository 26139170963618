/* eslint-disable react/prop-types */
import { styled } from "@mui/system";
import React from "react";

const Ul = styled("ul")`
  display: block;

  position: absolute;
  bottom: -10%;
  left: 0;
  padding: 0;
  margin: 0;
  list-style: none;

  width: 100%;

  li {
    background: ${({ theme }) => theme.error};
    border-radius: 0 0 11px 11px;
    color: ${({ theme }) => theme.white};

    font-size: 14px;
    font-weight: 600;

    padding: 15px;
  }
`;

export default ({ errors, ...props }) =>
  Boolean(errors.length) && (
    <Ul role="alert" {...props}>
      {errors.map(error => (
        <li key={`error-${error}`}>{error}</li>
      ))}
    </Ul>
  );
