import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import Logo from "./Logo";
import Link from "./Link";
import date from "../../Utils/date";
import { DATE_TIME_USER } from "../../Constants/luxonDateFormats";
import RefreshButton from "./Refresh/RefreshButton";
import onRouteChange from "../../Utils/onRouteChange";
import AuthRoot from "../Layout/AuthRoot";
import AuthWrapper from "../Layout/AuthWrapper";

const MaintenanceSplash = () => {
  const [loading, setLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(date());
  const [refreshDisabled, setRefreshDisabled] = useState(false);

  const refresh = async () => {
    setRefreshDisabled(true);
    setLoading(true);
    const { isInMaintenance } = await onRouteChange();

    if (!isInMaintenance) {
      window.location.reload();
    }

    setLastUpdate(date());
    setLoading(false);
  };

  useEffect(() => {
    // Poll for the end of maintenance every 10 seconds
    const autoRefreshInterval: NodeJS.Timeout = setInterval(async () => {
      await refresh();
    }, 10000);

    // Disable the refresh button for 1 seconds after each click to prevent spam click
    const interval: NodeJS.Timeout = setInterval(() => {
      const diff = date().diff(lastUpdate, "seconds");

      if (diff > 1) {
        setRefreshDisabled(false);
      }
    }, 100);

    return () => {
      clearInterval(interval);
      clearInterval(autoRefreshInterval);
    };
  }, [lastUpdate]);

  return (
    <AuthRoot>
      <AuthWrapper $minHeight="300px">
        <Box sx={{ position: "absolute", top: 6, right: 6 }}>
          <RefreshButton
            title=""
            loading={loading}
            disabled={refreshDisabled}
            refreshFn={async () => {
              await refresh();
            }}
          />
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Logo width="50%" />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center", mb: 10 }}>
            The Promotion Centre is currently undergoing planned maintenance.
          </Typography>
          <Typography variant="h6" sx={{ mb: 10 }}>
            For more information please{" "}
            <Link
              to="https://skiddlepromotioncentre.statuspage.io/"
              isRouterLink={false}
              target="_blank"
            >
              visit our status page
            </Link>
          </Typography>
          <Typography variant="caption" sx={{ position: "absolute", bottom: 0, paddingBottom: 3 }}>
            last updated {lastUpdate.format(DATE_TIME_USER)}
          </Typography>
        </Box>
      </AuthWrapper>
    </AuthRoot>
  );
};

export default MaintenanceSplash;
