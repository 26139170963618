import { SESSION_TICKETING } from "../../../../../Constants/events/ticketingTypes";
import { sessionEventTooltipMessage } from "../../../../Layout/Navigation/helpers";
import TICKET_AVAILABILITY_STATUS from "../../../../../Constants/tickets/availabilityStatus";
import lazy from "../../../../../Utils/lazy";

const TicketAvailability = lazy(() => import("../../FieldComponents/TicketAvailability"));

const availability = {
  name: "availability",
  subtitle: "Visibility",
  displayName: () => "",
  sendToApi: () => true,
  type: TicketAvailability,
  defaultValue: TICKET_AVAILABILITY_STATUS.STANDARD,
  advancedOption: true,
  options: [
    { label: "Available to public", value: TICKET_AVAILABILITY_STATUS.STANDARD, grid: { md: 6 } },
    {
      label: "Password protected",
      value: TICKET_AVAILABILITY_STATUS.PASSWORDED,
      grid: { md: 6 },
      disabled: vals => vals.ticketingType === SESSION_TICKETING,
      tooltip: sessionEventTooltipMessage,
    },
    {
      label: "Box Office only",
      value: TICKET_AVAILABILITY_STATUS.BOX_OFFICE,
      disabled: vals => vals.ticketingType === SESSION_TICKETING,
      tooltip: sessionEventTooltipMessage,
    },
  ],
};
export default availability;
