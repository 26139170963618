import { Typography } from "@mui/material";
import React from "react";
import ButtonAsLink from "./ButtonAsLink";

const NewVersionWarning = ({ countdown, onClick }: { countdown: number; onClick: () => void }) => (
  <Typography variant="body2">
    A new version of the Promotion Centre has been released, we will automatically refresh the page
    for you in{" "}
    {Number(countdown / 1000)
      .toString()
      .slice(0, 1)}{" "}
    seconds,{" "}
    <ButtonAsLink onClick={onClick} sx={{ paddingBottom: "2px" }}>
      click here to cancel
    </ButtonAsLink>{" "}
    (you will be prompted to refresh on each page load going forward).
  </Typography>
);

export default NewVersionWarning;
