import { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import store from "../../../../Store";
import { Auth } from "./types/auth.type";

export default function useAuth() {
  const [auth, setAuth] = useState(store.getState().auth);
  const [previous, setPrevious] = useState<Auth>();

  useEffect(
    () =>
      store.subscribe(() => {
        const { auth: authState } = store.getState();
        if (!isEqual(authState, previous)) {
          setAuth(authState);
          setPrevious(authState);
        }
      }),
    [],
  );

  return auth;
}
