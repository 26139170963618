import displayGroup from "./displayGroup";
import isAddonField from "./isAddonField";
import password from "./password";
import ticketCategory from "./ticketCategory";
import ticketDescription from "./ticketDescription";
import ticketName from "./ticketName";

const basicInfo = {
  isGroup: true,
  label: "Basic info",
  fields: [ticketName, ticketDescription, ticketCategory, displayGroup, isAddonField, password],
  hidden: (formValues, context) =>
    formValues.skipTickets === "yes" && !context.currentTicketData.length,
  showDivider: true,
};

export default basicInfo;
