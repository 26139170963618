/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const REQUEST_LIMIT = 20;

export const adminPanelSlice = createSlice({
  name: "adminPanel",
  initialState: {
    currentForms: {},
    requests: [],
    pageLogs: null,
  },
  reducers: {
    setCurrentForms: (state, action) => {
      if (action.payload === "RESET") {
        state.currentForms = {};
      }
      if (action.payload.id) {
        state.currentForms[action.payload.id] = action.payload.form;
      }
    },
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
    setPageSpecificLogs: (state, action) => {
      state.pageLogs = action.payload;
    },
  },
});

export const { setCurrentForms, setRequests, setPageSpecificLogs } = adminPanelSlice.actions;

export default adminPanelSlice.reducer;
