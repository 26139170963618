import get from "lodash/get";
import useAuth from "./useAuth";

export default function useAuthName() {
  const auth = useAuth();
  const nameObject = { firstname: "", surname: "" };
  if (get(auth, "promoter.firstname")) {
    if (get(auth, "promoter.surname")) {
      nameObject.surname = auth.promoter.surname;
    }
    nameObject.firstname = auth.promoter.firstname;
  }
  return nameObject;
}
