import React, { useContext } from "react";
import PropTypes from "prop-types";

import { styled, Grid2 as Grid, Typography as MuiTypography } from "@mui/material";
import { spacing } from "@mui/system";

import { checkUserPermission, isOwner, rolesBitwise, userRoles } from "../../../Utils/permissions";
import useAuth from "../../NamedComponents/Auth/hooks/useAuth";
import isFeatureFlagOn, { featureFlags } from "../../../Utils/checkFeatureFlag";
import Link from "../../Reusable/Link";
import ButtonAsLink from "../../Reusable/ButtonAsLink";
import { AppContext } from "../../../Context";
import SwitchToMainAccount from "../SwitchToMainAccount";

const Typography = styled(MuiTypography)(spacing);

const StyledLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;
const StyledButton = styled(ButtonAsLink)`
  font-size: 14px;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const ManageAccountLinks = ({ setOpen = () => null }) => {
  const auth = useAuth();
  const { isMobile, setAppMaxWidth, setIsFullscreen, isFullscreen } = useContext(AppContext);

  return (
    (isFeatureFlagOn(featureFlags.MANAGE_USERS) || isFeatureFlagOn(featureFlags.PROFILE)) && (
      <>
        {isFeatureFlagOn(featureFlags.RAPIDSCAN_DEVICE_CODES) &&
          checkUserPermission(auth.promoter.permissions, userRoles[rolesBitwise.Admin]) && (
            <Grid size={12}>
              <StyledLink to="/manage/rapidscan-devices">Manage Rapidscan devices</StyledLink>
            </Grid>
          )}
        {!isMobile && isFeatureFlagOn(featureFlags.FULLSCREEN) && (
          <Grid size={12}>
            <StyledButton
              $fontSize="16px"
              onClick={() => {
                if (isFullscreen) {
                  setIsFullscreen(false);
                  setAppMaxWidth({ width: 1400 });
                  localStorage.setItem("pc_fullscreen", 0);
                } else {
                  setIsFullscreen(true);
                  localStorage.setItem("pc_fullscreen", 1);
                }
                setOpen(false);
              }}
            >
              {isFullscreen ? "Exit" : "Enter"} fullscreen mode
            </StyledButton>
          </Grid>
        )}
        {!isOwner(auth) ? (
          <>
            <Grid size={12}>
              <Typography variant="body2">
                To manage your profile and users, please switch to your main account.
              </Typography>
            </Grid>
            <Grid size={12}>
              <SwitchToMainAccount setOpen={setOpen} />
            </Grid>
          </>
        ) : (
          <>
            {isFeatureFlagOn(featureFlags.PROFILE) && (
              <Grid size={12}>
                <StyledLink to="/manage/profile">Manage profile</StyledLink>
              </Grid>
            )}
            {isFeatureFlagOn(featureFlags.MANAGE_USERS) && (
              <Grid size={12}>
                <StyledLink to="/manage/users">Manage and invite users</StyledLink>
              </Grid>
            )}
          </>
        )}
      </>
    )
  );
};

ManageAccountLinks.propTypes = {
  setOpen: PropTypes.func,
};

export default ManageAccountLinks;
