import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Typography } from "@mui/material";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { useIntercom } from "react-use-intercom";
import { AppContext, AppContextType } from "../../Context";

interface FallbackProps {
  eventId: string;
  error: object;
  code: string;
  sx: object;
  resetError: () => void;
  erroredPage: string;
  isMobile: boolean;
}

const Fallback = ({
  eventId,
  error,
  code,
  sx,
  resetError,
  erroredPage,
  isMobile,
}: FallbackProps) => {
  const { showNewMessage } = useIntercom();

  useEffect(() => {
    if (erroredPage !== `${window.location.pathname}${window.location.search}`) {
      resetError();
    }
  }, [window.location.pathname, window.location.search]);

  return (
    <Alert sx={{ mx: 2, my: 2, ...sx }} severity="error" icon={false}>
      <Typography variant="body1" gutterBottom>
        It looks like something went wrong. Please contact our support team.
      </Typography>
      {error.toString().includes("Failed to fetch dynamically imported module") ||
      error.toString().includes("Importing a module script failed") ? (
        <>
          <Typography variant="body2" gutterBottom>
            There was an issue loading a new version of the application. Please reload the page, if
            this does not fix the issue you may need to clear your browsers cache.
          </Typography>
          {!isMobile && (
            <Typography variant="body2">
              How to reload the page:
              <ul>
                <li>Windows: Press Shift + F5 or Ctrl + Shift + R</li>
                <li>Mac: Press Cmd + Option + R</li>
              </ul>
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="body2"> {error?.toString()}</Typography>
      )}
      {code && <Typography variant="body2">Code: {code}</Typography>}
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        color="primary"
        onClick={() =>
          showNewMessage(
            `ID: ${eventId}\n\nI'd like to report an error on the Promotion Centre when I performed the following actions: \n\n`,
          )
        }
      >
        Report this error
      </Button>
    </Alert>
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  sx?: object;
  code: string;
}

const ErrorBoundary = ({ children, sx, code }: ErrorBoundaryProps) => {
  const [erroredPage, setErroredPage] = useState<string>("");
  const appContext = useContext<AppContextType | null>(AppContext);

  const myFallback = (props: any) => (
    <Fallback
      {...props}
      sx={sx}
      code={code}
      erroredPage={erroredPage}
      isMobile={appContext?.isMobile}
    />
  );
  return (
    <SentryErrorBoundary
      onError={() => {
        setErroredPage(`${window.location.pathname}${window.location.search}`);
      }}
      fallback={myFallback}
    >
      {children}
    </SentryErrorBoundary>
  );
};

export default ErrorBoundary;
