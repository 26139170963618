import { reportOptions } from "../../../../../Constants/tickets/ticketOptions";
import lazy from "../../../../../Utils/lazy";

const InputSelect = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputSelect"),
);

const ticketCountPeriod = {
  name: "ticketCountPeriod",
  hidden: formValues => formValues.setUpTicketReports === "no",
  displayName: () => "Receive email updates at",
  defaultValue: 1,
  sendToApi: () => true,
  advancedOption: true,
  options: reportOptions,
  type: InputSelect,
  hide: () => false,
  postFormat: (val, formValues) => (formValues.setUpTicketReports === "no" ? "9" : val),
};
export default ticketCountPeriod;
