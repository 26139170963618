import React, { useState } from "react";
import PropTypes from "prop-types";
import { resendSignUpCode } from "aws-amplify/auth";
import { Alert, Box, Typography } from "@mui/material";

import getAwsErrorMessage from "../../../../Utils/getAwsErrorMessage";
import { Button } from "../../../Reusable";

const RegisterResend = ({ email, cognitoUUID, resendTimeout, setResendTimeout }) => {
  const [error, setError] = useState();

  const resendConfirmation = async () => {
    try {
      await resendSignUpCode({ username: cognitoUUID });
      setResendTimeout(15000);
      setError(null);
    } catch (err) {
      const message = getAwsErrorMessage(err);
      error(message);
    }
  };

  return (
    <Box
      sx={{
        mb: 3,
        mt: 3,
      }}
    >
      <Alert id="success" severity="success">
        <Typography variant="body1" sx={{ mb: 3 }}>
          An email with a confirmation link has been sent to {email}.
        </Typography>
        <Typography variant="body2" gutterBottom>
          Not received?{" "}
        </Typography>
        <Typography variant="body2">
          {resendTimeout > 0 ? (
            <>Please wait {resendTimeout / 1000} seconds before resending the confirmation email</>
          ) : (
            <Button onClick={resendConfirmation}>Resend</Button>
          )}
        </Typography>
      </Alert>
    </Box>
  );
};

RegisterResend.propTypes = {
  email: PropTypes.string.isRequired,
  cognitoUUID: PropTypes.string.isRequired,
  resendTimeout: PropTypes.number.isRequired,
  setResendTimeout: PropTypes.func.isRequired,
};
export default RegisterResend;
