import { AuthUser } from "aws-amplify/auth";
import { LOGGED_IN_AS_PROMOTER_ID } from "../../Constants/auth";
import isFeatureFlagOn, { featureFlags } from "../checkFeatureFlag";
import date from "../date";
import { roleToPermissions } from "../permissions";
import { User } from "./types/User.type";
import { Session } from "./types/Session.type";

function hasPromoters(user: User): user is User & { promoters: { [key: number]: any } } {
  return user.promoters !== undefined;
}

export default async function getUserData(
  user: AuthUser,
  session: Session,
  promoterId?: string | null,
) {
  const {
    tokens: {
      idToken: { payload },
    },
  } = session;

  const userCopy: User = { ...user };

  // Parse it
  userCopy.promoters = JSON.parse(payload.promoters);

  if (!hasPromoters(userCopy)) {
    throw new Error("Unexpected error. Please try again later.");
  }

  // Convert expiries to date times
  Object.entries(userCopy.promoters).forEach(async ([key, value]) => {
    userCopy.promoters[Number(key)].emailVerified = session.tokens.idToken.payload.email_verified;
    if (value.expires) {
      userCopy.promoters[Number(key)] = {
        ...value,
        expires: value.expires ? date(value.expires) : null,
      };
    }

    if (!isFeatureFlagOn(featureFlags.SKIDDLE_STAFF)) {
      userCopy.promoters[Number(key)].skiddleStaff = false;
    }
  });

  // Grab and assign the main promoter id
  userCopy.mainPromoterId = Object.keys(userCopy.promoters).find(
    (us: any) => userCopy.promoters[us].mainAccount,
  );

  let newPromoterId: string | number = Object.keys(userCopy.promoters)[0];

  if (localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID)) {
    newPromoterId = localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID)!;
  } else if (userCopy.mainPromoterId) {
    newPromoterId = userCopy.mainPromoterId;
  }

  // If a promoter ID is manually passed in, treat it as an override
  // regardless of what is in local storage or anywhere else.
  // This is used when switching promoters to help keep things in sync.
  if (promoterId) {
    newPromoterId = promoterId;
  }

  // Set the current promoter
  userCopy.promoter = {
    ...userCopy.promoters[Number(newPromoterId)],
    promoterId: newPromoterId,
    skiddleStaff:
      !isFeatureFlagOn(featureFlags.SKIDDLE_STAFF) ||
      !userCopy.promoters[Number(newPromoterId)]?.skiddleStaff
        ? false
        : userCopy.promoters[Number(newPromoterId)].skiddleStaff,
  };

  // Break down the role into the permissions bitwise value
  const roleToPerms = roleToPermissions(userCopy?.promoter?.roles ?? 0);
  userCopy.promoter!.permissions = roleToPerms;
  userCopy.promoters[Number(newPromoterId)].permissions = roleToPerms;
  userCopy.expiry = date(payload.exp);

  // Add the extra promoter info to current promoter
  userCopy.promoters[Number(newPromoterId)] = {
    ...userCopy.promoters[Number(newPromoterId)],
    promoterId: Number(newPromoterId),
  };

  return userCopy;
}
