import { LOGGED_IN_AS_PROMOTER_ID } from "../../Constants/auth";
import store from "../../Store";
import { updateCurrentPromoter, userLoggedIn } from "../../Store/reducers/AuthReducer";
import getUserSession from "./getUserSession";

const changePromoter = async (promoterId, auth) => {
  try {
    const {
      promoters,
      promoter: { promoterId: currentPromoterId },
    } = auth.userData;
    const mainPromoterId = Number(auth.userData.mainPromoterId);
    const targetPromoterId = Number(promoterId);

    // If switching to main account promoter id, remove from local storage
    if (targetPromoterId === mainPromoterId) {
      localStorage.removeItem(LOGGED_IN_AS_PROMOTER_ID);
    }

    // If switching from main account to sub user, set new id in local storage
    if (currentPromoterId === mainPromoterId) {
      localStorage.setItem(LOGGED_IN_AS_PROMOTER_ID, targetPromoterId);
    }

    // If Switching from sub user to sub user, set new id in local storage
    if (targetPromoterId !== mainPromoterId && currentPromoterId !== mainPromoterId) {
      localStorage.setItem(LOGGED_IN_AS_PROMOTER_ID, targetPromoterId);
    }

    const newPromoter = { ...promoters[promoterId], promoterId };

    const newUser = await getUserSession(promoterId);

    await Promise.all([
      store.dispatch(updateCurrentPromoter(newPromoter)),
      store.dispatch(userLoggedIn(newUser)),
    ]);

    return newPromoter;
  } catch (e) {
    return e;
  }
};

export default changePromoter;
