import { radioOptions } from "../../../../../Constants/tickets/ticketOptions";
import lazy from "../../../../../Utils/lazy";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);

const showPendingOnsale = {
  name: "showPendingOnsale",
  displayName: () => "Show tickets before on-sale",
  sendToApi: () => true,
  handleClick: (action, form) => form.addValue("showPendingOnsale", action),
  type: InputRadio,
  options: radioOptions,
  advancedOption: true,
  defaultValue: "yes",
  placeholder: "Show tickets before on-sale",
  postFormat: val => (val === "no" || val === 0 ? 0 : 1),
};
export default showPendingOnsale;
