import "./init";

import React from "react";
import { createRoot } from "react-dom/client";
import {
  browserProfilingIntegration,
  browserTracingIntegration,
  captureException,
  init,
  reactRouterV6BrowserTracingIntegration,
  withScope,
} from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import { CookiesProvider } from "react-cookie";

import { AdapterLuxon as DateAdapter } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Alert, Box, Typography } from "@mui/material";
import { IntercomProvider } from "react-use-intercom";

import { Provider } from "react-redux";

import store from "./Store";
import "./index.scss";
import App from "./App";
import checkEnv from "./checkEnv";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Package CSS
import theme from "./theme";
import AuthLayout from "./Components/Layout/AuthLayout";
import { Logo } from "./Components/Reusable";
import getVersion from "./Utils/getVersion";
import { isFeatureFlagOn } from "./Utils";
import { featureFlags } from "./Utils/checkFeatureFlag";
import MetaPixel from "./Pixels/MetaPixel";
import LinkedinPixel from "./Pixels/LinkedinPixel";
import GooglePixel from "./Pixels/GooglePixel";
import AmplifyBridge from "./AmplifyBridge";
import configureAmplify from "./Utils/Auth/configureAmplify";
import StatusPage from "./Pixels/StatusPage";
import MaintenanceSplash from "./Components/Reusable/MaintenanceSplash";
import getCatchErrorMessage from "./Utils/getCatchErrorMessage";

// Configure AWS Amplify for use with Cognito & create instance of Amplify Bridge
configureAmplify();

const bridgeClass = new AmplifyBridge(store);

(async () => {
  const versionText = await getVersion();

  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      browserProfilingIntegration(),
      browserTracingIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set to 10 instead of default 3 for Redux
    normalizeDepth: 10,

    // Can be set to 1.0 to allow dynamic sampling
    tracesSampleRate: 1.0,

    profilesSampleRate: 1.0,

    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    release: `${import.meta.env?.VITE_NAME}@${versionText.trim() || "unknown"}`,

    initialScope: {
      user: { isLoggedIn: false },
    },

    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      // Disabled as only happening on really old browsers
      "o.replaceAll is not a function",
      // We manually catch and capture to sentry, this stops
      // duplication
      "Network Error",
      "NetworkError",
      "UserUnAuthenticatedException",
    ],

    denyUrls: [/https:\/\/.*\.analytics\.google\.com.*/],

    beforeSend(event) {
      return {
        ...event,
        contexts: { ...event.contexts, network: store.getState().network.network },
      };
    },
  });
})();

// Catch and handle chunk cache errors (refresh user page)
const sessionStorageKey = "retry-lazy-refreshed";
const hasRefreshed = JSON.parse(window.sessionStorage.getItem(sessionStorageKey) ?? "false");
window.addEventListener("vite:preloadError", event => {
  if (!hasRefreshed) {
    window.sessionStorage.setItem(sessionStorageKey, "true");

    interface ExtendedError extends ErrorOptions {
      name?: string;
    }

    withScope(scope => {
      scope.setLevel("warning");
      captureException(
        new Error("CHUNK_LOADING", {
          name: "CHUNK_LOADING",
          cause: event,
          message: "Chunk loading error encountered, refreshing page manually for user.",
        } as ExtendedError),
      );
    });

    window.location.reload(); // for example, refresh the page
  }
});

const container = document.getElementById("root");
const root = createRoot(container!);

try {
  // Make sure all required env variables are present when the app starts
  checkEnv();
  root.render(
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <CookiesProvider>
              <IntercomProvider
                appId={import.meta.env.VITE_INTERCOM_APP_ID}
                shouldInitialize={isFeatureFlagOn(featureFlags.INTERCOM)}
                autoBoot={false}
              >
                <CssBaseline />
                <React.StrictMode>
                  <StatusPage />
                  <MetaPixel />
                  <LinkedinPixel />
                  <GooglePixel />

                  {import.meta.env.VITE_MAINTENANCE === "1" ? <MaintenanceSplash /> : <App />}
                </React.StrictMode>
              </IntercomProvider>
            </CookiesProvider>
          </Provider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>,
  );
} catch (error: unknown) {
  const errorMessage = getCatchErrorMessage(error);

  root.render(
    <AuthLayout>
      <Box textAlign="center">
        <Logo width="50%" />
      </Box>
      <Alert severity="error">
        {errorMessage.includes("Missing env variable") &&
        import.meta.env.NODE_ENV === "development" ? (
          errorMessage
        ) : (
          <>
            Something went wrong. We&apos;re working hard to fix this issue, please try again later.
            <br />
            <br />
            <Typography variant="caption">Error code: env</Typography>
          </>
        )}
      </Alert>
    </AuthLayout>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

export default bridgeClass;
