import React from "react";
import { Typography } from "@mui/material";

import AuthLayout from "./AuthLayout";
import LogoutButton from "../NamedComponents/Auth/Components/LogoutButton";

const BannedAccount = () => (
  <AuthLayout>
    <Typography variant="h6" gutterBottom>
      Sorry, your account is suspended. Please contact Skiddle on 0333 301 0301 if you need more
      information.
    </Typography>

    <LogoutButton />
  </AuthLayout>
);

export default BannedAccount;
