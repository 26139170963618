import { setContext } from "@sentry/react";
import { Endpoints } from "../../Constants";
import errorMessages from "../../Utils/errorMessages";
import { error } from "../../Utils/toast";

const setCurrentEvent = (endpoint, method, res, setCurrentEventFn) => {
  try {
    if (setCurrentEventFn && endpoint === Endpoints.EVENT_BY_ID && method === "GET") {
      if (Array.isArray(res)) {
        setCurrentEventFn(res[0]);
      } else {
        setCurrentEventFn(res);
        const {
          id: eventId,
          listingId,
          eventName,
          startDate,
          endDate,
          openingTimes: {
            doorsOpen: { label: doorsOpenLabel },
            doorsClose: { label: doorsCloseLabel },
            lastEntry,
          },
          venue,
        } = res;
        setContext("event", {
          eventId,
          listingId,
          eventName,
          startDate,
          endDate,
          doorsOpen: doorsOpenLabel,
          lastEntry: lastEntry?.label || "",
          doorsClose: doorsCloseLabel,
          venue,
        });
      }
    }
  } catch {
    error(errorMessages.eventLoading);
  }
};

export default setCurrentEvent;
