import React from "react";
import get from "lodash/get";

import Store from "../../Store";
import date from "../../Utils/date";
import { formatOnlyTime } from "../luxonDateFormats";
import { SESSION_TICKETING } from "./ticketingTypes";
import { isFeatureFlagOn } from "../../Utils";
import { featureFlags } from "../../Utils/checkFeatureFlag";
import links from "../../Utils/links";

export const dateTimeFormat = "yyyy-MM-DD HH:mm";
export const timeFormat = "HH:mm";
export const dateFormat = "yyyy-MM-DD";

export const reviewTypes = {
  STEP: "step",
  EVENT: "event",
};

export const monthOptions = [
  {
    label: "January",
    value: 0,
  },
  {
    label: "February",
    value: 1,
  },
  {
    label: "March",
    value: 2,
  },
  {
    label: "April",
    value: 3,
  },
  {
    label: "May",
    value: 4,
  },
  {
    label: "June",
    value: 5,
  },
  {
    label: "July",
    value: 6,
  },
  {
    label: "August",
    value: 7,
  },
  {
    label: "September",
    value: 8,
  },
  {
    label: "October",
    value: 9,
  },
  {
    label: "November",
    value: 10,
  },
  {
    label: "December",
    value: 11,
  },
];

export const durationOptions = [
  { value: "0", label: "0 days" },
  { value: "1", label: "1 day" },
  { value: "2", label: "2 days" },
  { value: "3", label: "3 days" },
  { value: "4", label: "4 days" },
  { value: "5", label: "5 days" },
  { value: "6", label: "6 days" },
  { value: "7", label: "7 days" },
  { value: "8", label: "8 days" },
  { value: "9", label: "9 days" },
  { value: "10", label: "10 days" },
  { value: "11", label: "11 days" },
  { value: "12", label: "12 days" },
  { value: "13", label: "13 days" },
  { value: "14", label: "14 days" },
];

export const timeOptions = () => {
  const opts = [];

  for (let index = 0; index < 24; index += 1) {
    const hour = date(`${index < 10 ? 0 : ""}${index}:00`);
    for (let j = 0; j <= 3 * 15; j += 15) {
      const formatted = hour.formatUserDate(formatOnlyTime);
      opts.push({
        label: formatted,
        value: hour.format("HH:mm"),
        meridiem: formatted.slice(formatted.length - 2).toUpperCase(),
        searchKey: hour.format("ha"),
      });
      hour.plus(15, "minutes");
    }
  }

  return opts;
};

export const disabledTimeOptions = (start, end) => {
  const opts = [];
  const startHour = start && Number(start.substring(0, 2));
  const startMinute = start && Number(start.substring(3, 5));
  const endHour = end && Number(end.substring(0, 2));
  const endMinute = end && Number(end.substring(3, 5));

  for (let index = 0; index < 24; index += 1) {
    const hour = date(`${index < 10 ? 0 : ""}${index}:00`);
    for (let j = 0; j <= 3 * 15; j += 15) {
      const formatted = hour.formatUserDate(formatOnlyTime);
      const beforeStart = (start && index < startHour) || (startHour === index && j <= startMinute);
      const afterEnd = (end && index > endHour) || (endHour === index && j > endMinute);
      if (!beforeStart && !afterEnd) {
        opts.push({
          label: formatted,
          value: hour.format("HH:mm"),
          meridiem: formatted.slice(formatted.length - 2).toUpperCase(),
        });
      }

      hour.plus(15, "minutes");
    }
  }

  return opts;
};

export const DELETE_EVENT = "0";
export const GO_LIVE = "1";
export const MAKE_DRAFT = "8";
export const SUSPEND = "9";

export const eventStatuses = {
  0: "Deleted",
  1: "Live",
  8: "Draft",
  9: "Suspended",
};

export const visibilityOptions = [
  {
    value: "public",
    label: "Public",
    grid: { md: 6 },
  },
  {
    value: "private",
    label: "Private",
    grid: { md: 6 },
  },
  {
    value: "password",
    label: "Private passworded",
  },
];

export const displayGroups = [
  { value: "tickets", label: "Tickets" },
  { value: "travel", label: "Travel" },
  { value: "accommodation", label: "Accommodation" },
  { value: "upgrades", label: "Upgrades" },
  { value: "fooddrink", label: "Food/Drink" },
  { value: "addons", label: "Addons" },
];

export const ticketCategoryHeaders = [
  { value: "", label: "None", header: "Test" },
  { value: "General Entry", label: "General Entry", header: "Test2" },
  { value: "VIP", label: "VIP", header: "Test3" },
  { value: "Earlybird", label: "Earlybird", header: "Test4" },
  { value: "Weekend tickets", label: "Weekend tickets", header: "Test5" },
  { value: "2 Day Weekend tickets", label: "2 Day Weekend tickets", header: "Test" },
  { value: "3 Day Weekend ticket", label: "3 Day Weekend ticket", header: "Test" },
  { value: "4 Day Weekend tickets", label: "4 Day Weekend tickets", header: "Test" },
  { value: "Weekday tickets", label: "Weekday tickets", header: "Test" },
  { value: "Day tickets", label: "Day tickets", header: "Test" },
  { value: "Group tickets", label: "Group tickets", header: "Test" },
  { value: "Adults", label: "Adults", header: "Test" },
  { value: "Concessions", label: "Concessions", header: "Test5" },
  { value: "Teenagers", label: "Teenagers", header: "Test" },
  { value: "Children", label: "Children", header: "Test2" },
  { value: "Deposit Scheme", label: "Deposit Scheme", header: "Test" },
  { value: "Monthly Payment Plan", label: "Monthly Payment Plan", header: "Test" },
  { value: "Camping tickets", label: "Camping tickets", header: "Test" },
  { value: "Accommodation", label: "Accommodation", header: "Test3" },
  { value: "Vehicles", label: "Vehicles", header: "Test" },
  { value: "Transport", label: "Transport", header: "Test" },
  { value: "Amenities", label: "Amenities", header: "Test5" },
  { value: "Toilets/Showers", label: "Toilets/Showers", header: "Test4" },
  { value: "Bar", label: "Bar", header: "Test4" },
  { value: "Merchandise", label: "Merchandise", header: "Test2" },
  { value: "Press", label: "Press", header: "Test" },
  { value: "Friends &amp; Family", label: "Friends &amp; Family", header: "Test" },
  { value: "Artists", label: "Artists", header: "Test" },
  { value: "Crew", label: "Crew", header: "Test" },
  { value: "Traders", label: "Traders", header: "Test" },
  { value: "Competitions Winners", label: "Competitions Winners", header: "Test" },
  { value: "Street", label: "Street", header: "Test" },
  { value: "Teams", label: "Teams", header: "Test" },
  { value: "After Party", label: "After Party", header: "Test" },
  { value: "10am Session", label: "10am Session", header: "Test" },
  { value: "11am Session", label: "11am Session", header: "Test" },
  { value: "12pm Session", label: "12pm Session", header: "Test" },
  { value: "1pm Session", label: "1pm Session", header: "Test" },
  { value: "2pm Session", label: "2pm Session", header: "Test" },
  { value: "3pm Session", label: "3pm Session", header: "Test" },
  { value: "4pm Session", label: "4pm Session", header: "Test" },
  { value: "5pm Session", label: "5pm Session", header: "Test" },
  { value: "6pm Session", label: "6pm Session", header: "Test" },
  { value: "7pm Session", label: "7pm Session", header: "Test" },
  { value: "8pm Session", label: "8pm Session", header: "Test" },
  { value: "9pm Session", label: "9pm Session", header: "Test" },
  { value: "10pm Session", label: "10pm Session", header: "Test" },
  { value: "Other", label: "Other", header: "Test" },
];

export const basicInfoStep = 1;
export const additionalInfoStep = 2;
export const imageStep = 3;
export const ticketStep = 4;
export const confirmStep = 5;
export const artistStep = 2;
export const artistStage = 3;

export const ticketFormStage = 1;
export const ticketSummaryStage = 2;

export const remindMeOptions = [
  {
    value: "1",
    label: "Enable",
    grid: { md: 6 },
  },
  {
    value: "2",
    label: "Always",
    grid: { md: 6 },
  },
  {
    value: "0",
    label: "Disable",
  },
];

const ticketsWillBePosted = "Tickets will be posted.";
export const ticketDeliveryOptions = [
  {
    name: "rapidscan",
    title: "RapidScan barcoded entry",
    label: (
      <>
        Recommended: Customers are issued with a barcoded ticket (to print or show on their phone).
        Use the{" "}
        <a
          href={`${links.BLOG}/the-new-rapidscan-iphone-app/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          RapidScan mobile app
        </a>{" "}
        to scan tickets for rapid entry. Highly recommended especially for larger capacity events.
      </>
    ),
  },
  {
    name: "collect",
    title: "Collect (COBO) manual list",
    label:
      "Ticketless entry. Verify each customer by checking their debit/credit card against a list as they arrive.",
  },
  {
    name: "recorded",
    title: "Recorded Delivery",
    label: ticketsWillBePosted,
  },
  {
    name: "special",
    title: "Tracked Delivery",
    label: ticketsWillBePosted,
  },
  {
    name: "intSpecial",
    title: "International Tracked Delivery",
    label: ticketsWillBePosted,
  },
];

export const venueTypeOptions = [
  {
    value: "Physical",
    label: "At a physical venue",
    grid: { md: 6 },
  },
  {
    value: "Virtual",
    label: "Virtual/Online",
    grid: { md: 6 },
  },
];

export const venueAccessibilityOptions = {
  Accessibility: [
    {
      label: "Accessible (step-free) bar",
      name: "bar",
    },
    {
      label: "Accessible (step-free) box office",
      name: "boxOffice",
    },
    {
      label: "Accessible (step-free) toilets",
      name: "toilets",
    },
    {
      label: "Accessible (step-free) performance space",
      name: "performanceSpace",
    },
    {
      label: "Accessible parking",
      name: "parking",
    },
    {
      label: "Viewing platform",
      name: "viewingPlatform",
    },
  ],
};

export const structureCategories = (d, ticketingType, isAddon) => {
  const toChange = [
    { from: "addons", to: "Add-ons" },
    { from: "fooddrink", to: "Food/Drink" },
  ];
  const categories = d.map(cat => {
    const froms = toChange.map(x => x.from);

    if (froms.indexOf(cat.displayGroup) !== -1) {
      return {
        ...cat,
        displayGroup: toChange[froms.indexOf(cat.displayGroup)].to,
        originalDisplayGroup: cat.displayGroup,
      };
    }
    return {
      ...cat,
      displayGroup: cat.displayGroup.charAt(0).toUpperCase() + cat.displayGroup.slice(1),
      originalDisplayGroup: cat.displayGroup,
    };
  });

  // Is a session event, and it's not an addon then only show categories
  // with tickets display group
  if (ticketingType === SESSION_TICKETING && isFeatureFlagOn(featureFlags.SBT_ADDONS)) {
    return categories.filter(cat =>
      !isAddon ? cat.displayGroup !== "Add-ons" : cat.displayGroup === "Add-ons",
    );
  }

  return categories;
};

export const VIRTUAL_VENUE_NATIONAL_ENT_ID = 1;

export const additionalInstalmentsOptions = () => {
  const { auth } = Store.getState();

  const skiddleStaff = get(
    auth,
    `userData.promoters[${get(auth, "userData.mainPromoterId")}].skiddleStaff`,
    false,
  );

  const opts = [];
  const cutOff = skiddleStaff ? 12 : 5;

  for (let index = 1; index <= cutOff; index += 1) {
    opts.push({ value: index, label: `${index}` });
  }

  return opts;
};

export const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const sessionIntervalOptions = [
  {
    label: "15 minutes",
    value: 15,
  },
  {
    label: "30 minutes",
    value: 30,
  },
  {
    label: "45 minutes",
    value: 45,
  },
  {
    label: "1 hour",
    value: 60,
  },
];
