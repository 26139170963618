import get from "lodash/get";
import groupBy from "lodash/groupBy";

import { Endpoints, TICKET_STATUS } from "../Constants";
import { ticketStep, ticketSummaryStage } from "../Constants/events/eventOptions";
import { ISO_DATE_TIME_FORMAT, TIME_FORMAT_API } from "../Constants/luxonDateFormats";
import waitingListWarningActions from "../Constants/tickets/waitingListWarningActions";
import addPageLogs from "./AdminPanel/addPageLogs";
import date from "./date";
import { CATEGORIES } from "./tickets/getTicketCategoryName";
import isFeatureFlagOn, { featureFlags } from "./checkFeatureFlag";
import httpRequestAsync from "./httpRequestAsync";
import addonOptions from "../Constants/tickets/addonOptions";
import { stopTicketOptions } from "../Constants/tickets/ticketOptions";

/**
 * @param {Object} qsValues
 * @param {Object} formValues
 * @returns Boolean
 */
export const isAddon = (qsValues, formValues = {}) => {
  return Boolean(
    (Number(qsValues?.addon) === 1 || (formValues?.addOnType && qsValues?.ticketId)) &&
      isFeatureFlagOn(featureFlags.SBT_ADDONS),
  );
};

export const getFlattenedTicketsArray = currentTicketData => {
  if (!currentTicketData) {
    return [];
  }
  const flattened = [];
  currentTicketData.forEach(td => {
    td.items.forEach(t => {
      flattened.push(t);
    });
  });
  return flattened;
};

export const findTicket = (ticketId, currentTicketData) =>
  getFlattenedTicketsArray(currentTicketData).find(t => Number(t.ticketId) === Number(ticketId));

export const findAndReplaceTicket = (ticketId, currentTicketData, newData) => {
  const ticket = findTicket(ticketId, currentTicketData);
  const newTicket = { ...ticket, ...newData };

  return currentTicketData.map(t => ({
    ...t,
    items: t.items.map(i => {
      if (Number(i.ticketId) === Number(ticketId)) {
        return {
          ...newTicket,
        };
      }

      return {
        ...i,
      };
    }),
  }));
};

export const deleteTicketFromArray = (ticketId, currentTicketData) =>
  currentTicketData.map(td => {
    if (td.items.find(ti => Number(ti.ticketId) === Number(ticketId))) {
      return {
        ...td,
        items: td.items.filter(tick => Number(tick.ticketId) !== Number(ticketId)),
      };
    }

    return { ...td };
  });

export const groupTickets = data => {
  const grouped = groupBy(data, a =>
    a.category === null ||
    a.category === undefined ||
    a.category === "none" ||
    a.category.toLowerCase() === "standard" ||
    a.category.toLowerCase() === "''" ||
    a.category.toLowerCase() === "uncategorised" ||
    a.category === ""
      ? "Uncategorised"
      : a.category.toLowerCase(),
  );

  const keys = Object.keys(grouped);

  return keys.map(y => {
    const nums = grouped[y].map(x => x.categoryOrder);
    const order = Math.min(...nums);

    return {
      order,
      category: grouped[y][0].category,
      items: [...grouped[y]].sort(
        (a, b) => a.displayOrder - b.displayOrder || a.ticketName - b.ticketName,
      ),
      itemIds: grouped[y].map(t => t.ticketId),
    };
  });
};

export const sortTickets = data =>
  data.sort((a, b) => a.order - b.order || a.category - b.category);

export const deleteTicketsFromArray = (ticketIds, currentTicketData) => {
  const flat = getFlattenedTicketsArray(currentTicketData);
  const filtered = flat.filter(ticket => ticketIds.indexOf(ticket.ticketId) === -1);
  const grouped = groupTickets(filtered);
  return sortTickets(grouped);
};

export const getInstalments = data => {
  const flat = getFlattenedTicketsArray(data);
  const pp = flat.filter(t => t.paymentPlan === 1);

  if (!pp.length) {
    return [];
  }

  const instalments = [];
  pp.forEach(t => {
    t.paymentPlanTickets.map(ppt => instalments.push(ppt.ticketId));
  });

  return instalments;
};

export const getTicketStatus = (ticket, guestlist) => {
  let theme = "";
  if (guestlist) {
    return { string: "guestlist", theme: "guestlist" };
  }

  switch (ticket.ticketStatus) {
    case TICKET_STATUS.PENDING:
    case TICKET_STATUS.PENDING_HIDDEN:
    case TICKET_STATUS.ONSALE_HIDDEN:
    case TICKET_STATUS.CLOSED_HIDDEN:
      theme = "draft";
      break;
    case TICKET_STATUS.ON_SALE:
      theme = "live";
      break;
    case TICKET_STATUS.CLOSED:
      theme = "cancelled";
      break;
    default:
      theme = "draft";
      break;
  }

  return { string: ticket.ticketStatus, theme };
};

export const mapDeliveryMethod = name => {
  switch (name) {
    case "rapidscan":
      return { display: "Rapidscan", api: "rapidscan" };
    case "etickets":
    case "collect":
      return { display: "Collect (COBO)", api: "etickets" };
    case "posted":
      return { display: "Posted", api: "posted" };
    default:
      return { display: "", api: "" };
  }
};

export const structureTicketData = (ticket, promoterId) => {
  const obj = {
    ...ticket,
    isAddon: Boolean(isAddon({}, ticket)),
    freeTicketOption: ticket.ticketPrice === 0 ? "yes" : "no",
    offSaleDateTime: ticket.offSaleDatetime
      ? {
          date: date(ticket.offSaleDatetime),
          time: date(ticket.offSaleDatetime).format(TIME_FORMAT_API),
          value: ticket.offSaleDatetime,
        }
      : "",
    onSaleDateTime: ticket.onSaleDatetime
      ? {
          date: date(ticket.onSaleDatetime),
          time: date(ticket.onSaleDatetime).format(TIME_FORMAT_API),
          value: ticket.onSaleDatetime,
        }
      : "",
    ticketCountEmail: ticket.ticketCountEmail,
    confirmTicketCountEmail: ticket.ticketCountEmail,
    setUpTicketReports:
      promoterId === import.meta.env.VITE_TEST_PROMOTER_ID || ticket.ticketCountPeriod === "9"
        ? "no"
        : "yes",
    ticketCountPeriod:
      promoterId === import.meta.env.VITE_TEST_PROMOTER_ID ? "9" : ticket.ticketCountPeriod,
    waitingListEligible: ticket.waitingListEligible === 1 ? "no" : "yes",
    showPendingOnsale: ticket.showPendingOnsale === 1 ? "yes" : "no",
    showTicketsRemaining: ticket.showTicketsRemaining === 1 ? "yes" : "no",
    newField: true,
    availability: ticket.availability,
    category: ticket.category || "Uncategorised",
    passwordDisplay: ticket.passwordDisplay,
    revealTicketId:
      ticket.revealTicketId && ticket.revealTicketId !== "0"
        ? ticket.revealTicketId.split(",")
        : [],
    affectsSessionCapacity: ticket?.affectsSessionCapacity ? "yes" : "no",
  };

  if (ticket?.addOnType) {
    obj.addOnType = addonOptions.find(o => o.value === ticket?.addOnType);
  }

  const { customFeesApplied, bookingFeeGross: bf, commissionGross: cm } = ticket;
  if (parseFloat(bf) > 0 && parseFloat(cm) === 0) {
    obj.bookingFeeMode = "c";
  }
  if (parseFloat(bf) === 0 && parseFloat(cm) > 0) {
    obj.bookingFeeMode = "p";
  }
  if (!customFeesApplied && Number(ticket.ticketPrice) > 0 && bf === 0 && cm === 0) {
    obj.customFeesApplied = 1;
  }

  obj.stopMode = stopTicketOptions.find(o => o.value === obj.stopMode);

  return obj;
};

export const structureTicketsData = (data, auth) => {
  const structuredTickets = data
    .filter(ticket => !ticket.paymentPlan || (ticket.paymentPlan && ticket.paymentPlanTickets))
    .map(ticket => structureTicketData(ticket, auth.promoter.promoterId));

  // Get an array of PP instalments IDs (if any)
  const paymentPlanInstalmentTicketsIds = structuredTickets
    .map(ticket => ticket.paymentPlan && ticket.paymentPlanTickets)
    .filter(Boolean)
    .flat(1)
    .map(({ ticketId }) => ticketId);

  // Get an array of instalment ticket objects
  const paymentPlanInstalmentTickets = structuredTickets.filter(ticket =>
    paymentPlanInstalmentTicketsIds.includes(ticket.ticketId),
  );

  // Remove instalments from main array
  const ticketsWithoutInstalments = structuredTickets.filter(
    ticket => !paymentPlanInstalmentTicketsIds.includes(ticket.ticketId),
  );

  // Add detailed instalment ticket info to the deposit ticket items array
  const ticketsWithDetailedInstalmentsAdded = ticketsWithoutInstalments.map(ticket => {
    if (ticket.paymentPlan) {
      return {
        ...ticket,
        paymentPlanTickets: ticket.paymentPlanTickets.map(ppTicket => {
          return {
            ...ppTicket,
            ...paymentPlanInstalmentTickets.find(t => t.ticketId === ppTicket.ticketId),
          };
        }),
      };
    }

    return { ...ticket };
  });

  // Group into categories
  const grouped = groupTickets(ticketsWithDetailedInstalmentsAdded);

  // Sort categories by their order from the API
  return grouped.sort((a, b) => a.order - b.order);
};

export const removeDepositsAndAddBalance = tickets => {
  const itemsToReturn = [];
  Object.values(tickets).forEach(group => {
    const newItems = [...group.items];
    newItems.forEach((ticket, i) => {
      if (ticket.paymentPlan === 1) {
        const balance = ticket.paymentPlanTickets.filter(ppTicket =>
          ppTicket.ticketName.toLowerCase().includes("balance"),
        )[0];

        newItems.splice(i, 1, { ...balance });
      }
    });
    itemsToReturn.push({ ...group, items: newItems, itemIds: newItems.map(t => t.ticketId) });
  });

  return itemsToReturn;
};

export const removeBalanceTickets = tickets => {
  return tickets.filter(ticket => !ticket.ticketName.toLowerCase().includes("balance"));
};

export const isBalanceTicket = (ticket, allTickets) => {
  const flat = getFlattenedTicketsArray(allTickets);
  const ppInstalments = [];

  flat.forEach(t => {
    if (t.paymentPlan === 1) {
      ppInstalments.push(...t.paymentPlanTickets.flat(1));
    }
  });

  return (
    ticket.ticketName.toLowerCase().includes("balance") &&
    ppInstalments.find(t => Number(t.ticketId) === Number(ticket.ticketId))
  );
};

export const isCategoryUncategorised = category => {
  if (!category) {
    return false;
  }
  return (
    category.toLowerCase() === CATEGORIES.UNCATEGORISED ||
    category.toLowerCase() === CATEGORIES.UNCATEGORIZED
  );
};

export const updateTicketState = (ticket, currentTicketData) => {
  const flat = getFlattenedTicketsArray(currentTicketData);
  // Check if ticket already exists
  const doesTicketExist = flat.find(t => Number(t.ticketId) === Number(ticket.ticketId));

  if (doesTicketExist) {
    return sortTickets(
      groupTickets([...flat.filter(t => Number(t.ticketId) !== Number(ticket.ticketId)), ticket]),
    );
  }

  return sortTickets(groupTickets([...flat, ticket]));
};

export const skipTickets = async (
  promoterId,
  eventId,
  form,
  setRunningOperation,
  setGlobalForm,
  globalForm,
) => {
  const res = await httpRequestAsync({
    authenticated: true,
    endpoint: Endpoints.EVENT_BY_ID,
    queryStringParams: { promoterId },
    params: { eventId },
    body: { entryPrice: form.values.entryPrice },
    method: "PUT",
  });
  setRunningOperation(false);

  setGlobalForm({
    values: { ...globalForm.values, ...form.values, entryPrice: form.values.entryPrice },
    errors: { ...globalForm.errors },
  });
  return res;
};

export const resetEntryPrice = async (promoterId, eventId, form, setGlobalForm, globalForm) => {
  const res = await httpRequestAsync({
    authenticated: true,
    endpoint: Endpoints.EVENT_BY_ID,
    queryStringParams: { promoterId },
    params: { eventId },
    body: { entryPrice: "" },
    method: "PUT",
  });
  form.setFieldValue("entryPrice", "");
  form.setFieldValue("skipTickets", "no");
  setGlobalForm({
    values: { ...globalForm.values, entryPrice: "", skipTickets: "no" },
    errors: { ...globalForm.errors },
  });
  return res;
};

export const createPayload = (form, fields, step, globalForm, listingId, eventId, qsValues) => {
  const newTick = {};
  const formValues = form.values;
  if (form.values.ticketId) {
    newTick.ticketId = form.values.ticketId;
  }
  const groupedFields = [];
  fields
    .filter(field => Number(field.step) === Number(step) && field.isGroup)
    .forEach(f => f.fields.forEach(field => groupedFields.push(field)));

  const stepFields = fields
    .filter(field => Number(field.step) === Number(step))
    .filter(f => !f.isGroup)
    .concat(groupedFields);

  const { venue } = globalForm.values;
  const pay = {
    listingId: Number(listingId),
    venueId: venue.id,
    listingInstanceId: Number(eventId),
  };

  Object.keys(form.values).forEach(val => {
    const field = stepFields.find(f => f.name === val);
    if (!field || field.name === "entryPrice") {
      return;
    }

    // If the field needs a different name to be sent to API as set in events constant
    const name = field.postApiName || field.name;
    // Grab the value
    const value = formValues[val];

    // Check that this field actually needs sending to the API
    if (field.sendToApi(val, formValues, form.initialValues, qsValues, globalForm.values)) {
      // Add it to the object if it does
      pay[name] = field.postFormat ? field.postFormat(value, formValues) : value;
    }

    newTick[field.name] = value;

    // form.addValue(val, "");
    // Remove any empty string values as backend cannot process empty string
    if (pay[name] === "") {
      delete pay[name];
    }
  });

  return { newTicket: newTick, payload: pay };
};

export const ticketButtonAction = (
  values,
  ticketId,
  currentTicketData,
  form,
  navigate,
  allowSkipTickets = true,
) => {
  if (values.relist === "true") {
    return "addTicket";
  }

  if (
    (ticketId || currentTicketData.length) &&
    (!form.dirty || !Object.keys(form.touched).length)
  ) {
    return navigate(
      `${window.location.pathname}?step=${ticketStep}&stage=${ticketSummaryStage}${
        values.min && values.max ? `&min=${values.min}&max=${values.max}` : ""
      }`,
    );
  }

  if (
    (!form.dirty || !Object.keys(form.touched).length) &&
    !ticketId &&
    form.values.skipTickets === "no" &&
    allowSkipTickets
  ) {
    return "skipTickets";
  }

  if (ticketId && form.dirty && !values.relist) {
    return "updateTicket";
  }

  if (
    (form.values.skipTickets === "yes" && form.touched.entryPrice && !currentTicketData.length) ||
    values.relist === "true"
  ) {
    return "addTicket";
  }

  if (
    form.values.skipTickets === "yes" &&
    form.values.entryPrice &&
    !form.touched.entryPrice &&
    !currentTicketData.length
  ) {
    return navigate(
      `${window.location.pathname}?step=5&stage=1${
        values.min && values.max ? `&min=${values.min}&max=${values.max}` : ""
      }`,
    );
  }

  return "addTicket";
};

export const ticketButtonText = (
  values,
  form,
  ticketId,
  currentTicketData,
  eventSteps,
  step,
  stage,
  allowSkipTickets = true,
) => {
  if (isAddon(values, form.values) && ticketId) {
    return "Edit add-on";
  }

  if (isAddon(values, form.values)) {
    return "Create add-on";
  }

  if (values.relist === "true") {
    return "Create ticket";
  }

  if (
    !form.dirty &&
    !ticketId &&
    form.values.skipTickets === "no" &&
    !currentTicketData.length &&
    allowSkipTickets
  ) {
    return "Skip tickets";
  }

  if (
    ticketId &&
    (form.values.skipTickets === "no" || currentTicketData.length) &&
    (!values.relist || values.relist !== "true")
  ) {
    return "Update ticket";
  }

  if (form.values.skipTickets === "yes") {
    return "Confirm entry price";
  }

  if (form.values.skipTickets === "yes" && form.values.entryPrice && !currentTicketData.length) {
    return "Continue";
  }

  return get(eventSteps, `[${step}].stages[${stage - 1}].buttons.addTicket.label`);
};

export const isEligibleForWaitingList = async (
  waitingListListingEnabled,
  event,
  ticket,
  action = waitingListWarningActions.CREATE,
) => {
  const { eventId, eventEndTime } = event;
  const { REOPEN, UNHIDE, UPDATE, UPDATE_ALLOCATION, CREATE } = waitingListWarningActions;
  const { CLOSED_HIDDEN, PENDING_HIDDEN, ONSALE_HIDDEN, CLOSED, ON_SALE } = TICKET_STATUS;
  const hiddenStatuses = [CLOSED_HIDDEN, PENDING_HIDDEN, ONSALE_HIDDEN];
  const { startMode, onSaleDateTime, ticketId, ticketsAvailable } = ticket;

  // Get number of people on WL
  const { data } = await httpRequestAsync({
    authenticated: true,
    endpoint: Endpoints.WAITING_LIST,
    queryStringParams: { count: 1 },
    params: { eventId },
  });

  const wlCount = data[0]?.count;

  let ticketStatus = ON_SALE;

  // Default to 0
  let ticketsLeftFinalValue = 0;

  if (action !== CREATE) {
    // Get the most up-to-date ticket status (in case of stale page)
    const { data: ticketData } = await httpRequestAsync({
      authenticated: true,
      endpoint: Endpoints.SINGLE_TICKET,
      params: { id: ticketId },
    });

    ({ string: ticketStatus } = getTicketStatus(ticketData));

    // If we're not updating, we can set ticketsLeft to the query value
    // in case of a stale page, as it's the most up-to-date.
    if (action !== UPDATE && action !== UPDATE_ALLOCATION) {
      ticketsLeftFinalValue = Number(ticketData.ticketsLeft) || 0;
    } else {
      const { ticketsSold, resaleTicketsSold, resaleTicketsAvailable } = ticketData;
      /** If we are updating, calculate the new ticketsLeft the same way the API would
       * to make sure we're getting the latest and most correct value. E.g. if we decrease
       * allocation to make a ticket sold out, we don't want this to trigger the modal
       * but if we use the ticketsLeft from the API, it currently isn't aware of this update
       * we're doing as it hasn't happened yet, and it therefore would trigger. So we need
       * to calculate for ourselves using the form value for ticketsAvailable (passed into func)
       * and ticketsSold, resaleTicketsSold and resaleTicketsAvailable from the latest API call.
       */
      ticketsLeftFinalValue =
        Number(ticketsAvailable) -
        (Number(ticketsSold) - Number(resaleTicketsSold)) +
        Number(resaleTicketsAvailable);
    }
  }

  const listingEligible = waitingListListingEnabled && waitingListListingEnabled !== "no";

  addPageLogs({
    listingEligible,
    ticketsLeftFinalValue,
    startMode,
    onSaleDateTime,
    wlCount,
    ticketStatus,
  });

  const toReturn = { count: wlCount, ticketsLeft: ticketsLeftFinalValue };

  // If event is in past
  // or not eligible on listing level
  // or is closed (as long as the action is not reopen)
  // or there's no one on the WL
  // it can never trigger WL modal
  if (
    date(eventEndTime).isBefore(date()) ||
    !listingEligible ||
    ((ticketStatus === CLOSED || ticketStatus === CLOSED_HIDDEN) && action !== REOPEN) ||
    wlCount === 0 ||
    !wlCount ||
    startMode === "chained"
  ) {
    return false;
  }

  // If being reopened or unhidden, and there are tickets left
  if (action === REOPEN || action === UNHIDE) {
    return ticketsLeftFinalValue > 0 ? toReturn : false;
  }
  // If being updated, there are tickets left and it's not hidden (closed covered by first check)
  if (action === UPDATE || action === UPDATE_ALLOCATION) {
    return ticketsLeftFinalValue > 0 && hiddenStatuses.indexOf(ticketStatus) === -1
      ? toReturn
      : false;
  }

  if (action === CREATE) {
    return toReturn;
  }

  return false;
};

export const getTicketResellDependencies = (ticketId, allTickets) =>
  allTickets.filter(
    t =>
      Number(t.resellAsTicketId) === Number(ticketId) &&
      Number(t.ticketId) !== Number(ticketId) &&
      !t.resellDisabled,
  );

export const isRescheduledPendingTickets = (event, currentTicketData) =>
  event?.cancellationRescheduledFromLID && (!currentTicketData || !currentTicketData.length);

export const handleButtonClick = async (
  action,
  form,
  postTicket,
  event,
  currentTicketData,
  navigate,
) => {
  switch (action) {
    case "skipTickets": {
      if (isRescheduledPendingTickets(event, currentTicketData)) {
        return navigate(`${window.location.pathname}?step=5&stage=1`);
      }
      return form.setFieldValue("skipTickets", "yes");
    }
    case "updateTicket":
    case "addTicket":
      return postTicket();
    default:
      return form.setFieldValue("skipTickets", "yes");
  }
};

export const postFormatDateTime = val => {
  let { value } = val;
  if (typeof val.value === "string") {
    // Not a moment yet
    value = date(val.value);
  }

  return value && value.format(ISO_DATE_TIME_FORMAT);
};
