import Axios from "axios";
import getResponse from "./getResponse";
import setCurrentEventFn from "./setCurrentEvent";

export default async (method, url, headers, queryString, timeout, body, requestConfig = {}) => {
  const { key, controller, endpoint } = requestConfig;

  const axiosObject = {
    method,
    url,
    headers,
    params: queryString,
    timeout,
    key,
    signal: controller?.signal || null,
    data: body,
  };

  const axiosResponse = await Axios(axiosObject);

  const { res, meta, links } = await getResponse(axiosResponse, requestConfig?.structureData);

  if (requestConfig?.setCurrentEvent) {
    setCurrentEventFn(endpoint, method, res, requestConfig?.setCurrentEvent);
  }

  return { res, meta, links, axiosResponse };
};
