import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

import Button from "../../../Reusable/Button/Button";

const LogoutButton = ({ variant = "contained", ...rest }) => {
  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault();
    navigate("/auth/logout");
  };

  return (
    <Button
      variant={variant}
      color="secondary"
      type="Button"
      onClick={async e => {
        const error = await handleClick(e);
        if (error) {
          throw error;
        }
      }}
      {...rest}
    >
      Logout
    </Button>
  );
};

LogoutButton.propTypes = {
  variant: PropTypes.string,
};
export default LogoutButton;
