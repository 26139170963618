const promoterActiveStatuses = {
  BANNED: {
    id: 0,
    label: "Deleted",
  },
  ACTIVE: {
    id: 1,
    label: "Active",
  },
  PENDING: {
    id: 3,
    label: "Awaiting email validation",
  },
  SECURITY_TIMEOUT: {
    id: 4,
    label: "Security Locked",
  },
};

export default promoterActiveStatuses;
