const pluralRules = new Intl.PluralRules("en-GB", {
  type: "ordinal",
});

const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);

const formatOrdinals = number => {
  const rule = pluralRules.select(number);
  const suffix = suffixes.get(rule);
  return `${number}${suffix}`;
};

export default formatOrdinals;
