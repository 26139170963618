import * as Yup from "yup";

const eTicketInfo = {
  name: "eTicketInfo",
  displayName: () => "Additional customisation",
  validation: () =>
    Yup.string()
      .min(3, "Additional customisation must be at least 3 characters")
      .max(100, "Additional customisation must be no more than 100 characters"),
  inputProps: {
    minLength: 3,
    maxLength: 100,
  },
  defaultValue: "",
  sendToApi: () => true,
  advancedOption: true,
  postFormat: val => val || null,
};

export default eTicketInfo;
