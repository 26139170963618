import { useEffect } from "react";
import { signOut } from "aws-amplify/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { setLoading, userLoggedOut } from "../../../../Store/reducers/AuthReducer";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      localStorage.clear();
      dispatch(setLoading(true));
      await signOut();
      dispatch(userLoggedOut());
      navigate("/auth/login");
      dispatch(setLoading(false));
    };

    logout();
  }, []);

  return null;
};

Logout.propTypes = {};
export default Logout;
