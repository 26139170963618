import * as Yup from "yup";

const minPerTransaction = {
  name: "minPerTransaction",
  label: "Minimum tickets per order",
  displayName: () => "Min per order",
  defaultValue: "1",
  inputType: "number",
  sendToApi: () => true,
  postFormat: val => (val ? Number(val) : 1),
  advancedOption: true,
  validation: () =>
    Yup.number()
      .min(1, "Minimum tickets per order must be 1 or more")
      .max(
        Yup.ref("maxPerTransaction"),
        "Minimum tickets per order must be less than or equal to the maximum tickets per order",
      ),
  grid: {
    md: 6,
  },
};
export default minPerTransaction;
