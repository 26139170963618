import { ConsoleLogger } from "aws-amplify/utils";
import { getCurrentUser, fetchAuthSession, signOut } from "aws-amplify/auth";
import get from "lodash/get";
import Intercom from "@intercom/messenger-js-sdk";
import { captureException, getCurrentScope } from "@sentry/react";

import { setLoading, userLoggedIn, userLoggedOut } from "../../Store/reducers/AuthReducer";
import getUserData from "./getUserData";
import promoterActiveStatuses from "../../Constants/promoterActiveStatuses";
import { isOwner as isOwnerFn } from "../permissions";
import httpRequestAsync from "../httpRequestAsync";
import { Endpoints } from "../../Constants";
import isFeatureFlagOn, { featureFlags } from "../checkFeatureFlag";
import { LOGGED_IN_AS_PROMOTER_ID } from "../../Constants/auth";
import sentryTags from "../../Constants/sentryTags";
import { warning } from "../toast";

const logger = new ConsoleLogger("AB");
const noUserError = "No current user";

export default async function confirmSignIn(dispatch) {
  try {
    // Set global app loading state
    dispatch(setLoading(true));

    // Grab current session
    const session = await fetchAuthSession();

    // If there is no session credentials the user isn't signed in anymore
    // so log them out
    if ((!session || !session?.credentials) && !window?.location?.pathname?.includes("/auth")) {
      dispatch(setLoading(false));

      warning("Your session has expired, please sign in again.");
      await signOut();
      localStorage.clear();
      return;
    }

    // Find the current authenticated user
    const user = await getCurrentUser();

    if (!user) {
      throw new Error(noUserError);
    }

    if (!session) {
      throw new Error(noUserError);
    }

    // Get user information from JWT
    let userData = await getUserData(user, session);

    const personalisationFullname = `${
      userData.promoters[userData.mainPromoterId]?.firstname || ""
    } ${userData.promoters[userData.mainPromoterId]?.surname || ""}`;

    const active = userData.promoters[userData.mainPromoterId]?.details?.active;

    // Check various conditions to make sure we don't needlessly make the API calls here.
    const { skiddleStaff } = userData.promoters[userData.mainPromoterId];
    const isBanned = active === promoterActiveStatuses.BANNED.id && !skiddleStaff;
    const isTimedout = active === promoterActiveStatuses.SECURITY_TIMEOUT.id && !skiddleStaff;
    const isOwner = isOwnerFn({ userData });
    const isPending = active === promoterActiveStatuses.PENDING.id && !isOwner;
    const needsToConfirmEmail =
      !get(userData, "promoter.emailVerified") ||
      (active === promoterActiveStatuses.PENDING.id && isOwner);

    const shouldMakeApiRequests = !isBanned && !isTimedout && !isPending && !needsToConfirmEmail;

    if (shouldMakeApiRequests && isOwner) {
      try {
        const res = await httpRequestAsync({
          authenticated: true,
          endpoint: Endpoints.GET_RECEIVED_INVITES,
          queryStringParams: {
            promoterId: userData.mainPromoterId,
          },
        });

        if (res?.data?.length) {
          userData.invites = res.data;
        }
        // empty catch to silently error as this isn't a critical page
        // eslint-disable-next-line
      } catch {}
    }

    if (shouldMakeApiRequests) {
      // Fetch the promoter to get extra info not passed in JWT
      const promoter = await httpRequestAsync({
        authenticated: true,
        endpoint: Endpoints.GET_PROMOTER_WITH_USERS,
        params: { id: userData?.promoter?.promoterId },
      });

      if (promoter?.data) {
        userData = { ...promoter.data, ...userData };
      }
    }

    dispatch(userLoggedIn(userData));
    dispatch(setLoading(false));

    const personalisationUserID = userData.mainPromoterId || null;
    const personalisationEmail = userData.promoter.email || null;

    /* Intercom user specific code */
    if (isFeatureFlagOn(featureFlags.INTERCOM)) {
      Intercom({
        app_id: import.meta.env.VITE_INTERCOM_APP_ID,
        name: personalisationFullname, // Full name
        email: personalisationEmail, // Email address
        user_id: personalisationUserID,
        product: "promotioncentre",
        hasUsedBeta: true,
      });
    }

    const scope = getCurrentScope();
    const loggedInAs = localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID);
    scope.setTag("mainPromoterId", userData?.mainPromoterId);
    scope.setTag("isLoggedIn", true);
    if (loggedInAs) {
      scope.setTag("loggedInAs", loggedInAs);
    }

    let userType = "Regular user";

    if (skiddleStaff) {
      userType = "Skiddle staff";
    }

    if (loggedInAs && skiddleStaff) {
      userType = `Skiddle staff (${userData?.mainPromoterId}) logged in as (${loggedInAs})`;
    }
    if (loggedInAs && !skiddleStaff) {
      userType = `Regular user (${userData?.mainPromoterId}) logged in as (${loggedInAs})`;
    }

    scope.setUser({
      id: userData.mainPromoterId,
      loggedInAs,
      username: userData.username,
      email: userData.promoter.email,
      userType,
      promoters: userData.promoters,
      expiry: userData.expiry,
    });
  } catch (e) {
    // Log to Sentry
    if (e !== "The user is not authenticated") {
      captureException(e, { tags: { ...sentryTags.amplify } });
    }

    // Log to console via AWS SDK's logger
    logger.error("Error caught: ", e);

    // Log them out
    await signOut();
    localStorage.clear();
    dispatch(userLoggedOut());
    dispatch(setLoading(false));
  }
}
