import isEqual from "lodash/isEqual";
import { v4 } from "uuid";

import Store from "../../Store";
import { setRequests } from "../../Store/reducers/RequestCacheReducer";
import date from "../../Utils/date";

const CACHE_EXPIRY = 5;

/**
 * @function removeRequestFromCache - Remove a request from the cache by id
 *
 * @param {String} requestId
 */
export const removeRequestFromCache = requestId => {
  const {
    requestCache: { requests },
  } = Store.getState();
  const newRequests = requests.filter(request => request.id !== requestId);
  Store.dispatch(setRequests({ requests: newRequests }));
};

/**
 * @function checkRequestCache - Check if a given request is present
 * in the cache, and return it if it is.
 *
 * Check if this request is already in the cache by checking against url
 * and by checking the query string params are equal, and that we're still
 * before the cache expiry (set at top of this file)
 *
 * @param {Object} requestObject.url - The base API endpoint
 * @param {Object} requestObject.queryStringParams - The parameters applied to the query string
 *
 * @returns cachedRequest || null
 */
export const checkRequestCache = requestObject => {
  const {
    requestCache: { requests },
  } = Store.getState();

  const find = requests.find(
    request =>
      request.url === requestObject.url &&
      isEqual(request.queryStringParams, requestObject.queryStringParams),
  );

  if (!find) {
    return null;
  }

  // If it is in cache, check if it's expired, if it has
  // remove it from the cache, if not, return it.
  if (find && date().isAfter(find.expiry, "seconds")) {
    removeRequestFromCache(find.id);
    return null;
  }

  return find;
};

/**
 * @function cacheRequest - Add a request to the cache. Currently only used when fetchAll
 * is true on useHttpRequest (i.e. single requests won't currently cache).
 *
 * @param {Object} requestObject.url - The base API endpoint
 * @param {Object} requestObject.queryStringParams - The parameters applied to the query string
 *
 * @returns newRequests
 */
export const cacheRequest = requestObject => {
  const {
    requestCache: { requests },
  } = Store.getState();

  const isAlreadyInCache = checkRequestCache(requestObject);

  if (isAlreadyInCache) {
    return null;
  }

  const newRequests = [
    ...requests,
    { ...requestObject, id: v4(), expiry: date().plus(CACHE_EXPIRY, "minutes") },
  ];

  Store.dispatch(setRequests({ requests: newRequests }));
  return newRequests;
};

export const getAllCachedRequests = () => {
  const {
    requestCache: { requests },
  } = Store.getState();
  return requests;
};
