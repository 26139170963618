import Axios from "axios";
import chunk from "lodash/chunk";

import { cacheRequest, checkRequestCache, getAllCachedRequests } from "./cacheRequest";
import getResponse from "./getResponse";
import { TIMEOUT } from "../../Constants/requests";

export const ROWS_PER_PAGE = 100;
export const CHUNK_SIZE = 30;

const fetchAllData = async (meta, requestOptions, controller, dispatch) => {
  const numberOfRequests = Math.ceil(Number(meta?.totalResults || 0) / ROWS_PER_PAGE);

  const requestsArr = [];
  const cachedResponses = [];

  const {
    method,
    url: endpointUrl,
    headers,
    queryString,
    structureData,
    structureAllData,
    pay,
    key,
  } = requestOptions;

  for (let index = 1; index <= numberOfRequests; index += 1) {
    const obj = {
      method,
      url: endpointUrl,
      headers,
      params: { ...queryString, "page[size]": ROWS_PER_PAGE, "page[number]": index },
      timeout: requestOptions.timeout || TIMEOUT,
      key,
      signal: controller?.signal,
    };

    const cachedRequest = checkRequestCache({
      url: endpointUrl,
      queryStringParams: {
        ...queryString,
        "page[size]": ROWS_PER_PAGE,
        "page[number]": index,
      },
    });

    if (!cachedRequest) {
      requestsArr.push(obj);
    } else {
      cachedResponses.push(cachedRequest);
    }
  }

  const allDataResponse = [];

  if (cachedResponses.length) {
    cachedResponses.forEach(cachedResponse => allDataResponse.push(cachedResponse.response));
  }

  if (requestsArr.length) {
    const chunkedRequests = chunk(requestsArr, CHUNK_SIZE);

    let chunksProcessed = 0;
    const processChunk = async chunks => {
      if (chunks.length === 0) {
        return;
      }

      const currentChunk = chunks[0];
      const promises = currentChunk.map(request => Axios(request));
      const chunkData = await Promise.all(promises);

      const responsePromises = chunkData.map(async d => {
        const { res: data } = await getResponse(d, structureData);
        return { data, request: d };
      });

      const responseRes = await Promise.all(responsePromises);

      responseRes.forEach(({ data, request }) => {
        cacheRequest({
          url: request.config.url,
          queryStringParams: request.config.params,
          response: data,
        });
        allDataResponse.push(data);
      });

      chunksProcessed += 1;

      if (dispatch) {
        dispatch({
          type: "FETCHING_ALL",
          payload: { key, meta, progress: (chunksProcessed / chunkedRequests.length) * 100 },
        });
      }

      // Process the next chunk
      await processChunk(chunks.slice(1));
    };

    // Start processing chunks
    await processChunk(chunkedRequests);
  }

  const structuredAllData = structureAllData
    ? structureAllData(allDataResponse.flat(1), meta)
    : allDataResponse.flat(1);

  dispatch({
    type: "FETCHED_ALL",
    payload: { ...pay, key, allData: structuredAllData, cachedRequests: getAllCachedRequests() },
  });
};

export default fetchAllData;
