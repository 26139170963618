import * as Yup from "yup";
import { isAddon, isCategoryUncategorised } from "../../../../../Utils/ticketHelpers";
import defaultTicketCategoryBlank from "../../../../../Constants/tickets/defaultTicketCategoryBlank";
import lazy from "../../../../../Utils/lazy";

const TicketCategory = lazy(() => import("../../../Tickets/Components/TicketCategory"));

const ticketCategory = {
  name: "category",
  displayName: () => "Ticket category",
  sendToApi: (val, values) => values[val] && values[val] !== "",
  postFormat: val => {
    const { value, inputValue, addValue } = val;

    if (inputValue) {
      return isCategoryUncategorised(inputValue) ? null : inputValue;
    }

    if (addValue) {
      return isCategoryUncategorised(addValue) ? null : value;
    }

    if (value) {
      return isCategoryUncategorised(value) ? null : value;
    }

    return null;
  },
  hidden: (formValues, { qsValues }) => isAddon(qsValues, formValues),
  type: TicketCategory,
  defaultValue: defaultTicketCategoryBlank,
  displayEmpty: true,
  subtitle: "Ticket category",
  validation: () =>
    Yup.object({
      label: Yup.string().nullable(),
      value: Yup.string().nullable(),
      category: Yup.string().nullable(),
      displayGroup: Yup.string().nullable(),
      originalDisplayGroup: Yup.string().nullable(),
    }),
};

export default ticketCategory;
