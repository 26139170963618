import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import capitalize from "lodash/capitalize";

import { grey } from "@mui/material/colors";
import { Button as MuiButton, Tooltip } from "@mui/material";
import { spacing } from "@mui/system";

import usePermission from "../../../Hooks/usePermission";
import { DEFAULT_MESSAGE } from "../Restricted/Restricted";

const PREFIX = "Button";

const classes = {
  outlinedSuccess: `${PREFIX}-outlinedSuccess`,
  outlinedError: `${PREFIX}-outlinedError`,
  outlinedWarning: `${PREFIX}-outlinedWarning`,
  outlinedInfo: `${PREFIX}-outlinedInfo`,
  containedSuccess: `${PREFIX}-containedSuccess`,
  containedError: `${PREFIX}-containedError`,
  containedWarning: `${PREFIX}-containedWarning`,
  containedInfo: `${PREFIX}-containedInfo`,
  containedGrey: `${PREFIX}-containedGrey`,
};

const StyledButton = styled(MuiButton)(({ theme }) => ({
  [`& .${classes.outlinedSuccess}`]: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },

  [`& .${classes.outlinedError}`]: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },

  [`& .${classes.outlinedWarning}`]: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
  },

  [`& .${classes.outlinedInfo}`]: {
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
  },

  [`& .${classes.containedSuccess}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },

  [`& .${classes.containedError}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },

  [`& .${classes.containedWarning}`]: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },

  [`& .${classes.containedInfo}`]: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },

  [`& .${classes.containedGrey}`]: {
    backgroundColor: grey[300],
    color: theme.palette.common.black,
  },
}));

const ButtonWithSpacing = styled(StyledButton)(spacing);

/**
 * A custom button, extending MUI
 * This can be used to have more colour
 * options, such as red for error.
 */
const Button = ({
  children,
  color = "primary",
  variant = "contained",
  hasAccess = null,
  fallbackMessage = DEFAULT_MESSAGE,
  disabled = false,
  tooltip = null,
  innerRef = undefined,
  ...props
}) => {
  const className = classes[`${variant}${capitalize(color)}`];
  const canAccess = usePermission(hasAccess || 0);

  const renderButton = (shouldBeDisabled = disabled) => (
    <ButtonWithSpacing
      {...props}
      ref={innerRef}
      color={color}
      className={className}
      variant={variant}
      disabled={shouldBeDisabled}
    >
      {children}
    </ButtonWithSpacing>
  );

  if (tooltip && canAccess) {
    return (
      <Tooltip title={tooltip}>
        <span>{renderButton()}</span>
      </Tooltip>
    );
  }

  if (canAccess) {
    return renderButton();
  }

  return (
    <Tooltip title={fallbackMessage}>
      <span>{renderButton(true)}</span>
    </Tooltip>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasAccess: PropTypes.number,
  fallbackMessage: PropTypes.string,
  disabled: PropTypes.bool,
  "data-testid": PropTypes.string,
  tooltip: PropTypes.string,
  innerRef: PropTypes.object,
};

export default Button;
