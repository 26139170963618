import React from "react";
import {
  styled,
  Drawer as MuiDrawer,
  IconButton as MuiIconButton,
  Grid2 as Grid,
  Box,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Button,
} from "@mui/material";
import get from "lodash/get";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";

import { spacing } from "@mui/system";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";

import "../../../vendor/perfect-scrollbar.css";
import useAuthMainPromoter from "../../NamedComponents/Auth/hooks/useAuthMainPromoter";
import useAuth from "../../NamedComponents/Auth/hooks/useAuth";
import MobileNotifications from "./MobileNotifications";
import SwitchPromoter from "./SwitchPromoter";
import ManageAccountLinks from "./ManageAccountLinks";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const IconButton = styled(MuiIconButton)`
  color: ${({ theme: { palette } }) => palette.skiddle.black};
  padding: 0;
`;

const SettingsDrawer = ({
  handleClearAllNotifications,
  handleClearNotification,
  notifications,
  handleLogout,
  ...rest
}) => {
  const mainPromoter = useAuthMainPromoter();
  const auth = useAuth();

  return (
    <Drawer variant="permanent" anchor="right" {...rest}>
      <Scrollbar>
        <Box
          sx={{
            p: 4,
          }}
        >
          <Grid
            container
            sx={{
              alignItems: "center",
            }}
          >
            <Grid size={3}>
              <IconButton color="inherit" aria-label="Open Settings" size="large">
                <AccountCircleIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">
                <strong>
                  {get(mainPromoter, "firstname")} {get(mainPromoter, "surname")}
                </strong>
              </Typography>
              <Typography variant="caption">{get(auth, "promoter.details.name")}</Typography>
            </Grid>
            <Grid size={1}>
              <IconButton size="small" edge="end" onClick={rest.onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid size={12}>
              <Divider my={6} />
            </Grid>
            <Grid size={12}>
              <SwitchPromoter setOpen={rest.onClose} />
            </Grid>
            <Grid size={12}>
              <Divider my={6} />
            </Grid>
            <ManageAccountLinks setOpen={rest.onClose} />

            <Grid size={12}>
              <Divider my={6} />
            </Grid>
            {notifications.length > 0 && (
              <>
                <Grid size={12}>
                  <Divider my={6} />
                </Grid>
                <Grid size={12}>
                  <MobileNotifications
                    notifications={notifications}
                    handleClearAllNotifications={handleClearAllNotifications}
                    handleClearNotification={handleClearNotification}
                  />
                </Grid>
                <Grid size={12}>
                  <Divider my={6} />
                </Grid>
              </>
            )}
            <Grid size={12}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={e => handleLogout(e)}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};

SettingsDrawer.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleClearNotification: PropTypes.func.isRequired,
  handleClearAllNotifications: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};
export default SettingsDrawer;
