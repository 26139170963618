import React from "react";
import PropTypes from "prop-types";
import Link from "../Components/Reusable/Link";
import links from "../Utils/links";

const { HELP_CENTRE } = links;
const ARTICLES = "/en/articles";
const COLLECTIONS = "/en/collections";

const buildUrl = (id, title, hash = null, collection = false) => {
  return `${HELP_CENTRE}${collection ? COLLECTIONS : ARTICLES}/${id}-${title}
  ${hash ? `#${hash}` : ""}`;
};

const helpCentre = {
  HOME: () => HELP_CENTRE,
  LISTING_EVENTS: () => buildUrl(9468569, "listing-events", null, true),
  DISCOUNT_CODES: () => buildUrl(9362797, "discount-codes"),
  FINANCE: hash => buildUrl(9468563, "finance", hash, true),
  CANCELLED_EVENT_NEXT_STEPS: () =>
    buildUrl(9362901, "i-have-a-new-date-for-my-postponed-event-what-do-i-do"),
  ADDING_MEMBERS_TO_ACCOUNT: () => buildUrl(9362821, "adding-team-members-to-your-account"),
  PAYMENT_PLANS: () => buildUrl(9362807, "everything-payment-plans"),
  PAYMENT_PLAN_CREATE: () => buildUrl(5312261, "how-to-create-a-payment-plan"),
  CANCEL_EVENT_HOW_TO: () => buildUrl(9362783, "how-to-cancel-your-event"),
  POSTPONE_EVENT_HOW_TO: () => buildUrl(9362782, "how-to-postpone-an-event"),
  REFUND_REQUEST: () => buildUrl(9362883, "what-happens-when-a-refund-request-is-referred-to-me"),
  DETACH_EVENT: () => buildUrl(9362777, "detaching-a-date-from-an-event-series"),
  RAPIDSCAN_ADD_USER: () => buildUrl(9362809, "adding-guest-users-to-rapidscan"),
  RAPIDSCAN_HOW_TO: () => buildUrl(9362938, "how-to-use-the-rapidscan-app"),
  RAPIDSCAN_FAQS: () => buildUrl(9362907, "rapidscan-app-faqs"),
};

export const HelpCentreLink = ({ children, to }) => (
  <Link isRouterLink={false} to={to} target="_blank" rel="noopener noreferrer">
    {children}
  </Link>
);
HelpCentreLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  to: PropTypes.string.isRequired,
};

export default helpCentre;
