import lazy from "../../../../../Utils/lazy";

const SingleCheckboxInput = lazy(
  () =>
    import(
      /* webpackChunkName: "SingleCheckboxInput"  */ "../../../../Reusable/Atoms/InputCheckbox/SingleCheckboxInput"
    ),
);

const restrictPurchase = {
  name: "restrictPurchase",
  displayName: () => "Only allow this ticket to be acquired when paid tickets are purchased?",
  type: SingleCheckboxInput,
  defaultValue: "0",
  postFormat: val => Number(val),
  sendToApi: (val, formValues) =>
    formValues.freeTicketOption === "yes" || Number(formValues.ticketPrice === 0),
  hidden: formValues => formValues.freeTicketOption === "no",
};

export default restrictPurchase;
