const replaceParams = (url, params) => {
  if (!url || typeof url !== "string") {
    return "";
  }

  if (!params || !params[Object.keys(params)[0]]) {
    return url;
  }

  let newUrl = url;
  if (newUrl.includes(":")) {
    const paramKeys = Object.keys(params);
    paramKeys.forEach(param => {
      newUrl = newUrl.replace(`:${param}`, params[param]);
    });
  }

  return newUrl;
};

export default replaceParams;
