const eTicketTerms = {
  name: "eTicketTerms",
  displayName: () => "Terms and conditions",
  defaultValue: "",
  inputType: "textArea",
  sendToApi: () => true,
  advancedOption: true,
  postFormat: val => val || null,
  subtitle:
    "Please keep this to a minimum as there's a limit to the information that fit on a PDF ticket.",
};

export default eTicketTerms;
