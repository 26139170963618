/* eslint-disable quotes */

const typography = {
  useNextVariants: true,
  fontFamily: [
    "Open Sans",
    "sans-serif",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 14,
  fontSizeSmall: 12,
  fontSizeMedium: 16,
  fontSizeLarge: 20,
  fontSizeExtraLarge: 26,
  fontSizeXXL: 32,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 800,
  h1: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 400,
    lineHeight: 1.2,
  },
  h4: {
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: "1.125rem",
    fontWeight: 400,
    lineHeight: 1.2,
  },
  h6: {
    fontSize: "1.0625rem",
    fontWeight: 400,
    lineHeight: 1.2,
  },
  body2: {
    fontSize: "0.8rem",
  },
  small1: {
    fontSize: "12.5px",
  },
  small2: {
    fontSize: "12px",
  },
};

export default typography;
