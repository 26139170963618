import React, { useEffect, useState } from "react";
import { confirmUserAttribute, sendUserAttributeVerificationCode } from "aws-amplify/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, Navigate } from "react-router";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";

import { Box, TextField, Typography, Alert, Divider } from "@mui/material";

import { Button, Logo } from "../../../Reusable";
import useAuth from "../hooks/useAuth";
import LoadingIcon from "../../../Reusable/LoadingIcon";
import { success } from "../../../../Utils/toast";
import getUserSession from "../../../../Utils/Auth/getUserSession";
import { userLoggedIn } from "../../../../Store/reducers/AuthReducer";
import useHttpRequest from "../../../../Hooks/useHttpRequest/useHttpRequest";
import promoterActiveStatuses from "../../../../Constants/promoterActiveStatuses";
import { Endpoints } from "../../../../Constants";
import { FETCHED } from "../../../../Constants/requestStatuses";
import LogoutButton from "./LogoutButton";

const ChangeEmailCodeConfirm = () => {
  const [error, setError] = useState(false);
  const [shouldExecutePut, setShouldExecutePut] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useAuth();

  const updateProfile = useHttpRequest({
    params: {
      promoterId: auth?.promoter?.promoterId,
    },
    queryStringParams: { promoterId: auth?.promoter?.promoterId },
    shouldExecute: shouldExecutePut,
    method: "PUT",
    body: { active: promoterActiveStatuses.ACTIVE.id },
    endpoint: Endpoints.UPDATE_PROMOTER,
    authenticated: true,
    showRawErrorMessage: () => true,
    showGlobalError: true,
  });

  useEffect(() => {
    const updateUser = async () => {
      const user = await getUserSession();
      dispatch(userLoggedIn(user));
      navigate("/");
    };
    if (updateProfile.status === FETCHED) {
      updateUser();
    }
  }, [updateProfile.status]);

  const onSubmit = async (vals, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await confirmUserAttribute({
        userAttributeKey: "email",
        confirmationCode: `${vals.code.trim()}`,
      });

      const user = await getUserSession();
      dispatch(userLoggedIn(user));
      setShouldExecutePut(true);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      if (err.code === "CodeMismatchException") {
        setError("Invalid verification code");
        return;
      }
      if (err.code === "InvalidParameterException") {
        setError(
          "This code does not appear to be valid, please make sure there are no spaces before or after the code",
        );
        return;
      }
      setError(err.message);
    }
  };

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      code: Yup.string().required("Code is required"),
    }),
    initialValues: {
      code: "",
    },
    onSubmit,
  });

  if (auth.loading) {
    return <LoadingIcon />;
  }

  if (
    !auth.isAuthenticated ||
    (auth?.promoter?.emailVerified &&
      auth?.promoter?.details?.active !== promoterActiveStatuses.PENDING.id)
  ) {
    return <Navigate to="/" />;
  }

  const handleEmail = async () => {
    await sendUserAttributeVerificationCode({ userAttributeKey: "email" });
    success("Email sent");
  };

  const { values, touched, errors, handleSubmit, handleBlur, handleChange, isSubmitting } = formik;
  return (
    <>
      <Helmet title="Confirm Email Address" />
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Logo width="50%" />
      </Box>
      {isSubmitting && (
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <LoadingIcon size={20} />
        </Box>
      )}
      {error && (
        <Box
          sx={{
            mb: 3,
            mt: 3,
          }}
        >
          <Alert id="error" severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Box>
      )}
      <Box
        sx={{
          mb: 3,
          mt: 3,
        }}
      >
        <Alert severity="success">
          <Typography variant="body2">
            An email has been sent to {auth?.userData?.attributes?.email} with instructions on how
            to verify your email address. If you didn&apos;t recieve an email, you can try to
            resend:
            <Button onClick={handleEmail}>Resend</Button>
          </Typography>
        </Alert>
      </Box>
      <Typography variant="body1">Enter Code</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          id="code"
          variant="outlined"
          label="Code"
          fullWidth
          name="code"
          value={values.code}
          placeholder="Code"
          error={touched.code && errors.code}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          helperText={touched.code && errors.code}
          margin="normal"
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          mt={2}
          type="submit"
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Divider sx={{ my: 6 }} />

        <LogoutButton variant="outlined" fullWidth sx={{ my: 2 }} />
      </form>
    </>
  );
};

export default ChangeEmailCodeConfirm;
