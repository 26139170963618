import React from "react";
import { confirmSignIn } from "aws-amplify/auth";
import Helmet from "react-helmet";
import { styled, Box, TextField, Typography, Button as MuiButton } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Navigate, useNavigate } from "react-router";

import { spacing } from "@mui/system";

import { Logo } from "../../../Reusable";
import useAuth from "../hooks/useAuth";
import store from "../../../../Store";
import { userLoggedIn } from "../../../../Store/reducers/AuthReducer";
import useAuthIsComplete from "../hooks/useAuthIsComplete";

const Button = styled(MuiButton)(spacing);

const SetNewPassword = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const isComplete = useAuthIsComplete;

  const onSubmit = async (vals, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const res = await confirmSignIn({ challengeResponse: vals.password });
      store.dispatch(userLoggedIn(res));
      setSubmitting(false);
      if (isComplete) {
        navigate("/");
      } else {
        navigate("/auth/init-register");
      }
    } catch {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required"),
    }),
    initialValues: {
      password: "",
    },
    onSubmit,
  });

  if (auth.userData?.challengeName !== "NEW_PASSWORD_REQUIRED" || !auth.isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  const { values, touched, errors, handleSubmit, handleBlur, handleChange, isSubmitting } = formik;

  return (
    <>
      <Helmet title="Set a password" />
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Logo width="50%" />
      </Box>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Welcome to the Promotion Centre
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Please set a new password to continue
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          id="password"
          data-testid="password"
          variant="outlined"
          label="Password"
          fullWidth
          name="password"
          value={values.password}
          placeholder="Password"
          error={errors.password && touched.password}
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          autoComplete="current-password"
          helperText={touched.password && errors.password}
          margin="normal"
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          mb={2}
          mt={2}
          type="submit"
          data-testid="submit"
          disabled={isSubmitting}
        >
          Set new password
        </Button>
      </form>
    </>
  );
};

export default SetNewPassword;
