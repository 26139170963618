import React from "react";
import { Helmet } from "react-helmet";

const LinkedinPixel = () => {
  return (
    <Helmet>
      <script id="linkedin-pixel-script" type="text/javascript">{`<!-- Linkedin tracker -->
       _linkedin_partner_id = "4269258";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    <!-- End Linkedin tracker -->`}</script>

      <script type="text/javascript">
        {`
      (function (l) {
        if (!l) {
          window.lintrk = function (a, b) {
            window.lintrk.q.push([a, b]);
          };
          window.lintrk.q = [];
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
      `}
      </script>

      <noscript id="linkedin-pixel-image">{`<img
        height="1"
        width="1"
        style="display: none"
        alt=""
        src="https://px.ads.linkedin.com/collect/?pid=4269258&fmt=gif"
      />`}</noscript>
    </Helmet>
  );
};

export default LinkedinPixel;
