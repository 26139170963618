import { isAddon } from "../../../../../Utils/ticketHelpers";

const isAddonField = {
  name: "isAddon",
  displayName: () => "isAddon",
  sendToApi: () => false,
  defaultValue: (event, __, qsValues, ticket) => Boolean(isAddon(qsValues, ticket)),
  hidden: () => true,
};

export default isAddonField;
