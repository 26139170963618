import { useState, useEffect } from "react";
import { LOGGED_IN_AS_PROMOTER_ID } from "../../../../Constants/auth";
import useAuthName from "./useAuthName";

export default function useAuthIsComplete() {
  const { firstname, surname } = useAuthName();
  const [complete, setComplete] = useState(
    Boolean(firstname || surname || localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID)),
  );

  useEffect(() => {
    setComplete(Boolean(firstname || surname || localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID)));
  }, [firstname, surname]);

  return complete;
}
