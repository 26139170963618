import React from "react";
import { Helmet } from "react-helmet";
import { styled } from "@mui/material";
import PropTypes from "prop-types";
import whiteLogo from "../../Assets/Images/LandscapeLogoWhite.svg";
import blackLogo from "../../Assets/Images/skiddle-logo-black-landscape.png";

const Img = styled("img")`
  width: ${props => (props.width ? props.width : "100%")};
  cursor: pointer;
  height: 100%;
`;

const Logo = ({ color = "black", width = "240px", ...rest }) => (
  <>
    <Helmet>
      <link rel="preload" as="image" href={color === "white" ? whiteLogo : blackLogo} />
    </Helmet>
    <Img
      alt="skiddle logo"
      src={color === "white" ? whiteLogo : blackLogo}
      width={width}
      {...rest}
    />
  </>
);

Logo.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
};

export default React.memo(Logo);
