import * as Yup from "yup";
import { isAddon } from "../../../../../Utils/ticketHelpers";
import lazy from "../../../../../Utils/lazy";

const TicketDescription = lazy(() => import("../../FieldComponents/TicketDescription"));

const ticketDescription = {
  name: "ticketDescription",
  displayName: () => "Ticket description",
  sendToApi: () => true,
  postFormat: val => (!val ? "" : val),
  defaultValue: null,
  validation: (_, __, ___, qsValues, _____, initialValues) =>
    Yup.string()
      .trim()
      .min(3, "Ticket descriptions are optional but must be at least 3 characters when given")
      .max(
        isAddon(qsValues, initialValues) ? 255 : 100,
        `Ticket description can only be ${isAddon(qsValues, initialValues) ? 255 : 100} characters`,
      )
      .nullable(),
  type: TicketDescription,
};

export default ticketDescription;
