const TICKET_STATUS = {
  PENDING: "pending", // Create, Update, UpdateAllocation
  PENDING_HIDDEN: "pending hidden", // Unhide
  ON_SALE: "on sale", // Create, Update, UpdateAllocation
  ONSALE_HIDDEN: "on sale hidden", // Unhide
  CLOSED: "closed", // Reopen
  CLOSED_HIDDEN: "closed hidden", // Reopen
};

export default TICKET_STATUS;
