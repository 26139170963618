/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

let initialVersion = "v3";

if (sessionStorage.getItem("api-version") || import.meta.env.VITE_API_URL_VERSION) {
  initialVersion = sessionStorage.getItem("api-version") || import.meta.env.VITE_API_URL_VERSION;
}

export const apiVersionSlice = createSlice({
  name: "apiVersion",
  initialState: {
    version: initialVersion,
  },
  reducers: {
    setVersion: (state, action) => {
      state.version = action.payload.version;
    },
  },
});

export const { setVersion } = apiVersionSlice.actions;

export default apiVersionSlice.reducer;
