import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Box } from "@mui/system";
import { Tooltip } from "@mui/material";
import { useIntercom } from "react-use-intercom";
import { useLocation, useNavigate } from "react-router";

import NavLink from "./NavLink";
import { AppContext } from "../../../Context";
import { Link as LinkStyle, LinkBadge, LinkText } from "./Styles";
import useAuth from "../../NamedComponents/Auth/hooks/useAuth";
import { checkUserPermission } from "../../../Utils/permissions";

const SidebarLink = ({
  name,
  to = null,
  badge = null,
  onClose = null,
  match = null,
  isSubLink = false,
  isDisabled = () => false,
  textStyle = {},
  show = () => true,
  permissions = null,
  disabledTooltipText = () => null,
  intercom = false,
  hideIfNoPermissions = true,
  ...rest
}) => {
  const auth = useAuth();

  const { pathname } = useLocation();
  const { currentEvent } = useContext(AppContext);
  const { show: showIntercom } = useIntercom();
  const navigate = useNavigate();

  // If no permissions on link, then set to true
  // (can't check falsy as perms might be 0, which we want to consider truthy in this case)
  let hasPermission = Boolean(permissions === null);

  // If permissions are set on the link and they don't have permission
  if (permissions && !checkUserPermission(auth?.promoter?.permissions, permissions)) {
    hasPermission = false;
  } else if (permissions && checkUserPermission(auth?.promoter?.permissions, permissions)) {
    hasPermission = true;
  }

  const disabled = isDisabled(currentEvent) || !hasPermission;

  useEffect(() => {
    const path = to.includes("?") ? to.split("?")[0] : to;
    if (pathname === path && disabled) {
      navigate("/404");
    }
  }, [pathname]);

  if (!show(match, currentEvent, auth?.promoter?.permissions)) {
    return null;
  }

  if (!hasPermission && hideIfNoPermissions) {
    return null;
  }

  const newProps = { ...rest, isOpen: rest.isOpen ?? false, isCollapsable: rest.isOpen ?? false };
  delete newProps.show;
  delete newProps.alternativePaths;
  delete newProps.isOpen;
  delete newProps.isCollapsable;

  if (newProps.component) {
    const props = { ...newProps, name, badge };
    return <newProps.component currentEvent={currentEvent} {...props} />;
  }

  if (intercom) {
    return (
      <LinkStyle button dense $activeClassName="active" onClick={showIntercom}>
        <LinkText>{name}</LinkText>
      </LinkStyle>
    );
  }

  return (
    <Tooltip
      title={disabled ? disabledTooltipText(currentEvent, hasPermission) : ""}
      placement="right"
      arrow
    >
      {/* This wrapping Box is necessary as tooltips won't work on disabled elements */}
      <Box sx={{ "white-space": "nowrap", overflow: "hidden" }}>
        <LinkStyle
          button
          dense
          component={NavLink}
          to={to}
          onClose={onClose}
          $activeClassName="active"
          onClick={() => onClose()}
          disabled={disabled}
          isDisabled={() => disabled}
          aria-disabled={disabled}
          data-testId={newProps.id}
          {...newProps}
        >
          <LinkText style={textStyle} hierarchy={isSubLink ? 2 : 1}>
            {name}
          </LinkText>
          {newProps.notification && (
            <NotificationsIcon style={{ color: "#ff6666", height: "20px" }} />
          )}
          {badge && <LinkBadge label={badge} />}
        </LinkStyle>
      </Box>
    </Tooltip>
  );
};

SidebarLink.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  badge: PropTypes.string,
  isCollapsable: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  match: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  isSubLink: PropTypes.bool,
  isDisabled: PropTypes.func,
  textStyle: PropTypes.objectOf(PropTypes.any),
  show: PropTypes.func,
  permissions: PropTypes.number,
  disabledTooltipText: PropTypes.func,
  intercom: PropTypes.bool,
  hideIfNoPermissions: PropTypes.bool,
};

export default SidebarLink;
