import useWindowSize from "./useWindowSize";

const useHeaderHeight = () => {
  const { innerWidth, innerHeight } = useWindowSize();
  if (innerWidth >= 600) {
    return 64;
  }
  if (innerWidth > innerHeight) {
    return 48;
  }
  return 56;
};

export default useHeaderHeight;
