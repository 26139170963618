import { useReducer } from "react";
import promoterActiveStatuses from "../../Constants/promoterActiveStatuses";

export const shouldReset = (options, details, authenticated, dispatch, key) => {
  const { endpoint, method, shouldExecute, body } = options;
  const isPostWithNoPayload = (method === "POST" || method === "PUT") && !body;
  if (
    !endpoint ||
    !shouldExecute ||
    isPostWithNoPayload ||
    (details?.active === promoterActiveStatuses.BANNED.id && authenticated)
  ) {
    dispatch({ type: "RESET", payload: { key } });
  }
};

const initialState = {
  status: "idle",
  error: null,
  data: [],
  allData: [],
  retries: 0,
  timestamp: null,
  requestCount: 0,
  fetchAllStatus: "idle",
  cachedRequests: [],
};

export const useRequestReducer = (hasMultipleRequests = false, options = []) => {
  const [state, dispatch] = useReducer(
    (st, action) => {
      let option = null;
      let newState = null;

      if (hasMultipleRequests) {
        option = options.find(o => o.key === action?.payload?.key);
        newState = st.filter(s => s.key !== action?.payload?.key);
      }

      switch (action.type) {
        case "RESET":
          return hasMultipleRequests
            ? [...newState, { ...initialState, ...option, status: "idle" }]
            : { ...initialState, ...st, status: "idle" };
        case "FETCHING": {
          return hasMultipleRequests
            ? [...newState, { ...initialState, ...option, status: "fetching" }]
            : {
                ...initialState,
                data: st.data,
                retries: st.retries,
                status: "fetching",
                meta: st.meta,
                requestCount: st.requestCount,
              };
        }
        case "FETCHED":
          return hasMultipleRequests
            ? [
                ...newState,
                {
                  ...initialState,
                  ...option,
                  status: "fetched",
                  data: action.payload.res,
                  meta: action.payload.meta,
                  retries: st.retries,
                  retryFn: action.payload.retryFn,
                  httpStatus: action.payload.status,
                  allData: action.payload?.allData || [],
                },
              ]
            : {
                ...initialState,
                status: "fetched",
                data: action.payload.res,
                meta: action.payload.meta,
                retries: st.retries,
                retryFn: action.payload.retryFn,
                httpStatus: action.payload.status,
                requestCount: st.requestCount + 1,
                links: action.payload?.links,
                allData: action.payload?.allData || [],
              };
        case "FETCH_ERROR":
          return hasMultipleRequests
            ? [
                ...newState,
                {
                  ...initialState,
                  ...option,
                  retries: st.retries,
                  status: "error",
                  error: action.payload.error,
                },
              ]
            : {
                ...initialState,
                retries: st.retries,
                status: "error",
                error: action.payload,
                requestCount: st.requestCount + 1,
              };
        case "RETRY":
          return hasMultipleRequests
            ? ""
            : {
                ...initialState,
                ...st,
                retries: action.payload.retries,
                timestamp: action.payload.timestamp + 1000,
                queryStringParams: {
                  ...action.payload.additionalParams,
                  timestamp: action.payload.timestamp + 1000,
                },
              };
        case "FETCHING_ALL":
          return {
            ...initialState,
            ...st,
            ...action.payload,
            fetchAllStatus: "fetching",
          };
        case "FETCHED_ALL":
          return {
            ...initialState,
            ...st,
            ...action.payload,
            fetchAllStatus: "fetched",
          };

        default:
          return st;
      }
    },
    hasMultipleRequests ? options.map(o => ({ ...initialState, key: o.key })) : initialState,
  );

  return [state, dispatch];
};

export const constructAdminPanelObject = (
  method,
  url,
  timeout,
  endpoint,
  shouldExecute,
  body,
  hooks = {},
) => ({
  method,
  url,
  timeout,
  endpoint,
  shouldExecute,
  retries: hooks?.state?.retries,
  body,
  originPage: `${window.location?.origin}${window.location.pathname}?key=${hooks?.location?.key || ""}`,
});
