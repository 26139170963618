import React, { useContext, useEffect, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";

import getVersion from "../../Utils/getVersion";
import { AppContext } from "../../Context";
import Store from "../../Store";

const AppVersion = ({ typography = true }) => {
  const [loading, setLoading] = useState(true);

  const { appVersion, setAppVersion } = useContext(AppContext);

  useEffect(() => {
    async function onLoad() {
      setLoading(true);

      const versionText = await getVersion();

      setAppVersion(versionText || "");

      setLoading(false);
    }

    if (!appVersion) {
      onLoad();
    }
  }, [appVersion]);

  if (loading && !appVersion) {
    return null;
  }

  const splitVersion = appVersion?.split("-");
  let splitSubVersion = "";
  if (splitVersion[1]) {
    splitSubVersion = splitVersion[1].split(".");
  }

  return (
    <Tooltip title={`${appVersion} (${Store.getState().apiVersion.version})`} placement="right">
      {typography ? (
        <Typography>
          {splitVersion[0]}
          {splitSubVersion ? `-${splitSubVersion[0].substring(0, 5)}` : ""}
        </Typography>
      ) : (
        <>
          {splitVersion[0]}
          {splitSubVersion ? `-${splitSubVersion[0].substring(0, 5)}` : ""}
        </>
      )}
    </Tooltip>
  );
};

AppVersion.propTypes = {
  typography: PropTypes.bool,
};
export default AppVersion;
