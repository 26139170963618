import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Grid2 as Grid,
  Typography as MuiTypography,
  styled,
  Avatar,
  Button,
  Divider,
} from "@mui/material";
import { spacing, useTheme } from "@mui/system";
import md5 from "md5";
import HelpIcon from "@mui/icons-material/Help";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SwitchPromoter from "./SwitchPromoter";
import ManageAccountLinks from "./ManageAccountLinks";
import IconWithText from "../../Reusable/IconWithText";
import DesktopHeaderPopout from "./Components/DesktopHeaderPopout";
import SwitchToMainAccount from "../SwitchToMainAccount";
import { isOwner } from "../../../Utils/permissions";
import helpCentre from "../../../Constants/helpCentre";

const Typography = styled(MuiTypography)(spacing);

const Container = styled("div")`
  margin: 4px 4px 0 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1201;
`;

const StyledAvatar = styled(Avatar)`
  width: 30px;
  height: 30px;
  && {
    font-size: 15px;
  }
`;

const DesktopHeaderContent = ({ mainPromoter, auth, handleLogout }) => {
  const { firstname, surname } = mainPromoter;
  const {
    email,
    details: { name },
  } = auth.promoter;
  const theme = useTheme();

  const showNotifications = false;
  const notificationsRef = React.useRef(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const onNotificationsClick = () => setNotificationsOpen(!notificationsOpen);

  const helpRef = React.useRef(null);
  const [helpOpen, setHelpOpen] = useState(false);
  const onHelpClick = () => setHelpOpen(!helpOpen);

  const settingsRef = React.useRef(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const onSettingsClick = () => setSettingsOpen(!settingsOpen);

  const profileRef = React.useRef(null);
  const [profileOpen, setProfileOpen] = useState(false);
  const onUserClick = () => setProfileOpen(!profileOpen);

  return (
    <Container data-tour="header-settings-container">
      <Grid container>
        <Grid sx={{ display: "flex", alignItems: "center", paddingRight: "6px" }} size={12}>
          <Typography
            variant="body2"
            textAlign="right"
            sx={{ color: "white", cursor: "pointer", ":hover": { opacity: 0.8 } }}
            onClick={onUserClick}
          >
            <strong>{name}</strong>
          </Typography>
          <Button
            data-testid="user-account"
            data-tour="header-toggle-drawer"
            onClick={onUserClick}
            sx={{
              ":hover": { opacity: 0.8 },
              padding: "0 4px 0 12px",
              minWidth: 0,
            }}
            startIcon={
              <StyledAvatar
                src={`https://www.gravatar.com/avatar/${md5(email)}.jpg?d=404`}
                ref={profileRef}
                alt={`${firstname} ${surname}`}
                sx={{
                  bgcolor: theme.palette.primary.dark,
                  color: "white",
                  border: "1px solid white",
                  textTransform: "uppercase",
                }}
              >
                {firstname[0]}
                {surname[0]}
              </StyledAvatar>
            }
          />
          <Divider
            orientation="vertical"
            sx={{
              borderStyle: "solid",
              borderColor: "white",
              marginRight: "10px",
              marginLeft: "0",
            }}
          />
          {showNotifications && (
            <IconWithText
              icon={<NotificationsIcon ref={notificationsRef} />}
              color="white"
              title="Notifications"
              onClick={onNotificationsClick}
              disabled
              disabledReason="Notifications coming soon"
            />
          )}
          <IconWithText
            icon={<SettingsIcon ref={settingsRef} />}
            color="white"
            title="Settings"
            onClick={onSettingsClick}
          />
          <IconWithText
            icon={<HelpIcon ref={helpRef} />}
            color="white"
            title="Help"
            onClick={onHelpClick}
          />
        </Grid>

        {/* Help Popout */}
        <DesktopHeaderPopout
          elementRef={helpRef}
          open={helpOpen}
          setOpen={setHelpOpen}
          title="Help"
        >
          <Grid size={12}>
            You can find additional help on using the Promotion Centre in our{" "}
            <a href={helpCentre.HOME()} target="_blank" rel="noreferrer">
              Help Centre
            </a>
          </Grid>
        </DesktopHeaderPopout>

        {/* Settings Popout */}
        <DesktopHeaderPopout
          elementRef={settingsRef}
          open={settingsOpen}
          setOpen={setSettingsOpen}
          title="Settings"
        >
          <ManageAccountLinks setOpen={setSettingsOpen} />
        </DesktopHeaderPopout>

        {/* Profile Popout */}
        <DesktopHeaderPopout
          elementRef={profileRef}
          open={profileOpen}
          setOpen={setProfileOpen}
          title="Promoter"
          verticalOffset={12}
        >
          <Grid size={12}>
            <div data-tour="header-settings-promoter-dropdown">
              <SwitchPromoter setOpen={setProfileOpen} />
            </div>
          </Grid>
          {!isOwner(auth) && (
            <Grid size={12}>
              <SwitchToMainAccount sx={{ width: "100%" }} setOpen={setProfileOpen} />
            </Grid>
          )}
          <Grid size={12}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              data-testid="logout-button"
              onClick={e => handleLogout(e)}
            >
              Logout
            </Button>
          </Grid>
        </DesktopHeaderPopout>
      </Grid>
    </Container>
  );
};

DesktopHeaderContent.propTypes = {
  mainPromoter: PropTypes.objectOf(PropTypes.any).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default DesktopHeaderContent;
