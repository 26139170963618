export const CATEGORIES = {
  UNCATEGORISED: "uncategorised",
  UNCATEGORIZED: "uncategorized",
};

const getTicketCategoryName = ticketCategoryName => {
  if (ticketCategoryName === "" || !ticketCategoryName) {
    return `${CATEGORIES.UNCATEGORISED.charAt(0).toUpperCase()}${CATEGORIES.UNCATEGORISED.slice(
      1,
    )}`;
  }

  return ticketCategoryName;
};

export default getTicketCategoryName;
