const ticketCountEmail = {
  name: "ticketCountEmail",
  displayName: () => "Enter email addresses, separate different emails with a comma (,)",
  defaultValue: "",
  hidden: formValues => formValues.setUpTicketReports === "no",
  postFormat: (val, data) =>
    data.setUpTicketReports === "no" ? null : val.split(",").map(v => v.trim()),
  sendToApi: (fieldName, values) => Boolean(values[fieldName] && values[fieldName] !== ""),
  advancedOption: true,
};
export default ticketCountEmail;
