import React, { useContext, useRef } from "react";
import { styled, Chip, Collapse } from "@mui/material";
import PropTypes from "prop-types";
import { useMatch } from "react-router";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Box } from "@mui/system";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SidebarLink from "./SidebarLink";
import SidebarLinkDropdown from "./SidebarLinkDropdown";
import { AppContext } from "../../../Context";
import { Category, CategoryDropdownSymbol, CategoryText } from "./Styles";
import useAuth from "../../NamedComponents/Auth/hooks/useAuth";
import { checkUserPermission } from "../../../Utils/permissions";
import ErrorBoundary from "../ErrorBoundary";
import { drawerTransitionTime } from "../../../Constants/layout";

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};
  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)};
    padding-right: ${props => props.theme.spacing(2)};
  }
`;
const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const IconContainer = styled(Box)`
  opacity: 0.5;
  display: flex;
`;

const SidebarCategory = ({
  name,
  icon = null,
  isOpen = false,
  isCollapsable,
  badge = null,
  notification = false,
  openRoutes = [],
  index = null,
  location = null,
  onClose = null,
  childLinks = null,
  show = null,
  matchString = null,
  onClick = null,
  button = true,
  permissions = null,
  hideIfNoPermissions = true,
  ...rest
}) => {
  const auth = useAuth();
  const { currentEvent, sidebarOpen } = useContext(AppContext);
  const ref = useRef();
  const match = useMatch(matchString || "");

  // If no permissions on link, then set to true
  let hasPermission = !permissions;

  // If permissions are set on the link and they don't have permission
  if (permissions && !checkUserPermission(auth?.promoter?.permissions, permissions)) {
    hasPermission = false;
  }

  if (show && !show(match, currentEvent, auth?.promoter?.permissions)) {
    return null;
  }

  if (!hasPermission && hideIfNoPermissions) {
    return null;
  }

  return (
    <>
      <Category
        ref={ref}
        button={button}
        onClick={onClick}
        $activeClassName={rest.activeClassName}
        onClose={onClose}
        {...rest}
      >
        <ErrorBoundary code="SC">
          <IconContainer>{icon(auth?.promoter?.currency)}</IconContainer>
          <CategoryText hierarchy={0}>{name}</CategoryText>
          {notification && isCollapsable && isOpen && (
            <NotificationsIcon style={{ color: "#ff6666", opacity: 1, height: "20px" }} />
          )}
          <CategoryDropdownSymbol
            className="sidebar-arrow"
            $sidebarOpen={sidebarOpen}
            $drawerTransitionTime={drawerTransitionTime}
          >
            {isCollapsable && isOpen ? <ExpandMore /> : <ExpandLess />}
          </CategoryDropdownSymbol>
          {badge ? <CategoryBadge label={badge} /> : ""}
        </ErrorBoundary>
      </Category>
      {isCollapsable && (
        <Collapse in={openRoutes?.[index]} timeout="auto" unmountOnExit>
          <ErrorBoundary code="SC2">
            {childLinks(match).map(route => (
              <React.Fragment key={route.id}>
                {/* subroute has children of its own we need to make it a dropdown */}
                {route.children ? (
                  <SidebarLinkDropdown
                    {...route}
                    key={route.id}
                    name={route.name}
                    to={route.path}
                    icon={route.icon}
                    badge={route.badge}
                    isCollapsable
                    isOpen
                    onClose={onClose}
                    location={location}
                    notification={notification}
                    match={match}
                  />
                ) : (
                  // else just render the link as normal; no dropdown
                  <SidebarLink
                    {...route}
                    key={route.id}
                    name={route.name}
                    to={route.path}
                    icon={route.icon}
                    badge={route.badge}
                    onClose={onClose}
                    location={location}
                    match={match}
                  />
                )}
              </React.Fragment>
            ))}
          </ErrorBoundary>
        </Collapse>
      )}
    </>
  );
};

SidebarCategory.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  badge: PropTypes.string,
  icon: PropTypes.func,
  isOpen: PropTypes.bool,
  isCollapsable: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  openRoutes: PropTypes.objectOf(PropTypes.any),
  index: PropTypes.number,
  location: PropTypes.objectOf(PropTypes.any),
  childLinks: PropTypes.func,
  show: PropTypes.func,
  matchString: PropTypes.string,
  onClick: PropTypes.func,
  button: PropTypes.bool,
  permissions: PropTypes.number,
  notification: PropTypes.bool,
  hideIfNoPermissions: PropTypes.bool,
};

export default SidebarCategory;
