import React from "react";
import { styled } from "@mui/material";
import LoadingIcon from "./LoadingIcon";

const Container = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default () => (
  <Container>
    <LoadingIcon debugName="fallback" displayDelay={800} />
  </Container>
);
