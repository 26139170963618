const getAllocationErrorMessage = (isRecurring, maxChildTicketSales, soldCount) => {
  if (isRecurring && maxChildTicketSales) {
    return `Allocation must be at least ${maxChildTicketSales}, the highest amount of tickets of this type sold on any
        individual Date`;
  }
  if (!isRecurring && soldCount) {
    return `Allocation must be equal to or higher than the number of tickets sold (${soldCount})`;
  }
  return "Allocation must be 0 or higher";
};

export default getAllocationErrorMessage;
