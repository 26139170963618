import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { isAddon } from "../../../../../Utils/ticketHelpers";
import { stopTicketOptions } from "../../../../../Constants/tickets/ticketOptions";
import lazy from "../../../../../Utils/lazy";
import { isFeatureFlagOn } from "../../../../../Utils";
import { featureFlags } from "../../../../../Utils/checkFeatureFlag";

const StopMode = lazy(() => import("../../FieldComponents/StopMode"));

const stopMode = {
  name: "stopMode",
  sendToApi: () => true,
  postFormat: val => val.value,
  advancedOption: false,
  type: StopMode,
  options: stopTicketOptions,
  displayName: () => "Stop selling tickets",

  defaultValue: (event, _, qsValues) => {
    const initialValue = stopTicketOptions.find(option => option.value === "period");

    if (
      !qsValues?.ticketId &&
      event.hasSessions &&
      isFeatureFlagOn(featureFlags.EVENT_END_STOP_MODE)
    ) {
      return stopTicketOptions.find(option => option.value === "eventEnd");
    }

    return initialValue;
  },
  group: "Stop selling tickets",
  creatable: false,
  disableClearable: true,
  renderOption: (props, option) => (
    <li {...props}>
      <Box>
        <Typography>{option.label}</Typography>
        {!option.disabled ? (
          <Typography variant="body2" color={grey[500]}>
            {option.text}
          </Typography>
        ) : (
          <Typography variant="body2">{option.disabledText}</Typography>
        )}
      </Box>
    </li>
  ),
  selectOnFocus: false,
  filterSelectedOptions: false,
  hidden: (formValues, { qsValues }) => isAddon(qsValues, formValues),
};

export default stopMode;
