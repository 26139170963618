import Store from "../../Store";
import { REQUEST_LIMIT, setRequests } from "../../Store/reducers/AdminPanelReducer";

const addOrUpdateAdminPanelRequest = (requestId, requestObject) => {
  const {
    adminPanel: { requests },
  } = Store.getState();

  if (!requestId) {
    throw new Error("Please provide a request ID to either initiate or update a request.");
  }

  let newRequests = [];

  const isNewRequest =
    !requests.length || !requests.some(request => request.requestId === requestId);

  // If it's a new request we can add it to the array
  if (isNewRequest) {
    newRequests = [...requests, { ...requestObject }];
  } else {
    // Must be an existing request, so update it instead
    newRequests = [...requests].map(request => {
      if (request.requestId === requestId) {
        return { ...request, ...requestObject };
      }

      return request;
    });
  }

  // Check if we're over request limit and remove from the start of
  // the array the number we're over
  if (newRequests.length > REQUEST_LIMIT) {
    const diff = newRequests.length - REQUEST_LIMIT;
    newRequests.splice(0, diff);
  }

  Store.dispatch(setRequests(newRequests));
};

export default addOrUpdateAdminPanelRequest;
