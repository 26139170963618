const reused = {
  BRANDS: "/brands/:brandId",
  TICKET_PASSWORDS: "/tickets/passwords/:id",
};

/**
 * @const Endpoints
 *
 * A consolidated list of all endpoints the app will use.
 *
 * Any endpoints with wildcards should be denoted using ":",
 * e.g. /artists/244352 (where 244352 is the artist ID) should
 * be denoted as /artists/:id. The request function will handle
 * replacing :id with the actual id.
 */
const Endpoints = {
  GET_ARTIST_DETAILS: "/artists/:id",
  EDIT_ARTIST: "/artists/:id",
  SEARCH_ARTISTS: "/artists/search",
  SEARCH_GENRES: "/genres/search",
  GET_GENRES: "/genres",
  GET_NOTIFICATIONS: "/promotersusers/notifications",
  DELETE_NOTIFICATION: "/promotersusers/notifications/:id",
  GET_OR_SEARCH_BRANDS: "/brands",
  GET_OR_SEARCH_TOURS: "/tours",
  SEARCH_VENUES: "/venues/search",
  SEARCH_EVENTS: "/events/search",
  GET_ORDERS: "/events/orders",
  EVENT_BY_ID: "/events/:eventId",
  EVENT_TIPS: "/events/:eventId/tips",
  POST_NEW_EVENT: "/events/",
  POST_TICKET: "/tickets",
  ISSUE_GUESTLIST_TICKET: "/tickets/issue",
  BULK_ISSUE_GUESTLIST_TICKET: "/tickets/bulk-issue",
  TICKET_PASSWORDS: "/tickets/passwords",
  SINGLE_TICKET_PASSWORD: reused.TICKET_PASSWORDS,
  EDIT_PASSWORD_LIST: reused.TICKET_PASSWORDS,
  DELETE_TICKET_PASSWORDS: reused.TICKET_PASSWORDS,
  PUT_TICKET: "/tickets/:ticketId",
  HIDE_TICKET: "/tickets/:ticketId/hide",
  UNHIDE_TICKET: "/tickets/:ticketId/unhide",
  CLOSE_TICKET: "/tickets/:ticketId/close",
  OPEN_TICKET: "/tickets/:ticketId/open",
  TICKET_DISCOUNT_BREAKDOWN: "/tickets/:ticketId/discount-breakdown",
  GET_TICKETS: "/tickets",
  REORDER_TICKETS: "/tickets/order",
  REORDER_CATEGORIES: "/tickets/categories/reorder",
  DELETE_TICKET: "/tickets/:ticketId",
  DASHBOARD_STATS: "/stats/dashboard/totals",
  HEADLINE_STATS: "/stats/dashboard/headline",
  GRAPH_STATS: "/stats/dashboard/graph",
  QUEUE_STATS: "/stats/dashboard/queue",

  VAT_REPORT: "/stats/vat",
  ADD_VENUE: "/venues",
  COPY_TICKETS: "/tickets/copy",
  ALLOW_TOGGLE: "/events/:eventId",
  WAITING_LIST: "/events/:eventId/waitingList",
  REMIND_ME_LIST: "/events/:eventId/remindMeList",
  TICKETS_RESELL: "/resell",
  ADD_ARTIST: "/artists",

  GET_PROMOTER: "/promotersusers",
  UPDATE_EMAIL: "/promoters/:id/edit",
  UPDATE_SAME_DAY_PAYMENT: "/promoters/:id/same-day-payment",
  GET_INVITED_USERS: "/promoters/:promoterId/invites",
  GET_RECEIVED_INVITES: "/promotersusers/invites",
  SEND_INVITE: "/invites",
  RESEND_INVITE: "/invites/:id/resend",
  INVITES: "/promotersusers/invites",
  GET_INVITE_BY_TOKEN: "/invites/:inviteId",
  REGISTER: "/promotersusers",

  GET_BANK_ACCOUNTS: "/bank-details",
  DELETE_BANK_ACCOUNT: "/bank-details/:bankAccountId",
  SUSPEND_BANK_ACCOUNT: "/bank-details/:bankAccountId/suspend",
  UNSUSPEND_BANK_ACCOUNT: "/bank-details/:bankAccountId/unsuspend",
  DEFAULT_BANK_ACCOUNT: "/bank-details/:bankAccountId/default",

  GET_PROMOTER_WITH_USERS: "/promoters/:id",
  UPDATE_PROMOTER: "/promoters/:promoterId/edit",
  REMOVE_SUB_USER: "/promoters/:removedPromoterId",

  UPDATE_SUB_USER: "/promoters/:promoterId",
  CREATE_SUB_USER: "/promoters/:promoterId",
  BULK_EDIT_EVENTS: "/events",
  BULK_EDIT_TICKETS: "/tickets",

  GET_POST_CUSTOM_TAG: "/events/:listingId/tags",
  PUT_DELETE_CUSTOM_TAG: "/events/:listingId/tags/:tagId",
  DASHBOARD_TAG_STATS: "/stats/dashboard/tags",

  GET_CATEGORIES: "/tickets/categories",
  GET_EVENT_CATEGORIES: "/events/categories",

  DELIVERY_METHODS: "/events/:id/deliveryMethods",

  SINGLE_TICKET: "/tickets/:id",
  SINGLE_ORDER: "/events/orders/:orderId",
  RESEND_ORDER_RECEIPT: "/events/orders/:orderId/receipt",
  REFUND_ORDER: "/events/orders/refund",

  POST_PAYMENT_PLAN: "/tickets/payment-plan",
  UPDATE_TICKET_PASSWORD: "/tickets/passwords",

  SUGGESTED_IMAGES: "/tools/suggestedImages",

  GET_TICKET_DOWNLOAD: "/tickets/delivery",
  TICKET_LIST_DATA: "/tickets/list/print",
  RAPIDSCAN_CSV_DATA: "/tickets/list/csv",
  TICKET_LIST_PRINT_DATA: "/tickets/list/print",

  TICKET_AUDIT_LOG: "/tickets/:id/audit",

  REPS: "/reps",
  REPS_OVERVIEW: "/events/:eventId/reps",
  REPS_COMMISSION: "/reps/commissions",
  REPS_LOGS: "/events/:eventId/reps/logs",
  REPS_REWARDS: "/reps/rewards",

  RAPIDSCAN_ENTRY_BREAKDOWN: "/stats/rapidscan-entry-breakdown",
  RAPIDSCAN_DEVICE_CODES: "/rapidscan-device-codes",
  RAPIDSCAN_DEVICE_CODE: "/rapidscan-device-codes/:deviceCodeID",

  TICKET_SALES_REPORT: "/events/sales-report",
  REMITTANCE_DUE: "/stats/remittance-due",
  REMITTANCE_PAID: "/stats/remittance-paid",
  REMITTANCE_OVERVIEW: "/stats/remittance-overview",
  REMITTANCE_BREAKDOWN: "/stats/remittance-breakdown",

  SAVE_CHECKOUT_QUESTIONS: "/events/checkout-questions",

  BANK_ACCOUNTS: "/bank-details",
  INDIVIDUAL_BANK_ACCOUNT: "/bank-details/:id",

  TEMP: "/genres/error",

  TICKET_PAYOUT_ITEMISATION: "/payouts",
  EDIT_REP_REWARD: "/reps/rewards/:id",

  BRAND_BY_ID: reused.BRANDS,
  BRAND_REPS: "/brands/:brandId/reps",
  REVIEW_SUMMARY: "/reviews/summary",
  REVIEWS: "/reviews",
  FAQS: "/faqs",
  EDIT_FAQS: "/faqs/:faqId",
  PUT_BRAND: reused.BRANDS,
  DELETE_BRAND: reused.BRANDS,
  CREATE_BRAND: "/brands",

  // Recurring
  DETACH_RECURRING: "/events/:id/detach",

  // Sessions
  SESSIONS: "/sessions",
  SESSION: "/sessions/:id",

  // Discount codes
  GET_PROMOTIONS: "/discounts",
  CREATE_PROMOTION: "/discounts",
  CREATE_DISCOUNT_CODES: "/discount-codes",
  UPDATE_DISCOUNT_CODES: "/discount-codes",
  UPDATE_PROMOTION: "/discounts/:id",
  GET_PROMOTION: "/discounts/:id",
  DISCOUNT_BREAKDOWN: "/discounts/breakdown",

  MODERATE_IMAGE: "/tools/images/moderation?",

  CANCEL_EVENT: "/events/:id/cancel",
  POSTPONE_EVENT: "/events/:id/postpone",
};

export default Endpoints;
