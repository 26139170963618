import React from "react";
import { Typography } from "@mui/material";

import AuthLayout from "./AuthLayout";
import LogoutButton from "../NamedComponents/Auth/Components/LogoutButton";

const SecurityLockedAccount = () => (
  <AuthLayout>
    <Typography variant="h6" gutterBottom data-testid="security-locked-account-message">
      Sorry, your account access has been temporarily suspended. Please try again later or contact
      Skiddle if you need more information.
    </Typography>

    <LogoutButton />
  </AuthLayout>
);

export default SecurityLockedAccount;
