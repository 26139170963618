import { radioOptions } from "../../../../../Constants/tickets/ticketOptions";
import lazy from "../../../../../Utils/lazy";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);

const showTicketsRemaining = {
  name: "showTicketsRemaining",
  displayName: () => "Show number of remaining tickets to customers",
  sendToApi: () => true,
  type: InputRadio,
  options: radioOptions,
  handleClick: (action, form) => form.addValue("showTicketsRemaining", action),
  advancedOption: true,
  placeholder: "Show number of remaining tickets to customers",
  // This is reversed to as to allow all the buttons to line up
  // by default, not a bug! :)
  postFormat: val => (val === "no" || val === 0 ? 0 : 1),
  defaultValue: "no",
};

export default showTicketsRemaining;
