import isEqual from "lodash/isEqual";

export const setValidationSchema = (fields, extra) => {
  const validation = {};
  fields.forEach(field => {
    validation[field.name] = field.validation ? field.validation(extra) : null;
  });
  return validation;
};

export const hasValueChanged = (key, formValues, initialValues) => {
  const val = formValues[key] || "";
  const initialVal = initialValues ? initialValues[key] : "";

  return !isEqual(val, initialVal);
};
