import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@mui/material";

import { Button, Modal } from "../Reusable";

const SessionExpiredModal = ({ showModal, setShowModal }) => {
  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(true)}
      showCloseButton={false}
      header="Session expired"
      actions={
        <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
          Refresh
        </Button>
      }
    >
      <Typography variant="body2">
        It looks like you switched accounts in another tab, to avoid issues please refresh the page
        to sync your tabs.
      </Typography>
    </Modal>
  );
};

SessionExpiredModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
export default SessionExpiredModal;
