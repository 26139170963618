const headers = {
  "x-api-key": import.meta.env.VITE_API_KEY,
  "Skiddle-Origin": "PCV2",
};

const buildHeaders = (token, authenticated, method) => {
  const headersToLog = { ...headers };

  if (authenticated) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (method !== "GET") {
    headers.Accept = "application/json";
    headersToLog.Accept = "application/json";
  }

  if (!authenticated) {
    delete headers.Authorization;
  }

  return { headers, headersToLog };
};

export default buildHeaders;
