import { ticketFormStage, ticketSummaryStage } from "../../../Constants/events/eventOptions";
import { isFeatureFlagOn } from "../../../Utils";
import { featureFlags } from "../../../Utils/checkFeatureFlag";
import date from "../../../Utils/date";
import permissions from "../../../Utils/permissions";
import { DEFAULT_MESSAGE } from "../../Reusable/Restricted/Restricted";
import {
  eventsRecurringModalSettings,
  isRecurringEvent,
  isSessionEvent,
  shouldShow,
  getDisabledTooltipText,
  isCancelled,
} from "./helpers";

const manageTicketsChildren = (id, match) => [
  {
    name: "View tickets",
    path: `/events/${id}/edit?step=4&stage=${ticketSummaryStage}`,
    route: "/events/:id/edit?step=4&stage=2",
    id: "viewTickets",
    activeOverride: params =>
      Number(params.step) === 4 && Number(params.stage) === ticketSummaryStage,
    needsMatch: false,
    show: (_m, event) => shouldShow(match) && event.tickets,
    disabledTooltipText: () => DEFAULT_MESSAGE,
    permissions: permissions().READ_TICKET,
    hideIfNoPermissions: false,
  },
  {
    name: "Create ticket",
    path: `/events/${id}/edit?step=4&stage=${ticketFormStage}&t-timestamp=${date().toUnixInteger()}`,
    route: "/events/:id/edit?step=4&stage=1",
    activeOverride: params => Number(params.step) === 4 && Number(params.stage) === ticketFormStage,
    needsMatch: false,
    id: "addTickets",
    show: shouldShow,
    isDisabled: event => isCancelled(event),
    disabledTooltipText: event => getDisabledTooltipText(event),
    permissions: permissions().READ_TICKET,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(
        event,
        `/events/${event.masterListingInstanceId}/edit?step=4&stage=1`,
      ),
  },
  {
    name: "Guestlist manager",
    path: `/events/${id}/guestlist`,
    route: "/events/:id/guestlist",
    id: "manageGuestlist",
    show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.GUESTLIST),
    activeStateFor: ["/events/:id/guestlist/:orders"],
    permissions: permissions().UPDATE_TICKET,
    hideIfNoPermissions: false,
    isDisabled: event => isSessionEvent(event) || isRecurringEvent(event) || isCancelled(event),
    disabledTooltipText: event => getDisabledTooltipText(event),
  },
  {
    name: "Waiting list",
    path: `/events/${id}/waiting-list`,
    route: "/events/:id/waiting-list",
    id: "manageWaitingList",
    show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.WAITLIST),
    permissions: permissions().UPDATE_TICKET,
    hideIfNoPermissions: false,
    modal: event =>
      eventsRecurringModalSettings(event, `/events/${event.masterListingInstanceId}/waiting-list`),

    isDisabled: event => isSessionEvent(event) || isRecurringEvent(event) || isCancelled(event),
    disabledTooltipText: event => getDisabledTooltipText(event),
  },
  {
    name: "Re:Sell",
    path: `/events/${id}/resale`,
    route: "/events/:id/resale",
    id: "manageResell",
    show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.RESELL),
    disabledTooltipText: event => getDisabledTooltipText(event),
    isDisabled: event => {
      return isRecurringEvent(event) || isSessionEvent(event) || isCancelled(event);
    },
    permissions: permissions().UPDATE_TICKET,
    hideIfNoPermissions: false,
  },
  {
    name: "Discount codes",
    path: `/events/${id}/discount-codes`,
    route: "/events/:id/discount-codes",
    id: "manageDiscountCodes",
    show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.DISCOUNT_CODES),
    isDisabled: event => isRecurringEvent(event) || isSessionEvent(event) || isCancelled(event),
    disabledTooltipText: event => getDisabledTooltipText(event),
    permissions: permissions().UPDATE_TICKET,
    hideIfNoPermissions: false,
    activeStateFor: ["/events/:id/discount-codes/create"],
  },
  {
    name: "Remind Me",
    path: `/events/${id}/remind-me`,
    route: "/events/:id/remind-me",
    id: "manageRemindMe",
    show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.REMIND_ME),
    disabledTooltipText: event => getDisabledTooltipText(event),
    isDisabled: event => isRecurringEvent(event) || isSessionEvent(event) || isCancelled(event),
    permissions: permissions().UPDATE_TICKET,
    hideIfNoPermissions: false,
  },
  {
    name: "Cool:Off",
    path: `/events/${id}/cool-off`,
    route: "/events/:id/cool-off",
    id: "manageCoolOff",
    show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.COOL_OFF),
    permissions: permissions().UPDATE_TICKET,
    hideIfNoPermissions: false,
  },
  {
    name: "Delivery options",
    path: `/events/${id}/ticket-delivery`,
    route: "/events/:id/ticket-delivery",
    id: "ticketDeliveryOptions",
    show: () => shouldShow(match) && isFeatureFlagOn(featureFlags.TICKET_DELIVERY_OPTIONS),
    permissions: permissions().UPDATE_TICKET,
    hideIfNoPermissions: false,
  },
];

export default manageTicketsChildren;
