import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Fade } from "@mui/material";

const LoadingIcon = ({
  size = 40,
  color = "primary",
  debugName = "",
  displayDelay = 0,
  height = 40,
}) => {
  return (
    <Box sx={{ height }}>
      <Fade
        in
        style={{
          transitionDelay: displayDelay,
        }}
        unmountOnExit
      >
        <CircularProgress
          disableShrink
          size={size}
          color={color}
          data-debugname={debugName}
          sx={{ padding: "4px" }}
        />
      </Fade>
    </Box>
  );
};

LoadingIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  debugName: PropTypes.string,
  displayDelay: PropTypes.number,
  height: PropTypes.number,
};

export default LoadingIcon;
