import { isFeatureFlagOn } from "../../Utils";
import { featureFlags } from "../../Utils/checkFeatureFlag";

const dashboardStatFilters = {
  TODAY: { name: "today", label: "today", defaultGroupBy: "hour" },
  YESTERDAY: { name: "yesterday", label: "yesterday", defaultGroupBy: "hour" },
  LAST_7_DAYS: { name: "week", label: "last 7 days", defaultGroupBy: "date" },
  LAST_14_DAYS: { name: "fortnight", label: "last 14 days", defaultGroupBy: "date" },
  LAST_30_DAYS: { name: "30days", label: "last 30 days", defaultGroupBy: "date" },
  THIS_MONTH: { name: "thismonth", label: "this month", defaultGroupBy: "date" },
  LAST_MONTH: { name: "lastmonth", label: "last month", defaultGroupBy: "date" },
  THIS_YEAR: { name: "thisyear", label: "this year", defaultGroupBy: "month" },
  LAST_YEAR: { name: "lastyear", label: "last year", defaultGroupBy: "month" },
  LAST_12_MONTHS: { name: "last12m", label: "last 12 months", defaultGroupBy: "month" },
  ...(isFeatureFlagOn(featureFlags.DASHBOARD_ALL_TIME) && {
    ALL_TIME: { name: "all", label: "all time", defaultGroupBy: "year" },
  }),
  DATE_RANGE: { name: "daterange", label: "date range" },
};

export default dashboardStatFilters;
