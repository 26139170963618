import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

import { Button } from "../Reusable";
import { error } from "../../Utils/toast";
import errorMessages from "../../Utils/errorMessages";
import changePromoter from "../../Utils/Auth/changePromoter";
import useAuthMainPromoter from "../NamedComponents/Auth/hooks/useAuthMainPromoter";
import useAuth from "../NamedComponents/Auth/hooks/useAuth";

const SwitchToMainAccount = ({ setOpen = null, ...rest }) => {
  const mainPromoter = useAuthMainPromoter();
  const navigate = useNavigate();
  const auth = useAuth();

  const handleSwitchToMainAccount = async () => {
    try {
      await changePromoter(Number(mainPromoter.promoterId), auth);
      navigate("/");
      if (setOpen) {
        setOpen(false);
      }
    } catch {
      error(errorMessages.switchAccounts);
    }
  };

  return (
    <Button
      color="secondary"
      variant="outlined"
      onClick={handleSwitchToMainAccount}
      data-testid="switch-to-main-account"
      {...rest}
    >
      Switch to main account
    </Button>
  );
};

SwitchToMainAccount.propTypes = {
  setOpen: PropTypes.func,
};
export default SwitchToMainAccount;
