import { handleError } from "../../Utils/handleApiErrors";

const handleMock = async (options, navigate, dispatch, retryFn, mockData, state, key) => {
  const {
    showGlobalError,
    showRawErrorMessage,
    mockError,
    mockStatus,
    fetchAll,
    structureData,
    authenticated,
  } = options;
  setTimeout(async () => {
    if (mockError) {
      await handleError(
        {
          response: { status: mockStatus, data: { detail: "Mock error message" } },
          message: "This is a mock error message.",
        },
        { showGlobalError, showRawErrorMessage, authenticated },
        navigate,
      );

      dispatch({
        type: "FETCH_ERROR",
        payload: {
          error: mockError,
          retryFn,
          retries: state.retries,
          status: mockStatus,
          key,
        },
      });
      return;
    }

    const data = structureData
      ? structureData(mockData?.data || mockData)
      : mockData?.data || mockData;

    if (fetchAll) {
      dispatch({
        type: "FETCHED_ALL",
        payload: {
          res: data,
          allData: data,
          meta: mockData?.meta || {},
          retryFn,
          retries: state.retries,
          status: mockStatus,
          key,
        },
      });
    } else {
      dispatch({
        type: "FETCHED",
        payload: {
          res: data,
          meta: mockData?.meta || {},
          retryFn,
          retries: state.retries,
          status: mockStatus,
          key,
        },
      });
    }
  }, 200);
};

export default handleMock;
