import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { Button, Modal } from "../../../Reusable";
import date from "../../../../Utils/date";
import { SHORT_DATE_USER } from "../../../../Constants/luxonDateFormats";
import useHttpRequest from "../../../../Hooks/useHttpRequest/useHttpRequest";
import { Endpoints } from "../../../../Constants";
import { FETCHED } from "../../../../Constants/requestStatuses";
import { success } from "../../../../Utils/toast";
import helpCentre, { HelpCentreLink } from "../../../../Constants/helpCentre";

const ConfirmDetachModal = ({
  children,
  startDate,
  disabled = false,
  listingId,
  eventId,
  refreshOnSubmit = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [shouldExecute, setShouldExecute] = useState(false);
  const navigate = useNavigate();

  const { status } = useHttpRequest({
    authenticated: true,
    params: {
      id: listingId,
    },
    method: "PUT",
    shouldExecute,
    endpoint: Endpoints.DETACH_RECURRING,
  });

  useEffect(() => {
    if (status === FETCHED) {
      success("Event successfully detached");
      if (refreshOnSubmit) {
        window.location.reload();
      }
      navigate(`/events/${eventId}`);
    }
  }, [status]);

  return (
    <>
      <Box onClick={() => setModalOpen(true)}>{children}</Box>
      {!disabled && (
        <Modal
          header="Detach confirmation"
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          actions={
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="error"
                onClick={() => {
                  setShouldExecute(true);
                }}
              >
                Detach
              </Button>
            </>
          }
        >
          <Typography>
            Would you like to detach the Date on{" "}
            <b>{date(startDate).formatUserDate({ ...SHORT_DATE_USER })}</b>?
          </Typography>
          <br />
          <Typography>
            This is useful for when you want to edit one Date without making changes to the other
            Dates in the Event Series. This Date will become a single stand alone event separate to
            the Event Series. You can read more on our{" "}
            <HelpCentreLink to={helpCentre.DETACH_EVENT()}>Help Centre.</HelpCentreLink>
          </Typography>
          <br />
          <Typography>
            After this action you can <b>not</b> add the detached Date back to the Event Series.
          </Typography>
        </Modal>
      )}
    </>
  );
};

ConfirmDetachModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  startDate: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  listingId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  refreshOnSubmit: PropTypes.bool,
};

export default ConfirmDetachModal;
