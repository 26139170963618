/**
 * permissions.js
 *
 * This file handles the main logic for permissions within the app.
 *
 * Key terminology to know:
 * ROLE: a role is a sum of individual permissions. A role will have it's own bitwise value,
 * separate to the permissions bitwise values. This is the value which is actually stored against
 * a user in the Database.
 * PERMISSIONS: a permission is a single bitwise value which can be part of a role, many permissions
 * can make up one ROLE
 *
 * When assigning a ROLE to a user, we would use the role bitwise value, stored below in
 * `rolesBitwise`.
 */

import isFeatureFlagOn, { featureFlags } from "./checkFeatureFlag";

/**
 * @rolesBitwise - Stores the actual roles which can be assigned to a user
 */
export const rolesBitwise = {
  Admin: 1,
  "Listings and tickets": 2,
  Finance: 4,
  Analytics: 8,
  "Customer data": 16,
  "Listings and tickets - read only": 32,
  Promote: 64,
};

/**
 * IMPORTANT, IF ADDING A NEW ROLE, MAKE SURE TO ADD IT AT THE BOTTOM!
 *
 * @permissions - This function generates the permissions by using an array of all
 * permissions, then assigning them a number, doubled each time
 *
 */
const permissions = () => {
  let count = 1;

  const permsArr = [
    "CREATE_EVENT",
    "READ_EVENT",
    "UPDATE_EVENT",
    "DELETE_EVENT",
    "COPY_EVENT",
    // End of events

    "CREATE_TICKET",
    "READ_TICKET",
    "UPDATE_TICKET",
    "DELETE_TICKET",
    // End of tickets

    "READ_ANALYTICS",
    "DOWNLOAD_DATA_ANALYTICS",
    // End of analytics

    "WRITE_FINANCE",
    "READ_FINANCE",
    "UPDATE_FINANCE",
    "DELETE_FINANCE",
    "DOWNLOAD_DATA_FINANCE",
    // End of finance

    "READ_CUSTOMER_DATA",
    "CREATE_CUSTOMER_DATA",
    "UPDATE_CUSTOMER_DATA",
    "DELETE_CUSTOMER_DATA",
    "DOWNLOAD_CUSTOMER_DATA",
    // End of customer data

    "CREATE_PROMOTE",
    "READ_PROMOTE",
    "UPDATE_PROMOTE",
    "DELETE_PROMOTE",
  ];

  const permissionsToReturn = {};

  permsArr.forEach(perm => {
    permissionsToReturn[perm] = count;
    count *= 2;
  });

  return permissionsToReturn;
};

const perms = permissions();

const roles = {
  [rolesBitwise["Listings and tickets"]]:
    perms.CREATE_EVENT |
    perms.READ_EVENT |
    perms.UPDATE_EVENT |
    perms.DELETE_EVENT |
    perms.CREATE_TICKET |
    perms.READ_TICKET |
    perms.UPDATE_TICKET |
    perms.DELETE_TICKET |
    perms.COPY_EVENT,

  [rolesBitwise.Finance]:
    perms.WRITE_FINANCE |
    perms.READ_FINANCE |
    perms.UPDATE_FINANCE |
    perms.DELETE_FINANCE |
    perms.DOWNLOAD_DATA_FINANCE,

  [rolesBitwise.Analytics]: perms.READ_ANALYTICS | perms.DOWNLOAD_DATA_ANALYTICS,

  [rolesBitwise["Customer data"]]:
    perms.READ_CUSTOMER_DATA |
    perms.UPDATE_CUSTOMER_DATA |
    perms.DELETE_CUSTOMER_DATA |
    perms.DOWNLOAD_CUSTOMER_DATA |
    perms.CREATE_CUSTOMER_DATA,

  [rolesBitwise["Listings and tickets - read only"]]: perms.READ_EVENT | perms.READ_TICKET,

  [rolesBitwise.Promote]:
    perms.CREATE_PROMOTE | perms.READ_PROMOTE | perms.UPDATE_PROMOTE | perms.DELETE_PROMOTE,
};

/**
 * @userRoles - An object containing the user roles but with a sum of their permissions, this
 * is different to the `rolesBitwise` object above, with that storing the bitwise numbers
 * of the role itself, this function stores the sum of all permissions within a role to get
 * its permission bitwise
 *
 * A user may have many roles
 */
export const userRoles = {
  [rolesBitwise.Admin]:
    roles[rolesBitwise["Listings and tickets"]] |
    roles[rolesBitwise.Finance] |
    roles[rolesBitwise.Analytics] |
    roles[rolesBitwise["Customer data"]] |
    roles[rolesBitwise["Listings and tickets - read only"]] |
    roles[rolesBitwise.Promote],
  ...roles,
};

/**
 * @roleToPermissions - A function which takes a users role bitwise and converts it to
 * a permissions bitwise. For example, if a user is assigned a role of "listings and tickets",
 * the bitwise number for that role is 2, however, it's permissions bitwise is 255 which is
 * a sum of all the permissions within that role (CRUD in this case)
 * @param {Number} userRoleBitwise - The bitwise number of a ROLE
 * @returns {Number} a bitwise value representing the users PERMISSIONS
 */
export const roleToPermissions = userRoleBitwise => {
  let userPermissions = 0;
  Object.keys(userRoles).forEach(key => {
    if (userRoleBitwise & key) {
      userPermissions |= userRoles[key];
    }
  });

  return userPermissions;
};

// Returns an array of roles available for a user based on their bitwise number
export const decodeUserRoles = userBitwise => {
  const decodedUserRoles = [];
  Object.keys(userRoles).forEach(key => {
    if (userBitwise & userRoles[key] && userBitwise >= userRoles[key]) {
      const role = Object.keys(rolesBitwise).find(r => Number(key) === Number(rolesBitwise[r]));
      decodedUserRoles.push({
        label: role,
        value: Number(key),
        perms: roleToPermissions(Number(key)),
      });
    }
  });

  const sorted = [...decodedUserRoles].sort((a, b) => b.perms - a.perms);

  let tempPerms = userBitwise;
  const filteredPerms = [];

  sorted.forEach(role => {
    if (role.perms <= tempPerms && role.perms & tempPerms) {
      filteredPerms.push(role);
    }

    tempPerms -= role.perms;
  });

  return filteredPerms;
};

// Remove roles that contain each other e.g. Finance Admin and Finance Read-Only
export const removeRepeatedRoles = decodedUserRoles => {
  return decodedUserRoles.filter(role => {
    let contained = true;
    decodedUserRoles.forEach(comparisonRole => {
      if (role.value & comparisonRole.value && role.value < comparisonRole.value) {
        contained = false;
      }
    });
    return contained;
  });
};

export const isOwner = auth => {
  if (!auth) {
    return false;
  }
  if (!auth?.userData?.promoter?.mainAccount) {
    return false;
  }

  return Number(auth?.userData.promoter?.permissions) === 0;
};

// Checks if a user is allowed to perform an action
export const checkUserPermission = (userBitwise, permissionBitwise) => {
  // Check if feature flag is on
  if (isFeatureFlagOn(featureFlags.PERMISSIONS)) {
    const adminPermissions = userRoles[rolesBitwise.Admin];

    // If user is owner
    if (userBitwise === 0) {
      return true;
    }

    // If anything has perms of 0 it means we want anyone to be able to access it
    if (permissionBitwise === 0) {
      return true;
    }

    const userIsAdmin = (userBitwise & adminPermissions) === adminPermissions;
    const permissionIsAdmin = (permissionBitwise & adminPermissions) === adminPermissions;

    // An admin is trying to access an admin-only permission
    if (userIsAdmin && permissionIsAdmin) {
      return true;
    }

    // Trying to access admin permission but not an admin
    if (!userIsAdmin && permissionIsAdmin) {
      return false;
    }

    // Actual bitwise permissions check
    return Boolean(userBitwise & permissionBitwise);
  }

  // If feature flag off, return true
  return true;
};

export default permissions;
