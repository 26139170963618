import { blue, green, grey, red } from "@mui/material/colors";
import { PaletteMode } from "@mui/material";
import cancellationTypes from "../Constants/events/cancellationTypes";

export const skiddle = {
  grey: {
    main: grey[300],
    dark: grey[400],
  },
  name: "skiddle",
  palette: {
    mode: "light" as PaletteMode,
    common: {
      black: "#333",
      white: "#FFF",
    },
    type: "light",
    primary: {
      light: "#7ef4ee",
      main: "#45c1bc",
      dark: "#00908c",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#5367be",
      main: "#163d8d",
      dark: "#00185f",
      contrastText: "#FFF",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "#FFF",
    },
    skiddle: {
      event: {
        statuses: {
          live: {
            main: "#034732",
            outlined: "#DEF7F7",
          },
          guestlist: {
            main: "#5367be",
            outlined: "rgb(229, 246, 253)",
          },
          draft: {
            main: "#F0A202",
            outlined: "#FFF2D7",
          },
          [cancellationTypes.CANCELLED]: {
            main: "#A60A0F",
            outlined: "#FDD9DA",
          },
          [cancellationTypes.POSTPONED]: {
            main: "#A60A0F",
            outlined: "#FDD9DA",
          },
          [cancellationTypes.RESCHEDULED]: {
            main: "#A60A0F",
            outlined: "#FDD9DA",
          },
          embargoed: {
            main: "#F0A202",
            outlined: "#FFF2D7",
          },
          processing: {
            main: "rgb(1, 67, 97)",
            outlined: "rgb(229, 246, 253)",
          },
        },
      },

      // Primary Colours
      primary: "#46C3BD",
      palePrimary: "#9ACCB9",

      //  Tip Colours
      TipText: "#1B7B75",
      TipBg: "#E3F6F5",

      paleGrey: "#F4F4F4",
      lightGrey: "#f4f4f445",
      grey: "#EBEBEB",
      darkGrey: "#C7C7C7",
      inputGrey: "#EBEBEB",
      backgroundDrop: "#EDEBED",

      mobileTicketStatus: "#454545",
      mobileCategoryTickBg: "#E8E8E8",

      // Red's
      danger: "#fd5f60",
      stop: "#F47A7A",

      // White's
      white: "#FFFFFF",
      transparentWhite: "rgba(255, 255, 255, 0.44)",

      // Black Colours
      black: "#222222",
      textBlack: "#333333",

      skiddleTurquoise: "#46C3BD",
      skiddleTurquoiseAlpha: "#46C3BDAA",
      skiddleBlue: "#163D8D",
      skiddleBlueAlpha: "#163D8D99",
      blue: "#163D8D",

      // Ticket Status Colours for Ticket Summary
      ticketStatusStatic: "#222",
      ticketStatusSold: "#8fbb79",
      ticketStatusReSale: "#eb7474",
      ticketStatusBasket: "#eb7474",

      amber: "#FFBF00",
      error: "#F47A7A",

      // Skiddle Background Gradient
      gradient: "linear-gradient(130deg,rgba(68,188,187,1) -10%,rgba(32,89,151,1) 100%)",
      // Skiddle Background Gradient Login
      gradientLogin:
        "linear-gradient(137deg, rgba(71,195,190,1) 0%, rgba(70,180,158,1) 28%, rgba(22,61,141,1) 100%)",
      alerts: {
        info: {
          primary: "#46c3bd54",
        },
      },
    },
  },
  header: {
    color: grey[200],
    background: blue[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: "#FFFFFF",
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[900],
      background: grey[100],
      online: {
        background: green[500],
      },
    },
    category: {
      fontWeight: 600,
    },
    badge: {
      color: "#FFF",
      background: green[600],
    },
    subheader: {
      fontWeight: 600,
    },
  },
  body: {
    background: "#F7F9FC",
  },
  ticketsSummary: {
    totalsBackground: "#EDEBED",
  },
};

const variants = skiddle;

export default variants;
