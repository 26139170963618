import * as Yup from "yup";
import { isRecurringEvent, isSessionEvent } from "../../../../Layout/Navigation/helpers";
import { findTicket, isAddon } from "../../../../../Utils/ticketHelpers";
import getAllocationErrorMessage from "../../config/getAllocationErrorMessage";
import { SESSION_TICKETING } from "../../../../../Constants/events/ticketingTypes";
import lazy from "../../../../../Utils/lazy";

const TicketAllocation = lazy(() => import("../../FieldComponents/TicketAllocation"));

const ticketsAvailable = {
  name: "ticketsAvailable",
  displayName: () => "Allocation",
  inputType: "number",
  defaultValue: (event, y, qsValues, ticket) => {
    if (isSessionEvent(event) && !isAddon(qsValues, {})) {
      return "N/A";
    }
    return ticket?.ticketsAvailable === 0 ? "0" : "500";
  },
  validation: (form, currentTicketData, x, qsValues, y, z, event) => {
    const isRecurring = isRecurringEvent(form.values);
    const ticket = findTicket(Number(qsValues.ticketId), currentTicketData);
    const soldCount = ticket?.ticketsSold || 0;

    const type = isSessionEvent(event) ? Yup.string() : Yup.number();

    return type
      .when("skipTickets", {
        is: "no",
        then: schema => schema.required("Allocation is required"),
        otherwise: schema => schema,
      })
      .min(isRecurring ? ticket?.maxChildTicketSales || 0 : soldCount || 0, () => {
        return getAllocationErrorMessage(isRecurring, ticket?.maxChildTicketSales, soldCount);
      })
      .max(999999, "Allocation can be no higher than 999,999");
  },
  sendToApi: (_, formValues, ___, qsValues, globalFormValues) => {
    const values = { ...globalFormValues, ...formValues };
    return (
      isAddon(qsValues, values) ||
      (!isAddon(qsValues, values) && values.ticketingType !== SESSION_TICKETING)
    );
  },
  handleClick: (action, form) => form.addValue("freeTicketOption", !form.values.freeTicketOption),
  postFormat: val => (!val ? 500 : Number(val)),
  grid: { md: 6 },
  type: TicketAllocation,
};

export default ticketsAvailable;
