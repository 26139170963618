import advancedOptions from "./advancedOptions";

const advanced = {
  isGroup: true,
  label: "Advanced options",
  fields: [advancedOptions],
  hidden: (formValues, context) =>
    formValues.skipTickets === "yes" && !context.currentTicketData.length,
};

export default advanced;
