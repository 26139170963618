import { displayGroups } from "../../../../../Constants/events/eventOptions";
import lazy from "../../../../../Utils/lazy";
import { isAddon } from "../../../../../Utils/ticketHelpers";

const InputSelect = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputSelect"),
);

const displayGroup = {
  name: "displayGroup",
  displayName: () => "Display Group",
  sendToApi: (val, values) => val && values[val] && values[val] !== "",
  postFormat: val => {
    const { value } = val;
    if (value) {
      return val.value;
    }

    if (val) {
      return val;
    }

    return null;
  },
  type: InputSelect,
  defaultValue: (_, __, qsValues, ticket) => (isAddon(qsValues, ticket) ? "addons" : ""),
  displayEmpty: true,
  subtitle: "Display Group",
  options: displayGroups,
  hidden: () => true,
};

export default displayGroup;
