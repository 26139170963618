import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import useAuth from "../NamedComponents/Auth/hooks/useAuth";
import Layout from "./Layout";
import AuthLayout from "./AuthLayout";
import { setCurrentForms, setPageSpecificLogs } from "../../Store/reducers/AdminPanelReducer";
import { LOGGED_IN_AS_PROMOTER_ID } from "../../Constants/auth";
import { error } from "../../Utils/toast";
import SessionExpiredModal from "./SessionExpiredModal";
import { AppContext } from "../../Context";

const onLocationChange = (location, setCurrentEvent) => {
  const isEventRoute = location.pathname.match(/^\/events\/(\d+)/);

  if (!isEventRoute) {
    setCurrentEvent(false);
  }
};

const onStorageChange = (e, navigate, setShowModal) => {
  if (e?.key === LOGGED_IN_AS_PROMOTER_ID && e?.oldValue !== e?.newValue) {
    navigate("/");
    error("Session expired. Please refresh");
    setShowModal(true);
  }
};

const Root = () => {
  const [showModal, setShowModal] = useState(false);

  const { setCurrentEvent } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { loading, isAuthenticated } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentForms("RESET"));

    setPageSpecificLogs(null);
  }, [location.pathname]);

  useEffect(() => {
    onLocationChange(location, setCurrentEvent);
  }, [location]);

  useEffect(() => {
    window.addEventListener("storage", e => onStorageChange(e, navigate, setShowModal));
    return () => {
      window.removeEventListener("storage", e => onStorageChange(e, navigate, setShowModal));
    };
  }, []);

  if (loading) {
    return null;
  }

  return (
    <>
      {!isAuthenticated || location.pathname.includes("/auth/") ? <AuthLayout /> : <Layout />}
      <SessionExpiredModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default Root;
