import TICKET_AVAILABILITY_STATUS from "../../../../../Constants/tickets/availabilityStatus";
import lazy from "../../../../../Utils/lazy";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);

const passwordDisplay = {
  name: "passwordDisplay",
  displayName: () => "Password display",
  sendToApi: () => true,
  type: InputRadio,
  defaultValue: "0",
  advancedOption: true,
  hidden: vals => vals.availability !== TICKET_AVAILABILITY_STATUS.PASSWORDED,
  postFormat: val => (val && val === "yes" ? 1 : 0),
  options: [
    { label: "Hide ticket until password entered", value: "no" },
    { label: "Show ticket, unable to buy until password entered", value: "yes" },
  ],
};
export default passwordDisplay;
