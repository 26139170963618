/**
 * Check if all required env variables have been set
 */

// Add required env vars here without the 'VITE_' prefix
const requiredEnv = [
  "REGION",
  "POOL_ID",
  "CLIENT",
  "API_URL",
  "API_KEY",
  "BANK_VALIDATE_LICENSE_KEY",
  "GOOGLE_RECAPTCHA_KEY",
  "LOQATE_KEY",
  "INTERCOM_APP_ID",
  "BANK_ACCOUNT_LIMIT",
  "BRANCH_IO_KEY",
  "EVENT_CACHE_TIME",
  "TINY_MCE",
  "SEATS_IO_WORKSPACE",
  "COGNITO_USER_POOL_CREDENTIALS_ID",
  "AWS_API_GATEWAY_KEY",
];

const prefix = "VITE_";

export default () => {
  requiredEnv.forEach(env => {
    if (!import.meta.env[prefix + env]) {
      throw new Error(`Missing env variable: ${prefix}${env}. Please add this to your .env file.`);
    }
  });
};
