export const IMAGE_TYPES = {
  "image/png": [".png"],
  "image/jpeg": [".jpeg"],
  "image/jpg": [".jpg"],
};

export const CSV_TYPES = {
  "text/csv": [".csv"],
  "application/vnd.ms-excel": [".csv"],
  "application/csv": [".csv"],
  "text/x-csv": [".csv"],
  "application/x-csv": [".csv"],
  "text/comma-separated-values": [".csv"],
  "text/x-comma-separated-values": [".csv"],
};

export const FILE_TYPES = {
  EVENT_IMAGE: "event_image",
  FLYER_BACK_IMAGE: "flyer_back_image",
  FLYER_FRONT_IMAGE: "flyer_front_image",
  ARTIST_IMAGE: "artist_image",
  BRAND_ICON_IMAGE: "brand_icon_image",
  BRAND_BANNER_IMAGE: "brand_banner_image",
  GUESTLIST_CSV: "guestlist_csv",
};
