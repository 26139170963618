import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { darken } from "polished";
import { Link as MuiLink, styled } from "@mui/material";

const StyledLink = styled(RouterLink)`
  font-family: ${({ theme: { typography } }) => typography.fontFamily};
  font-weight: 600;
  color: ${({ theme: { palette }, $white }) =>
    $white ? palette.common.white : palette.secondary.light};
  &:hover {
    color: ${({ theme: { palette }, $white }) =>
      $white ? darken(0.2, palette.common.white) : darken(0.2, palette.secondary.light)};
  }
  cursor: pointer;
  text-decoration: ${({ $isButton }) => $isButton && "none"};
`;

const StyledMuiLink = styled(MuiLink)`
  font-family: ${({ theme: { typography } }) => typography.fontFamily};
  font-weight: 600;
  color: ${({ theme: { palette }, $white }) =>
    $white ? palette.common.white : palette.secondary.light};
  text-decoration-color: ${({ theme: { palette } }) => palette.secondary.light};
  &:hover {
    color: ${({ theme: { palette }, $white }) =>
      $white ? darken(0.2, palette.common.white) : darken(0.2, palette.secondary.light)};
  }
  cursor: pointer;
  text-decoration: ${({ $isButton }) => $isButton && "none"};
`;

const Link = ({ to, isRouterLink = true, white = false, ...rest }) => {
  if (isRouterLink) {
    return <StyledLink to={to} $white={white} {...rest} />;
  }

  return <StyledMuiLink href={to} rel="noopener noreferrer" $white={white} {...rest} />;
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  isRouterLink: PropTypes.bool,
  white: PropTypes.bool,
};

export default Link;
