import React, { useContext, useState, useEffect, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  IconButton,
  List as MuiList,
  ListItem,
  styled,
  useMediaQuery,
  Typography,
  Grid2 as Grid,
} from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box } from "@mui/system";
import { rgba } from "polished";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import routes from "./Navigation/Navigation";
import NavLink from "./Navigation/NavLink";
import "../../vendor/perfect-scrollbar.css";
import SidebarCategory from "./Navigation/SidebarCategory";
import AppVersion from "../Reusable/AppVersion";
import { AppContext } from "../../Context";
import LoadingIcon from "../Reusable/LoadingIcon";
import Modal from "../Reusable/Modal";
import useAuthMainPromoter from "../NamedComponents/Auth/hooks/useAuthMainPromoter";
import ErrorBoundary from "./ErrorBoundary";
import useSidebarWidth from "../../Hooks/useSidebarWidth";
import theme from "../../theme";
import skiddleLogo from "../../Assets/Images/skiddle_logo.svg";
import skiddleText from "../../Assets/Images/skiddle-text-black-landscape.svg";
import { closedDrawerWidth, drawerTransitionTime, drawerWidth } from "../../Constants/layout";
import Store from "../../Store";
import { isFeatureFlagOn } from "../../Utils";
import { featureFlags } from "../../Utils/checkFeatureFlag";
import Link from "../Reusable/Link";
import lazy from "../../Utils/lazy";

const AdminPanel = lazy(() => import("./AdminPanel/AdminPanel"));

const CollapseButton = styled(Box)`
  position: fixed;
  left: ${({ $currentDrawerWidth }) => `calc(${$currentDrawerWidth} - 12px)`};
  top: 15px;
  width: 28px;
  height: 28px;
  background-color: ${theme.palette.common.white};
  border-radius: 50%;
  border: ${`1px solid ${theme.palette.skiddle.grey}`};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${rgba(theme.sidebar.color, 0.5)};
  cursor: pointer;
  transition: ${({ $drawerTransitionTime }) => `left ${$drawerTransitionTime}`};
  &:hover {
    left: ${({ $sidebarOpen, $drawerWidth, $closedDrawerWidth }) =>
      $sidebarOpen
        ? `${$drawerWidth - 12}px !important`
        : `${$closedDrawerWidth - 12}px !important`};
  }
`;

const SidebarContent = styled(Box)`
  position: relative;
  margin-top: 60px;
  width: ${({ $width }) => $width};
  height: 100%;
  transition: ${({ $drawerTransitionTime }) => `width ${$drawerTransitionTime}`};
  &:hover {
    width: ${({ $drawerWidth }) => `${$drawerWidth}px`};
    .sidebar-arrow {
      opacity: 0.5;
    }
    .sidebar-collapse-button {
      left: ${({ $drawerWidth }) => `${$drawerWidth - 12}px`};
    }
    .small-sidebar-logo {
      left: 56px;
    }
  }
`;

const SidebarContainer = styled(Box)`
  position: relative;
  height: calc(100% - 60px);
  &:hover > .sidebar-collapse-button {
    left: ${({ $drawerWidth }) => `${$drawerWidth - 12}px`};
  }
`;

const Brand = styled(ListItem)`
  position: absolute;
  top: -60px;
  left: 0;
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)};
  padding-right: ${props => props.theme.spacing(6)};

  .logo-with-text {
    width: 80%;
    margin: 0 auto;
    position: absolute;
    left: 36px;
    top: 0;
    width: 154px;
  }
  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 50px;
  overflow-x: hidden;
`;

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

const Items = styled("div")`
  overflow-x: hidden;
  padding-top: ${props => props.theme.spacing(2.5)};
  padding-bottom: ${props => props.theme.spacing(2.5)};
`;

const SmallLogo = styled("img")`
  cursor: pointer;
  margin-top: 10px;
  position: absolute;
  left: ${({ $sidebarOpen }) => ($sidebarOpen ? "56px" : "20px")};
  top: 0;
  width: 21px;
  transition: ${({ $drawerTransitionTime }) => `left ${$drawerTransitionTime}`};
`;

const LogoText = styled("img")`
  cursor: pointer;
  padding-top: 7px;
  padding-left: 25px;
  height: 54px;
`;

const Sidebar = ({ ...rest }) => {
  const mainPromoter = useAuthMainPromoter();
  const location = useLocation();
  const currentDrawerWidth = useSidebarWidth();
  const navigate = useNavigate();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { admin } = qs.parse(location?.search);

  const { currentEvent, isMobile, showAdminModal, setShowAdminModal, sidebarOpen, setSidebarOpen } =
    useContext(AppContext);

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;
    let open = {};
    routes.forEach((route, index) => {
      if (route.activeOverride && route.activeOverride(pathName)) {
        open = { ...open, [index]: true };
      } else {
        const isActive = pathName.indexOf(route.path) === 0;
        const isOpen = route.open;
        open = { ...open, [index]: isActive || isOpen };
      }
    });

    return open;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const handleMouseEnter = () => {
    if (!sidebarOpen) {
      setOpenRoutes(initOpenRoutes());
    }
  };
  const handleMouseExit = () => {
    if (!sidebarOpen) {
      setOpenRoutes({});
    }
  };

  useEffect(() => {
    if (sidebarOpen) {
      setOpenRoutes(initOpenRoutes());
    }
  }, [location]);

  useEffect(() => {
    if (!sidebarOpen) {
      setOpenRoutes({});
    } else {
      setOpenRoutes(initOpenRoutes());
    }
  }, [sidebarOpen]);

  useEffect(() => {
    if (admin) {
      setShowAdminModal(true);
    }
  }, [admin]);

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(or => ({ ...or, [item]: false })),
    );

    // Toggle selected element
    setOpenRoutes(or => ({ ...or, [index]: !or[index] }));
  };

  if (currentEvent === null) {
    return <LoadingIcon debugName="current-event" />;
  }

  const renderSidebarCategory = (category, index) => {
    const { id, icon, path, show, matchString, badge, permissions, notification, intercom } =
      category;
    const { onClose } = rest;

    const sidebarCategoryProps = {
      name: id,
      id,
      icon,
      onClose,
      path,
      show,
      matchString,
      openRoutes,
      permissions,
      notification,
      intercom,
    };

    if (category.children) {
      // This is the Top level route with icon
      return (
        <SidebarCategory
          isOpen={!openRoutes[index]}
          isCollapsable
          button
          onClick={() => toggle(index)}
          index={index}
          location={location}
          childLinks={category.children}
          notification={notification}
          {...sidebarCategoryProps}
        />
      );
    }

    // If no children, just render the category with no dropdown
    return (
      <SidebarCategory
        isCollapsable={false}
        to={path}
        $activeClassName="active"
        component={NavLink}
        badge={badge}
        notification={notification}
        {...sidebarCategoryProps}
      />
    );
  };

  const onAdminPanelClose = () => {
    const search = { ...qs.parse(location?.search) };
    delete search.requestId;
    delete search.admin;
    delete search.tab;
    setShowAdminModal(false);
    navigate(`${window.location.pathname}?${qs.stringify(search)}`, { state: location?.state });
  };

  const showAdminPanel = isFeatureFlagOn(featureFlags.ADMIN_PANEL, true);

  return (
    <SidebarContainer $drawerWidth={drawerWidth}>
      <SidebarContent
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseExit}
        className="sidebar-content"
        $width={currentDrawerWidth}
        $sidebarOpen={sidebarOpen}
        $drawerWidth={drawerWidth}
        $drawerTransitionTime={drawerTransitionTime}
        height="100%"
      >
        <Brand>
          <SmallLogo
            onClick={() => navigate("/")}
            src={skiddleLogo}
            alt="Skiddle logo"
            className="small-sidebar-logo"
            $sidebarOpen={sidebarOpen}
            $drawerTransitionTime={drawerTransitionTime}
          />
          <LogoText
            onClick={() => navigate("/")}
            src={skiddleText}
            alt="skiddle text"
            className="logo-with-text"
          />
        </Brand>

        <Scrollbar sx={{ borderRight: 0 }}>
          <List disablePadding>
            <ErrorBoundary code="SBL">
              <Items>
                {routes.map((category, index) => (
                  <React.Fragment key={category.id}>
                    {renderSidebarCategory(category, index)}
                  </React.Fragment>
                ))}
              </Items>
            </ErrorBoundary>
          </List>
        </Scrollbar>
        {sidebarOpen && (
          <Grid
            container
            size={12}
            spacing={2}
            sx={{
              position: "absolute",
              bottom: 0,
              justifyContent: "space-between",
              backgroundColor: "#fff",
              alignItems: "center",
              width: "100%",
              padding: "8px 16px",
            }}
          >
            <Grid size={3}>
              <AppVersion />
            </Grid>
            <Grid
              container
              spacing={2}
              size={9}
              sx={{ flexDirection: "row-reverse", alignItems: "center" }}
            >
              {showAdminPanel && (
                <Grid size={3}>
                  <ErrorBoundary code="AP">
                    <IconButton sx={{ padding: 1 }} onClick={() => setShowAdminModal(true)}>
                      <BugReportIcon />
                    </IconButton>
                  </ErrorBoundary>
                </Grid>
              )}
              <Grid size={2}>
                <Link
                  sx={{ alignItems: "center", display: "flex", color: "rgba(0, 0, 0, 0.54)" }}
                  isRouterLink={false}
                  target="_blank"
                  to="https://www.instagram.com/skiddlepromoters/"
                >
                  <InstagramIcon />
                </Link>
              </Grid>
              <Grid size={2}>
                <Link
                  sx={{ alignItems: "center", display: "flex", color: "rgba(0, 0, 0, 0.54)" }}
                  isRouterLink={false}
                  target="_blank"
                  to="https://www.linkedin.com/company/skiddle/"
                >
                  <LinkedInIcon />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}
        {showAdminPanel && (
          <Modal
            header={
              <>
                <Typography variant="body1">Debug tools</Typography>
                <Typography variant="caption">
                  App version: <AppVersion typography={false} /> | API version:{" "}
                  {Store.getState().apiVersion.version} | Promoter ID:{" "}
                  {Number(mainPromoter?.promoterId)}{" "}
                  {localStorage.getItem("loggedInAsPromoterId") &&
                    `(Logged in as: ${localStorage.getItem("loggedInAsPromoterId")})`}
                </Typography>
              </>
            }
            open={showAdminModal}
            onClose={onAdminPanelClose}
            maxWidth="lg"
            contentProps={{
              sx: { height: "100vh", padding: isMobile ? "8px" : "20px 24px" },
            }}
          >
            <Suspense fallback={<LoadingIcon />}>
              <AdminPanel onClose={onAdminPanelClose} />
            </Suspense>
          </Modal>
        )}
      </SidebarContent>
      {!isMdDown && (
        <CollapseButton
          className="sidebar-collapse-button"
          $currentDrawerWidth={currentDrawerWidth}
          $drawerWidth={drawerWidth}
          $closedDrawerWidth={closedDrawerWidth}
          $drawerTransitionTime={drawerTransitionTime}
          $sidebarOpen={sidebarOpen}
          onClick={() => setSidebarOpen(prev => !prev)}
        >
          {sidebarOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
        </CollapseButton>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
