import { DateTime, Settings } from "luxon";
import merge from "lodash/merge.js";
import formatOrdinals from "./formatOrdinals";
import { DATE_PICKER_FORMAT } from "../Constants/luxonDateFormats";

/**
 * @function getDateTime - A function which returns a Luxon DateTime
 *
 * @param {string} dt - A date/time string
 */
export const getDateTime = (dt, format = null) => {
  if (DateTime.fromISO(dt).isValid) {
    return DateTime.fromISO(dt);
  }

  if (DateTime.fromSQL(dt).isValid) {
    return DateTime.fromSQL(dt);
  }

  if (DateTime.fromJSDate(dt).isValid) {
    return DateTime.fromJSDate(dt);
  }

  if (Number.isInteger(dt) && DateTime.fromSeconds(dt).isValid) {
    return DateTime.fromSeconds(dt);
  }

  if (format) {
    return DateTime.fromFormat(dt, format);
  }

  return DateTime.invalid("Invalid datetime");
};

const validateTime = time => {
  if (time["24hr"] && time.meridiem) {
    throw new Error(
      "You have asked for 24 hour time AND to show meridiem (AM/PM), which will result in times such as 19:00pm. 24 hour time does not need to show the meridiem.",
    );
  }

  if (!time["24hr"] && !time.meridiem) {
    throw new Error("You must pass meridiem: true when using 12 hour time.");
  }
};

const buildTime = time => {
  let t = "h:mm";

  // Is 24 hour time
  if (time["24hr"]) {
    t = "H:mm";
  }

  if (time.seconds) {
    t += ":ss";
  }

  // Only show meridiem if time is 12hr
  if (time.meridiem && !time["24hr"]) {
    t += "a";
  }

  return t;
};

export class DateClass {
  constructor(dateConstructor, name = null, format = DATE_PICKER_FORMAT) {
    Settings.defaultZone = "Europe/London";
    Settings.defaultLocale = "en-GB";

    this.name = name;

    // If someone passes a luxon DateTime through
    // for whatever reason, just assign to this.date
    if (dateConstructor?.isLuxonDateTime) {
      this.date = dateConstructor;
    } else if (dateConstructor) {
      // If an actual date is passed in, convert it to Luxon
      this.date = getDateTime(dateConstructor, format);
    } else {
      // If no date is passed, default to new Date()
      this.date = getDateTime(new Date());
    }

    this.units = ["milliseconds", "seconds", "minutes", "hours", "days", "months", "years"];
  }

  /**
   * @function formatUserDate - Returns a formated user date time, by default
   * (Wednesday 13th April 2022 15:46)
   * @param date - A Luxon DateTime instance
   * @param options.day - { show: bool| default = true, short: bool | default = false }
   * @param options.month - { show: bool | default = true, short: bool | default = false }
   * @param options.year - { show: bool | default = true, short: bool | default = false }
   * @param options.time - { show: bool }
   *
   */
  formatUserDate(options) {
    const defaultOptions = {
      weekday: { show: true, short: false },
      day: { show: true, short: false },
      month: { show: true, short: false },
      year: { show: true, short: false },
      time: { show: false, meridiem: true, "24hr": false, seconds: false, separator: false },
    };

    if (options?.time?.["24hr"]) {
      defaultOptions.time = { ...defaultOptions.time, meridiem: false };
    }

    // Merge user-passed options with the defaults
    const { weekday, day, month, year, time } = merge(defaultOptions, options);
    const formattedWeekday = day.short ? "EEE" : "EEEE";
    const formattedDay = formatOrdinals(this.date.c.day);

    const formattedMonth = month.short ? "MMM" : "MMMM";
    const formattedYear = year.short ? "yy" : "yyyy";

    let toReturn = "";

    // Build the final format string
    if (weekday.show) {
      toReturn += `${formattedWeekday} `;
    }

    if (day.show) {
      toReturn += `'${formattedDay}' `;
    }

    if (month.show) {
      toReturn += `${formattedMonth} `;
    }

    if (year.show) {
      toReturn += `${formattedYear} `;
    }

    if (time.show) {
      validateTime(time);
      const t = buildTime(time);

      if (time.separator) {
        toReturn += `'${time.separator}' ${t}`;
      } else {
        toReturn += t;
      }
    }

    return this.date.toFormat(toReturn.trim());
  }

  /**
   * @function formatDate - Returns a formatted datetime string
   * @param {String} date - A date string
   * @param {String | optional} format - a date/time format, uses ISO if
   * no format passed
   */
  format(format) {
    if (!format) {
      return this.formatUserDate();
    }

    return this.date.toFormat(format);
  }

  diff(otherDate, unit = "minutes", truncate = false) {
    const d = this.date.isValid ? this.date : getDateTime(new Date());

    let od = otherDate;
    if (
      !otherDate?.isLuxonDateTime &&
      !otherDate?.date?.isLuxonDateTime &&
      !otherDate?.date?.date?.isLuxonDateTime
    ) {
      od = getDateTime(otherDate);
    }

    if (otherDate?.date?.date?.isLuxonDateTime) {
      od = otherDate.date.date;
    }

    if ((od?.date && !od.date.isValid) || ("isValid" in od && !od.isValid)) {
      od = getDateTime(new Date());
    }

    if (truncate) {
      const val = d.diff(od.date || od, unit).values[unit];
      return val < 0 && val > -1 ? 0 : Math.trunc(val);
    }

    return d.diff(od.date || od, unit).values[unit];
  }

  plus(duration, unit = "minutes") {
    this.date = this.date.plus({ [unit]: duration });
    return this;
  }

  minus(duration, unit = "minutes") {
    this.date = this.date.minus({ [unit]: duration });
    return this;
  }

  startOf(unit) {
    this.date = this.date.startOf(unit);
    return this;
  }

  endOf(unit) {
    this.date = this.date.endOf(unit);
    return this;
  }

  isSame(otherDate, unit = "minutes") {
    let od = otherDate;
    if (!otherDate?.isLuxonDateTime && !otherDate?.date?.isLuxonDateTime) {
      od = getDateTime(otherDate);
    }

    if ((od?.date && !od.date.isValid) || ("isValid" in od && !od.isValid)) {
      od = getDateTime(new Date());
    }

    const unitsToCheckStartIndex = this.units.indexOf(unit);
    const unitsToCheck = this.units.slice(unitsToCheckStartIndex, this.units.length);

    let isSame = true;
    unitsToCheck.forEach(u => {
      const diff = this.diff(od, u);
      if (diff < 0 && Math.floor(diff * -1) !== 0) {
        isSame = false;
      }

      if (diff > 0 && Math.floor(diff) !== 0) {
        isSame = false;
      }
    });

    return isSame;
  }

  hasSame(otherDate, unit = "day") {
    let od = otherDate;
    if (!otherDate?.isLuxonDateTime && !otherDate?.date?.isLuxonDateTime) {
      od = getDateTime(otherDate);
    }

    if ((od?.date && !od.date.isValid) || ("isValid" in od && !od.isValid)) {
      od = getDateTime(new Date());
    }
    return this.date.hasSame(od.date, unit);
  }

  isSameOrBefore(otherDate, unit = "minutes") {
    let od = otherDate;
    if (!otherDate?.isLuxonDateTime && !otherDate?.date?.isLuxonDateTime) {
      od = getDateTime(otherDate);
    }

    if ((od?.date && !od.date.isValid) || ("isValid" in od && !od.isValid)) {
      od = getDateTime(new Date());
    }

    const isSame = this.isSame(od, unit);

    return isSame || this.diff(od, unit) <= -1;
  }

  isSameOrAfter(otherDate, unit = "minutes") {
    let od = otherDate;
    if (!otherDate?.isLuxonDateTime && !otherDate?.date?.isLuxonDateTime) {
      od = getDateTime(otherDate);
    }

    if ((od?.date && !od.date.isValid) || ("isValid" in od && !od.isValid)) {
      od = getDateTime(new Date());
    }

    const isSame = this.isSame(od, unit);

    return isSame || this.diff(od, unit) >= 1;
  }

  isBefore(otherDate, unit = "minutes") {
    let od = otherDate;
    if (!otherDate?.isLuxonDateTime && !otherDate?.date?.isLuxonDateTime) {
      od = getDateTime(otherDate);
    }

    if ((od?.date && !od.date.isValid) || ("isValid" in od && !od.isValid)) {
      od = getDateTime(new Date());
    }

    return this.diff(od, unit) <= -1;
  }

  isAfter(otherDate, unit = "minutes") {
    let od = otherDate;
    if (!otherDate?.isLuxonDateTime && !otherDate?.date?.isLuxonDateTime) {
      od = getDateTime(otherDate);
    }

    if ((od?.date && !od.date.isValid) || ("isValid" in od && !od.isValid)) {
      od = getDateTime(new Date());
    }
    return this.diff(od, unit) >= 1;
  }

  set(values) {
    this.date = this.date.set(values);
    return this;
  }

  toUnixInteger() {
    return this.date.toUnixInteger();
  }

  toISO() {
    return this.date.toISO();
  }

  toSeconds() {
    return this.date.toSeconds();
  }

  toUTC() {
    this.date = this.date.toUTC();
    return this;
  }

  toJSDate() {
    return this.date.toJSDate();
  }
}

export const isDateTimeInstance = val => val instanceof DateClass;

const date = (dt, format = null, name = null) => {
  if (dt instanceof DateClass) {
    return new DateClass(dt.toISO(), name, format);
  }

  if (dt?.date instanceof DateClass) {
    return new DateClass(dt.date.toISO(), name, format);
  }

  if (dt instanceof DateTime) {
    return new DateClass(dt.toISO(), name, format);
  }

  return new DateClass(dt, name, format);
};

export const isValidDate = value => getDateTime(value) !== "Invalid DateTime";

export default date;
