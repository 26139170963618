import { styled, Chip, ListItem, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { darken, rgba } from "polished";

const getFactoredFontSize = (fontSize, hierarchy = 1) => {
  let size = Math.floor(Number(fontSize.split("rem")[0]));

  const factor = 0.1;
  const type = "rem";

  if (hierarchy) {
    size -= factor * hierarchy;
  }

  return `${size}${type};`;
};

export const Category = styled(ListItem)`
  cursor: pointer;
  padding-top: ${props => props.theme.spacing(3)};
  padding-bottom: ${props => props.theme.spacing(3)};
  padding-left: ${props => props.theme.spacing(5)};
  padding-right: ${props => props.theme.spacing(5)};
  font-weight: ${props => props.theme.typography.fontWeightRegular};
  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
  ${({ $sub }) => {
    if (!$sub) {
      return `
        &:hover {
          background: rgba(0, 0, 0, 0.08);
        }`;
    }
    return "";
  }}
  &.${props => props.$activeClassName} {
    background-color: ${props => darken(0.05, props.theme.sidebar.background)};
    span {
      color: ${props => props.theme.sidebar.color};
    }
  }

  ${({ $sub }) => {
    if ($sub) {
      return `
        padding-left: 0;
      `;
    }
    return "";
  }}
`;

export const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-weight: ${props => props.theme.sidebar.category.fontWeight};
    padding: 0 ${props => props.theme.spacing(4)};

    font-size: ${({
      hierarchy,
      theme: {
        typography: {
          h6: { fontSize },
        },
      },
    }) => getFactoredFontSize(fontSize, hierarchy)};

    ${({ $sub, theme }) => {
      if ($sub) {
        return `
          font-size: ${theme.typography.body2.fontSize};
          padding: 0;
          padding-left: 60px;
        `;
      }
      return "";
    }}
  }
`;

export const CategoryDropdownSymbol = styled(Box)`
  color: ${props => props.theme.sidebar.color};
  transition: ${({ $drawerTransitionTime }) => `opacity ${$drawerTransitionTime}`};
  opacity: ${({ $sidebarOpen }) => ($sidebarOpen ? 0.5 : 0)};
  display: flex;
`;

export const Link = styled(ListItem)`
  white-space: nowrap;
  ${({ $underlined }) => $underlined && "text-decoration: underline;"};
  padding-left: ${props => props.$paddingLeft || props.theme.spacing(15)};
  padding-top: ${props => props.$paddingTop || props.theme.spacing(2)};
  padding-bottom: ${props => props.$paddingBottom || props.theme.spacing(2)};
  &.${props => props.$activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};
    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }
  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }
  &:hover {
    ${({ isHeader, theme }) => {
      if (isHeader) {
        return `
          background: transparent;
          cursor: default;
          span {
            color: ${rgba(theme.sidebar.color, 0.7)} !important;
          }
        `;
      }
      return null;
    }}
  }
`;

export const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    ${({ style }) => style};
    font-size: ${({
      hierarchy,
      theme: {
        typography: {
          h6: { fontSize },
        },
      },
    }) => getFactoredFontSize(fontSize, hierarchy)};
  }
  margin-top: 0;
  margin-bottom: 0;
`;

export const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};
  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)};
    padding-right: ${props => props.theme.spacing(2)};
  }
`;

export const StyledListItem = styled(ListItem)`
  padding-left: ${props => props.theme.spacing(15)};
  padding-top: ${props => props.theme.spacing(2)};
  padding-bottom: ${props => props.theme.spacing(2)};
  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }
  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }
  &.${props => props.$activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};
    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;
