import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { signOut } from "aws-amplify/auth";

import { CssBaseline } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router";
import useAuth from "../NamedComponents/Auth/hooks/useAuth";
import { warning } from "../../Utils/toast";
import ErrorBoundary from "./ErrorBoundary";
import onRouteChange from "../../Utils/onRouteChange";
import { AppContext, AppContextType } from "../../Context";
import MaintenanceSplash from "../Reusable/MaintenanceSplash";
import AuthRoot from "./AuthRoot";
import AuthWrapper from "./AuthWrapper";
import warningToast, {
  cancelClick,
  defaultCountdown,
  updateWarning,
} from "../Reusable/warningToast";

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const [inMaintenance, setInMaintenance] = useState(false);
  const { isAuthenticated, loading } = useAuth();
  const [loadingLocal, setLoadingLocal] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const context = useContext<AppContextType | null>(AppContext);
  const appVersion = context?.appVersion ?? "unknown";

  const [countdown, setCountdown] = useState(defaultCountdown);

  const fullPath = `${location.pathname}${location.search}`;

  useEffect(() => {
    const logout = async () => signOut();
    if (!isAuthenticated && !location.pathname.includes("/auth")) {
      const attemptedPage = `${location.pathname}${location.search}`;
      warning("Your session has expired, please sign in again.");
      logout();
      localStorage.clear();
      navigate("/auth/login", { state: { attemptedPage } });
      setLoadingLocal(false);
    } else {
      setLoadingLocal(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    async function onLoad() {
      const { hasNewVersion, isInMaintenance } = await onRouteChange(appVersion);
      setInMaintenance(isInMaintenance);

      // Version is state is out of sync with version in version.txt
      if (hasNewVersion) {
        const toast = warningToast(countdown, () => cancelClick(interval, setCountdown));

        interval = setInterval(() => {
          setCountdown(prevState => {
            if (prevState - 100 >= 0) {
              // Update the toast content so the countdown is dynamic
              updateWarning(toast.toastId, toast, prevState, () =>
                cancelClick(interval, setCountdown),
              );

              return prevState - 100;
            }

            // If the user hasn't cancelled the operation, reload the page
            window.location.reload();
            return prevState;
          });
        }, 100);
      }
    }

    onLoad();

    return () => clearInterval(interval);
  }, [fullPath]);

  if (inMaintenance) {
    return <MaintenanceSplash />;
  }

  if (loading || loadingLocal) {
    return null;
  }

  return (
    <AuthRoot data-testid="auth-layout-container">
      <CssBaseline />
      <ErrorBoundary code="Main-auth">
        <AuthWrapper>{children || <Outlet />}</AuthWrapper>
      </ErrorBoundary>
    </AuthRoot>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
export default AuthLayout;
