import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import Modal from "./Modal";

const RecurringEventRedirectModal = ({
  header,
  secondary,
  primary,
  showModal,
  setShowModal,
  content,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      header={header}
      actions={
        <>
          {secondary && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                if (secondary.url) {
                  navigate(secondary.url);
                }
                setShowModal(false);
              }}
            >
              {secondary.label}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(primary.url);
              setShowModal(false);
            }}
          >
            {primary.label}
          </Button>
        </>
      }
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      {content}
    </Modal>
  );
};

RecurringEventRedirectModal.propTypes = {
  header: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string])
    .isRequired,
  primary: PropTypes.shape({ label: PropTypes.string, url: PropTypes.string }).isRequired,
  secondary: PropTypes.shape({ label: PropTypes.string, url: PropTypes.string }).isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default RecurringEventRedirectModal;
