import { useContext } from "react";
import { AppContext } from "../Context";
import { closedDrawerWidth, drawerWidth } from "../Constants/layout";

const useSidebarWidth = () => {
  const { sidebarOpen } = useContext(AppContext);
  return sidebarOpen ? `${drawerWidth}px` : `${closedDrawerWidth}px`;
};

export default useSidebarWidth;
