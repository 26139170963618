import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { styled, Button, DialogContentText, IconButton as MuiIconButton } from "@mui/material";
import { useCookies } from "react-cookie";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import Modal from "./Modal";
import { AppContext, AppContextType } from "../../Context";
import ButtonAsLink from "./ButtonAsLink";

import { HelpTip } from "./types/helpTip.type";

const IconButton = styled(MuiIconButton)`
  padding: 5px;
`;

const string = "promotioncentre-tips";

const Help = ({
  tip,
  modalProps = {},
  contentContext = {},
  preventPermanentDismissal = false,
  inline = false,
  label = "",
  initialOpen = false,
  ...rest
}: {
  tip: HelpTip;
  modalProps?: { maxWidth?: string };
  contentContext?: object;
  preventPermanentDismissal?: boolean;
  inline?: boolean;
  label?: string;
  initialOpen?: boolean;
}) => {
  const [open, setOpen] = useState(initialOpen);
  const [cookies, setCookie] = useCookies([string]);
  const sanitisedName = tip.name.replace(/[\W_]+/g, "").toLowerCase();
  const [showTip, setShowTip] = useState(
    !cookies[string] || (cookies[string] && !cookies[string][sanitisedName]),
  );

  const context = useContext<AppContextType | null>(AppContext);
  const currentEvent = context?.currentEvent ?? {};

  const handleCookie = () => {
    const current = cookies[string] ?? {};
    const newVal = { ...current, [sanitisedName]: true };

    setCookie(string, newVal, {
      path: "/",
      expires: new Date(Date.now() + 1000 * 365 * 24 * 60 * 60 * 1000), // 1000 years from now
    });

    setOpen(false);
    setShowTip(false);
  };

  if (!showTip) {
    return null;
  }

  return (
    <>
      {inline ? (
        <ButtonAsLink sx={{ paddingBottom: "3px" }} {...rest} onClick={() => setOpen(true)}>
          {label || "Help"}
        </ButtonAsLink>
      ) : (
        <IconButton
          onClick={() => setOpen(true)}
          size="large"
          aria-label={`Help tip: ${tip.name}`}
          {...rest}
        >
          <HelpOutlineIcon titleAccess="Help" fontSize="small" />
        </IconButton>
      )}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        header={tip.name}
        actions={
          <>
            {!preventPermanentDismissal && (
              <Button variant="outlined" color="secondary" onClick={handleCookie}>
                Don&apos;t show again
              </Button>
            )}

            <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
              Close
            </Button>
          </>
        }
        {...modalProps}
      >
        <DialogContentText>{tip.content(currentEvent, contentContext)}</DialogContentText>
      </Modal>
    </>
  );
};

Help.propTypes = {
  tip: PropTypes.objectOf(PropTypes.any).isRequired,
  modalProps: PropTypes.objectOf(PropTypes.any),
  contentContext: PropTypes.objectOf(PropTypes.any),
  preventPermanentDismissal: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string,
  initialOpen: PropTypes.bool,
};

export default Help;
