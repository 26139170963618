import React from "react";
import PropTypes from "prop-types";
import { styled, IconButton as MuiIconButton, Tooltip, Typography } from "@mui/material";
import LoadingIcon from "./LoadingIcon";

const IconButton = styled(MuiIconButton)`
  flex-direction: column;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 0;
`;

const IconText = styled(Typography)`
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 2px;
`;

const IconWithText = ({
  icon,
  title,
  color = null,
  onClick = () => {},
  loading = false,
  hoverColor = null,
  disabledReason = null,
  ...props
}) => {
  if (props.disabled && disabledReason) {
    return (
      <Tooltip title={disabledReason}>
        <span>
          <IconButton
            aria-label={title}
            onClick={e => onClick(e)}
            size="large"
            {...props}
            sx={{ color, ":hover": { color: hoverColor } }}
          >
            {loading ? <LoadingIcon size={24} height={null} /> : icon}
            <IconText>{title}</IconText>
          </IconButton>
        </span>
      </Tooltip>
    );
  }
  return (
    <IconButton
      aria-label={title}
      onClick={e => onClick(e)}
      size="large"
      {...props}
      sx={{ color, ":hover": { color: hoverColor } }}
    >
      {loading ? <LoadingIcon size={24} height={null} /> : icon}
      <IconText>{title}</IconText>
    </IconButton>
  );
};

IconWithText.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  hoverColor: PropTypes.string,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
};
export default IconWithText;
