import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "regenerator-runtime/runtime";
import { useIntercom } from "react-use-intercom";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { useNetworkState, usePreferredLanguage } from "@uidotdev/usehooks";
import { useDispatch } from "react-redux";
import { fetchAuthSession } from "aws-amplify/auth";

import "./LayoutStructure.scss";
import "react-toastify/dist/ReactToastify.min.css";

import ErrorBoundary from "./Components/Layout/ErrorBoundary";

import LoadingIcon from "./Components/Reusable/LoadingIcon";
import useAuth from "./Components/NamedComponents/Auth/hooks/useAuth";
import useAuthMainPromoter from "./Components/NamedComponents/Auth/hooks/useAuthMainPromoter";
import { AppContext } from "./Context";
import SessionExpiredModal from "./Components/Layout/SessionExpiredModal";
import router from "./router";
import getVersion from "./Utils/getVersion";
import Store from "./Store";
import { setNetwork } from "./Store/reducers/NetworkReducer";

const StyledToastContainer = styled(ToastContainer)`
  ${({ theme }) => ({ ...theme.typography.body2 })};
  margin-top: 50px;
`;

function getPrefersReducedMotion() {
  const QUERY = "(prefers-reduced-motion: no-preference)";
  const mediaQueryList = window.matchMedia(QUERY);
  return !mediaQueryList.matches;
}

const fullScreenDefault = Boolean(Number(localStorage.getItem("pc_fullscreen"))) || false;

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const App = () => {
  const language = usePreferredLanguage();
  const network = useNetworkState();
  const dispatch = useDispatch();
  const { boot } = useIntercom();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFullscreen, setIsFullscreen] = useState(fullScreenDefault);
  const [appMaxWidth, setAppMaxWidth] = useState({ width: 1400 });
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const [showModal, setShowModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [appVersion, setAppVersion] = useState();
  const mainPromoter = useAuthMainPromoter();
  const [currentEvent, setCurrentEvent] = useState(false);
  const { loading } = useAuth();

  const [isLoggedIn, setIsLoggedIn] = useState();

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const session = await fetchAuthSession();
      setIsLoggedIn(Boolean(session && session.credentials));
    };

    checkIsLoggedIn();
  }, [window.location.pathname]);

  useEffect(() => {
    if (network) {
      dispatch(setNetwork(network));
    }
  }, [network]);

  useEffect(() => {
    if (language) {
      localStorage.setItem("USER_LOCALE", language);
    }
  }, [language]);

  useEffect(() => {
    async function onLoad() {
      const versionText = await getVersion();

      setAppVersion(versionText || "");
    }

    if (!appVersion) {
      onLoad();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      boot({
        name: `${mainPromoter?.firstname || ""} ${mainPromoter?.surname || ""}`,
        email: mainPromoter?.email,
        userId: mainPromoter?.promoterId,
        customAttributes: {
          product: "promotioncentre",
          hasUsedBeta: true,
        },
      });
    }, 5000);
  });

  const appRouter = sentryCreateBrowserRouter(
    router(Store.getState().auth?.promoter?.permissions, Store.getState().auth?.loading),
  );

  const contextValues = React.useMemo(
    () => ({
      isMobile,
      currentEvent,
      setCurrentEvent,
      prefersReducedMotion: getPrefersReducedMotion(),
      showAdminModal,
      setShowAdminModal,
      isFullscreen,
      setIsFullscreen,
      appMaxWidth,
      setAppMaxWidth,
      sidebarOpen,
      setSidebarOpen,
      appVersion,
      setAppVersion,
      isLoggedIn,
    }),
    [
      isMobile,
      currentEvent,
      showAdminModal,
      isFullscreen,
      appMaxWidth,
      appVersion,
      sidebarOpen,
      isLoggedIn,
    ],
  );

  if (loading || !appVersion) {
    return <LoadingIcon debugName="app" />;
  }

  return (
    <>
      <Helmet titleTemplate="%s | Promotion Centre" defaultTitle="Promotion Centre" />
      <StyledToastContainer autoClose={3800} newestOnTop className="toaster-container" />
      <ErrorBoundary code="App">
        <AppContext.Provider value={contextValues}>
          <RouterProvider router={appRouter} />
          <SessionExpiredModal showModal={showModal} setShowModal={setShowModal} />
        </AppContext.Provider>
      </ErrorBoundary>
    </>
  );
};

export default App;
