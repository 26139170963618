import React from "react";
import { Helmet } from "react-helmet";

const GooglePixel = () => {
  return (
    <Helmet>
      {/* Marketing pixel */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-926715938"></script>
      <script id="google-pixel-script">{`<!-- Google tag (gtag.js) -->
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "AW-926715938");`}</script>

      {/* Dev/analytics pixel */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-NYTE8GTGLK"></script>
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
            dataLayer.push(arguments);
            }
            gtag("js", new Date());

            gtag("config", "G-NYTE8GTGLK");
        `}
      </script>
    </Helmet>
  );
};

export default GooglePixel;
