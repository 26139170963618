import * as Yup from "yup";
import lazy from "../../../../../Utils/lazy";

const AutoStartTicketId = lazy(() => import("../../FieldComponents/AutoStartTicketId"));

const autoStartTicketId = {
  name: "autoStartTicketId",
  hidden: formValues => formValues.startMode !== "chained",
  validation: () =>
    Yup.string().when("startMode", {
      is: "chained",
      then: schema => schema.required("You must select a ticket"),
    }),
  displayName: () => "Ticket",
  sendToApi: (val, values) => values.startMode === "chained",
  advancedOption: false,
  type: AutoStartTicketId,
  placeholder: "Ticket",
};

export default autoStartTicketId;
