/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LOGGED_IN_AS_PROMOTER_ID } from "../../Constants/auth";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: undefined,
    promoter: {},
    isAuthenticated: false,
    loading: false,
  },
  reducers: {
    userLoggedIn: (state, action) => {
      const promoterId =
        action.payload.promoters &&
        Object.keys(action.payload.promoters).find(p => action.payload.promoters[p].mainAccount);

      let promoterInfo = {};

      if (action.payload.promoters) {
        if (
          localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID) &&
          action.payload.promoters[localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID)]
        ) {
          promoterInfo = {
            promoterId: localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID),
            ...action.payload.promoters[localStorage.getItem(LOGGED_IN_AS_PROMOTER_ID)],
          };
        } else {
          promoterInfo = {
            promoterId,
            ...action.payload.promoters[promoterId],
          };
        }
      }

      state.userData = action.payload;
      state.isAuthenticated = true;
      state.promoter = promoterInfo;
    },
    userLoggedOut: state => {
      state.userData = undefined;
      state.isAuthenticated = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setInnerLoading: (state, action) => {
      state.innerLoading = action.payload;
    },
    updateCurrentPromoter: (state, action) => {
      state.userData = {
        ...state.userData,
        promoters: {
          ...state.userData.promoters,
          [action.payload.promoterId]: { ...action.payload, active: true },
        },
      };

      state.promoter = { ...action.payload };
    },
    removePromoter: (state, action) => {
      const newPromoters = { ...state.userData.promoters };
      delete newPromoters[action.payload];

      state.userData = {
        ...state.userData,
        promoters: newPromoters,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userLoggedIn,
  userLoggedOut,
  setLoading,
  updateCurrentPromoter,
  removePromoter,
  setInnerLoading,
} = authSlice.actions;

export default authSlice.reducer;
