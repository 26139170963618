import React from "react";
import { Route, Routes } from "react-router";
import Page404 from "../../Components/Layout/ErrorPages/Page404";

import Layout from "../../Components/Layout/Layout";
import ProtectedRoute from "../../Components/NamedComponents/Auth/Components/ProtectedRoute";

import isFeatureFlagOn from "../checkFeatureFlag";
import { checkUserPermission, userRoles } from "../permissions";

// The deafault options for the routes
export const routeDefaults = {
  suspense: true,
  protectedRoute: true,
  Layout,
  ownerOnly: false,
  featureFlag: false,
  permissions: false,
};

const renderChildRoute = (path, ChildComponent, permission, promoterPermissions) => {
  if (!permission || (permission && checkUserPermission(promoterPermissions, permission))) {
    return <Route key={path} path={path} element={<ChildComponent />} />;
  }

  // Doesn't have permission
  return <Route key={path} path={path} element={<Page404 />} />;
};

/**
 * @function renderChildRoutes - If a route has child-routes, this is the function
 * which renders them. E.g. /events is the top level route, /events/:id or /events/create
 * are child-routes.
 *
 * @param {Array | required} children - The array of child-routes
 * @param {React.Component | optional} Component - If the top level route needs to render
 * a component around the children, include it here. E.g. /finance does a top level http
 * request to get the selfBillRequired boolean, so we need to include the <Finance />
 * component here to facilitate that.
 */
const renderChildRoutes = (children, Component, promoterPermissions) => {
  // If it has a Component AND children, render the Component
  // and then the children within it
  if (Component) {
    return (
      <Component key={children.map(child => child.path).join(".")}>
        <Routes>
          {children.map(({ path, Component: ChildComponent, featureFlag, permissions }) =>
            featureFlag && !isFeatureFlagOn(featureFlag) ? (
              <Route key={path} path={path} element={<Page404 />} />
            ) : (
              renderChildRoute(path, ChildComponent, permissions, promoterPermissions)
            ),
          )}
        </Routes>
      </Component>
    );
  }

  // Just children, no parent component
  return (
    <Routes key={children.map(child => child.path).join(".")}>
      {children.map(({ path, Component: ChildComponent, featureFlag, permissions }) =>
        featureFlag && !isFeatureFlagOn(featureFlag) ? (
          <Route key={path} path={path} element={<Page404 />} />
        ) : (
          renderChildRoute(path, ChildComponent, permissions, promoterPermissions)
        ),
      )}
    </Routes>
  );
};

/**
 * @function renderRoute - This is the main function called by <Routes>. Renders
 * top level routes, and calls @renderChildRoutes for any routes with children.
 *
 * @param {Boolean | optional | default = true} protectedRoute - Whether or not
 * a <ProtectedRoute /> should be rendered around this route. Defaults to true so
 * can be ommitted from the config.
 * @param {Boolean | optional | default = false} routePermissions - The bitwise number
 * for the route permissions. Omit from config if no permissions are needed.
 * @param {React.Component | optional} Component - If there are no child routes this
 * will just be the component the route should render. If there are children this needs
 * to be a component which renders {children} at some point to make sure it renders the
 * child-routes.
 * @param {Array | optional | default = false} children - An array of child-routes
 * @param {String | optional | default = false} featureFlag - If the route needs feature
 * flagging, include the constant here.
 * @param {Boolean | optional | default = false} ownerOnly - If the route should be owner-only
 */
export const renderRoute = (
  protectedRoute,
  routePermissions,
  Component,
  children,
  featureFlag,
  ownerOnly,
  promoterPermissions,
) => {
  const hasChildren = Boolean(children && children.length);

  // If there is a feature flag, and it's off, return 404
  if (featureFlag && !isFeatureFlagOn(featureFlag)) {
    return <Page404 />;
  }

  // If it's a protected route but doesn't need to check permissions
  if (protectedRoute && !routePermissions) {
    return (
      <ProtectedRoute>
        {hasChildren ? renderChildRoutes(children, null, promoterPermissions) : <Component />}
      </ProtectedRoute>
    );
  }

  // Protected route AND permissions
  if (protectedRoute && routePermissions) {
    return (
      <ProtectedRoute routePermissions={userRoles[routePermissions]}>
        {hasChildren ? renderChildRoutes(children, Component, promoterPermissions) : <Component />}
      </ProtectedRoute>
    );
  }

  // Protected, owner-only route
  if (protectedRoute && ownerOnly) {
    return (
      <ProtectedRoute ownerOnly>
        {hasChildren ? renderChildRoutes(children, Component, promoterPermissions) : <Component />}
      </ProtectedRoute>
    );
  }

  // Normal, non-protected, non-permissioned route
  return hasChildren ? renderChildRoutes(children, Component, promoterPermissions) : <Component />;
};
