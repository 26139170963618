import * as Yup from "yup";

import date from "../../../../../Utils/date";
import { SHORT_DAY_USER } from "../../../../../Constants/luxonDateFormats";
import { postFormatDateTime } from "../../../../../Utils/ticketHelpers";
import lazy from "../../../../../Utils/lazy";

const InputDateTime = lazy(
  () => import(/* webpackChunkName: "InputDateTime"  */ "../../../../Reusable/Atoms/InputDateTime"),
);

const offSaleDateTime = {
  name: "offSaleDateTime",
  hidden: formValues => formValues.stopMode.value !== "datetime",
  displayName: () => "Off sale date & time",
  sendToApi: (val, values) => values.stopMode.value === "datetime",
  advancedOption: false,
  type: InputDateTime,
  defaultValue: { date: "", time: "", value: "" },
  postFormat: val => postFormatDateTime(val),
  validation: globalForm => {
    return Yup.object()
      .when(
        ["stopMode", "onSaleDateTime", "startMode"],
        ([stopM, onSale, currStartMode], schema) => {
          if (stopM.value !== "datetime") {
            return Yup.object({
              date: Yup.object().nullable(),
              time: Yup.string().nullable(),
              value: Yup.string(),
            });
          }

          return schema.shape({
            date: Yup.object().required("Date is required").nullable(),
            time: Yup.string().required("Time is required").nullable(),
            value: Yup.string()
              .required("Off sale date/time is required")
              .test(
                "embargo-after-event-end-date-test",
                `Please choose a date & time before your event end time of ${date(
                  globalForm.values.endDateTime.value,
                ).formatUserDate({ ...SHORT_DAY_USER, time: { show: true, separator: "," } })}`,
                value => date(value).isSameOrBefore(date(globalForm.values.endDateTime.value)),
              )
              .test(
                "after-on-sale",
                "Date and time should be after your onsale date and time",
                value => {
                  if (onSale.value && currStartMode === "datetime") {
                    return date(value).isAfter(date(onSale.value), "minutes");
                  }
                  return true;
                },
              )
              .test("after now", "Date must be in the future", value =>
                date(value).isAfter(date()),
              ),
          });
        },
      )
      .nullable();
  },
  group: "Stop selling tickets",
};

export default offSaleDateTime;
