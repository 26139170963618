import React from "react";
import Link from "../../../../Reusable/Link";
import lazy from "../../../../../Utils/lazy";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);

const repCommissionType = {
  name: "repCommissionType",
  displayName: (globalForm, context, form) => {
    return (
      <>
        We will automatically reward {Math.round(form.values.ticketPrice * 10)} points when a rep
        sale is tracked for this ticket, which can be used for Rep Rewards. If you wish to add a
        cash commission, please set this below.
        <br />
        <br />
        {context.event.defaultRepCommissionPercentage &&
          form.values?.repCommissionType !== "none" && (
            <>
              You currently have a default rep commission set of{" "}
              {parseFloat(context.event.defaultRepCommissionPercentage)}%, which will be reflected
              below. If you wish to change your default rep commission please{" "}
              <Link to={`/events/${context?.eventId}/reps?tab=4&setDefaultCommission=1`}>
                click here
              </Link>
              .
            </>
          )}
        {!context.event.defaultRepCommissionPercentage && (
          <>
            If you would like to set a default rep commission, which will be automatically applied
            to any new tickets where the rep commission field is set to &#39;Fixed price per ticket
            sale&#39;, please{" "}
            <Link to={`/events/${context?.eventId}/reps?tab=4&setDefaultCommission=1`}>
              click here
            </Link>
            .
          </>
        )}
      </>
    );
  },
  type: InputRadio,
  defaultValue: event => (event?.defaultRepCommissionPercentage ? "fixed" : "none"),
  options: [
    { value: "fixed", label: "Fixed price per ticket sale", grid: { md: 7 } },
    { value: "none", label: "No commission available", grid: { md: 5 } },
  ],
  sendToApi: (_, formValues) => formValues.freeTicketOption === "no",
};

export default repCommissionType;
