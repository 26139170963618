import {
  basicInfo,
  entryCosts,
  repCommission,
  startSelling,
  stopSelling,
  advanced,
} from "../fields/stepFour";

export { basicInfo, entryCosts, repCommission, startSelling, stopSelling, advanced };

export const allTicketInputs = [
  basicInfo,

  entryCosts,

  repCommission,

  startSelling,
  stopSelling,

  advanced,
];
