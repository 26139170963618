import React from "react";
import { Divider, Typography } from "@mui/material";
import { PARENT } from "./events/listingTypes";
import { isFeatureFlagOn } from "../Utils";
import { featureFlags } from "../Utils/checkFeatureFlag";
import Link from "../Components/Reusable/Link";
import helpCentre, { HelpCentreLink } from "./helpCentre";
import { ONE_OFF } from "./events/eventTypes";
import links from "../Utils/links";

const addOnAllocation = (
  <Typography variant="body1">
    Allocation of add-on tickets can be one of the following:
    <ol>
      <li>
        Session allocated: allocation is used per each session on a given day in your event series.
      </li>
      <li>
        Day allocated: allocation is used per entire day of your event series, across all sessions
        for that day.
      </li>
      <li>
        Series allocated: allocation is used across your entire event series, across all days and
        sessions.
      </li>
    </ol>
  </Typography>
);

const helpTips = {
  eventsOverview: {
    name: "Your events overview",
    content: () =>
      "An overview of your combined event hits and tickets sold. You can customise the number of days or period shown using the cog icon.",
  },
  sales: {
    name: "Sales",
    content: () =>
      "Combined tickets sold and sales value for all your events. By default this shows the last 7 days but use the buttons above to change this period.",
  },
  views: {
    name: "Views",
    content: () =>
      "Combined reach and hits for all your events. Reach includes anywhere your event is showcased (e.g. events guide, search results, event page). Hits includes just visitors to your event pages. By default this shows the last 7 days but use the buttons above to change this period.",
  },
  yourEvents: {
    name: "Your events",
    content: () => (
      <>
        Access and manage your events quickly from the list below. Click an event name to view the
        full event dashboard. Use the cog icon to view event specific quick-links.
        <br />
        <br />
        By default all upcoming events are shown, but you can change this filter using the dropdown.
        <br />
        <br />
        If you&apos;d like to amend multiple events at once, select multiple events using the tick
        boxes then click &apos;Bulk Edit&apos;
      </>
    ),
  },
  eventOverview: {
    name: "Your event overview",
    content: () =>
      "Hits and tickets sold for this specific event. You can customise the number of days or period shown using the cog icon.",
  },
  shareEvent: {
    name: "Share event",
    content: () =>
      "Use this short link to share on your social media or email campaigns. The share buttons will open pre-populated share dialogs for over 50 different social networks.",
  },
  queueStats: {
    name: "Queue stats",
    content: () => (
      <>
        Live information about how many customers are in the queue during an onsale. Refreshing (and
        auto-refreshing) will update these numbers.
        <br />
        <br />
        You can enable auto-refreshing for the entire dashboard at the top right on the page.
      </>
    ),
  },
  improveYourEvent: {
    name: "Improve your event",
    content: () =>
      "The tips shown below have been generated by our recommendations engine and are specific to your event. Following the advice and improving your event will lead to higher exposure and ticket sales.",
  },
  tagArtists: {
    name: "Tag artists",
    content: () =>
      "Tag artists appearing at your event to maximise exposure. Please note, this is moderated so please only tag artists which are appearing. Do not tag tributes to the main act.",
  },
  uploadImage: {
    name: "Upload image",
    content: () => (
      <>
        You can choose to use one of our suggested images, a previously used image, or upload your
        own.
        <br />
        <br />
        When uploading, a good quality image is essential to receive the most exposure for your
        event. Events without images will not appear in some search results.
        <br />
        <ul>
          <li>Jpg/Png/Gif images accepted</li>
          <li>Maximum 20% text (no lineups)</li>
          <li>Please upload the highest quality image you have</li>
          <li>Minimum resolution 800px x 800px</li>
          <li>Maximum filesize is 30Mb</li>
        </ul>
      </>
    ),
  },
  ticketSummary: {
    name: "Ticket summary",
    content: () => (
      <>
        Manage your event tickets from here. You can add multiple tickets, organised by categories.
        All sales figures are real-time.
        <br />
        <br />
        Tick multiple tickets and select Copy to copy these tickets to a new event, saving you time
        when setting up a series of similar events.
      </>
    ),
  },
  ticketAvailability: {
    name: "Ticket availability",
    content: () => (
      <>
        When selecting &apos;password protected&apos;, you can create a password for this ticket on
        the next screen by clicking the &apos;padlock&apos; icon
      </>
    ),
  },
  fbPixel: {
    name: "Facebook Pixel",
    content: () => (
      <>
        For more information, see{" "}
        <a
          href={`${links.BLOG}/an-update-to-facebook-api-conversions/`}
          target="_blank"
          rel="noreferrer"
        >
          {links.BLOG}/an-update-to-facebook-api-conversions/
        </a>
      </>
    ),
  },
  paymentPlanWizard: {
    name: "Payment plan wizard",
    content: () => (
      <>
        When working out your pricing, ensure that the deposit and instalments add up to the total
        cost of your ticket. Keep an eye on the &apos;Total payment plan price&apos; section below
        to confirm the value of this ticket. For more information head to this article:{" "}
        <HelpCentreLink to={helpCentre.PAYMENT_PLAN_CREATE()}>
          {helpCentre.PAYMENT_PLAN_CREATE()}
        </HelpCentreLink>
      </>
    ),
  },
  orders: {
    name: "Orders",
    content: () => (
      <>
        A list of orders for this specific event. You can filter by order status or date range and
        search for the customers name or order ID.
      </>
    ),
  },
  guestlist: {
    name: "Guestlist",
    content: () => (
      <>
        A list of orders sent out for this event via the guestlist manager. You can filter by order
        status or date range and search for the customers name or order ID.
      </>
    ),
  },
  allOrders: {
    name: "All orders",
    content: () => (
      <>
        A list of all orders across all your events. You can filter by order status or date range
        and search for the customers name or order ID.
      </>
    ),
  },
  invites: {
    name: "Invites",
    content: () => (
      <>
        You have landed on this page as you have outstanding invites from organisation(s), which you
        need to either accept or reject before you can continue using the Promotion Centre.
      </>
    ),
  },
  changeEmail: {
    name: "Change email",
    content: () => (
      <>
        You can change the email address associated with your account. Be aware that this will
        change the email address you use to login.
        <br />
        <br />
        You will be sent an email upon submission of this form to the new email you have chosen,
        once you follow the instructions in this email, you will be able to log in to the Promotion
        Centre with your new email address.
      </>
    ),
  },
  flyerUpload: {
    name: "Flyer upload",
    content: () => (
      <>
        If you have an event flyer detailing more information about your event, please upload it
        here.
        <br />
        <br />
        There are no text restrictions for this image upload.
        <br />
        <br />
        Animated images are not permitted.
      </>
    ),
  },
  remittance: {
    name: "Remittance",
    content: () => (
      <>
        This takes into account your contract reserve and remittance delay, plus any orders within
        the Cool:Off window.
      </>
    ),
  },
  rapdiscan: {
    name: "Rapidscan",
    content: () => <>Scan dates/times are dependant upon the local time set on the device</>,
  },
  standardTicketTotal: {
    name: "Standard",
    content: () => (
      <>Tickets sold through Skiddle checkout. Skiddle takes payment for these sales.</>
    ),
  },
  paperTicketTotal: {
    name: "Paper",
    content: () => (
      <>
        Printed paper tickets created by Skiddle. These have a value which is printed on the ticket,
        but are usually sold in physical shops. Skiddle does not take payment for these sales.
      </>
    ),
  },
  boxOfficeTicketTotal: {
    name: "Box office",
    content: () => (
      <>
        Tickets sold via the RapidScan Box Office app. Sales will be paid for in person, by cash or
        by iZettle by customers at your event. Skiddle does not take payment for these sales.
      </>
    ),
  },
  repTicketTotal: {
    name: "Rep tickets",
    content: () => (
      <>
        Rep tickets are automatically generated when your Reps unlock rewards. These tickets allow
        the reps to claim the rewards they have earned through the Rep System.
      </>
    ),
  },
  guestlistTicketTotal: {
    name: "Guestlist",
    content: () => (
      <>
        Tickets issued through the Guestlist Manager on the Promotion Centre. Skiddle does not take
        payment for these sales as they are free.
      </>
    ),
  },
  eventsInThisSeries: {
    name: "Events in this series",
    content: () => (
      <>
        This is a list of the events in this Event Series, split into Upcoming and Past. Click a
        date to view the full dashboard for that individual event.
      </>
    ),
  },
  savedAndUnsavedEvents: {
    name: "Saved and Unsaved Dates",
    content: () => (
      <>
        Dates are not automatically saved once you add them to this calendar. You must progress to
        the next stage of the event creation, or press &apos;Save and exit&apos; to make sure your
        changes are saved. Dates filled in with colour are saved, and those with borders are
        unsaved.
      </>
    ),
  },
  addRepeatingSeries: {
    name: "Add repeating Dates",
    content: () => (
      <>
        You can generate a series of Dates automatically between a start and end date. This is
        useful when your dates are predictable, for example every Friday and Saturday, or on the
        first Tuesday of every month.
      </>
    ),
  },
  eventSeriesAllocation: {
    name: "Allocation per Date",
    content: () => (
      <>
        This column displays your allocation per ticket for each Date in the Event Series. The
        allocation can not be set lower than the amount of tickets of this type sold on any
        individual Date.
      </>
    ),
  },
  displaySettings: {
    name: "Display settings on Skiddle",
    content: (event: { listingType: string }) => (
      <>
        <Typography variant="h6" sx={{ mb: 1 }}>
          <strong>Visibility</strong>
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
          Your event can be public or private. If you choose to make your event private, only those
          with the link will be able to access it.
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          <strong>Status</strong>
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
          Publish your event to Skiddle by choosing &apos;live&apos;, or keep it in draft mode,
          where the event will not be visible on Skiddle.
        </Typography>
        {event &&
          event.listingType === PARENT &&
          isFeatureFlagOn(featureFlags.GROUP_RECURRING_EVENTS) && (
            <>
              <Typography variant="h6" sx={{ mb: 1 }}>
                <strong>Group Event Series</strong>
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Choose between grouping your event Dates on Skiddle, or keeping them as separate
                events:
              </Typography>
              <ul style={{ paddingLeft: "24px", marginTop: "6px" }}>
                <li>
                  <strong>Grouped:</strong> Your entire Event Series will be presented as a single
                  event in the Skiddle search, accompanied by a calendar that displays each
                  individual event date. Users can then select the specific date they are interested
                  in.
                </li>
                <li>
                  <strong>Ungrouped:</strong> Each individual date within the series will be listed
                  separately in the Skiddle search results and act as its own event
                </li>
              </ul>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Note: If your event is an Event Series that uses timed sessions it will always be
                grouped
              </Typography>
            </>
          )}
      </>
    ),
  },
  groupingRecurringEvents: {
    name: "Grouping Event Series",
    content: () => (
      <>
        <Typography variant="h6" sx={{ mb: 1 }}>
          <strong>Group Event Series</strong>
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Choose between grouping your events on Skiddle, or keeping them separate:
        </Typography>
        <ul style={{ paddingLeft: "24px", marginTop: "6px" }}>
          <li>
            <strong>Grouped:</strong> Your entire Event Series will be presented as a single event
            in the Skiddle search, accompanied by a calendar that displays each individual event
            date. Users can then select the specific date they are interested in.
          </li>
          <li>
            <strong>Ungrouped:</strong> Each individual date within the series will be listed
            separately in the Skiddle search results and act as its own event
          </li>
        </ul>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Note: If your event is an Event Series that uses timed sessions it will always be grouped
        </Typography>
      </>
    ),
  },
  addingDatesToRecurringEvent: {
    name: "Adding a Date to an Event Series",
    content: () => (
      <>
        <Typography sx={{ mt: 0, mb: 4 }}>
          When adding a Date, you&apos;ll be prompted to provide the following times
        </Typography>
        <ul style={{ paddingLeft: "24px", marginTop: "6px" }}>
          <li>
            <strong>
              Start time* <i>(required)</i> :
            </strong>{" "}
            Start time of the event, or the time of the first session if it&apos;s a session based
            event.
          </li>
          <li>
            <strong>
              Last entry <i>(optional)</i> :
            </strong>{" "}
            When the doors close and entry into an event is no longer permitted. If it&apos;s a
            session based event, this will be the latest that sessions can start.
          </li>
          <li>
            <strong>
              End time* <i>(required)</i> :
            </strong>{" "}
            When the event finishes.
          </li>
        </ul>
      </>
    ),
  },
  lastEntry: {
    name: "Last entry",
    content: () => (
      <>
        <Typography sx={{ mb: 4 }}>
          You don&apos;t currently have a Last entry time set for this date. If you set one you can
          control when the last session in a day should be.
        </Typography>
        <Typography>
          You will be able to change and remove individual sessions later using the manage sessions
          page.
        </Typography>
      </>
    ),
  },
  allocationSessionBased: {
    name: "Why can I not set an allocation on my ticket?",
    content: () => (
      <Typography variant="body1">
        Session based events do not have individual allocations per ticket, instead each session has
        a &apos;capacity&apos;. Session capacity can be changed on the{" "}
        <Link to="..">Manage Sessions page</Link>.
      </Typography>
    ),
  },
  addOnType: {
    name: "How can I control allocation on my session-based event?",
    content: () => addOnAllocation,
  },
  countTowardsCapacity: {
    name: "Should this ticket affect the session capacity?",
    content: (_: any, context: { countTowardsCapacity: boolean }) => (
      <>
        <Typography variant="body1" sx={{ mb: 3 }}>
          If you select <b>&apos;yes&apos;</b> for this option, all sales of this ticket will count
          towards the maximum capacity for their respective session.
        </Typography>

        <Divider sx={{ my: 3 }} />
        {context?.countTowardsCapacity && (
          <Typography variant="body1" sx={{ mb: 3 }}>
            Your current selection: <b>{context?.countTowardsCapacity}</b>
          </Typography>
        )}
      </>
    ),
  },
  singleOrRecurringEvent: {
    name: "Single event or Event Series",
    content: () => (
      <>
        <Typography>You can choose between a single event or an Event Series</Typography>
        <br />
        <Typography>
          All events in an Event Series share the same event details, including name, description,
          tickets, and pricing. Editing the Event Series will apply changes to all Dates in the
          series.
        </Typography>
      </>
    ),
  },
  defaultSessionCapacity: {
    name: "Default session capacity",
    content: () => (
      <Typography>
        This is the maximum capacity that your sessions will have by default. You can override this
        manually later on the Manage Sessions page for any and all sessions if you like.
      </Typography>
    ),
  },
  sessionsCalendar: {
    name: "Sessions calendar",
    content: (currentEvent: { eventType: string }) => (
      <>
        <Typography sx={{ mb: 3 }}>
          This is your <b>Sessions calendar</b>, you can manage and customise each session of your
          event individually here, as well as see a sales breakdown for each session or day.
        </Typography>
        {currentEvent?.eventType !== ONE_OFF && (
          <Typography sx={{ mb: 3 }}>
            You can navigate through the different days or weeks of your event using the navigation
            at the top of the calendar.
          </Typography>
        )}
        <Typography>
          Where they exist, black horizontal lines indicate the <i>start</i> and <i>end</i> of a
          days event. This can be modified using the 3 dots symbol next to a days date. Adding a
          session before or after an event starts is not currently possible, so to do this make sure
          to change the event days start or end times first.
        </Typography>
      </>
    ),
  },
  cancelEventReferCustomersToMe: {
    name: "Refer customers to me",
    content: () => (
      <>
        You will need to abide by Consumer Law at all times and process any refunds via Order Admin
        that you wish to action. Refusing to issue refunds may result in chargebacks which incur
        additional fees.
        <br />
        <br />
        Please see{" "}
        <HelpCentreLink to={helpCentre.REFUND_REQUEST()}>our knowledge base</HelpCentreLink> for
        more information.
      </>
    ),
  },
  addOnTicket: {
    name: "Add-on vs. regular tickets",
    content: () => (
      <>
        <Typography sx={{ mb: 3 }}>
          You can choose whether a regular ticket on a session based event will count towards the
          session capacity when sold. Allocation cannnot be set on a regular ticket as this is
          instead handled by <b>session capacity</b>.
        </Typography>
        <Typography sx={{ mb: 3 }}>
          Add-on tickets will appear on a separate stage of the checkout process on Skiddle.com,
          after regular tickets have been added to the basket. These tickets will <b>never</b>{" "}
          affect session capacity. Add-on tickets are useful for things like merchandise,
          programmes, drinks tokens etc.
        </Typography>
        {addOnAllocation}
      </>
    ),
  },
  welcomeToSbt: {
    name: "Creating tickets on a session-based event",
    content: () => (
      <>
        <Typography sx={{ mb: 3 }}>
          You can choose whether a <b>regular</b> ticket on a session based event will count towards
          the session capacity when sold. Allocation cannnot be set on a regular ticket as this is
          instead handled by <b>session capacity</b>.
        </Typography>
        <Typography sx={{ mb: 3 }}>
          <b>Add-on tickets</b> will appear on a separate stage of the checkout process on
          Skiddle.com, after regular tickets have been added to the basket. These tickets will{" "}
          <b>never</b> affect session capacity. Add-on tickets are useful for things like
          merchandise, programmes, drinks tokens etc.
        </Typography>
      </>
    ),
  },
};

export default helpTips;
