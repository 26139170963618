/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const requestCacheSlice = createSlice({
  name: "requestCache",
  initialState: {
    requests: [],
  },
  reducers: {
    setRequests: (state, action) => {
      if (action.payload === "RESET") {
        state.requests = [];
      }
      if (action.payload.requests) {
        state.requests = action.payload.requests;
      }
    },
  },
});

export const { setRequests } = requestCacheSlice.actions;

export default requestCacheSlice.reducer;
