import * as Yup from "yup";

const ticketName = {
  name: "ticketName",
  id: "ticketName",
  displayName: () => "Ticket name",
  sendToApi: () => true,
  defaultValue: "",
  validation: () =>
    Yup.string()
      .trim()
      .when("skipTickets", {
        is: "no",
        then: schema => schema.required("Ticket name is required"),
        otherwise: schema => schema,
      })
      .min(3, "Ticket name must be at least 3 characters")
      .max(65, "Ticket name can only be 65 characters"),
  inputProps: {
    minLength: 3,
    maxLength: 65,
  },
};

export default ticketName;
