import { LOGGED_IN_AS_PROMOTER_ID } from "../../Constants/auth";
import Store from "../../Store";
import { removePromoter, userLoggedIn } from "../../Store/reducers/AuthReducer";
import date from "../date";
import { error } from "../toast";
import getUserSession from "./getUserSession";

const checkIsExpired = async (promoterId, name, navigate) => {
  const {
    auth: {
      promoter: { expires },
    },
  } = Store.getState();

  if (expires && date(expires).isBefore(date(), "milliseconds")) {
    // Have expired
    localStorage.removeItem(LOGGED_IN_AS_PROMOTER_ID);
    removePromoter(promoterId);
    const newUser = await getUserSession();
    Store.dispatch(userLoggedIn(newUser));
    error(`Your login as '${name}' has expired`);
    if (navigate) {
      return navigate("/");
    }

    return null;
  }

  return null;
};

export default checkIsExpired;
