declare global {
  interface Window {
    lintrk: (_name: string, _opts: object) => void;
    fbq: (_name: string, _type: string) => void;
    gtag: (_name: string, _type: string, _opts: object) => void;
  }
}

export const trackAccountCreation = () => {
  window.lintrk("track", { conversion_id: 10160730 });
  window.fbq("track", "CompleteRegistration");
  window.fbq("track", "Lead");
  window.gtag("event", "conversion", { send_to: "AW-926715938/xIpuCNa974kCEKKg8rkD" });
};

export const trackEventCreation = () => {
  window.lintrk("track", { conversion_id: 10860074 });
  window.fbq("trackCustom", "createEvent");
  window.gtag("event", "conversion", { send_to: "AW-926715938/YuwlCL_2v4cYEKKg8rkD" });
};

export const trackTicketCreation = () => {
  window.fbq("trackCustom", "createTicket");
  window.gtag("event", "conversion", { send_to: "AW-926715938/churCKjNyq4ZEKKg8rkD'" });
};
