import React from "react";
import PropTypes from "prop-types";
import { Alert, Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import date from "./date";

const ERROR_TIMEOUT = 10000;
const SUCCESS_TIMEOUT = 5000;
const WARNING_TIMEOUT = 10000;

const CustomToast = ({ Icon, severity, message }) => {
  let colour = "#e74c3c";
  if (severity === "warning") {
    colour = "#f1c40f";
  }

  if (severity === "success") {
    colour = "#07bc0c";
  }

  return (
    <Alert
      severity={severity}
      icon={false}
      variant="outlined"
      sx={{
        border: "none",
        height: "calc(100% - 5px)",
        marginBottom: "5px",
        padding: 0,
        display: "flex",
        alignItems: "center",
        borderTopLeftRadius: "5px",
      }}
    >
      <Box display="flex" alignItems="center" padding="0" height="100%">
        <Box
          bgcolor={colour}
          color="white"
          display="flex"
          alignItems="center"
          height="100%"
          padding="5px 10px 0 10px"
        >
          <Icon color="#fff" />
        </Box>
        <Box padding="5px 0 0 10px">{message}</Box>
      </Box>
    </Alert>
  );
};
CustomToast.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.object])
    .isRequired,
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export const dismissToast = (type = null) => {
  if (!type) {
    toast.dismiss();
  } else {
    toast.dismiss(type);
  }
};

export const error = (message, id) => {
  dismissToast();
  toast.error(<CustomToast Icon={ErrorOutlineIcon} severity="error" message={message} />, {
    autoClose: ERROR_TIMEOUT,
    toastId: id || `error-${date().format("hh:mm:ss")}`,
    delay: 250,
    icon: false,
  });
};

export const success = message => {
  dismissToast();
  toast.success(
    <CustomToast Icon={CheckCircleOutlineIcon} severity="success" message={message} />,
    {
      autoClose: SUCCESS_TIMEOUT,
      toastId: `success-${date().format("hh:mm:ss")}`,
      delay: 250,
      icon: false,
    },
  );
};

export const warning = (message, configObj) => {
  const toastId = `warning-${date().format("hh:mm:ss")}`;
  const config = {
    autoClose: WARNING_TIMEOUT,
    toastId,
    delay: 250,
    icon: false,
    ...configObj,
  };
  dismissToast();
  toast.warning(
    <CustomToast Icon={WarningAmberIcon} severity="warning" message={message} />,
    config,
  );

  return config;
};

export const updateToast = (id, configObj, newContent) => {
  toast.update(id, {
    ...configObj,
    render: <CustomToast Icon={WarningAmberIcon} severity="warning" message={newContent} />,
  });
};
