import get from "lodash/get";
import useAuth from "./useAuth";
import Store from "../../../../Store";

export const getMainPromoter = () => {
  const { auth } = Store.getState();
  if (
    auth &&
    get(auth, "userData.mainPromoterId") &&
    get(auth, "userData.promoters") &&
    Object.keys(auth.userData.promoters).length &&
    auth.userData.promoters[auth.userData.mainPromoterId]
  ) {
    return {
      ...auth.userData.promoters[auth.userData.mainPromoterId],
      promoterId: auth.userData.mainPromoterId,
    };
  }

  return {};
};

export default function useAuthMainPromoter() {
  const auth = useAuth();

  if (
    auth &&
    get(auth, "userData.mainPromoterId") &&
    get(auth, "userData.promoters") &&
    Object.keys(auth.userData.promoters).length &&
    auth.userData.promoters[auth.userData.mainPromoterId]
  ) {
    return {
      ...auth.userData.promoters[auth.userData.mainPromoterId],
      promoterId: auth.userData.mainPromoterId,
    };
  }
  return null;
}
