import { AuthSession, fetchAuthSession } from "aws-amplify/auth";

export default async function getIdToken(): Promise<string> {
  const session: AuthSession = await fetchAuthSession();

  if (!session.tokens?.idToken) {
    return "";
  }

  return session.tokens.idToken.toString();
}
