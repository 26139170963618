import { getMainPromoter } from "../Components/NamedComponents/Auth/hooks/useAuthMainPromoter";
import { ABORTED, ERRORED, FETCHED, FETCHING, NETWORK_ERR } from "../Constants/requestStatuses";
import handleMock from "../Hooks/useHttpRequest/handleMock";
import prepareRequest from "../Hooks/useHttpRequest/prepareRequest";
import { constructAdminPanelObject } from "../Hooks/useHttpRequest/utils";
import Store from "../Store";
import addOrUpdateAdminPanelRequest from "./AdminPanel/addOrUpdateAdminPanelRequest";
import date from "./date";
import buildQueryString from "../Hooks/useHttpRequest/buildQueryString";
import buildHeaders from "../Hooks/useHttpRequest/buildHeaders";
import makeRequest from "../Hooks/useHttpRequest/makeRequest";
import formatRequestResponse from "../Hooks/useHttpRequest/formatRequestResponse";
import { handleError } from "./handleApiErrors";
import getIdToken from "./Auth/getIdToken";

const httpRequestAsync = async options => {
  const token = await getIdToken();
  const controller = new AbortController();
  const mainPromoter = getMainPromoter();

  const {
    queryStringParams = null,
    body = {},
    method = "GET",
    structureData = null,
    shouldExecute = true,
    showGlobalError = true,
    endpoint = undefined,
    authenticated = false,
    cacheBust = false,
    showRawErrorMessage = false,
    mockData = null,
    mockStatus = 200,
    mockError = null,
    fetchAll,
  } = options;

  const {
    promoter: { promoterId },
  } = Store.getState().auth;

  const { uuid, startTime, timeout, isMock, url } = prepareRequest(options);

  const adminPanelObject = constructAdminPanelObject(
    method,
    url,
    timeout,
    endpoint,
    shouldExecute,
    body,
    {},
  );

  if (mainPromoter?.skiddleStaff) {
    addOrUpdateAdminPanelRequest(uuid, {
      ...adminPanelObject,
      params: {},
      queryStringParams,
      headers: {},
      date: date(),
      requestId: uuid,
      startTime,
      endTime: null,
      requestStatus: FETCHING,
    });
  }

  if (isMock) {
    handleMock(
      {
        showGlobalError,
        showRawErrorMessage,
        mockError,
        mockStatus,
        fetchAll,
        structureData,
        requestId: uuid,
      },
      null,
      null,
      null,
      mockData,
      null,
      "",
    );
    return {};
  }

  const queryString = buildQueryString(
    queryStringParams,
    {},
    authenticated,
    promoterId,
    cacheBust,
    fetchAll,
    method,
  );

  const { headersToLog, headers } = buildHeaders(token, authenticated, method);

  try {
    const { res, meta, links, axiosResponse } = await makeRequest(
      method,
      url,
      headers,
      queryString,
      timeout,
      body,
      { controller, endpoint, structureData },
    );

    const pay = formatRequestResponse(res, meta, links, null, axiosResponse?.status);

    if (Object.keys(pay).length && mainPromoter?.skiddleStaff) {
      addOrUpdateAdminPanelRequest(uuid, {
        ...pay,
        ...adminPanelObject,
        queryStringParams: queryString,
        headers: { ...headersToLog, Authorization: `Bearer ${token}` },
        date: date(),
        startTime,
        endTime: date(),
        requestStatus: FETCHED,
      });
    }

    return {
      ...pay,
      status: FETCHED,
      data: pay.res,
      meta: pay.meta,
      retries: 0,
      retryFn: () => null,
      httpStatus: pay.status,
      allData: pay?.allData || [],
    };
  } catch (e) {
    if (e?.code === "ERR_NETWORK") {
      addOrUpdateAdminPanelRequest(uuid, {
        ...adminPanelObject,
        params: queryString,
        headers: { ...headersToLog, Authorization: `Bearer ${token}` },
        date: date(),
        startTime,
        endTime: date(),
        key: "",
        requestStatus: NETWORK_ERR,
      });

      await handleError(e, {
        request: {
          ...adminPanelObject,
          params: queryString,
          headers: { ...headersToLog, Authorization: `Bearer ${token}` },
          date: date(),
          startTime,
          endTime: date(),
          key: "",
          requestStatus: NETWORK_ERR,
        },
        showGlobalError,
        showRawErrorMessage,
        redirectOn403: false,
        requestId: uuid,
        timeout,
        authenticated,
      });
      return null;
    }

    if (e?.message === "canceled") {
      addOrUpdateAdminPanelRequest(uuid, {
        ...adminPanelObject,
        params: queryString,
        headers: { ...headersToLog, Authorization: `Bearer ${token}` },
        date: date(),
        startTime,
        endTime: date(),
        key: "",
        requestStatus: ABORTED,
      });
      return null;
    }

    const err = await handleError(e, {
      showGlobalError,
      showRawErrorMessage,
      redirectOn403: false,
      requestId: uuid,
      timeout,
      authenticated,
      request: {
        ...adminPanelObject,
        params: queryString,
        headers: { ...headersToLog, Authorization: `Bearer ${token}` },
        date: date(),
        startTime,
        endTime: date(),
        key: "",
      },
    });

    const reduxErr = { ...err };
    delete reduxErr.htmlError;
    delete reduxErr.request;
    delete reduxErr.config;
    delete reduxErr.message;

    if (mainPromoter?.skiddleStaff) {
      let { status } = err;
      if (e?.response?.status) {
        status = e?.response?.status;
      }
      addOrUpdateAdminPanelRequest(uuid, {
        ...adminPanelObject,
        queryStringParams: queryString,
        headers: { ...headersToLog, Authorization: `Bearer ${token}` },
        timestamp: date(),
        date: date(),
        status,
        error: { ...reduxErr, response: { ...e?.response?.data } },
        response: { ...e?.response?.data },
        startTime,
        endTime: date(),
        key: "",
        requestStatus: ERRORED,
      });
    }

    if (showRawErrorMessage && showRawErrorMessage()) {
      throw err.errData;
    }

    throw new Error(e);
  }
};

export default httpRequestAsync;
