import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router";
import { confirmSignUp as authConfirmSignUp } from "aws-amplify/auth";
import Helmet from "react-helmet";

import { Box, Typography, Alert } from "@mui/material";

import Link from "../../../Reusable/Link";

const ConfirmSignUp = () => {
  const { search } = useLocation();
  const { code, username } = qs.parse(search);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(!code && !username ? "Invalid parameters" : "");
  const [success, setSuccess] = useState(false);

  const confirmSignUp = async () => {
    try {
      await authConfirmSignUp({
        username,
        confirmationCode: code,
        options: {
          clientMetadata: { userAgent: window?.navigator?.userAgent || "" },
        },
      });
      setSuccess(true);
      setLoading(false);

      setTimeout(() => {
        navigate("/auth/login");
      }, 3000);
    } catch (e) {
      setLoading(false);
      setError(e?.message || "There was an issue confirming your account, please try again later.");
    }
  };

  useEffect(() => {
    if (code && username) {
      confirmSignUp();
    }
  }, [code, username]);

  return (
    <>
      <Helmet title="Confirm Sign Up" />
      {loading && (
        <Box
          sx={{
            my: 3,
          }}
        >
          <Typography variant="h6">Attempting to confirm your account...</Typography>
        </Box>
      )}
      {error && !loading && (
        <Box
          sx={{
            my: 3,
          }}
        >
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      {success && (
        <Box
          sx={{
            my: 3,
          }}
        >
          <Alert severity="success">
            Your account has been succcessfully confirmed. You will now be redirected, if you are
            not redirected automatically, please click <Link to="/auth/login">here</Link>
          </Alert>
        </Box>
      )}
    </>
  );
};

export default ConfirmSignUp;
