import React, { useContext, useState } from "react";
import { styled } from "@mui/material";
import PropTypes from "prop-types";
import qs from "query-string";

import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { match, pathToRegexp } from "path-to-regexp";
import { AppContext } from "../../../Context";
import RecurringEventRedirectModal from "../../Reusable/RecurringEventRedirectModal";
import ButtonAsLink from "../../Reusable/ButtonAsLink";

const DisabledLink = styled(RouterLink)`
  pointer-events: none;
  opacity: 0.5;
`;

const buildRegexString = splitPath => {
  let string = `${splitPath[0]}`;

  if (splitPath[1]) {
    string += `\\?${splitPath[1]}`;
  }
  return string;
};

export const isActive = (
  path,
  route,
  location,
  activeOverride,
  activeStateFor = [],
  params = {},
  needsMatch = true,
) => {
  let active = false;
  let regexp;

  if (path.substring(0, 4) === "http") {
    return false;
  }

  if (path) {
    const p = path.split("?");
    ({ regexp } = pathToRegexp(buildRegexString(p)));
  }

  if (route) {
    const p = route.split("?");
    ({ regexp } = pathToRegexp(buildRegexString(p)));
  }

  if (
    regexp &&
    (regexp.exec(`${location.pathname}${location.search}`) || regexp.exec(location.pathname))
  ) {
    active = true;
  }

  if (activeStateFor.length) {
    activeStateFor.forEach(alternateRoute => {
      const p = alternateRoute.split("?");
      ({ regexp } = pathToRegexp(buildRegexString(p)));

      if (
        regexp.exec(location.pathname) ||
        regexp.exec(`${location.pathname}${location?.search || ""}`)
      ) {
        active = true;
      }
    });
  }

  // If we need even more specific logic
  // This takes precedence
  if (activeOverride) {
    const p = route.split("?");
    const string = buildRegexString(p);
    const m = match(string, {
      decode: decodeURIComponent,
    });

    const doesMatch = m(`${location.pathname}${location.search}`);

    if (!needsMatch) {
      return activeOverride(params, location);
    }

    if (doesMatch && doesMatch.params) {
      active = activeOverride(doesMatch.params, location);
    }
  }

  return active;
};

const NavLink = React.forwardRef(
  (
    {
      path,
      route,
      activeOverride,
      activeStateFor,
      className,
      onClose,
      to,
      useAnchor = false,
      needsMatch,
      ...props
    },
    ref,
  ) => {
    const { currentEvent } = useContext(AppContext);

    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const params = useParams();
    const qsValues = qs.parse(location.search);
    const isExternal = to?.substring(0, 4) === "http";
    const rest = { ...props };
    delete rest.exact;
    delete rest.activeClassName;
    delete rest.isDisabled;

    const disabled = props.isDisabled ? props.isDisabled() : false;

    if (disabled) {
      return (
        <DisabledLink
          ref={ref}
          to={to}
          className={`${className} ${
            isActive(
              path,
              route,
              location,
              activeOverride,
              activeStateFor,
              { ...params, ...qsValues },
              needsMatch,
            )
              ? "active"
              : ""
          }`}
          onClick={() => (onClose ? onClose() : null)}
          {...rest}
          disabled={disabled}
          aria-disabled={disabled}
        />
      );
    }

    if (rest.modalOverride) {
      return rest.modalOverride(
        currentEvent,
        <ButtonAsLink
          ref={ref}
          className={`${className} ${
            isActive(
              path,
              route,
              location,
              activeOverride,
              activeStateFor,
              { ...params, ...qsValues },
              needsMatch,
            )
              ? "active"
              : ""
          }`}
          {...rest}
          onClick={e => {
            e.preventDefault();
          }}
          disabled={disabled}
          aria-disabled={disabled}
        />,
      );
    }

    if (rest.modal) {
      const {
        header,
        content,
        actions: { primary, secondary },
        shouldShow,
      } = rest.modal(currentEvent);

      if (shouldShow) {
        return (
          <>
            <RouterLink
              ref={ref}
              to={to}
              className={`${className} ${
                isActive(
                  path,
                  route,
                  location,
                  activeOverride,
                  activeStateFor,
                  { ...params, ...qsValues },
                  needsMatch,
                )
                  ? "active"
                  : ""
              }`}
              {...rest}
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}
              disabled={disabled}
              aria-disabled={disabled}
            />
            <RecurringEventRedirectModal
              header={header}
              secondary={secondary}
              primary={primary}
              showModal={showModal}
              setShowModal={setShowModal}
              content={content}
            />
          </>
        );
      }
    }

    if (isExternal) {
      return (
        <a
          ref={ref}
          href={to}
          rel="noreferrer"
          target="_blank"
          className={className}
          onClick={() => (onClose ? onClose() : null)}
          {...rest}
          disabled={disabled}
          aria-disabled={disabled}
        >
          {rest.children}
        </a>
      );
    }

    if (useAnchor) {
      return (
        <a
          ref={ref}
          href={to}
          className={`${className} ${
            isActive(
              path,
              route,
              location,
              activeOverride,
              activeStateFor,
              { ...params, ...qsValues },
              needsMatch,
            )
              ? "active"
              : ""
          }`}
          onClick={() => (onClose ? onClose() : null)}
          {...rest}
          disabled={disabled}
          aria-disabled={disabled}
        >
          {rest.children}
        </a>
      );
    }

    return (
      <RouterLink
        ref={ref}
        to={to}
        className={`${className} ${
          isActive(
            path,
            route,
            location,
            activeOverride,
            activeStateFor,
            { ...params, ...qsValues },
            needsMatch,
          )
            ? "active"
            : ""
        }`}
        onClick={() => (onClose ? onClose() : null)}
        {...rest}
        disabled={disabled}
        aria-disabled={disabled}
      />
    );
  },
);

NavLink.propTypes = {
  activeOverride: PropTypes.func,
  needsMatch: PropTypes.bool,
  className: PropTypes.string.isRequired,
  route: PropTypes.string,
  path: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  to: PropTypes.string.isRequired,
  isDisabled: PropTypes.func,
  useAnchor: PropTypes.bool,
  activeStateFor: PropTypes.array,
  intercom: PropTypes.bool,
};

export default NavLink;
