import React from "react";
import { Box } from "@mui/material";

import Help from "../../../../Reusable/Help";
import helpTips from "../../../../../Constants/helpTips";
import { isAddon } from "../../../../../Utils/ticketHelpers";
import { isSessionEvent } from "../../../../Layout/Navigation/helpers";
import lazy from "../../../../../Utils/lazy";

const InputRadio = lazy(
  () => import(/* webpackChunkName: "InputRadio"  */ "../../../../Reusable/Atoms/InputRadio"),
);

const affectsSessionCapacity = {
  name: "affectsSessionCapacity",
  displayName: (_, __, form) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        Should this ticket affect the session capacity?
        <Help tip={helpTips.countTowardsCapacity} contentContext={form.values} />
      </Box>
    );
  },
  type: InputRadio,
  options: [
    { value: "no", label: "No", grid: { md: 6 } },
    { value: "yes", label: "Yes", grid: { md: 6 } },
  ],
  defaultValue: (event, _, qsValues, ticket) => (isAddon(qsValues, ticket) ? "no" : "yes"),
  postFormat: val => (val === "yes" ? 1 : 0),
  sendToApi: () => true,
  hidden: (formValues, { qsValues, event }) =>
    isAddon(qsValues, formValues) || !isSessionEvent(event),
};

export default affectsSessionCapacity;
